import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countries: [],
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setCountries: (state, action) => {
      return {
        ...state,
        countries: action.payload,
      }
    }
  }
});


export const { setCountries } = countriesSlice.actions;
export const countriesReducer = countriesSlice.reducer;
import stylesGeneral from './pravila.module.css'
import styles from './rules.module.css'
import { useEffect } from 'react';

export function RulesVerifacationPer() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={stylesGeneral.wrapper}>
      <div className="inner">
        <div className={styles.rules__body}>
          <div class="wrapper">
            <div class="container">
              <div className={`${styles.rules__wrapper}`}>
                <h1 className={`${styles.rules__title}`}>قوانین اعتبار سنجی حساب کاربری               </h1>
                <h2 className={`${styles.items__title}`}> قوانین کلی برای ثبت کردن مشخصات خود : </h2>
                <div>
                  <p className={`${styles.item}`}>با ثبت نام در سایت ما و همچین با کمک افتتاح حساب کاربری در سایت IRISTRANS.COM  می  توانید به اطلاعات مربوطه دسترسی داشته باشید.
                  </p>
                  <p className={`${styles.item}`}>عد از چک کردن حساب و تایید از حساب کاربری و اعتبار سنجی شخص حقیقی و حقوقی می توانید وارد سایت ما شوید.
                  </p>
                  <p className={`${styles.item}`}>تایید حساب کاریری رایگان می باشد. </p>
                  <p className={`${styles.item}`}>جهت کسب اطلاعات بیشتر در مورد سایت و حساب کاربری می توانید از طریق ایمیل به info@iristrans.com  با پشتیبانی سایت تماس حاصل فرمایید .
                  </p>
                  <p className={`${styles.item}`}>گر به هر دلیلی ضمن احراز هویت  و تایید پروفایل، مایلید از اعطای وضعیت موفقیت آمیز تایید پروفایل به حساب خود خودداری کنید، لطفاً از طریق ارسال پیام به info@iristrans.com به ما اطلاع دهید.
                  </p>
                  <p className={`${styles.item}`}>گر جزئیات پرداخت شما به دلایلی با احراز هویت یا با داده‌های حسابتان مطابقت ندارد، یا اگر هنوز فرصتی برای ارزیابی مزایای خدمات پولی IrisTrans نداشته‌اید، باز هم از این فرصت را برای انجام تأیید صحت نمایه با ارسال پیام به ما را خواهید داشت. برخی از اسناد تکمیل موفقیت‌آمیز تأیید نمایه شما را از مسئولیت یکپارچگی حساب خود که پیش‌فرض برای همه کاربران است، سلب نمی‌کند. تعهدات متناظر کاربر و عواقب احتمالی ثبت نام و/یا استفاده از یک حساب کاربری غیر معتبر در بخش 4 متن اصلی قرارداد کاربر توضیح داده شده است.
                  </p>
                  <p className={`${styles.item}`}>ما هیچ محدودیت خاصی برای تاریخ ها و/یا دوره های مرتبط با اعتبار وضعیت تأیید نمایه شما قائل نمی شویم.
                  </p>
                  <p className={`${styles.item}`}>ین مسئولیت شماست که پس از گذشت مدتی از تأیید نمایه، حساب شما به روز باشد. شما حق دارید داده های خود را در هر زمان با تکمیل مراحل تأیید نمایه به هر طریقی که برای شما مناسب است، همانطور که در زیر توضیح داده شده است، به روز کنید.
                  </p>
                  <p className={`${styles.item}`}>ا این حق را برای خود محفوظ می داریم که صحت داده های مرتبط با حساب شما، به ویژه اعتبار ثبت شرکت شما، و همچنین مطابقت نام و ثبت نام (شناسایی، کد مالیاتی) (شماره) شما را بررسی کنیم. شرکت می تواند با منابع رسمی موجود، و در صورت شناسایی ناسازگاری، از بررسی ثبت نام شما امتناع کند یا متعاقباً وضعیت شما را لغو کند.
                  </p>
                  <p className={`${styles.item}`}>دلایل امتناع از ارائه و دلایل لغو وضعیت تکمیل موفقیت آمیز تأیید پروفایل عبارتند از (با در نظر گرفتن این واقعیت که تخلفات مربوطه نیز زمینه ای برای مسدود کردن حساب است):
                  </p>

                  <p className={`${styles.item}`}>1. غیرقابل اعتماد بودن داده های موجود در حساب مربوط به تأیید نمایه که در هر زمان و با هر وسیله قانونی شناسایی شده است.
                  </p>
                  <p className={`${styles.item}`}>2. نقض قوانین اساسی برای استفاده از حساب مندرج در قرارداد کاربر.</p>
                  <p className={`${styles.item}`}>أیید نمایه مستلزم آن است که اطلاعات حساب شما که توسط ما تأیید شده باشد، تمامی مراحل تایید کاربر بر عهده پشتیبانی سایت بوده و  ما بنا به صلاحدید خود این حق را محفوظ می داریم:
                  </p>
                  <p className={`${styles.item}`}>1. ایجاد یک حالت پیش فرض برای تغییرات داده های مربوط به شرکت در حساب اعمال شده برای تأیید نمایه و/یا با وضعیت تأیید صحت نمایه با موفقیت انجام شده (شما از تغییر مستقل مقادیر فیلدهای مربوطه مسدود خواهید شد. داده های ثبت نام)؛ یا در صورت ایجاد تغییرات، و عدم مشخص کردن وضعیت ، تأیید نمایه در صورت موفق بودن به طور خودکار حذف خواهد شد.</p>
                  <p className={`${styles.item}`}>ما این حق را برای خود محفوظ می داریم که بنا به صلاحدید خود، در مورد تضمین های صحت ارائه شده توسط مکانیسم های تأیید حساب خاص، برای سایر کاربران رزرو (مطمئن ) نجام دهیم.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
export default RulesVerifacationPer;
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import styles from './chat-room.module.css';
import close from './image/close.svg';
import { closeChatRoom } from '../../../redux/reducers/chat-reducer';
import { createMessage, changeMessageStatus } from '../../../http/messageAPI';
import { saveCountUnreadMessages, saveCountUnreadDialogs } from '../../../redux/reducers/unread-reducer';
import { saveMessages } from '../../../redux/reducers/messages-reducer';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveCurrentSelectedUserDialog } from '../../../redux/reducers/current-selected-user-reducer';
import { saveMyDialogs } from '../../../redux/reducers/dialogs-reducer';
import io from 'socket.io-client';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { BaseURL } from "../../../http";

const socketIO = io('', { secure: true, transports: ['websocket'] });
// const socketIO = io(`${BaseURL}`);


export function ChatRoom({ username }) {
  const { t } = useTranslation();
  const currentLanguage = useSelector(state => state.language.language);
  const dialogId = useSelector(state => state.chatIsOpen.chatRoomId);
  const dialogData = useSelector(state => state.chatIsOpen);
  const chatRoomUserName = useSelector(state => state.chatIsOpen.chatRoomUserName);
  const chatRoomUserId = useSelector(state => state.chatIsOpen.chatRoomUserId);
  const userData = useSelector(state => state.userData.userData);
  const userMessages = useSelector(state => state.userMessages.userMessages);
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [messagesArch, setMessagesArch] = useState([]);
  const [asd, setasd] = useState(null);
  const [inputValue, setInputValue] = useState('');
  async function getAllMessages() {
    const response = await axios.get(`${BaseURL}/api/message/dialog/${dialogId}`)
    dispatch(saveMessages(response.data));
  }

  async function changeMessageStatusToRead() {
    let message = await changeMessageStatus({ dialogId, partnerId: userData.id });
    await getNewMessages()
  }

  let userId = userData.id;
  async function getNewMessages() {
    try {
      const unreadMessages = await axios.get(`${BaseURL}/api/message/unread/${userId}`)
      dispatch(saveCountUnreadMessages(unreadMessages.data.length));
      if (unreadMessages.data.length > 0) {
        let newMessagesData = [];
        unreadMessages.data.forEach(el => {
          newMessagesData.push(el.dialogId)
        })
        const res = newMessagesData.reduce((acc, i) => {
          if (acc.hasOwnProperty(i)) {
            acc[i] += 1;
          } else {
            acc[i] = 1;
          }
          return acc;
        }, {})
        let countOfDialogs = res;

        dispatch(saveCountUnreadDialogs(countOfDialogs));
      } else if (unreadMessages.data.length === 0) {
        dispatch(saveCountUnreadDialogs({}));
      }
    }
    catch (e) {
      console.log(e.response?.data?.message)
    }
  }

  useEffect(() => {
    changeMessageStatusToRead()
    getNewMessages()
  }, []);

  useEffect(() => {



    socketIO.on("connect", (...params) => {
      // console.log(params, "params");
    });



    socketIO.on('response', (message) => {
      // console.log(message, "message");
      setMessages((prevMessages) => [...prevMessages, message]);
    });
    return () => {
      socketIO.off('response');
    };
  }, [])

  const messageEl = useRef(null);

  useEffect(() => {
    if (messageEl) {
      messageEl.current.scrollIntoView({ behavior: 'instant', block: "end" });
    }
  }, [])

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = async () => {
    if (inputValue.trim() !== '' && username) {
      const newMessage = {
        text: inputValue,
        sender: username,
        socketId: socketIO.id,
        partner: chatRoomUserId,
        dialogId: dialogId,
      };
      setInputValue('');
      let createdMessage = await createMessage({ ...newMessage, senderId: userData.id, dialogId: dialogId, partnerId: chatRoomUserId })
      // console.log("createdMessage", createdMessage)
      socketIO.emit('response', newMessage);
    }
  };

  async function getMyDialogs() {
    try {
      const response = await axios.get(`${BaseURL}/api/dialog/${userId}`)
      dispatch(saveMyDialogs(response.data));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }

  function clearCurrentUserDialog() {
    dispatch(saveCurrentSelectedUserDialog(false));
    dispatch(closeChatRoom());
    getMyDialogs();
  }

  return (
    <div className={styles.chat} id="chat">
      <div className={styles.chat_header}>
        {chatRoomUserName}
        <img className={styles.chat_header_close} src={close} alt="close" onClick={() => clearCurrentUserDialog()} />
      </div>
      <div className={currentLanguage === 'per' ? `${styles.chatbody__pers} ${styles.chatbody}` : `${styles.chatbody} `} ref={messageEl}>
        {(userMessages.length > 0) && userMessages.map((message, index) => {
          if (message.dialogId === dialogId) {
            return (
              <>
                {currentLanguage === "per" ?
                  <div key={index + message.dialogId + 'b'} className={styles.chat_message_text}>
                    {message.text} <strong>:{message.senderId === userData.id ? userData.name : chatRoomUserName}</strong>
                  </div>
                  :
                  <div key={index + message.dialogId + 'b'} className={styles.chat_message_text}>
                    <strong>{message.senderId === userData.id ? userData.name : chatRoomUserName}:</strong> {message.text}
                  </div>
                }
              </>
            )
          }
          return ""
        })}
        {messages.length > 0 && messages.map((message, index) => {
          if (message.dialogId === dialogId) {
            return (
              <>
                {currentLanguage === "per" ?
                  <div key={index + message.dialogId + 'm'} className={styles.chat_message_text}>
                    {message.text} <strong key={index + message.dialogId + 's'}> :{message.sender}</strong>
                  </div>
                  : <div key={index + message.dialogId + 'm'} className={styles.chat_message_text}>
                    <strong key={index + message.dialogId + 's'}>{message.sender}:</strong> {message.text}
                  </div>
                }
                {messageEl.current.scrollIntoView({ behavior: 'smooth', block: "end" })}
              </>
            )
          }
          return ""
        })}
      </div>
      <div className={styles.chat_message_block} >
        <input className={currentLanguage === 'per' ? `${styles.chat_input__pers} ${styles.chat_input}` : `${styles.chat_input} `} type="text" placeholder={t('placeholder.chat.message')} value={inputValue} onChange={handleInputChange} onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSendMessage();
          }
        }} />
        <button className={styles.chat_button} type="submit" onClick={handleSendMessage}>{t('butttons.sendText')}</button>
      </div>
    </div>
  )
}

export default ChatRoom;

import styles from './user-management.module.css';
import user from './images/user.png'
import parcel from './images/parcel.svg'
import truck from './images/truck.svg'
import balance from './images/balance.svg'
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openSelectedPage } from '../../redux/reducers/selected-page-reducer';
import { logoutUserData } from '../../redux/reducers/user-data-reducer';
import { saveFoundCargo } from '../../redux/reducers/cargo-search-reducer';
import { saveCurrentImg } from '../../redux/reducers/current-img-reducer';
import { saveCurrentID } from '../../redux/reducers/current-id-reducer';
import AuthService from '../../services/AuthService';
import { useTranslation } from "react-i18next";
import useOnClickOutside from '../../hooks/onClickOutside';
import { getAllUsersAdmin } from '../../http/userAPI';
import { setUsers, setUsersInvoice } from '../../redux/reducers/users-list-reducer';
import axios from "axios";
import { BaseURL } from "../../http";

export function UserManagement() {
  const userMenu = useRef();
  const transportMenu = useRef();
  const cargoMenu = useRef();


  async function getAllUsers() {
    try {
      const response = await getAllUsersAdmin();
      const resp = response.data.rows.reverse();
      dispatch(setUsers(resp));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }

  async function getAllUsersInvoice() {
    try {
      const response = await axios.get(`${BaseURL}/api/user/invoice-list`)
      const resp = response.data.reverse();
      dispatch(setUsersInvoice(resp));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }



  //Translation
  const { t } = useTranslation();
  const currentLanguage = useSelector(state => state.language.language);
  let stylesMenu = currentLanguage === 'per' ? `${styles.menu__pers} ${styles.menu}` : `${styles.menu} `;
  const navigate = useNavigate();

  const userData = useSelector(state => state.userData.userData);
  const [isItem1MenuOpen, setIsItem1MenuOpen] = useState(false);
  const [isItem2MenuOpen, setIsItem2MenuOpen] = useState(false);
  const [isItem3MenuOpen, setIsItem3MenuOpen] = useState(false);


  function openMenuUser() {
    setIsItem1MenuOpen(!isItem1MenuOpen);
  }
  useEffect(() => {
   }, [isItem1MenuOpen]);

  function openMenuBalance() {
    setIsItem1MenuOpen(false);
    setIsItem2MenuOpen(false);
    setIsItem3MenuOpen(false);
  }

  const dispatch = useDispatch();

  function goToMyPage() {
    openMenuBalance()
    dispatch(openSelectedPage("mypage"))
  }

  function goToUsersPage() {
    getAllUsers()
    openMenuBalance()
    dispatch(openSelectedPage("userspage"))
    dispatch(saveCurrentID(""))
  }
  function goToUsersInvoicePage() {
    getAllUsersInvoice()
    openMenuBalance()
    dispatch(openSelectedPage("usersInvoicePage"))
    dispatch(saveCurrentID(""))
  }
  function goToBalancePage() {
    openMenuBalance()
    dispatch(openSelectedPage("balancepage"))
  }

  const logoutUser = async () => {
    try {
      await AuthService.logout();
      localStorage.removeItem('token');
      dispatch(logoutUserData());
      dispatch(saveFoundCargo(""))
      dispatch(saveCurrentImg(""))
      dispatch(openSelectedPage("homepage"))
      navigate("/");
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  useOnClickOutside(userMenu, () => {
    if (isItem1MenuOpen) {
      openMenuBalance();
    }
  });



  return (
    <div className={styles.wrapper}>
      <div className={styles.items} >
        <div className={isItem1MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} ref={userMenu} onClick={() => setIsItem1MenuOpen(!isItem1MenuOpen)} >

          {/* <div className={isItem1MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`}  > */}
          <img src={user} alt="icon" />
          {/* </div>  */}

          {userData.role === "Admin" ?
            // <div className={isItem1MenuOpen ? `${styles.menu}` : `${styles.menu__hidden}`} ref={userMenu}>
            <div className={isItem1MenuOpen ? stylesMenu : `${styles.menu__hidden}`} >
              <div className={`${styles.menu__item} ${styles.item_name}`}>
                {`${userData.surname} ${userData.name} ${userData.secondname}`}
              </div>
              <div className={styles.menu__item} onClick={() => dispatch(goToUsersPage)}>
                {t('pages.admin.usersList')}
              </div>
              <div className={styles.menu__item} onClick={() => dispatch(goToUsersInvoicePage)}>
                {t('pages.admin.invoiceRequests')}
              </div>
              <div className={styles.menu__item} onClick={() => logoutUser()}>
                {t('exit')}
              </div>
            </div> :

            <div className={isItem1MenuOpen ? stylesMenu : `${styles.menu__hidden}`} ref={userMenu}>
              <div className={`${styles.menu__item} ${styles.item_name}`}>
                {`${userData.surname} ${userData.name} ${userData.secondname}`}
              </div>

              <div className={styles.menu__item} onClick={() => dispatch(goToMyPage)}>
                {/* Моя страница */}
                {t('my-page')}
              </div>
              <div className={styles.menu__item} onClick={() => dispatch(goToBalancePage)}>
                {/* Пополнить баланс */}
                {t('buy-tariff')}
              </div>
              <div className={styles.menu__item} onClick={() => logoutUser()}>
                {t('exit')}
                {/* Выход */}
              </div>
            </div>
          }
        </div>

      </div>
    </div>
  )
}
export default UserManagement;

import { useState } from 'react';
import styles from './footer-menu.module.css';
// import styles from './footer-menu-pers.module.css';
import Button from '../button/button';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ModalRegistr } from '../modal-registr';
import { openRegistrForm } from '../../redux/reducers/registration-reducer';
import { useTranslation } from "react-i18next";


export function FooterMenu() {
  const registrationOpen = useSelector(state => state.registration.registrationOpen);
  const [isContsctsOpen, setIsContactsOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function changeRegistrationOpen() {

    dispatch(openRegistrForm());
  }
  return (
    <div className={styles.contacts_wrapper}>
      <div className="inner">
        <h2 className={styles.title}>IrisTrans.com</h2>
        <div className={styles.contacts_items}>
          <div className={styles.contacts_item}>
            <p className={styles.item_subtitle}>{t('footer.infoTitle')}</p>
            <Button click={changeRegistrationOpen}>{t('button.registrationText')}</Button>
            {registrationOpen && <ModalRegistr />}
            <p className={styles.info}>{t('footer.menu.info1')} <br />
              {t('footer.menu.info2')}<br /> {t('footer.info.3')}</p>
          </div>
          <div className={styles.contacts_item}>
            <h4 className={styles.item_title}>{t('footer.menu.title1')}</h4>
            <ul className={styles.contacts_list}>
              <NavLink to='/perevozchiku' key='/perevozchiku'>
                <li className={styles.nav__item}>{t("header.menu.toCarrier")}</li>
              </NavLink>
              <NavLink to='/gruzootpravitelyu' key='/gruzootpravitelyu' >
                <li className={styles.nav__item}>{t("header.menu.toShipper")}</li>
              </NavLink>
              <NavLink to='/ekspeditoru' key='ekspeditoru'  >
                <li className={styles.nav__item}>{t("header.menu.toFreightForwarder")}</li>
              </NavLink>
            </ul>
          </div>
          <div className={styles.contacts_item}>
            <h4 className={styles.item_title}>{t('footer.menu.title2')}</h4>
            <ul className={styles.contacts_list}>
              <NavLink to='/tariff' key='tariff'  >
                <li className={styles.nav__item}>{t('header.menu.Rates')}</li>
              </NavLink>
              <NavLink to='/oplata' key='oplata'  >
                <li className={styles.nav__item}>{t('header.menu.payment')}</li>
              </NavLink>
              <NavLink to='/faq' key='faq'  >
                <li className={styles.nav__item}>{t('header.menu.faq')}</li>
              </NavLink>

              <li className={`${styles.nav__item} ${styles.nav__item_last}`} onClick={() => setIsContactsOpen(!isContsctsOpen)} >{t('contact')}</li>
              {isContsctsOpen &&
                <>

                  <div className={styles.contacts_item1}>
                    <p className={styles.item_text}>{t('footer.menu.contacts1')} <br /> {t('footer.menu.contacts2')}</p>
                    <p className={styles.item_text}>{t('footer.menu.contacts3')}</p>
                  </div>
                  <div className={styles.contacts_item2}>
                    <ul className={styles.contacts_list}>
                      <li className={styles.list__item}>{t('email')}: irisintergrupp@mail.ru</li>
                    </ul>
                  </div>
                </>


              }
            </ul>
          </div>
          <div className={styles.contacts_item}>
            <h4 className={styles.item_title}>{t('header.menu.rules')}</h4>
            <ul className={styles.contacts_list}>
              <NavLink to='/pravila/polzovatelskoe-soglashenie' key='/polzovatelskoe-soglashenie'>
                <li className={styles.nav__item}>{t("header.menu.termsOfUse")}</li>
              </NavLink>
              <NavLink to='/pravila/politika-konfidencialnosti' key='/politika-konfidencialnosti' >
                <li className={styles.nav__item}>{t("header.menu.PrivacyPolicy")}</li>
              </NavLink>
              <NavLink to='/pravila/pravila-razmeshcheniya-zayavok' key='pravila-razmeshcheniya-zayavok'  >
                <li className={styles.nav__item}>{t("header.menu.rulesPlacing")}</li>
              </NavLink>
              <NavLink to='/pravila/verifikaciya' key='verifikaciya'  >
                <li className={styles.nav__item}>{t("header.menu.rulesAccountValidation")}</li>
              </NavLink>
              <NavLink to='/pravila/publichnaya-oferta' key='publichnaya-oferta'  >
                <li className={styles.nav__item}>{t("header.menu.publicContract")}</li>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default FooterMenu;
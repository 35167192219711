import styles from './main-clients.module.css';
// import styles from './main-clients-pers.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import 'swiper/css';
// import company from "./images/company-logo.jpg"
import company1 from "./images/IrIs-inter-GROUP.jpg"
import company2 from "./images/SUPP.png"
import company3 from "./images/Vi-Parmis-International.png"
import company4 from "./images/tek.png"
import company5 from "./images/vtop.jpg"
import CardMainClients from '../card-main-clients/card-main-clients';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export function MainClients() {
  const { t } = useTranslation();
  return (
    <div className={styles.clients_wrapper}>
      <div className="inner">
        <h2 className={`${styles.title} section__title`} >{t('main.clients.title')}</h2>
        {/* <div className={styles.clients__slider}>
          <div className={`${styles.prev} prev-clients `}> &#60; </div>
          <div className={`${styles.next} next-clients`}> &#62; </div>
          <Swiper
            spaceBetween={20}
            slidesPerView={2}
            modules={[Navigation]}
            navigation={{
              nextEl: ".next-clients",
              prevEl: ".prev-clients",
              disabledClass: "swiper-button-disabled"
            }}
            breakpoints={{
              700: {
                slidesPerView: 3,
              },
              1236: {
                slidesPerView: 4,
                spaceBetween: 32
              },
              1400: {
                slidesPerView: 6,
              }
            }}
          >
            <SwiperSlide>
              <CardMainClients img={company} text="Название компании" />
            </SwiperSlide>
            <SwiperSlide>
              <CardMainClients img={company} text="Название компании" />
            </SwiperSlide>
            <SwiperSlide>
              <CardMainClients img={company} text="Название компании" />
            </SwiperSlide>
            <SwiperSlide>
              <CardMainClients img={company} text="Название компании" />
            </SwiperSlide>
            <SwiperSlide>
              <CardMainClients img={company} text="Название компании" />
            </SwiperSlide>
            <SwiperSlide>
              <CardMainClients img={company} text="Название компании" />
            </SwiperSlide>
          </Swiper>


        </div> */}
        <div className={styles.clients_cards}>
          <CardMainClients img={company1} text="IrIs inter GROUP" />
          <CardMainClients img={company2} text="SUPP Lines" />
          <CardMainClients img={company3} text="Vi Parmis International" />
          <CardMainClients img={company4} text="ТЭК мслогистика" />
          <NavLink to="https://vtop3.by/uslugi/sozdanie-sajtov/ " target="_blank">
            <CardMainClients img={company5} text="VTOP3.by" />
          </NavLink>
        </div>

      </div>
    </div >
  )
}
export default MainClients;
import stylesGeneral from './pravila.module.css'
import styles from './rules.module.css'
import { useEffect } from 'react';

export function RulesVerifacation() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={stylesGeneral.wrapper}>
      <div className="inner">
        <div className={styles.rules__body}>
          <div class="wrapper">
            <div class="container">
              <div className={`${styles.rules__wrapper}`}>
                <h1 className={`${styles.rules__title}`}>Правила проверки достоверности учетной записи
                </h1>
                <h2 className={`${styles.items__title}`}> Общие правила Проверки профиля</h2>
                <div>
                  <p className={`${styles.item}`}>Регистрация и/или использование Вами учетной записи подразумевает Ваше понимание того, что Мы(IrisTrans) можем естественным образом сверять имеющиеся у нас данные о Вашей Фирме (полученные как от Вас, так из других законных источников) с данными, указанными Вами в учетной записи. Далее, в конечном итоге Мы вправе принимать решения относительно действительности Вашей учетной записи на основании таких сверок. Кроме того, мы намерены особым образом выделять учетные записи, удовлетворяющие требованиям по достоверности согласно критериям специальной проверки (далее — “Проверка профиля”).</p>
                  <p className={`${styles.item}`}>Проверка профиля подразумевает публичное подтверждение Нами, к сведению Ваших реальных и потенциальных партнеров, достоверности Вашей учетной записи. Проверкой профиля Пользователя Мы не поручаемся за качество предоставляемых Фирмой услуг по перевозке грузов и/или "платежеспособность" Фирмы в качестве заказчика перевозки (для оценки бизнес-надежности/эффективности работы с Фирмой, на Сайте предназначены отзывы), однако Проверкой профиля мы фиксируем достоверность ключевых данных, указанных в учетной записи Фирмы.</p>
                  <p className={`${styles.item}`}>
                    Проверка профиля является бесплатным Сервисом.</p>
                  <p className={`${styles.item}`}>Если Вы по каким-либо причинам, отвечая условиям прохождения Проверки профиля, желаете отказаться от присвоения Вашей учетной записи статуса успешного прохождения Проверки профиля, просим уведомить Нас сообщением на info@iristrans.com.</p>
                  <p className={`${styles.item}`}>В случае если Ваши платежные реквизиты по каким-либо добросовестным причинам не совпадают с данными из Вашей учетной записи, или же если Вы еще не успели оценить преимущества платных Сервисов IrisTrans — Вы все равно не лишены возможности пройти Проверку профиля, выслав Нам некоторые документы.</p>
                  <p className={`${styles.item}`}>Успешное прохождение Проверки профиля не снимает с Вас ответственности за достоверность учетной записи, которая действует по умолчанию для всех Пользователей. Соответствующие обязательства Пользователя и возможные последствия регистрации и/или использования недостоверной учетной записи описаны в разделе 4 основного текста Пользовательского соглашения.<br></br>
                    Мы не устанавливаем специально определенных ограничений относительно каких-либо дат и/или сроков, связанных с действием Вашего статуса успешно пройденной Проверки профиля.</p>
                  <p className={`${styles.item}`}>Актуальность состояния достоверности вашей учетной записи по прошествии некоторого времени после прохождения Проверки профиля — относится к зоне вашей ответственности. Вы имеете право в любой момент обновить ваши данные путем прохождения процедуры Проверки профиля любым удобным для вас нижеописанным способом.</p>
                  <p className={`${styles.item}`}>Мы оставляем за собой право дополнительно проверять достоверность данных, связанных с Вашей учетной записью, в частности, действительность регистрации Вашей Фирмы, а также соответствие наименования и регистрационного (идентификационного, налогового) кода (номера) Вашей Фирмы, в доступных официальных источниках, и отказать Вам в Проверке профиля либо в дальнейшем отменить Ваш статус в случае выявления несоответствий.
                  </p>
                  <p className={`${styles.item}`}>Основаниями для отказа в предоставлении и основаниями для отмены статуса успешного прохождения Проверки профиля являются (с учетом того, что соответствующие нарушения также являются основаниями для блокировки учетной записи):</p>
                  <p className={`${styles.item}`}>a. выявленная в любой момент, любыми легитимными способами, недостоверность данных в учетной записи, касающихся Проверки профиля;
                  </p>
                  <p className={`${styles.item}`}>b. нарушение основоположных правил использования учетной записи, изложенных в Пользовательском Соглашении.</p>
                  <p className={`${styles.item}`}>Проверка профиля предполагает, что сверенные Нами данные из Вашей учетной записи не должны изменяться без дополнительного подтверждения для действительности Вашего статуса успешно пройденной Проверки профиля. Мы оставляем за собой право по Нашему усмотрению:
                    <br></br>
                    a. устанавливать режим предварительной модерации для изменений данных о Фирме в учетной записи, заявленной к Проверке профиля и/или с предоставленным статусом успешно пройденной Проверки профиля (Вам будет заблокирована возможность самостоятельного изменения значений соответствующих полей регистрационных данных); либо же
                    <br></br>
                    b. автоматически снимать статус успешно пройденной Проверки профиля в случае внесения Вами изменений.
                  </p>      <p className={`${styles.item}`}>Мы оставляем за собой право по своему усмотрению делать оговорки (предостережения) для других Пользователей о гарантиях достоверности, предоставляемых теми или иными механизмами проверки учетных записей.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
export default RulesVerifacation;
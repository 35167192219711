import styles from './card-main-tariff.module.css'
import { useSelector } from 'react-redux';

export function CardMainTariff(props) {
  const currentLanguage = useSelector(state => state.language.language);
  return (
    <div className={styles.card}>
      <div className={currentLanguage === 'per' ? `${styles.card__info__pers} ${styles.card__info}` : `${styles.card__info} `}>
        <div className={styles.card__title}>{props.title}</div>
        <div className={`${styles.card__subtitle} ${styles.hidden}`}>{props.subtitle}</div>
      </div>
      <div className={currentLanguage === 'per' ? `${styles.card__img__pers}` : `${styles.card__img}`}>
        <img src={props.img} alt="" />
      </div>
    </div>
  )
}
export default CardMainTariff;
import styles from './update-user-data-form.module.css';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserData } from '../../../redux/reducers/user-data-reducer';
import { toggleEditForm } from '../../../redux/reducers/edit-form-reducer';
import { openRegFinishModal } from '../../../redux/reducers/registration-finish';
import { updateUserData } from '../../../http/userAPI';
import { useTranslation } from "react-i18next";


// /^[^@\s]+@[^@\s]+\.[^@\s\.]{2,}$/;

export function UserUpdate() {
  const { t } = useTranslation();

  const userData = useSelector(state => state.userData.userData);
  const userRole = useSelector(state => state.userData.userData.role);

  const initialValues = {
    orgname: userData.orgname,
    surname: userData.surname,
    secondname: userData.secondname,
    name: userData.name,
    country: userData.country,
    city: userData.city,
    unp: userData.unp,
    number: userData.number,
    userId: userData.id,
    email: userData.email,
    telegram: userData.telegram,
    skype: userData.skype,
    whatsapp: userData.whatsapp
  }
  const validate = values => {
    let errors = {}
    if (userRole === "Юридическое лицо") {
      if (!values.orgname) {
        errors.orgname = t('field.validate')
      }
      if (!values.name) {
        errors.name = t('field.validate')
      }
      else if (!/^([^0-9]*)$/i.test(values.name)) {
        errors.name = t('field.notNumber')
      }

      if (!values.country) {
        errors.country = t('field.validate')
      }
      // if (!values.city) {
      //   errors.city =  t('field.validate')
      // }
      if (!values.unp) {
        errors.unp = t('field.validate')
      } else if (!/^(\d){9}$/i.test(values.unp)) {
        errors.unp = t('field.wrongNumber')
      }
      if (!values.number) {
        errors.number = t('field.validate')
      } else if (!/^\+?\d+$/i.test(values.number)) {
        errors.number = t('field.wrongNumber')
      }
      if (!values.email) {
        errors.email = t('field.validate')
      }
    } else {
      if (!values.name) {
        errors.name = t('field.validate')
      }
      if (!values.surname) {
        errors.surname = t('field.validate')
      }
      if (!values.country) {
        errors.country = t('field.validate')
      }
      // if (!values.city) {
      //   errors.city =  t('field.validate')
      // }

      if (!values.number) {
        errors.number = t('field.validate')
      } else if (!/^\+?\d+$/i.test(values.number)) {
        errors.number = t('field.wrongNumber')
      }
      if (!values.email) {
        errors.email = t('field.validate')
      }
    }
    return errors
  }


  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    // dispatch(toggleEditForm());
    if (values.email !== initialValues.email) {
      dispatch(openRegFinishModal());
      let newData = await updateUserData(values)
      dispatch(saveUserData(newData.data));

    }
    else {
      let newData = await updateUserData(values)

      dispatch(saveUserData(newData.data));
      dispatch(toggleEditForm());
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  })

  return (
    <>

      <div className={styles.form_wrapper}>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.input_wrapper}>
            {userData.role === "Юридическое лицо" ?
              <div className={styles.input_box}>
                <label className={styles.input_label} htmlFor="orgname">{t('forms.field.orgName')}*</label>
                <input className={styles.input} type="text" id="orgname" name="orgname" onChange={formik.handleChange} value={formik.values.orgname} placeholder={t('forms.field.enter')} />
                {formik.errors.orgname && <div className={styles.error_message}>{formik.errors.orgname}</div>}
              </div>
              : <div className={styles.input_box_fl}>
                <div className={styles.input_box}>
                  <label className={styles.input_label} htmlFor="surname">{t('forms.field.secondName')}*</label>
                  <input className={styles.input} type="text" id="surname" name="surname" onChange={formik.handleChange} value={formik.values.surname} placeholder={t('forms.field.secondName')} />
                  {formik.errors.surname && <div className={styles.error_message}>{formik.errors.surname}</div>}
                </div>
                <div className={styles.input_box}>
                  <label className={styles.input_label} htmlFor="name">{t('forms.field.name')}*</label>
                  <input className={styles.input} type="text" id="name" name="name" onChange={formik.handleChange} value={formik.values.name} placeholder={t('forms.field.name')} />
                  {formik.errors.name && <div className={styles.error_message}>{formik.errors.name}</div>}
                </div>
                <div className={styles.input_box}>
                  <label className={styles.input_label} htmlFor="secondname">{t('forms.field.fatherName')}</label>
                  <input className={styles.input} type="text" id="secondname" name="secondname" onChange={formik.handleChange} value={formik.values.secondname} placeholder={t('forms.field.fatherName')} />

                </div>
              </div>}
            <div className={styles.input_box}>
              <label className={styles.input_label} htmlFor="country">{userRole === "Юридическое лицо" ? t('forms.field.countryOflive') + "*" : t('forms.field.country') + "*"}</label>
              <input className={styles.input} type="text" id="country" name="country" onChange={formik.handleChange} value={formik.values.country} placeholder={t('forms.field.enter')} />
              {formik.errors.country && <div className={styles.error_message}>{formik.errors.country}</div>}
            </div>
            <div className={styles.input_box}>
              <label className={styles.input_label} htmlFor="city">{userRole === "Физическое лицо" ? t('forms.field.cityOfLive') : t('cityoflRegist') + "*"}</label>
              <input className={styles.input} type="text" id="city" name="city" onChange={formik.handleChange} value={formik.values.city} placeholder={t('forms.field.enter')} />
              {/* {formik.errors.city && <div className={styles.error_message}>{formik.errors.city}</div>} */}

            </div>
            {(userData.role === "Юридическое лицо" || userRole === "Предприниматель") &&
              <div className={styles.input_box}>
                <label className={styles.input_label} htmlFor="unp">{t('forms.field.unp')}*</label>
                <input className={styles.input} type="text" id="unp" name="unp" onChange={formik.handleChange} value={formik.values.unp} placeholder={t('forms.field.enter')} />
                {formik.errors.unp && <div className={styles.error_message}>{formik.errors.unp}</div>}
              </div>
            }
            {userData.role === "Юридическое лицо" &&
              <div className={styles.input_box}>
                <label className={styles.input_label} htmlFor="name">{t('forms.field.fio')}*</label>
                <input className={styles.input} type="text" id="name" name="name" onChange={formik.handleChange} value={formik.values.name} placeholder={t('forms.field.name')} />
                {formik.errors.name && <div className={styles.error_message}>{formik.errors.name}</div>}
              </div>
            }
            <div className={styles.input_box}>
              <input className={styles.input} onChange={formik.handleChange} id="number" name="number" value={formik.values.number} placeholder={t('forms.field.enter')} >
              </input>
              {/* <InputMask value={formik.values.number} className={styles.input} mask="+375 (99) - 999- 99 -99" placeholder="+375 (__) - ___ - __ - __" onChange={formik.handleChange} id="number" name="number">
            </InputMask> */}
              <label className={styles.input_label} htmlFor="number">{t('forms.field.phoneNumber')}</label>
              {formik.errors.number && <div className={styles.error_message}>{formik.errors.number}</div>}
            </div>

            <div className={styles.input_box}>
              <label className={styles.input_label} htmlFor="email">Email*</label>
              <input className={styles.input} type="text" id="email" name="email" onChange={formik.handleChange} value={formik.values.email} placeholder={t('forms.field.enter')} />
              {formik.errors.email && <div className={styles.error_message}>{formik.errors.email}</div>}
            </div>
            <div className={styles.input_box}>
              <input className={styles.input} onChange={formik.handleChange} id="telegram" name="telegram" value={formik.values.telegram} placeholder={t('forms.field.enter')} >
              </input>
              <label className={styles.input_label} htmlFor="telegram">Telegram</label>
            </div>
            <div className={styles.input_box}>
              <input className={styles.input} onChange={formik.handleChange} id="skype" name="skype" value={formik.values.skype} placeholder={t('forms.field.enter')} >
              </input>
              <label className={styles.input_label} htmlFor="skype">Skype</label>
            </div>
            <div className={styles.input_box}>
              <input className={styles.input} onChange={formik.handleChange} id="whatsapp" name="whatsapp" value={formik.values.whatsapp} placeholder={t('forms.field.enter')} >
              </input>
              <label className={styles.input_label} htmlFor="whatsapp">WhatsApp</label>
            </div>
            <button type="submit" className={styles.button_long} >{t('buttons.saveText')}</button>
          </div>
        </form>
      </div>
    </>
  )
}
export default UserUpdate;
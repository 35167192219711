import styles from './modal-reg-completed.module.css';
// import styles from './modal-reg-completed-pers.module.css';
import { useDispatch } from 'react-redux';
import { closeRegFinishModal } from '../../redux/reducers/registration-finish';
import { openEntranceModal } from '../../redux/reducers/entrance-reducer';
import { useTranslation } from "react-i18next";

export function ModalRegCompleted() {

  const dispatch = useDispatch();

  function closeAllModal() {
    dispatch(closeRegFinishModal());
  }
  const { t } = useTranslation();

  function openEntranceModalWindow() {
    dispatch(closeRegFinishModal());
    dispatch(openEntranceModal());
  }

  return (
    <>
      <div className={styles.darkBG} onClick={() => dispatch(closeAllModal)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <p className={styles.heading}>{t('vam-na-pochtu-otpravleno-pismo')}</p>
          </div>
          <div className={styles.modalContent}>
            <p>{t('dlya-podtverzhdeniya-email')}</p>
            {/* <button type="submit" className={styles.button_long} onClick={() => dispatch(openEntranceModalWindow)}>Войти</button> */}
          </div>
        </div>
      </div>
    </>
  )
}
export default ModalRegCompleted
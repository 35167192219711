import MainAbout from '../../components/main-about/main-about';
import { MainTop } from '../../components/main-top';
import { MainStages } from '../../components/main-stages';
import { MainTariff } from '../../components/main-tariff';
import styles from './main.module.css'
import MainPromo from '../../components/main-promotions/main-promotions';
import { MainClients } from '../../components/main-clients';
import MainContacts from '../../components/main-contacts/main-contacts';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function MainPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const location = useLocation();


  // const isValid = /^#[A-Za-z]+$/.test(selector) || 
  //              /^\.[A-Za-z]+$/.test(selector);

  useEffect(() => {
    // console.log("location.hash useEffect", location.hash)
    if (location.hash) {
      // console.log("location.hash", location.hash)
      const element = document.querySelector(location.hash);
      // console.log("location.element", element)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <main className="main">
      <MainTop />
      <MainAbout />
      <MainStages />
      <MainTariff />
      <MainPromo />
      <MainClients />
      {/* <MainContacts /> */}
    </main>
  )
}
export default MainPage;
import styles from './modal-entrance-form.module.css';
// import styles from './modal-entrance-form-pers.module.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { closeEntranceModal } from '../../redux/reducers/entrance-reducer';
import { openRegistrForm } from '../../redux/reducers/registration-reducer';
import { loginUserData } from '../../redux/reducers/user-data-reducer';
import { openPasswordModal } from '../../redux/reducers/password-reducer';
import AuthService from '../../services/AuthService';
import { useState } from 'react';
import cookies from 'js-cookie';
import { useTranslation } from "react-i18next";



export function ModalEntranceForm() {
  const { t } = useTranslation();
  let [passwordError, setPasswordError] = useState(null);
  const initialValues = {
    email: "",
    password: "",
  }

  const validate = values => {
    setPasswordError(null)
    let errors = {}
    if (!values.email) {
      errors.email = t('field.validate')
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = t('field.checkEmail')
    }
    if (!values.password) {
      errors.password = t('field.validate')
    } else if (passwordError) {
      errors.password = t('ukazan-nevernyi-parol')
      setPasswordError(null)
    }
    return errors
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function changeRegistrationOpen() {
    dispatch(openRegistrForm());
    dispatch(closeEntranceModal());
  }

  function openPasswordModalWindow() {
    dispatch(openPasswordModal());
    dispatch(closeEntranceModal());
  }

  function closeAllModal() {
    dispatch(closeEntranceModal());
  }

  const onSubmit = async (values) => {
    try {
      const response = await AuthService.login(values.email, values.password);
      // cookies.set('refToken', response.data.refreshToken)
      localStorage.setItem('token', response.data.accessToken);
      dispatch(loginUserData(response.data));
      dispatch(closeAllModal)
      navigate("/profile");
    } catch (e) {
      // setPasswordError(e.response?.data?.message)
      console.log(e.response?.data?.message);
      alert(e.response?.data?.message)
    }
  }
  const formik = useFormik({

    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit,
  })

  return (
    <>
      <div className={styles.darkBG} onClick={() => dispatch(closeAllModal)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <p className={styles.heading}>{t('vkhod')}</p>
          </div>
          <div className={styles.modalContent}>
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.input_box}>
                <label className={styles.input_label} htmlFor="email">Email</label>
                <input className={styles.input} type="text" id="email" name="email" onChange={formik.handleChange} value={formik.values.email} placeholder={t('forms.field.enter')} />
                {formik.errors.email ? <div className={styles.error_message}>{formik.errors.email}</div> : ""}
              </div>
              <div className={styles.input_box}>
                <label className={styles.input_label} htmlFor="password">{t('forms.field.password')}</label>
                <input className={styles.input} type="password" id="password" name="password" onChange={formik.handleChange} value={formik.values.password} placeholder={t('forms.field.enter')} />
                {formik.errors.password ? <div className={styles.error_message}>{formik.errors.password}</div> : ""}
              </div>
              <div className={styles.input_box}>
                <input className={styles.check} type="checkbox" id="remember" name="remember" onChange={formik.handleChange} value={formik.values.remember} />
                <label className={styles.check_label} htmlFor="remember">{t('zapomnit-menya')}</label>
              </div>
              <button type="submit" className={styles.button_long}>{t('voiti')}</button>
            </form>
          </div>
          <div className={styles.actionsContainer}>
            <button type="submit" className={styles.button_add} onClick={openPasswordModalWindow}>{t('zabyli-parol')}</button>
            <button type="submit" className={styles.button_add} onClick={changeRegistrationOpen}>{t('button.registrationText')}</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalEntranceForm;
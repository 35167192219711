import styles from './modal-promotions.module.css';
// import styles from './modal-promotions-pers.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalPromo } from '../../redux/reducers/modal-promo';
import { useTranslation } from "react-i18next";


export function ModalPromo(props) {
  const currentLanguage = useSelector(state => state.language.language);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  function closeAllModal() {
    dispatch(closeModalPromo());
  }

  return (
    <>
      <div className={styles.darkBG} onClick={() => dispatch(closeAllModal)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <p className={styles.heading}>{props.title}</p>
            <p>{t('main.ptomotions.period3m')}</p>
          </div>
          <div className={styles.modalContent}>
            <p>{t('main.promotions.offer1Text')}</p>
            <button type="submit" className={styles.button_long} onClick={() => dispatch(closeAllModal)}>{t('zakryt')}</button>
          </div>
        </div>
      </div>
    </>
  )
}
export default ModalPromo
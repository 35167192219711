/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense } from "react";
// import "dotenv/config.js";
import { Route, Routes, BrowserRouter, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import { MainPage } from "./pages/main";
import { CarrierPage } from "./pages/сarrier";
import { ShipperPage } from "./pages/shipper";
import { ForwarderPage } from "./pages/forwarder";
import { PaymentPage } from "./pages/payment";
import { TariffPage } from "./pages/tariff";
import { NotFoundPage } from "./pages/404";
import FAQPage from "./pages/faq/faq";
import { MainRegisteredPage } from "./pages/main-registered";
import { RulesPage } from "./pages/pravila";
import { HeaderRegistered } from "./components/header-registered";
import {
  checkUserAuth,
  loginUserData,
} from "./redux/reducers/user-data-reducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { FooterCopyrightLine } from "./components/footer-bottom-copyright";
import { PrivacyPolicy } from "./pages/pravila-privacy-policy";
import { RulesPlacing } from "./pages/pravila-placing";
import { RulesVerifacation } from "./pages/pravila-verifacation";
import { RulesOferta } from "./pages/pravila-oferta";
import { PasswordConfirm } from "./components/password-confirm";
import { EmailConfirm } from "./components/email-confirm";
import { getCountries } from "./http/userAPI";
import { setCountries } from "./redux/reducers/countries-list-reducer";
import cookies from "js-cookie";
import { FAQPageEn } from "./pages/faq-en";
import FAQPagePer from "./pages/faq-per/faq-per";
import { RulesPageEn } from "./pages/pravila-en";
import { RulesPagePer } from "./pages/pravila-per";
import { PrivacyPolicyEn } from "./pages/pravila-privacy-policy-en";
import { PrivacyPolicyPer } from "./pages/pravila-privacy-policy-per";
import { RulesPlacingEn } from "./pages/pravila-placing-en";
import { RulesPlacingPer } from "./pages/pravila-placing-per";
import { RulesVerifacationEn } from "./pages/pravila-verifacation-en";
import { RulesVerifacationPer } from "./pages/pravila-verifacation-per";
import { RulesOfertaEn } from "./pages/pravila-oferta-en";
import { RulesOfertaPer } from "./pages/pravila-oferta-per";
import { useTranslation } from "react-i18next";
import { BaseURL } from "./http";

function App() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  // const currentLanguage = cookies.get('i18next') || 'ru'
  const currentLanguage = useSelector((state) => state.language.language);

  const userAuht = useSelector((state) => state.userData.auth);
  const userRole = useSelector((state) => state.userData.userData.role);
  const dispatch = useDispatch();
  const [userIsAuth, setUserIsAuth] = useState(null);
  async function checkAuth() {
    try {
      const response = await axios.get(`${BaseURL}/api/user/refresh`, {
        withCredentials: true,
      });
      localStorage.setItem("token", response.data.accessToken);
      dispatch(checkUserAuth());
      // setUserIsAuth(true);
      dispatch(loginUserData(response.data));
    } catch (e) {
      console.log(e, "error");
      console.log(e.response?.data?.message);
    }
  }

  async function getAllCountries() {
    try {
      const response = await getCountries();
      dispatch(setCountries(response.data));
    } catch (e) {
      console.log(e, "error");
      console.log(e.response?.data?.message);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      checkAuth();
    }
  }, []);

  const Layout = () => {
    return (
      <div className={currentLanguage === "per" ? `wrapper__pers` : `wrapper`}>
        <Header />
        <Outlet />
        <Footer />
      </div>
    );
  };

  const LayoutRegistered = () => {
    return (
      <div className={currentLanguage === "per" ? `wrapper__pers` : `wrapper`}>
        <HeaderRegistered />
        <Outlet />
        <FooterCopyrightLine />
      </div>
    );
  };

  return (
    <BrowserRouter>
      <div className={i18n.language}>
        <Suspense fallback={<div className="loading_box">Loading...</div>}>
          <Routes>
            <Route exact path="/" element={<Layout />}>
              <Route index element={<MainPage />} />
              <Route path="/perevozchiku" element={<CarrierPage />} />
              <Route path="/gruzootpravitelyu" element={<ShipperPage />} />
              <Route path="/ekspeditoru" element={<ForwarderPage />} />
              <Route path="/oplata" element={<PaymentPage />} />
              <Route path="/tariff" element={<TariffPage />} />
              {currentLanguage === "en" && (
                <Route path="/faq" element={<FAQPageEn />} />
              )}
              {currentLanguage === "ru" && (
                <Route path="/faq" element={<FAQPage />} />
              )}
              {currentLanguage === "per" && (
                <Route path="/faq" element={<FAQPagePer />} />
              )}
              {currentLanguage === "ru" && (
                <Route
                  path="/pravila/polzovatelskoe-soglashenie"
                  element={<RulesPage />}
                />
              )}
              {currentLanguage === "en" && (
                <Route
                  path="/pravila/polzovatelskoe-soglashenie"
                  element={<RulesPageEn />}
                />
              )}
              {currentLanguage === "per" && (
                <Route
                  path="/pravila/polzovatelskoe-soglashenie"
                  element={<RulesPagePer />}
                />
              )}
              {currentLanguage === "ru" && (
                <Route
                  path="/pravila/politika-konfidencialnosti"
                  element={<PrivacyPolicy />}
                />
              )}
              {currentLanguage === "en" && (
                <Route
                  path="/pravila/politika-konfidencialnosti"
                  element={<PrivacyPolicyEn />}
                />
              )}
              {currentLanguage === "per" && (
                <Route
                  path="/pravila/politika-konfidencialnosti"
                  element={<PrivacyPolicyPer />}
                />
              )}
              {currentLanguage === "ru" && (
                <Route
                  path="/pravila/pravila-razmeshcheniya-zayavok"
                  element={<RulesPlacing />}
                />
              )}
              {currentLanguage === "en" && (
                <Route
                  path="/pravila/pravila-razmeshcheniya-zayavok"
                  element={<RulesPlacingEn />}
                />
              )}
              {currentLanguage === "per" && (
                <Route
                  path="/pravila/pravila-razmeshcheniya-zayavok"
                  element={<RulesPlacingPer />}
                />
              )}
              {currentLanguage === "ru" && (
                <Route
                  path="/pravila/verifikaciya"
                  element={<RulesVerifacation />}
                />
              )}
              {currentLanguage === "en" && (
                <Route
                  path="/pravila/verifikaciya"
                  element={<RulesVerifacationEn />}
                />
              )}
              {currentLanguage === "per" && (
                <Route
                  path="/pravila/verifikaciya"
                  element={<RulesVerifacationPer />}
                />
              )}
              {currentLanguage === "ru" && (
                <Route
                  path="/pravila/publichnaya-oferta"
                  element={<RulesOferta />}
                />
              )}
              {currentLanguage === "en" && (
                <Route
                  path="/pravila/publichnaya-oferta"
                  element={<RulesOfertaEn />}
                />
              )}
              {currentLanguage === "per" && (
                <Route
                  path="/pravila/publichnaya-oferta"
                  element={<RulesOfertaPer />}
                />
              )}
              <Route
                path="/password/confirm/:id"
                element={<PasswordConfirm />}
              />
              <Route path="/email/confirm/:id" element={<EmailConfirm />} />
              {!userAuht && (
                <Route
                  path="/profile"
                  element={
                    <div className="loading_box">
                      {t("voidite-v-lichnyi-kabinet-ili-proidite-registraciyu")}
                    </div>
                  }
                />
              )}
              <Route path="*" element={<NotFoundPage />} />
            </Route>
            {userAuht && (
              <Route path="/profile" element={<LayoutRegistered />}>
                <Route index element={<MainRegisteredPage />} />
              </Route>
            )}

            <Route exact path="/per" element={<Layout />}>
              <Route index element={<MainPage />} />
              <Route path="/per/perevozchiku" element={<CarrierPage />} />
              <Route path="/per/gruzootpravitelyu" element={<ShipperPage />} />
              <Route path="/per/ekspeditoru" element={<ForwarderPage />} />
              <Route path="/per/oplata" element={<PaymentPage />} />
              <Route path="/per/tariff" element={<TariffPage />} />
              <Route path="/per/faq" element={<FAQPagePer />} />
              <Route
                path="/per/pravila/polzovatelskoe-soglashenie"
                element={<RulesPagePer />}
              />
              <Route
                path="/per/pravila/politika-konfidencialnosti"
                element={<PrivacyPolicyPer />}
              />
              <Route
                path="/per/pravila/pravila-razmeshcheniya-zayavok"
                element={<RulesPlacingPer />}
              />
              <Route
                path="/per/pravila/verifikaciya"
                element={<RulesVerifacationPer />}
              />
              <Route
                path="/per/pravila/publichnaya-oferta"
                element={<RulesOfertaPer />}
              />
              <Route
                path="/per/password/confirm/:id"
                element={<PasswordConfirm />}
              />
              <Route path="/per/email/confirm/:id" element={<EmailConfirm />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>

            <Route exact path="/eng" element={<Layout />}>
              <Route index element={<MainPage />} />
              <Route path="/eng/perevozchiku" element={<CarrierPage />} />
              <Route path="/eng/gruzootpravitelyu" element={<ShipperPage />} />
              <Route path="/eng/ekspeditoru" element={<ForwarderPage />} />
              <Route path="/eng/oplata" element={<PaymentPage />} />
              <Route path="/eng/tariff" element={<TariffPage />} />
              <Route path="/eng/faq" element={<FAQPagePer />} />
              <Route
                path="/eng/pravila/polzovatelskoe-soglashenie"
                element={<RulesPagePer />}
              />
              <Route
                path="/eng/pravila/politika-konfidencialnosti"
                element={<PrivacyPolicyPer />}
              />
              <Route
                path="/eng/pravila/pravila-razmeshcheniya-zayavok"
                element={<RulesPlacingPer />}
              />
              <Route
                path="/eng/pravila/verifikaciya"
                element={<RulesVerifacationPer />}
              />
              <Route
                path="/eng/pravila/publichnaya-oferta"
                element={<RulesOfertaPer />}
              />
              <Route
                path="/eng/password/confirm/:id"
                element={<PasswordConfirm />}
              />
              <Route path="/eng/email/confirm/:id" element={<EmailConfirm />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>

            {userAuht && (
              <Route path="/profile" element={<LayoutRegistered />}>
                <Route index element={<MainRegisteredPage />} />
              </Route>
            )}
          </Routes>
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;

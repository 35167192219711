
import styles from './forwarder.module.css'
import { PagesDescription } from '../../components/pages-description';
import boxes from "./images/img.png"
import tableSearch from "./images/table1.png"
import tableReg from "./images/table2.png"
import { PagesItem } from '../../components/pages-item';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";


export function ForwarderPage() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={styles.wrapper}>
      <div className="inner">
        <PagesDescription title={t('to-the-forwarder')}
          text={t('iristrans-is-a-convenient')}
          img={boxes} />
        <PagesItem title={t('easy-partner-search')}
          text={t('we-help-you-find-proven')}
          img={tableSearch} />
        <PagesItem title={t('quickly-add-cargo-and-transport')}
          text={t('add-freight-requests')}
          subtext={t('we-have-simplified-the-process')}
          img={tableReg} />
        <p className={styles.item_title}>{t('favorable-deals')} </p>
        <p className={styles.item_text}>{t('cooperate-with-partners')} </p>
        <p className={styles.item_title}>{t('counterparty-rating')} </p>
        <p className={styles.item_text}>{t('we-provide-important-legal')} </p>
      </div>
    </main>
  )
}
export default ForwarderPage;

import styles from './footer-chat.module.css';
import { useSelector } from 'react-redux';
export function FooterChat() {

  const currentLanguage = useSelector(state => state.language.language);

  return (
    <>
      {currentLanguage === "ru" &&
        <div className={styles.footer_chat}>
          {
            (function (w, d, u) {
              var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 60000 | 0);
              var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
            })(window, document, 'https://cdn-ru.bitrix24.by/b28326892/crm/site_button/loader_3_7keuu2.js')
          }
        </div>
      }

      {(currentLanguage === "en" || currentLanguage === "per") &&
        <div className={styles.footer_chat}>
          {
            (function (w, d, u) {
              var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 60000 | 0);
              var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
            })(window, document, 'https://cdn-ru.bitrix24.by/b28228844/crm/site_button/loader_6_ptfoee.js')
          }
        </div>
      }
    </>

  )
}

export default FooterChat;
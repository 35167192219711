import styles from './button-entrance.module.css'
import {  useSelector } from 'react-redux';


export function ButtonEntrance(props) {

  const currentLanguage = useSelector(state => state.language.language);
  return (
    <div 
    className={currentLanguage === 'per' ? `${styles.btnEntrance} ${styles.btnEntrance__pers}` : `${styles.btnEntrance} `}  onClick={props.click}>
      {props.children}
    </div>
  )
}
export default ButtonEntrance;


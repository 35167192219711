import styles from './rules.module.css'
import stylesGeneral from './pravila.module.css'
import { useEffect } from 'react';

export function PrivacyPolicyEn() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={stylesGeneral.wrapper}>
      <div className="inner">
        <div className={styles.rules__body}>
          <div className={`${styles.rules__wrapper}`}>
            <h1 className={`${styles.rules__title}`}>Privacy Policy
            </h1>
            <h2 className={`${styles.items__title}`}>1. General Provisions</h2>
            <p className={`${styles.item}`}>
              1.1. This Privacy Policy describes how iristrans.com uses and stores the personal information of customers visiting iristrans.com (hereinafter referred to as the Site).<br></br>
              1.2. The use of the Site by the client and the provision of private information to IrisTrans.com through the Site means acceptance of this Privacy Policy and the terms of processing the client's personal data, as well as consent to the transfer, use and disclosure of his personal data in accordance with the terms of this Privacy Policy.<br></br>
              1.3. In case of disagreement with the terms of the Privacy Policy, the client must
stop using the Site.
 <br></br>
              1.4. This Privacy Policy applies only to private information collected through this Site. Private information is information that allows, when used alone or in combination with other available information, to identify the client's personal data.<br></br>
              1.5. This Privacy Policy applies only to the Site. The Site does not deliberately publish links to Internet resources that can damage the client's computer or infect it with viruses.<br></br>
              1.6. The Site Administration does not verify the accuracy of personal data provided by the Site User.
            </p>
            <h2 className={`${styles.items__title}`}>2. Subject of the Privacy Policy</h2>
            <p className={`${styles.item}`}>
              2.1. Data collected and stored:<br></br>
              2.1.1. Personal data, as well as any other information permitted for processing under this Privacy Policy, which customers knowingly and voluntarily provide by filling out the registration form or performing other actions on the Site:</p>
            <ul className={`${styles.list_items}`}>

              <li>last name, first name;</li>
              <li>email address (e-mail);</li>
              <li>contact number;</li>
              <li>goods chosen by the customer.</li>
            </ul>
            <p className={`${styles.item}`}>Information is collected for the purpose of accepting, processing and delivering an order; providing customer feedback, providing customer support, including sending notifications, requests regarding the use of the Site.<br></br>
              2.2.2. Data from cookies. These are small text files in which, after visiting the Site, cookies store information about the client's actions on the resource. These files do not affect the computer's operating system. Why cookies are collected: IrisTrans uses this information to keep statistics about users and their requests. These studies help us to analyze, evaluate, improve our work, improve the quality of services, improve the mechanisms of communication with clients. The Client may delete cookies after visiting the Site. However, in this case, IrisTrans is not responsible for the inadequate quality of the resource.<br></br>
              2.2.3. Information from the browser - data that the browser automatically sends to the server, for example, IP address, recent browsing history, operating system name, name and version of the program through which the client accesses the Internet, date and time the user visited the site. You can prevent your browser from transmitting such information by changing your settings. For what purpose it is collected: the online store keeps statistics of visits for the functioning of its servers and accounting for its own traffic. In this case, the collected statistical information is not associated with specific users and is processed only in the form of generalized statistics. The client's IP address and system access time, according to the user ID, are stored in a database in order to prevent fraud.<br></br>
              2.2.4. Information from third parties is personal data that is provided to IrisTrans by partners, suppliers or other third parties.<br></br>
              2.4. Any other personal information not specified above is subject to secure storage and non-distribution, except as provided in paragraphs. 3.1., 3.2. and 3.3. of this Privacy Policy.
            </p>
            <h2 className={`${styles.items__title}`}>3. Protection of personal information of users</h2>
            <p className={`${styles.item}`}>
              3.1. IrisTrans never and under no circumstances discloses to third parties personal (personal) information about its customers.<br></br>
              3.2. IrisTrans has the right to use other companies and individuals to perform certain types of work, such as: mail and email delivery, removal of duplicate information from customer lists, data analysis, marketing services, credit card and credit payment processing. These legal entities/individuals have access to users' personal information only when it is necessary to perform their functions. This information cannot be used by them for other purposes.<br></br>
              3.3. IrisTrans implements measures to protect the personal (personal) data of customers in the following areas: </p>
            <ul className={`${styles.list_items}`}>
              <li>prevention of leakage of information containing personal (personal) data through technical communication channels and other means;</li>
              <li>prevention of unauthorized access to information containing personal (personal) data, special influences on such information (information carriers) in order to obtain, destroy, distort and block access to it;</li>
              <li>protection against malware;</li>
              <li>detection of intrusions and computer attacks.</li>
            </ul>
            <h2 className={`${styles.items__title}`}>4. Period of storage of personal information</h2>
            <p className={`${styles.item}`}>
            IrisTrans stores personal information of clients for exactly as long as it remains necessary to achieve the purposes for which it was originally obtained, or other lawful purposes, except in cases where a longer period of information storage is required in accordance with the law or permitted by them.
            </p>
            <h2 className={`${styles.items__title}`}>5. Security</h2>
            <p className={`${styles.item}`}>
              5.1. We take technical and organizational measures to protect your confidential information from unauthorized actions of third parties. From Our side, access to your personal data is available to a limited number of employees and authorized persons who need this information to process data on our behalf and for the purposes expressly provided for in this Privacy Policy. All of them undertake confidentiality obligations and in case of violation of the mentioned obligations will be subject, as applicable, to disciplinary, civil, administrative and / or criminal liability.<br></br>
              5.2. Access to your confidential information can also be carried out through the usual authorization system using your login and password. If You neglect to independently ensure the security of Your account, third parties may gain unauthorized access to Your information, for which We are in no way responsible.
            </p>
            <h2 className={`${styles.items__title}`}>6. Introduction of changes and additions</h2>
            <p className={`${styles.item}`}>
              6.1. This policy is in effect indefinitely until replaced by a new version.<br></br>
              6.2. Any changes to the terms or conditions of the privacy policy will be reflected in this document. IrisTrans reserves the right to make changes to certain sections of this document at any time without prior notice by posting an updated version of this Privacy Policy on the Site. From the moment of its publication, users are subject to the new terms of the Privacy Policy.
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}
export default PrivacyPolicyEn;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cargoUpdateForm: false,
};

export const udateCargoFormSlice = createSlice({
  name: 'cargoUpdateForm',
  initialState,
  reducers: {
    openEditCargoForm: (state, action) => {
      return {
        ...state,
        cargoUpdateForm: true
      }
    },
    closeEditCargoForm: (state, action) => {
      return {
        ...state,
        cargoUpdateForm: false
      }

    },
  }
});

export const { openEditCargoForm, closeEditCargoForm } = udateCargoFormSlice.actions;
export const updateCargoFormReducer = udateCargoFormSlice.reducer;
export const block1 = [
  {
    title: "How to register?",
    content: [
      '1. Click Register in the upper right corner of the site.', '2. Select Registration type.', '3. Select Activity.', '4. Fill in the contact details in the "Registration" form.', 'If you are registering as a legal entity, there may be several legal entity identification numbers on the registration form, each of which has its own name. One of the numbers is mandatory, the rest are optional. The number of identification numbers and their names depend on the country of registration.', '5. Verify the email address provided when registering your account.'
    ]
  },
  {
    title: "Complete registration and profile verification",
    content: ['Log in to the IrisTrans website.', ' You can pass a profile check when registering on the site by attaching documents for verification (passport or certificate of state registration). Passing the profile verification is considered successful if the “Profile verified” icon appears in the account.']

  },
  {
    title: "Editing contact information",
    content: ['Log in to the IrisTrans website', 'In the menu on the left, click "Account / My Page".', 'Enter the "Contacts" section.', 'Click on the edit icon.', 'Make changes.']
  },
  {
    title: "What restrictions on the site do individuals have?",
    content: ['An individual, not a business entity, therefore, as a User of the Site and its Services, has a number of restrictions, including:', 'in posting reviews', 'the impossibility of adding employees, brand, logo and individual topics of the forum.', 'IrisTrans has the right to change the list of restrictions at its own discretion and without special notice to such User.']
  },
  {
    title: "What to do if your account has been hacked?",
    content: ['Notify the IrisTrans contact center.', 'Protect your computer from viruses.', 'Check your access settings and change your email password.', 'Change your password to log in to your account on the IrisTrans website.', 'If you log in to the IrisTrans website through a social network account, check if this account has been hacked.']
  },
  {
    title: "What to do if you forgot your password?",
    content: ['On the login page, click "Forgot Password".', 'Enter the email address associated with your account and click "Submit Request".', 'An email will be sent to you with a reminder of your login and a link to reset your password.', 'You need to follow this link and enter a new password.']
  }
]

export const block2 = [
  {
    title: "Placement of applications",
    content: [
      '1. To add applications, click on "≡” next to the IrisTrans logo, select “I am a shipper - My cargo / I am a carrier - My transport” button “Add cargo / Add transport” or with the left side menu collapsed:', '2. on any page, click on the right “+”, select Freight / Transport', '3. click “+” in the “Cargo/Transport” block in your personal account.', '4. Fill in the required fields in the opened form "Add cargo/Add transport".', '5. Check the box "I agree with the Rules for placing applications".', '6. Click "Add Application".'
    ]
  },
  {
    title: "Application update",
    content: [
      '1. Log in to the IrisTrans website.', '2. To update applications, click on "≡” next to the IrisTrans logo, select “I am a shipper - My cargo / I am a carrier - My transport” or with the left side menu collapsed, go to the "My cargo" / "My transport" page.', '3. In the list of applications, select the one you want to update.', '4. In the "Repeat" column of the selected order, click "Repeat".'
    ]
  },
  {
    title: "Application search",
    content: [
      '1. To search for applications, click on "≡” next to the IrisTrans logo, select “I am a shipper - Search for transport / I am a carrier - Search for cargo” or with the left side menu collapsed, go to the page "Search for cargo" (or "Search for transport").',
      '2. In the opened form "Search for cargo for transportation from IrisTrans" / "Search for transport for transportation" enter the search parameters or select the filters you created. You can choose between simple or advanced search.',
      '3. Click "Find".'
    ]
  },
  {
    title: "Creating a filter and automatic search",
    content: [
      '1. In the Cargo/Transport Search, enter all the necessary parameters and click "Find".'
    ]
  },
  {
    title: "View offers for cargo/transport",
    content: [
      'To view offers on cargo/transport, click on “≡” next to the IrisTrans logo, select “I am a carrier / I am a shipper” - “My transport / My cargoes” or with the left side menu collapsed, go to the “My transport” page.'
    ]
  },
]

export const block3 = [
  {
    title: "Connecting a service package",
    content: [
      '1. Log in to the IrisTrans website.',
      '2. To connect the service package, click on “≡” next to the IrisTrans logo, select “Services and prices”. ',
      '3. On the "Services and prices" page that opens, select the required service package.',
      '4. Click Connect.',
    ]
  },
]
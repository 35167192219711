
import styles from './shipper.module.css'
import { PagesDescription } from '../../components/pages-description';
import boxes from "./images/boxes.png"
import tableSearch from "./images/table1.png"
import tableReg from "./images/table2.png"
import { PagesItem } from '../../components/pages-item';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

export function ShipperPage() {
  const typeOfLanguage = useSelector(state => state.language.language);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={styles.wrapper}>
      <div className="inner">
        <PagesDescription title={t('to-the-consignor')}
          text={t('use-the-iristrans-platform')}
          img={boxes} />
        <PagesItem title={t('extensive-base-of-carriers')}
          text={t('we-offer-a-wide-selection-of-verified-carriers-ready-to-fulfil-your-order-all-hauliers-on-our-platform-have-been-checked-which-allows-us-to-guarantee-the-safety-of-your-cargo-and-its-delivery-in-due-time')}
          img={tableSearch} />
        <PagesItem title={t('convenient-addition-of-cargo')}
          text={t('in-a-few-minutes-add-a-request')}
          img={tableReg} />
        <p className={styles.item_title}>{t('quick-transport-search')} </p>

        <p className={styles.item_text}> {t('we-have-implemented-a-universal')}</p>

        <p className={styles.item_title}>{t('carrier-rating')}</p>

        <p className={styles.item_text}>{t('shippers-can-leave-feedback')}</p>
      </div>
    </main>
  )
}
export default ShipperPage;

// import { useTranslation } from "react-i18next";
// const { t } = useTranslation();
export const navItems = [
  {
    id: "1",
    // title: "Перевозчику",
    title: 'header.menu.toCarrier',
    path: "perevozchiku"
  },
  {
    id: "2",
    // title: "Грузоотправителю",
    title: "header.menu.toShipper",
    path: "gruzootpravitelyu"
  },
  {
    id: "3",
    // title: "Экспедитору",
    title: "header.menu.toFreightForwarder",
    path: "ekspeditoru"
  },
  {
    id: "4",
    // title: "Тарифы",
    title: "header.menu.Rates",
    path: "tariff"
  },
  {
    id: "5",
    // title: "Оплата",
    title: "header.menu.payment",
    path: "oplata"
  },
  {
    id: "6",
    // title: "FAQ",
    title: "header.menu.faq",
    path: "faq"
  },
  // {
  //   id: "7",
  //   // title: "Правила",
  //   title: "header.menu.rules",
  //   path: "pravila",
  //   submenu: [{
  //     // title: "Пользовательское соглашение",
  //     title: "header.menu.termsOfUse",
  //     path: "polzovatelskoe-soglashenie",
  //   },
  //   {
  //     // title: "Политика конфиденциальности",
  //     title: "header.menu.PrivacyPolicy",
  //     path: "politika-konfidencialnosti",
  //   },
  //   {
  //     // title: "Правила размещения заявок",
  //     title: "header.menu.rulesPlacing",
  //     path: "pravila-razmeshcheniya-zayavok",
  //   },
  //   {
  //     // title: "Правила проверки достоверности учётной записи (верификация)",
  //     title: "header.menu.rulesAccountValidation",
  //     path: "verifikaciya",
  //   },
  //   {
  //     // title: "Публичная оферта",
  //     title: "header.menu.publicContract",
  //     path: "publichnaya-oferta",
  //   }
  //   ]
  // }
];

import stylesGeneral from './pravila.module.css'
import styles from './rules.module.css'
import { useEffect } from 'react';

export function RulesPlacingPer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={stylesGeneral.wrapper}>
      <div className="inner">
        <div className={styles.rules__body}>
          <div class="wrapper">
            <div class="container">
              <div className={`${styles.rules__wrapper}`}>
                <h1 className={`${styles.rules__title}`}>قوانین برای قرار دادن برنامه ها
                </h1>
                <h2 className={`${styles.items__title}`}>1. اطلاعات عمومی</h2>
                <p className={`${styles.item}`}>
                  1.1. . برنامه - پیامی که توسط کاربر در بخش های "محموله" و "حمل و نقل" وب سایت IrisTrans.com با پر کردن یک فرم خاص ارسال می شود و حاوی پیشنهادی برای نیاز به حمل بار و / یا پیشنهادی برای در دسترس بودن است. حمل و نقل رایگان برای حمل بار.<br></br>
                  1.2. هر کاربر می تواند پس از ثبت نام در وب سایت IrisTrans.com برای حمل بار سفارش دهد. قرار دادن برنامه برای در دسترس بودن حمل و نقل رایگان تنها پس از تأیید حساب امکان پذیر است.<br></br>
                  1.3. هر برنامه ای می تواند توسط مدیریت سایت برای مطابقت با الزامات این قوانین بررسی شود.<br></br>
                  1.4. . در صورت شکایت کاربر، مدیریت سایت برنامه را برای مطابقت با الزامات قانون فعلی جمهوری بلاروس، توافقنامه کاربر سایت IrisTrans.com و این قوانین بررسی می کند.


                </p>
                <h2 className={`${styles.items__title}`}>2. قوانین کاربردی</h2>
                <p className={`${styles.item}`}>
                  2.1. . برای قرار دادن یک درخواست با پیشنهاد نیاز به حمل بار و / یا پیشنهاد برای در دسترس بودن حمل و نقل رایگان برای حمل بار، لازم است تمام فیلدهای مورد نیاز را در بخش مربوطه سایت در فرم پر کنید. برای اضافه کردن یک برنامه<br></br>
                  2.2. تمام اطلاعات موجود در برنامه باید با اطلاعات کاربری که برنامه را ارسال می کند مطابقت داشته باشد.<br></br>
                  2.3. مسئولیت صحت اطلاعات مشخص شده در برنامه بر عهده کاربری است که برنامه مربوطه را قرار داده است.<br></br>
                  2.4. . تمام بازخوردهای مثبت و منفی در مورد اجرای برنامه های کاربردی ارسال شده به کاربر ارسال کننده برنامه و کاربری که برنامه را پذیرفته (شرایط آن را پذیرفته است) خطاب می شود و مربوط به آن است.<br></br>
                  2.5. ارائه یک برنامه و پذیرش آن (پذیرش شرایط آن) می تواند با استفاده از روش های تأیید اضافی انجام شود، به عنوان مثال، با وارد کردن کدهای اضافی ارسال شده توسط کاربر به ایمیل یا شماره تلفن همراه مشخص شده در نمایه.<br></br>
                  2.6. در صورت عدم انجام روش های تأیید اضافی، برنامه در وب سایت IrisTrans.com قرار نمی گیرد.


                </p>
                <h2 className={`${styles.items__title}`}>3. محدودیت های کاربردی</h2>
                <p className={`${styles.item}`}>
                  3.1. ارائه درخواست به نفع اشخاص ثالث و / یا از طرف اشخاص حقیقی، حقوقی یا کارآفرینان فردی که کاربران ثبت نام نشده وب سایت IrisTrans.com هستند ممنوع است.<br></br>
                  3.2. . قرار دادن برنامه هایی که حاوی پیشنهادهای دیگری غیر از پیشنهادات برای نیاز به حمل بار و / یا پیشنهادهایی برای در دسترس بودن وسایل نقلیه رایگان برای حمل و نقل بار باشد ممنوع است.<br></br>
                  3.3. . ارائه درخواست برای حمل کالا و / یا در دسترس بودن حمل و نقل رایگان به / از قلمرو (هایی) که مطابق با قوانین بین المللی به رسمیت شناخته نشده اند، ممنوع است.<br></br>
                  3.4. استفاده از فحاشی و/یا فحاشی پنهان (به ویژه، اما نه منحصراً، کلمات (عبارات) ناسزا که حروف جداگانه آنها با نمادهای دیگر جایگزین می شود، ممنوع است).<br></br>
                  3.5. نوشتن کلمات به طور کامل با حروف بزرگ ممنوع است، به جز نام های رسمی، عناوین و / یا اختصارات.<br></br>
                  3.6. درج اطلاعاتی که با اطلاعات پروفایل کاربری ارسال کننده برنامه مطابقت ندارد در برنامه ممنوع است.<br></br>
                  3.7. . به روز رسانی برنامه ها بیشتر از یک بار در هر 60 دقیقه ممنوع است.<br></br>
                  3.8. . نشان دادن محموله / حمل و نقل یکسان در چندین برنامه یکسان (کلون) ممنوع است.<br></br>
                  3.9. برنامه ها از قرار دادن اطلاعات تماس (شماره تلفن، آدرس، ICQ و غیره) در هر زمینه ای غیر از قسمت "اطلاعات تماس" منع شده اند.<br></br>
                  3.10. در "یادداشت" فقط می توانید اطلاعاتی را مشخص کنید که هیچ فیلد خاصی در فرم درخواست وجود ندارد.<br></br>
                  3.11. . پر کردن فرم درخواست باید منحصراً مطابق با هدف فیلدها انجام شود.<br></br>
                  3.12. در درخواست حمل کالا باید نقاط دقیق بارگیری / تخلیه ذکر شود.


                </p>
                <h2 className={`${styles.items__title}`}>4. حذف برنامه ها و مسئولیت کاربران در قبال نقض قوانین ارسال برنامه</h2>
                <p className={`${styles.item}`}>
                  4.1. اگر کاربر مفاد بندهای 3.1 - 3.12 از بخش 3 این قوانین را نقض کند، برنامه مشخص شده حذف می شود و ممکن است تحریم هایی برای کاربر اعمال شود از مسدود کردن امکان قرار دادن برنامه ها تا مسدود کردن حساب. مدت زمان سلب حق ثبت سفارش در هر مورد با در نظر گرفتن تمامی شرایط به تشخیص آیریس ترنس تعیین می شود.<br></br>
                  4.2. رفع انسداد فرصت ارسال برنامه ها توسط مدیریت سایت پس از بررسی درخواست تجدید نظر کاربر از مدیریت سایت با توضیح دلایل نقض الزامات این قوانین انجام می شود.<br></br>
                  4.3. . مدیریت وب‌سایت IrisTrans.com این حق را برای خود محفوظ می‌دارد که برنامه‌هایی را که با الزامات قانون فعلی جمهوری بلاروس، الزامات قرارداد کاربر وب‌سایت IrisTrans.com و الزامات این قوانین مطابقت ندارند، حذف کند. و همچنین از رفع انسداد امکان ارسال برنامه های کاربردی بر اساس نتایج رسیدگی به درخواست کاربر توسط کاربر خودداری می کند.
                </p>
                <h2 className={`${styles.items__title}`}>5. حذف درخواست های نامربوط</h2>
                <p className={`${styles.item}`}>
                  5.1. پس از پایان تاریخ مشخص شده در قسمت "تاریخ آپلود از" یا در قسمت "تاریخ آپلود به" فرم درخواست، برنامه ها به طور خودکار از پایگاه داده سایت حذف می شوند.<br></br>
                  5.2. اگر برنامه قبل از مهلت های تعیین شده بسته شود، کاربر موظف است به طور مستقل برنامه های بسته یا نامربوط را حذف کند.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
export default RulesPlacingPer;
import stylesGeneral from './pravila.module.css'
import styles from './rules.module.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

export function RulesOfertaPer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className={stylesGeneral.wrapper}>
      <div className="inner">
        <div className={styles.rules__body}>
          <div class="container">
            <div className={`${styles.rules__wrapper}`}>
              <h3 className={`${styles.rules__title}`}>قرارداد عمومی
(پیشنهاد)
ارائه خدمات پرداخت شده با انتقال بانکی
سرور حمل و نقل و اطلاعات IrisTrans.com

              </h3>
              <p className={`${styles.item}`}>این توافق نامه عمومی (از این پس به عنوان "توافقنامه" نامیده می شود) روند ارائه خدمات سایت - "سرور حمل و نقل و اطلاعات IrisTrans.com" و همچنین حقوق متقابل، تعهدات و رویه روابط بین:<br></br>
                - نماینده سایت - LLC "IRIS inter GROUP" به نمایندگی از مدیر Isapur Iraja، بر اساس منشور (از این پس به عنوان - مجری)<br></br>
                و<br></br>
                - - مصرف کننده خدمات سایت (یک شخص، یک کارآفرین فردی یا یک شخص حقوقی) که یک پیشنهاد عمومی (پیشنهاد) را برای انعقاد این توافق نامه (از این پس به عنوان - پذیرفته است)مشتری).<br></br>
                این توافقنامه مطابق با مفاد مواد 396، 404، 407، 408 قانون مدنی جمهوری بلاروس و همچنین سایر مقررات قوانین جمهوری بلاروس که این روابط حقوقی را تنظیم می کند، منعقد و اجرا می شود.
              </p>

            </div>
          </div>
        </div>


        <h2 className={`${styles.items__title}`}>1. تعاریف استفاده شده در این موافقتنامه</h2><br></br>
        <p className={`${styles.item}`}>
          1.1. کاربر- مشتری تحت این قرارداد یک فرد، یک کارآفرین فردی، یک شخص حقوقی و همچنین نمایندگان مجاز آنها است که یک پیشنهاد عمومی (پیشنهاد) را برای انعقاد این توافقنامه و دسترسی به سایت و / یا استفاده از سایت پذیرفته اند (پذیرفته اند). خدمات با ایجاد یک حساب کاربری.<br></br>
          1.2. سایت اینترنتی— سرور حمل و نقل و اطلاعات IrisTrans.com، و همچنین یک منبع اطلاعاتی موجود در اینترنت با استفاده از نام دامنه iristrans.com (از جمله از طریق هر نام دامنه دیگری که به iristrans.com هدایت می شود).<br></br>
          1.3. .Iris Trans— سرور حمل و نقل و اطلاعات IrisTrans.com، و همچنین LLC "Iris inter GROUP" یا سایر نمایندگان مجاز سرور حمل و نقل و اطلاعات IrisTrans.com.<br></br>
          1.4. خدمات/خدمات- خدمات اطلاعات جداگانه ای که از طریق سایت و / یا به هر طریق دیگری (به ویژه، اما نه منحصراً، از طریق توزیع ایمیل، برنامه های کاربردی نصب شده جداگانه و / یا سایر منابع اینترنتی) با استفاده قانونی از علامت تجاری IrisTrans به کاربر ارائه می شود. و / یا نام‌های مشابه (مشتق) و همچنین بخش‌های کاربردی مربوطه سایت و روش‌های ارائه خدمات.<br></br>
          1.5. خدمات- دسترسی به اطلاعات و عملکرد سایت و همچنین دریافت سایر خدمات اطلاعاتی سایت با ایجاد و استفاده از حساب کاربری.<br></br>
          1.6. توافق- در قرارداد کاربر سایت، سیاست حفظ حریم خصوصی و همچنین سایر شرایط و قوانین استفاده از سایت و / یا خدمات فردی سایت ارسال شده است.<br></br>
          1.7. حساب- مجموعه ای از داده های ذخیره شده در سایت در مورد کاربر، که برای شناسایی او و امکان دسترسی به خدمات و خدمات سایت پس از مجوز با استفاده از ورود و رمز عبور کاربر ضروری است.<br></br>
          1.8. کلیه اصطلاحات و تعاریف دیگر در این توافقنامه به معانی تعیین شده توسط مفاد قانون فعلی جمهوری بلاروس استفاده می شود.

        </p>
        <h2 className={`${styles.items__title}`}>2. موضوع قرارداد</h2><br></br>
        <p className={`${styles.item}`}>
          2.1. پیمانکار از طرف مشتری متعهد می شود که خدمات سایت و/یا خدمات را مطابق با بسته خدمات انتخاب شده توسط مشتری ارائه دهد.<br></br>
          2.2. مشتری متعهد می شود که خدمات و / یا خدمات ارائه شده توسط پیمانکار را بپذیرد و هزینه آنها را به روش و شرایط تعیین شده توسط این توافق نامه و لیست خدمات و قیمت های ذکر شده در سایت بپردازد.<br></br>
          2.3. تعرفه ها، فهرستی از خدمات، خدمات و عملکردهای خاص سایت، که برای پرداخت به مشتری ارائه می شود، برای بررسی و انتخاب در حساب مشتری و/یا در آدرس زیر موجود است:  <NavLink to={'/tariff'} key={'tariffinfo'}>
            <span className={styles.link}>نرخ ها</span>
          </NavLink>.


        </p>
        <h2 className={`${styles.items__title}`}>3. مراحل انعقاد قرارداد</h2><br></br>
        <p className={`${styles.item}`}>
          3.1. این قرارداد یک توافق عمومی است (ماده 396 قانون مدنی جمهوری بلاروس) که طبق آن پیمانکار تعهد می کند که خدمات را به تعداد نامحدودی از افراد (مشتریان) که برای ارائه خدمات درخواست کرده اند ارائه دهد.<br></br>
          3.2. انتشار (قرار دادن) متن این توافقنامه در وب سایت رسمی پیمانکار به نشانی: iristrans.com یک پیشنهاد عمومی (پیشنهاد) پیمانکار است که خطاب به حلقه نامحدودی از افراد برای انعقاد این توافقنامه (بند 2، ماده 407) قانون مدنی جمهوری بلاروس).<br></br>
          3.3. انعقاد این توافقنامه با الحاق مشتری به این قرارداد انجام می شود، یعنی. از طریق پذیرش (پذیرش) توسط مشتری شرایط این توافقنامه به طور کلی، بدون هیچ گونه شرط، استثنا و رزرو (ماده 398 قانون مدنی جمهوری بلاروس).<br></br>
          3.4. واقعیت پذیرش (پذیرش) شرایط این توافقنامه عبارت است از: 3.4.1. ثبت درخواست آنلاین توسط مشتری برای ارائه خدمات و پرداخت خدمات سفارش داده شده به روش و شرایط تعیین شده توسط این توافقنامه (بند 3 ماده 408 قانون مدنی جمهوری بلاروس).<br></br>
          3.5. این موافقتنامه، با رعایت مراحل پذیرش آن، به صورت کتبی ساده (بند 2، بند 3 ماده 404 و بند 3 ماده 408 قانون مدنی جمهوری بلاروس) منعقد شده تلقی می شود و نیازی به کاغذبازی و اسناد ندارد. کاملا قانونی به زور دارد.<br></br>
          3.6. برای دسترسی به خدمات، مشتری مراحل ثبت نام مشتری جدید را در صفحه پیمانکار به نشانی iristrans.com طی می کند یا با استفاده از ورود و رمز ورود به سایتی که مشتری از قبل در اختیار دارد، مجوز دریافت می کند.<br></br>
          3.7. هنگام ثبت نام، مشتری موظف است اطلاعات قابل اعتماد زیر را در حساب کاربری خود درج کند:<br></br>
          3.7.1. شخص حقیقی - نام خانوادگی، نام، نام خانوادگی (در صورت وجود)، آدرس پستی یا آدرس ثبت نام، شماره شناسایی (در صورت وجود)، آدرس ایمیل، شماره تلفن تماس؛<br></br>
          3.7.2. . کارآفرین فردی - اطلاعات مشخص شده در زیر بند 3.7.1. بند 3.7. این موافقتنامه، و همچنین اطلاعات مربوط به ثبت نام ایالتی (ثبت نام یا شماره شناسایی دیگر، نام نهاد (سازمان) که ثبت نام دولتی را انجام داده است، تاریخ ثبت، شماره تصمیم (در صورت وجود)).<br></br>
          3.7.3. شخص حقوقی - نام کامل، محل (آدرس پستی)، اطلاعات مربوط به ثبت نام دولتی (ثبت نام یا شماره شناسایی دیگر، شماره حساب پرداخت کننده (در صورت وجود)، آدرس ایمیل، شماره تلفن تماس، نام خانوادگی، نام، نام خانوادگی (در صورت وجود) رهبر.<br></br>
          3.8. داده های مشخص شده توسط مشتری به روشی که در بند 3.7 مشخص شده است. این قرارداد به طور خودکار برای شناسایی مشتری و دسترسی به سایت و/یا خدمات استفاده خواهد شد.<br></br>
          3.9. حساب ایجاد شده در سایت پس از تأیید اطلاعات مشخص شده در حساب توسط IrisTrans با استفاده از شماره تلفن مشخص شده، ایمیل یا سایر داده های شناسایی و ابزارهای فنی که امکان شناسایی هویت و / یا نام کاربر را فراهم می کند، فعال می شود. در همان زمان، یک شماره تلفن و / یا یک مجموعه از داده های شناسایی دیگر را می توان برای ثبت بیش از یک حساب کاربری در سایت استفاده کرد.<br></br>
          3.10. ثبت درخواست آنلاین برای ارائه خدمات توسط مشتری در وب سایت رسمی پیمانکار با استفاده از رابط وب واقع در: iristrans.com با انجام یک سری اقدامات برای پر کردن فرم های قالب ویژه به منظور ارائه انجام می شود. اطلاعات لازم برای انعقاد این قرارداد و ارائه خدمات بعدی.<br></br>
          3.11. مشتری موظف است اطلاعات مندرج در متن نهایی درخواست آنلاین برای ارائه خدمات را بررسی کند و در صورت لزوم برای اصلاح اطلاعات نادرست، مراحل تکمیل درخواست آنلاین را تکرار کند. نشان دادن اطلاعات نادرست یا نادرست برای IrisTrans مبنای امتناع از ارائه خدمات و / یا خدمات است.<br></br>
          3.12. فهرست خدماتی که تحت این توافقنامه ارائه می شود در برنامه آنلاین ارائه خدمات که توسط مشتری به روش و شرایط مشخص شده در این توافقنامه و همچنین در تقاضای پرداخت برای خدمات سفارش داده شده انجام می شود نشان داده شده است. (حساب برای اشخاص حقوقی و کارآفرینان فردی یا رسید پرداخت برای اشخاص حقیقی) که توسط پیمانکار برای پرداخت توسط مشتری صادر می شود.<br></br>
        </p>
        <h2 className={`${styles.items__title}`}>4. حقوق و تعهدات طرفین</h2><br></br>
        <p className={`${styles.item}`}>
          4.1. . پیمانکار متعهد می شود:<br></br>
          4.1.1. خدمات را به میزان و در بازه زمانی تعیین شده توسط تعرفه ها، لیست خدمات، خدمات و عملکردهای خاص سایت و مشخص شده در تقاضای پرداخت برای خدمات سفارش شده توسط مشتری (فاکتور یا رسید پرداخت) به مشتری ارائه دهید. ، که توسط مشتری به طور مستقل با استفاده از رابط وب واقع در سوابق حسابداری تشکیل می شود.<br></br>
          4.1.2. در طول ساعات کاری (از ساعت 9:00 تا 18:00، به استثنای تعطیلات آخر هفته و تعطیلات رسمی رسمی)، مشاوره تلفنی و / یا ایمیل در مورد مسائل ناشی از مشتری در ارتباط با ارائه خدمات به مشتری ارائه دهید.<br></br>
          4.1.3. از محرمانه بودن پارامترهای دسترسی به سرور (ورود، رمز عبور) اطمینان حاصل کنید، مگر در مواردی که ارائه دسترسی به چنین اطلاعاتی برای اشخاص ثالث شرط لازم برای ارائه خدمات باشد یا به دلیل الزامات اجباری باشد. قانون جمهوری بلاروس<br></br>
          4.2. مجری حق دارد:<br></br>
          4.2.1. . دریافت پرداخت برای خدمات ارائه شده به میزان و به روشی که در توافقنامه مشخص شده و همچنین تعرفه ها، لیستی از خدمات خاص، خدمات و عملکردهای سایت تعیین شده است.<br></br>
          4.2.2. در صورتی که مشتری شرایط این قرارداد را نقض کند یا مشتری مرتکب اقداماتی در سایت شود که حقوق و منافع قانونی پیمانکار، سایر کاربران سایت و / یا هر شخص ثالثی را نقض می کند، ارائه خدمات را خاتمه دهید. <br></br>
          4.2.3. شرایط این توافقنامه، تعرفه ها و لیست خدمات و/یا خدمات ارائه شده به مشتری را با اطلاع رسانی اجباری تغییرات آتی حداقل 1 (یک) ماه قبل از چنین تغییراتی به طور یکجانبه تغییر دهید.<br></br>
          4.3. مشتری متعهد می شود:<br></br>
          4.3.1. مطابق با شرایط این توافقنامه، مفاد قانون فعلی جمهوری بلاروس و سایر هنجارهایی که برای اجرا در قلمرو جمهوری بلاروس الزامی است.<br></br>
          4.3.2. به پیمانکار برای خدمات سفارش داده شده به روش، به میزان و در مدت زمان مشخص شده در این توافق نامه بر اساس فاکتور یا رسید پرداختی که توسط مشتری به طور مستقل با استفاده از رابط وب واقع در حساب ایجاد می شود، پرداخت کنید.<br></br>
          4.3.3. اطمینان از ایمنی و محرمانه بودن اطلاعات خدمات دریافتی از پیمانکار (لینک ها، نام های دسترسی و رمز عبور، شماره تلفن همراه متخصصان پیمانکار و غیره)؛<br></br>
          4.3.4. در صورت وجود ادعایی برای خدمات ارائه شده توسط پیمانکار، ظرف 2 (دو) روز کاری از لحظه ای که مشتری از عدم انجام یا اجرای نادرست پیمانکار از تعهدات مطلع شده یا باید آگاه می شد، مراتب را به پیمانکار اطلاع دهید. بر اساس این موافقتنامه پذیرفته شده است.<br></br>
          4.4. مشتری حق دارد:<br></br>
          4.4.1. از پیمانکار بخواهید که خدمات را به درستی ارائه دهد.<br></br>
          4.4.2. دریافت مشاوره از پیمانکار در ساعات کاری (از ساعت 9:00 تا 18:00، به استثنای تعطیلات آخر هفته و تعطیلات رسمی رسمی) از طریق تلفن و / یا ایمیل در مورد مسائل ناشی از ارائه خدمات؛<br></br>
          4.4.3. فسخ این قرارداد در صورت: <br></br>
          عدم انجام یا اجرای نادرست توسط پیمانکار به تعهدات خود برای ارائه خدمات تحت این قرارداد؛
عدم موافقت با تغییرات و / یا اضافات ایجاد شده توسط پیمانکار به این قرارداد.
<br></br>
       
          4.5. با انعقاد این قرارداد، مشتری رضایت کامل و بدون قید و شرط خود را برای دریافت اطلاعات تبلیغاتی از پیمانکار و همچنین سایر اطلاعات توزیع شده توسط پیمانکار (از جمله از طریق پیامک، پیام به آدرس های ایمیل و/یا پیام های شخصی در حساب) می دهد. چنین رضایتی توسط IrisTrans برای مقاصد و در مواردی که نیاز به دریافت چنین رضایتی توسط قوانین جمهوری بلاروس پیش بینی شده است استفاده می شود. مشتری از حق خود مطلع می شود که از پیمانکار درخواست خاتمه فوری قرار دادن (توزیع) اطلاعات تبلیغاتی خطاب به مشتری را داشته باشد.<br></br>
          4.6. پیمانکار موظف است در اولین درخواست مشتری، در صورتی که قابلیت‌های فنی و نرم‌افزاری به پیمانکار اجازه می‌دهد بدون محدود کردن دسترسی مشتری به سایت، فورا درج (توزیع) اطلاعات تبلیغاتی به مشتری را متوقف کند. در غیر این صورت، پیمانکار این حق را دارد که دسترسی مشتری به خدمات سایت و/یا خدمات را محدود یا مسدود کند. پرداختی که مشتری در این مورد انجام داده است قابل استرداد نیست.<br></br>
          4.7. پیمانکار کارکرد 100% بدون خطا و بدون وقفه سایت و/یا خدمات را تضمین نمی کند و مسئولیتی در قبال آسیب احتمالی وارده به کاربر و/یا اشخاص ثالث در اثر نقص فنی سخت افزار و/یا نرم افزار از هر طرف ندارد.<br></br>
          4.8. پیمانکار صحت اطلاعات ارسال شده در سایت توسط اشخاص ثالث (به ویژه، اما نه منحصراً: برنامه های کاربردی برای کالاهای ارائه شده برای حمل و نقل و / یا در دسترس بودن حمل و نقل رایگان) را تضمین نمی کند و مسئولیتی در قبال آسیب احتمالی ناشی از آن ندارد. کاربر و/یا اشخاص ثالث در نتیجه استفاده از سایت/خدمات.
        </p>
        <h2 className={`${styles.items__title}`}>5. هزینه، رویه و شرایط پرداخت خدمات</h2><br></br>
        <p className={`${styles.item}`}>
          5.1. هزینه خدمات ارائه شده تحت این قرارداد بر اساس حجم، ماهیت و مدت خدمات سفارش داده شده توسط مشتری مطابق با تعرفه های تایید شده توسط پیمانکار، لیست خدمات خاص، خدمات و عملکردهای سایت، تعیین می شود. بلافاصله در زمان درخواست پرداخت برای خدمات سفارش داده شده.<br></br>
          5.2. تعرفه هایی که بلافاصله در زمان درخواست پرداخت برای خدمات سفارش داده شده اعمال می شوند، جزء لاینفک این توافقنامه هستند.<br></br>
          5.3. مشتری هزینه خدمات پیمانکار را بر اساس یک فاکتور یا رسید پرداخت می پردازد که توسط مشتری به طور مستقل با استفاده از رابط وب واقع در حساب ایجاد می شود.<br></br>
          5.4. مشتریان هزینه خدمات سفارش داده شده را از طریق انتقال غیرنقدی وجه به حساب تسویه حساب پیمانکار مطابق با داده ها و جزئیات مشخص شده در فاکتور پرداخت می کنند.<br></br>
          5.5. روش پرداخت و مشخصات بانکی لازم پیمانکار، که پرداخت خدمات سفارش داده شده پذیرفته می شود، در وب سایت رسمی پیمانکار به نشانی: https://IrisTrans.com.، و همچنین در فاکتور یا رسید پرداخت مربوطه که توسط مشتری به طور مستقل با استفاده از رابط وب واقع در حساب ایجاد می شود.
        </p>
        <h2 className={`${styles.items__title}`}>6. . مراحل تحویل و پذیرش خدمات</h2><br></br>
        <p className={`${styles.item}`}>
          6.1. ارائه خدمات تحت این قرارداد برای مشتریانی که اشخاص حقوقی و کارآفرینان فردی هستند توسط گواهی ارائه خدمات تأیید شده است که صرفاً توسط پیمانکار (مطابق با فرمان وزارت دارایی جمهوری بلاروس مورخ فوریه) تهیه شده است. 12، 2018 شماره 13 "در مورد تهیه تنها اسناد حسابداری اولیه و اعلام بی اعتباری فرمان وزارت دارایی جمهوری بلاروس مورخ 21 دسامبر 2015 شماره 58").<br></br>
          6.2. تاریخ ارائه خدمات و / یا خدمات، لحظه واریز وجه به حساب های تسویه سایت، مشروط به وجود حساب مشتری ثبت شده در سایت است.<br></br>
          6.3. مشتری تأیید می کند و موافقت می کند که گواهی ارائه خدمات مطابق با این توافق نامه صرفاً توسط پیمانکار تهیه شده است و مشتری به طور مستقل گواهی ارائه خدمات را با استفاده از رابط وب واقع در حساب دریافت می کند.<br></br>
          6.4. ارائه خدمات تحت این قرارداد برای مشتریانی که شخصی هستند، با فعال سازی موفقیت آمیز حساب مشتری در وب سایت و عدم وجود ادعاهای کتبی از مشتری در مورد خدمات و/یا خدمات ارائه شده توسط پیمانکار، که ظرف مدت 5 (5) ارسال شده است، تأیید می شود. پنج) روز تقویمی از تاریخ پایان دوره، که در آن خدمات و/یا خدمات ارائه شده است.
        </p>
        <h2 className={`${styles.items__title}`}>7. مسئولیت های طرفین</h2><br></br>
        <p className={`${styles.item}`}>
          7.1. در صورت عدم انجام یا اجرای نادرست تعهدات خود تحت این موافقتنامه، طرف مقصر مطابق با قوانین فعلی جمهوری بلاروس، با در نظر گرفتن مشخصات تعیین شده توسط این موافقتنامه، مسئول خواهد بود.<br></br>
          7.2. پیمانکار از مسئولیت خسارات وارده به طور صریح یا غیرمستقیم در نتیجه مصرف کامل یا جزئی یا ناتوانی در مصرف خدمات در موارد زیر برکنار می شود:<br></br>
          7.2.1. اگر در نتیجه عدم رعایت محرمانه بودن اعتبارنامه یا سایر اطلاعات محرمانه مشتری و همچنین به دلیل دسترسی غیرمجاز اشخاص ثالث به منابع فنی یا اطلاعاتی مشتری، ضرر و زیان ایجاد شود.<br></br>
          7.2.2. اگر به دلیل نقص فنی سخت افزار و/یا نرم افزار و همچنین ارائه دهندگان اینترنت در طرف پیمانکار یا مشتری خسارت ایجاد شود.<br></br>
          7.3. طرفین این توافقنامه بدون قید و شرط موافقت می کنند که حداکثر میزان خسارتی که می تواند از پیمانکار بازیابی شود محدود به مبلغ خدمات پرداخت شده توسط مشتری است که عدم انجام یا اجرای نادرست آن باعث خسارات می شود.<br></br>
          7.4. محدودیت مسئولیت پیمانکار مندرج در بند 7.5 این موافقتنامه در موارد زیر قابل اعمال نیست:<br></br>
          7.4.1. میزان مسئولیت برای این نوع تعهدات یا این تخلف توسط قانون جمهوری بلاروس تعیین می شود.<br></br>
          7.4.2. عدم انجام یا انجام نادرست تعهدات پیمانکار به موجب این موافقتنامه، نتیجه قصد مستقیم آن است و این واقعیت با تصمیم دادگاهی که لازم الاجرا شده است ثابت شده است.<br></br>
          7.5. . مشتری تنها مسئول هر گونه اقدامات غیرمجاز اشخاص ثالث است که در نتیجه عدم انطباق مشتری با محرمانه بودن اعتبار خود یا سایر اطلاعات محرمانه و همچنین عواقب چنین اقداماتی رخ داده است.<br></br>
          7.6. مشتری به طور مستقل مسئولیت کامل نقض احتمالی حق چاپ، علائم تجاری و سایر هنجارهای قانون جمهوری بلاروس را در رابطه با قرار دادن هرگونه اطلاعات مشتری در اینترنت بر روی اطلاعات و منابع فنی پیمانکار بر عهده دارد.<br></br>
          7.7. مشتری باید خسارات وارده به پیمانکار یا مقامات آن را در ارتباط با قرار دادن اطلاعات در مورد اطلاعات و منابع فنی مشتری که محتوای آن مغایر با قوانین جمهوری اسلامی باشد، به پیمانکار و/یا مقامات پیمانکار جبران کند. بلاروس و همچنین در سایر مواردی که عدم رعایت الزامات قانون جمهوری بلاروس و / یا تعهدات تحت این موافقتنامه عمومی توسط مشتری باعث ایجاد خسارت شده است.
        </p>
        <h2 className={`${styles.items__title}`}>8. فورس ماژور</h2><br></br>
        <p className={`${styles.item}`}>
          8.1. . در صورتی که این امر نتیجه شرایط فورس ماژور (فورس ماژور) باشد که پس از انعقاد این موافقتنامه در نتیجه رویدادهای فوق العاده ای که طرفین می توانستند به وجود آمده باشد، از مسئولیت در قبال عدم انجام کامل یا جزئی تعهدات خود تحت این موافقتنامه خلاص می شوند. اقدامات معقول را نه پیش بینی کنید و نه از آن جلوگیری کنید.<br></br>
          8.2. حوادث فورس ماژور شامل وقایعی است که حزب هیچ نفوذی بر آنها نداشته و مسئولیت وقوع آنها را ندارد، مانند: جنگ، شورش، اعتصاب، زلزله، سیل، آتش سوزی، آب و هوای شدید یا سایر بلایای طبیعی، مقررات دولتی، دستورات فرامین) ارگانها و مقامات دولتی، قوانین و سایر مقررات مقامات ذیصلاح که پس از پذیرش این موافقتنامه اتخاذ شده و انجام تعهدات مقرر در این موافقتنامه و همچنین اقدامات مقامات و مقامات دولتی ایالتی یا محلی یا آنها را غیرممکن می سازد. نمایندگانی که مانع اجرای شرایط این موافقتنامه و سایر شرایط پیش بینی نشده از جمله نقص در شبکه برق شهری می شوند.<br></br>
          8.3. در صورت بروز شرایط فورس ماژور که مانع از اجرای تعهدات مندرج در این موافقتنامه می شود، مدت اجرای این تعهدات توسط طرفین به تناسب مدت چنین شرایطی و همچنین زمان لازم برای رفع عواقب آن به تعویق خواهد افتاد، اما نه. بیش از شصت روز تقویمی
          8.4. در صورتی که شرایط فورس ماژور بیش از مدت مشخص شده در بند 8.3 این موافقتنامه به کار خود ادامه دهد، یا در صورت وقوع آنها برای هر دو طرف آشکار شود که شرایط برای بیش از این مدت عمل خواهد کرد، طرفین متعهد می شوند که در مورد آن بحث کنند. امکان راه های جایگزین برای اجرای این توافقنامه یا فسخ آن بدون خسارت.
        </p>
        <h2 className={`${styles.items__title}`}>9. اطلاعیه ها و اطلاعیه ها</h2><br></br>
        <p className={`${styles.item}`}>
          9.1. طرفین موافقت می‌کنند که بدون قید و شرط، قوت قانونی متون اسناد دریافتی از طریق کانال‌های ارتباطی (ایمیل) را بر اساس اسنادی که به صورت نوشتاری ساده بر روی کاغذ اجرا می‌شوند، به رسمیت می‌شناسند، مگر در مواردی که اجرای اسناد روی کاغذ اجباری باشد. با توجه به الزامات قانون جمهوری بلاروس.<br></br>
          9.2. طرفین بدون قید و شرط موافقت می کنند که کلیه مکاتبات، اطلاعیه ها و اعلان های دریافت شده در آدرس های ایمیلی که در سایت به عنوان مشخصات پیمانکار و در حساب مشتری به عنوان جزئیات مشتری ذکر شده است.<br></br>
          9.3. طرفین موظفند به موقع مکاتبات دریافتی را در آدرس های ایمیل خود که در سایت ذکر شده است بررسی کنند.<br></br>
          9.4. کلیه خطرات مرتبط با شروع عواقب نامطلوب ناشی از عدم رعایت الزامات بند 9.3 این موافقتنامه بر عهده طرفی است که مرتکب چنین تخلفی شده است.

        </p>
        <h2 className={`${styles.items__title}`}>10. مدت توافق و مراحل فسخ آن</h2><br></br>
        <p className={`${styles.item}`}>
          10.1. . این قرارداد از لحظه ای که وجوه پرداخت شده توسط مشتری برای پرداخت خدمات سفارش داده شده توسط وی مطابق با تقاضای پرداخت برای خدمات سفارش داده شده به حساب پیمانکار واریز می شود منعقد تلقی می شود.<br></br>
          10.2. این قرارداد برای مدت نامحدود تا زمان فسخ آن به روشی که در این موافقتنامه تعیین شده است معتبر است.<br></br>
          10.3. این قرارداد ممکن است فسخ شود:<br></br>
          10.3.1 با توافق طرفین؛<br></br>
          10.3.2 به طور یکجانبه به ابتکار پیمانکار مطابق بند 4.2.2 این موافقتنامه.<br></br>
          10.3.3 3 به طور یکجانبه به ابتکار مشتری مطابق بند 4.4.3 این قرارداد.<br></br>
          10.4. مشتری بدون قید و شرط موافقت می کند که در صورت فسخ این توافقنامه مطابق با بند 10.3.1 یا بند 10.3.2 این قرارداد، حق درخواست از پیمانکار را از دست می دهد که مبالغ پرداخت شده در پرداخت برای خدمات را از پیمانکار بخواهد. حتی اگر این خدمات هنوز منقضی نشده باشند.

        </p>
        <h2 className={`${styles.items__title}`}>11. روش ارائه تغییرات و الحاقات به توافقنامه</h2><br></br>
        <p className={`${styles.item}`}>
          11.1. تغییرات و / یا اضافات به این توافقنامه به صورت یک طرفه با تصمیم پیمانکار انجام می <br></br>
          11.2. تغییرات و / یا اضافات ایجاد شده توسط پیمانکار به ابتکار خود به این توافقنامه، زودتر از روز پس از انتشار آنها در وب سایت پیمانکار لازم الاجرا می شود.<br></br>
          11.3. تغییرات و / یا اضافات ایجاد شده توسط پیمانکار به این توافق نامه در ارتباط با تغییرات در قوانین همزمان با لازم الاجرا شدن تغییرات در این قوانین قانونی لازم الاجرا می شود.<br></br>
          11.4. متن اصلاحات و/یا الحاقات به این توافقنامه یا نسخه جدید آن توسط پیمانکار با قرار دادن (انتشار) اطلاعات مربوطه در وب سایت به آدرس زیر در اختیار عموم قرار می گیرد 
          <NavLink to={'https://IrisTrans.com'}>
            <span className={styles.link}> https://IrisTrans.com.</span>
          </NavLink>
          <br></br>
          11.5. در صورت عدم موافقت با تغییرات و/یا اضافات انجام شده، مشتری حق فسخ این قرارداد را مطابق بند 4.4.3 این توافقنامه دارد.<br></br>
          11.6. اخطار فسخ این قرارداد همچنین به عنوان هرگونه اخطار کتبی مشتری که بر روی کاغذ تنظیم شده باشد، در مورد عدم موافقت با تغییرات و/یا اضافات انجام شده، یا عدم پایبندی به نسخه جدید این توافقنامه یا امتناع از پیروی شناخته می شود. شرایط آن<br></br>
          11.7. طرفین بدون قید و شرط موافقت می‌کنند که عدم اعلام کتبی خاتمه این قرارداد، یا عدم موافقت با برخی مفاد این قرارداد، از جمله تغییرات در فهرست قیمت خدمات، به عنوان رضایت مشتری و الحاق به نسخه جدید این توافقنامه شناخته می‌شود. موافقت نامه (بند 3 ماده 159 قانون مدنی جمهوری بلاروس).
        </p>
        <h2 className={`${styles.items__title}`}>12. حل و فصل اختلافات</h2><br></br>
        <p className={`${styles.item}`}>
          12.1. کلیه اختلافات و اختلافات مربوط به این موافقتنامه، طرفین متعهد می شوند که از طریق مذاکره حل و فصل کنند.<br></br>
          12.2. اگر طرفین نتوانند همه اختلافات را به روش مقرر در بند 12.1 این موافقتنامه حل و فصل کنند، تمام اختلافات ناشی از این توافقنامه، از جمله موارد مربوط به انعقاد، اصلاح، فسخ، اجرا، بی اعتباری آن، در دادگاه مطابق با قانون جمهوری بلاروس
        </p>
        <h2 className={`${styles.items__title}`}>13. سایر شرایط</h2><br></br>
        <p className={`${styles.item}`}>
          13.1. طرفین بدون قید و شرط موافقت می کنند که این قرارداد در آدرس دفتر پیمانکار منعقد شود.<br></br>
          13.2. با انعقاد این قرارداد، مشتری بدینوسیله اعلام می کند که:<br></br>
          13.2.1. بر اساس اطلاعات وی، محتوای داده های وی مغایر با قوانین جمهوری بلاروس نیست و حقوق و منافع مشروع اشخاص ثالث، از جمله حقوق مالکیت معنوی را نقض نمی کند. 13.2.2. اطلاعات ارائه شده توسط او هنگام سفارش برای ارائه خدمات کامل، واقعی و دقیق است.<br></br>
          13.2.3. او آگاه است و موافقت می کند که برخی از اطلاعاتی که هنگام سفارش برای ارائه خدمات ارائه کرده است، ممکن است به دلیل الزامات قانون جمهوری بلاروس در دسترس اشخاص ثالث باشد.<br></br>
          13.3. اگر هر یک از شرایط این توافقنامه قدرت قانونی خود را از دست بدهد، غیرقانونی اعلام شود یا از این توافقنامه مستثنی شود، این امر مستلزم بی اعتباری شرایط باقیمانده این توافقنامه نیست، که از نظر قانونی معتبر خواهد بود و برای همه طرفین الزام آور است.<br></br>
          13.4. تمام مسائلی که توسط این توافقنامه تنظیم نشده است، مطابق با قوانین فعلی جمهوری بلاروس و همچنین اسناد قانونی محلی پیمانکار، مشروط به انطباق آنها با قوانین فعلی جمهوری بلاروس حل و فصل خواهد شد.

        </p>
        <h2 className={`${styles.items__title}`}>14. مشخصات طرفین</h2><br></br>
        <p className={`${styles.item}`}>
          14.1. . طرفین بدون قید و شرط بر اساس جزئیات مشتری موافقت می کنند که هنگام سفارش برای ارائه خدمات، اطلاعات مشخص شده توسط او را در نظر بگیرند.<br></br>
          14.2. مشخصات پیمانکار:<br></br>
                 LLC "IRIS inter GROUP" با نمایندگی ایساپور ایرج کارگردان<br></br>
                 آدرس پستی: 220034، جمهوری بلاروس، مینسک، صندوق پستی 15<br></br>
                 آدرس حقوقی: مینسک، خیابان بابوشکینا، 29، از. 14<br></br>
                 UNP: 193438125<br></br>
                 تلفن (+375 17) 303 96 99<br></br>
                 اوباش (+375 44) 653 04 30<br></br>
                 ایمیل: info@irisintergroup.com <br></br>
                 اطلاعات دقیق بانکی:<br></br>
                 IBAN: BY66 BPSB 3012 3269 1601 2933 0000 (USD)،<br></br>
          IBAN: BY62 BPSB 3012 3269 1603 8840 0000 (BYN),<br></br>
          IBAN: BY45 BPSB 3012 3269 1604 1978 0000 (EUR),<br></br>
          IBAN: BY66 BPSB 3012 3269 1605 4643 0000 (RUB)<br></br>
          در JSC "Sber Bank" Minsk, b-r Mulyavina, 6, BIC SWIFT BPSBBY2X
        </p>
      </div>
    </main >
  )
}
export default RulesOfertaPer;

import styles from './tariff.module.css'
// import styles from './tariff-pers.module.css'
import icon from "./images/icon-attention.png"
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

export function TariffPage() {
  const currentLanguage = useSelector(state => state.language.language);

  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={styles.wrapper} >
      <div className="inner">
        <h2 className={currentLanguage=== 'per' ?  `${styles.title_pers}` : `${styles.title}`} >{t('main.tarif.title')}</h2>
        <div className={styles.notice__card}>
          <div className={currentLanguage=== 'per' ?  `${styles.notice__title_pers}` : `${styles.notice__title}`}>
            <img src={icon} alt="attention-icon" />
            <span> {t('vnimanie')}</span>
          </div>
          <div className={currentLanguage=== 'per' ?  `${styles.notice__text_pers}` : `${styles.notice__text}`}>
           {t('main.promotions.offer1Text')}
          </div>
        </div>
      </div>
    </main>
  )
}
export default TariffPage;
import styles from './sidebar.module.css';
import user from './images/user.png'
import parcel from './images/parcel.svg'
import truck from './images/truck.svg'
import balance from './images/balance.svg'
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openSelectedPage } from '../../../redux/reducers/selected-page-reducer';
import { logoutUserData } from '../../../redux/reducers/user-data-reducer';
import { saveFoundCargo } from '../../../redux/reducers/cargo-search-reducer';
import { saveCurrentImg } from '../../../redux/reducers/current-img-reducer';
import { saveCurrentID } from '../../../redux/reducers/current-id-reducer';
import AuthService from '../../../services/AuthService';
import { useTranslation } from "react-i18next";
import useOnClickOutside from '../../../hooks/onClickOutside';
import { getAllUsersAdmin } from '../../../http/userAPI';
import { setUsers, setUsersInvoice } from '../../../redux/reducers/users-list-reducer';
import axios from "axios";
import { BaseURL } from "../../../http";

export function Sidebar() {
  const userMenu = useRef();
  const transportMenu = useRef();
  const cargoMenu = useRef();
  const userPaidAdd = useSelector(state => state.userData.userData.addperiod);
  const userPromo15 = useSelector(state => state.userData.userData.promo15);

  async function getAllUsers() {
    try {
      const response = await getAllUsersAdmin();
      const resp = response.data.rows.reverse();
      dispatch(setUsers(resp));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }

  async function getAllUsersInvoice() {
    try {
      const response = await axios.get(`${BaseURL}/api/user/invoice-list`)
      const resp = response.data.reverse();
      dispatch(setUsersInvoice(resp));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }



  //Translation
  const { t } = useTranslation();
  const currentLanguage = useSelector(state => state.language.language);
  let stylesMenu = currentLanguage === 'per' ? `${styles.menu__pers} ${styles.menu}` : `${styles.menu} `;
  const navigate = useNavigate();

  const userData = useSelector(state => state.userData.userData);
  const [isItem1MenuOpen, setIsItem1MenuOpen] = useState(false);
  const [isItem2MenuOpen, setIsItem2MenuOpen] = useState(false);
  const [isItem3MenuOpen, setIsItem3MenuOpen] = useState(false);
  const userWork = useSelector(state => state.userData.userData.work);

  function openMenuUser() {
    setIsItem1MenuOpen(true);
    setIsItem2MenuOpen(false);
    setIsItem3MenuOpen(false);
  }
  function openMenuTransport() {
    setIsItem1MenuOpen(false);
    setIsItem2MenuOpen(true);
    setIsItem3MenuOpen(false);

  }
  function openMenuCargo() {
    setIsItem1MenuOpen(false);
    setIsItem2MenuOpen(false);
    setIsItem3MenuOpen(true);

  }
  function openMenuBalance() {
    setIsItem1MenuOpen(false);
    setIsItem2MenuOpen(false);
    setIsItem3MenuOpen(false);

  }

  const dispatch = useDispatch();


  function goToMyPage() {
    openMenuBalance()
    dispatch(openSelectedPage("mypage"))
  }

  function goToUsersPage() {
    getAllUsers()
    openMenuBalance()
    dispatch(openSelectedPage("userspage"))
    dispatch(saveCurrentID(""))
  }
  function goToUsersInvoicePage() {
    getAllUsersInvoice()
    openMenuBalance()
    dispatch(openSelectedPage("usersInvoicePage"))
    dispatch(saveCurrentID(""))
  }
  function goToBalancePage() {
    openMenuBalance()
    dispatch(openSelectedPage("balancepage"))
  }
  function goToCargoPage() {
    openMenuBalance()
    dispatch(openSelectedPage("cargoSearch"))
    dispatch(saveCurrentID(""))
  }
  function goToTransportPage() {
    openMenuBalance()
    dispatch(openSelectedPage("transportSearch"))
    dispatch(saveCurrentID(""))
  }
  function goToAddTarif() {
    // openMenuBalance()
    dispatch(openSelectedPage("addTarif"))
    dispatch(saveCurrentID(""))
  }
  function goToTransportRegistration() {
    openMenuBalance()
    dispatch(openSelectedPage("transportRegistration"))
    dispatch(saveCurrentID(""))
  }
  function goToCargoRegistration() {
    openMenuBalance()
    dispatch(openSelectedPage("cargoRegistration"))
    dispatch(saveCurrentID(""))
  }
  function goToservicesPrices() {
    openMenuBalance()
    dispatch(openSelectedPage("servicesPrices"))
    dispatch(saveCurrentID(""));
  }

  const logoutUser = async () => {
    try {
      await AuthService.logout();
      localStorage.removeItem('token');
      dispatch(logoutUserData());
      dispatch(saveFoundCargo(""))
      dispatch(saveCurrentImg(""))
      dispatch(openSelectedPage("homepage"))
      navigate("/");
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  useOnClickOutside(userMenu, () => {
    if (isItem1MenuOpen) {
      openMenuBalance();
    }
  });
  useOnClickOutside(transportMenu, () => {
    if (isItem2MenuOpen) {
      openMenuBalance();
    }
  });
  useOnClickOutside(cargoMenu, () => {
    if (isItem3MenuOpen) {
      openMenuBalance();
    }
  });



  return (
    <div className={styles.wrapper}>
      <div className={styles.items}>
        {userWork === "Перевозчик" &&
          <div className={isItem2MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} ref={transportMenu}>
            <div className={isItem2MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} onClick={() => openMenuTransport()}>
              <img src={truck} alt="truck" />
            </div>

            <div className={isItem2MenuOpen ? stylesMenu : `${styles.menu__hidden}`}>
              <div className={styles.menu__item} onClick={() => dispatch(goToCargoPage)}>
                {/* Поиск груза */}
                {t('cargo-search')}
              </div>
              <div className={styles.menu__item} onClick={() => dispatch(goToTransportRegistration)}>
                {/* Мой транспорт */}
                {t('my-transport')}
              </div>
            </div>

          </div>
        }
        {userWork === "Грузоотправитель" &&

          <div className={isItem2MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} ref={transportMenu}>
            {(userPaidAdd || userPromo15) ?
              <>
                <div className={isItem2MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} onClick={() => openMenuTransport()}>
                  <img src={truck} alt="truck" />
                </div>

                <div className={isItem2MenuOpen ? stylesMenu : `${styles.menu__hidden}`}>
                  <div className={styles.menu__item} onClick={() => dispatch(goToCargoPage)}>
                    {/* Поиск груза */}
                    {t('cargo-search')}
                  </div>
                  <div className={styles.menu__item} onClick={() => dispatch(goToTransportRegistration)}>
                    {/* Мой транспорт */}
                    {t('my-transport')}
                  </div>
                </div>
              </> :
              <div className={isItem2MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} onClick={() => goToAddTarif()}>
                <img src={truck} alt="truck" />
              </div>
            }
          </div>
        }
        {userWork === "Грузоотправитель" &&
          <div className={isItem3MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} ref={cargoMenu}>
            <div className={isItem3MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} onClick={() => openMenuCargo()}>
              <img src={parcel} alt="parcel" />
            </div>
            <div className={isItem3MenuOpen ? stylesMenu : `${styles.menu__hidden}`}>
              <div className={styles.menu__item} onClick={() => dispatch(goToTransportPage)}>
                {/* Поиск транспорта */}
                {t('transport-search')}
              </div>
              <div className={styles.menu__item} onClick={() => { openMenuBalance(); goToCargoRegistration() }}>
                {/* Мой груз */}
                {t('my-cargo')}
              </div>
            </div>
          </div>
        }
        {userWork === "Перевозчик" &&
          <div className={isItem3MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} ref={cargoMenu}>
            {(userPaidAdd || userPromo15) ?
              <>
                <div className={isItem3MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} onClick={() => openMenuCargo()}>
                  <img src={parcel} alt="parcel" />
                </div>
                <div className={isItem3MenuOpen ? stylesMenu : `${styles.menu__hidden}`}>
                  <div className={styles.menu__item} onClick={() => dispatch(goToTransportPage)}>
                    {/* Поиск транспорта */}
                    {t('transport-search')}
                  </div>
                  <div className={styles.menu__item} onClick={() => { openMenuBalance(); goToCargoRegistration() }}>
                    {/* Мой груз */}
                    {t('my-cargo')}
                  </div>
                </div>
              </> :

              <div className={isItem3MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} onClick={() => goToAddTarif()}>
                <img src={parcel} alt="parcel" />
              </div>

            }
          </div>
        }
        {(userWork === "Экспедитор" || userWork === "Admin") &&
          <div className={isItem2MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} ref={transportMenu}>
            <div className={isItem2MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} onClick={() => openMenuTransport()}>
              <img src={truck} alt="truck" />
            </div>
            <div className={isItem2MenuOpen ? stylesMenu : `${styles.menu__hidden}`}>
              <div className={styles.menu__item} onClick={() => dispatch(goToCargoPage)}>
                {/* Поиск груза */}
                {t('cargo-search')}
              </div>
              <div className={styles.menu__item} onClick={() => dispatch(goToTransportRegistration)}>
                {/* Мой транспорт */}
                {t('my-transport')}
              </div>
            </div>
          </div>
        }
        {(userWork === "Экспедитор" || userWork === "Admin") &&
          <div className={isItem3MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} ref={cargoMenu}>
            <div className={isItem3MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`} onClick={() => openMenuCargo()}>
              <img src={parcel} alt="parcel" />
            </div>
            <div className={isItem3MenuOpen ? stylesMenu : `${styles.menu__hidden}`}>
              <div className={styles.menu__item} onClick={() => dispatch(goToTransportPage)}>
                {/* Поиск транспорта */}
                {t('transport-search')}
              </div>
              <div className={styles.menu__item} onClick={() => { openMenuBalance(); goToCargoRegistration() }}>
                {/* Мой груз */}
                {t('my-cargo')}
              </div>
            </div>
          </div>
        }
        {(userData.role !== "Admin") &&
          <div className={styles.item} onClick={() => { openMenuBalance(); goToservicesPrices() }}>
            <img src={balance} alt="balance" />
          </div>
        }
      </div>
    </div>
  )
}
export default Sidebar;

/* eslint-disable react-hooks/exhaustive-deps */
import styles from './results-table-transport.module.css';
import { saveCurrentID } from '../../../redux/reducers/current-id-reducer';
import { useSelector, useDispatch } from 'react-redux';
import attentionIcon from './images/icon-attention.png';
import { useMemo, useState, useRef } from 'react';
import { Pagination } from '../../pagination';
import { LocationUpdate } from '../update-location';
import { deleteTransport } from '../../../http/transportApi';
import { openEditLocationForm } from '../../../redux/reducers/edit-location-reducer';
import { saveTransportsUserData } from '../../../redux/reducers/transports-user-reducer';
import { openEdittransportForm } from '../../../redux/reducers/transport-update-reducer';
import pencil from "./images/pencil.svg";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { BaseURL } from "../../../http";
import telegram from "./images/telegram.png";
import whatsapp from "./images/whatsapp.png";
import skype from "./images/skype.svg";
import { NavLink } from 'react-router-dom';

export function ResultsTableTransport(props) {
  const { t } = useTranslation();
  const currentLanguage = useSelector(state => state.language.language);
  const dispatch = useDispatch();
  const userData = useSelector(state => state.userData.userData);
  const myTransportList = useSelector(state => state.transportsUser.transportsUser);
  const foundTransport = useSelector(state => state.transportFound.transportFound);
  let PageSize = 30;
  const refDetails = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [location, setLocation] = useState(null);
  const editForm = useSelector(state => state.editLocationForm.editLocationForm);
  const userId = useSelector(state => state.userData.userLogin.user.id);
  let [resultsDataRegistration, setResultsDataRegistration] = useState((props.typeOfAction === "registrationTransport") ? myTransportList : "");
  let resultsDataSearch = (props.typeOfAction === "searchTransport") ? foundTransport : ""
  let resultsData = (props.typeOfAction === "searchTransport") ? resultsDataSearch : myTransportList
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return resultsData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const scrollToElement = () => {
    refDetails.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getDetails = (transportId, e) => {
    if (!e.target.classList.contains("results-table-transport_update__ImExK") && !e.target.classList.contains("results-table-transport_delete__OHRHh")) {
      dispatch(saveCurrentID(transportId))
      scrollToElement()
    }

  };


  async function getMyTransport() {
    try {
      const response = await axios.get(`${BaseURL}/api/transport/list/${userId}`)
      dispatch(saveTransportsUserData(response.data));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }

  const deleteCurrenTransport = async (transportId, e) => {
    const response = await deleteTransport({ transportId })
    if (response.statusText === "OK") {
      setResultsDataRegistration(resultsDataRegistration.filter(transport => transport.id !== transportId))
      dispatch(saveCurrentID(""))
      getMyTransport()

    } else {
    }
  }

  const updateCurrenTransport = async (transportId, e) => {
    dispatch(saveCurrentID(transportId))
    dispatch(openEdittransportForm())
  }

  let transportTypeLang = function (transport) {
    if (currentLanguage === "ru") {
      return transport.name
    }
    if (currentLanguage === "en") {
      return transport.english
    }
    if (currentLanguage === "per") {
      return transport.persian
    }
  }

  let docTypeLang = function (doc) {
    if (currentLanguage === "ru") {
      return doc.name
    }
    if (currentLanguage === "en") {
      return doc.english
    }
    if (currentLanguage === "per") {
      return doc.persian
    }
  }
  let loadingTypeLang = function (loading) {
    if (currentLanguage === "ru") {
      return loading.name
    }
    if (currentLanguage === "en") {
      return loading.english
    }
    if (currentLanguage === "per") {
      return loading.persian
    }
  }
  let paymentTypeLang = function (payment) {
    if (currentLanguage === "ru") {
      return payment.name
    }
    if (currentLanguage === "en") {
      return payment.english
    }
    if (currentLanguage === "per") {
      return payment.persian
    }
  }

  return (
    <div className={styles.payment}>
      <section className={`${styles.block} ${styles.block__data} `}>
        <div className={` ${styles.block__content_payment} ${styles.block__header}`}>
          <div className={styles.payment_title}>{t('tables.result.derection')}</div>
          <div className={styles.payment_title}>{t('tables.result.location')}</div>
          <div className={styles.payment_title}>{t('transport')}</div>
          <div className={styles.payment_title}>{t('tables.result.cargo')}</div>
          <div className={styles.payment_title}>{t('tables.result.payment')}</div>
          <div className={styles.payment_title}>{t('contact')}</div>
        </div>
        {resultsData.length <= 30 &&
          resultsData.map((transport, index) => {
            return (
              <div className={`${styles.block__content} ${styles.block__content_payment} ${transport.additionalSearch ? styles.additional_search_item : ''} `} key={index + transport.id} onClick={(e) => getDetails(transport.id, e)}>
                <div className={styles.payment_text}>{transport.countryfrom}
                  {transport.regions && <p>{transport.regions}</p>}
                </div>
                <div className={`${styles.payment_text} ${styles.location}`}>
                  {((props.typeOfAction === "registrationTransport" && editForm && location === index)) ? <LocationUpdate transportId={transport.id} text={transport.location} /> :
                    <>
                      {transport.location}
                      {props.typeOfAction === "registrationTransport" &&
                        <div className={`${styles.data__edit}`} >
                          <img src={pencil} alt="edit" id={index} onClick={(e) => {
                            setLocation(+e.target.id);
                            dispatch(openEditLocationForm());
                          }} />
                        </div>
                      }
                    </>
                  }
                </div>
                <div className={styles.payment_text}>
                  {(transport.transportTypeTransport && transport.transportTypeTransport.length > 0) ?
                    transport.transportTypeTransport.map((transportTypeTransport, index) => {
                      if (index === transport.transportTypeTransport.length - 1) {
                        return (
                          <span key={index + transportTypeTransport.id}>{transportTypeLang(transportTypeTransport) || transportTypeTransport.value} </span>
                        )
                      }
                      return (
                        <span key={index + transportTypeTransport.id}>{transportTypeLang(transportTypeTransport) || transportTypeTransport.value}, </span>
                      )
                    })
                    : <div>-</div>
                  }
                </div>
                <div className={styles.payment_text}>
                  {(transport.danger) && <span>{transport.danger} </span>}
                  {(transport.weighMax > 0) && <span>{transport.weighMax}  </span>}
                  {((transport.long > 0) || (transport.weighMax > 0) || (transport.heightMax > 0)) &&
                    <p> {t('gabarity')}:
                      {(transport.long > 0) && <span>  {`${transport.long}`}  </span>}
                      {(transport.weighMax > 0) && <span>  {`${transport.weighMax}`}   </span>}
                      {(transport.heightMax > 0) && <span> {`${transport.heightMax}`}  </span>}
                    </p>
                  }
                  {(transport.loadingTypeTransport
                    && transport.loadingTypeTransport
                      .length > 0) &&
                    <p>{t('zagruzka')}:
                      {transport.loadingTypeTransport
                        .map((loading, index) => {
                          if (index === transport.loadingTypeTransport.length - 1) {
                            return (
                              <span key={index + loading.id}> {loadingTypeLang(loading) || loading.value}, </span>
                            )
                          }
                          return (
                            <span key={index + loading.id}> {loadingTypeLang(loading) || loading.value}, </span>
                          )
                        })}
                    </p>
                  }
                  {(transport.docTypeTransport && transport.docTypeTransport.length > 0) &&
                    <p>{t('dokumenty')}:
                      {transport.docTypeTransport.map((doc, index) => {
                        if (index === transport.docTypeTransport.length - 1) {
                          return (
                            <span key={index + doc.id}> {docTypeLang(doc) || doc.value}  </span>
                          )
                        }
                        return (
                          <span key={index + doc.id}> {docTypeLang(doc) || doc.value},  </span>
                        )
                      })}
                    </p>
                  }</div>
                <div className={styles.payment_text}>
                  {(transport.paymentTypeTransport
                    && transport.paymentTypeTransport.length > 0) ?
                    transport.paymentTypeTransport
                      .map((payment, index) => {
                        if (index === transport.paymentTypeTransport
                          .length - 1) {
                          return (
                            <span key={index + payment.id}>{paymentTypeLang(payment) || payment.value}</span>
                          )
                        }
                        return (
                          <span key={index + payment.id}>{paymentTypeLang(payment) || payment.value}, </span>
                        )
                      })
                    : <div>-</div>
                  }
                </div>
                {props.typeOfAction === "searchTransport" &&
                  <>
                    {((props.typeOfAction === "searchTransport" && userData.promo15) || (props.typeOfAction === "searchTransport" && userData.isPaid) || (props.typeOfAction === "searchTransport" && userData.role === "Admin")) ?
                      <div className={styles.payment_text}>
                        <p>{(transport.user.role === "Юридическое лицо") ? `${transport.user.orgname} ` : `${transport.user.name} ${transport.user.surname}`}
                          {(transport.user.role === "Юридическое лицо") ? `${transport.user.name}` : ""}
                        </p> <p>{transport.user.number} </p></div> : < div className={`${styles.payment_text} ${styles.payment_text_attention} `} >
                        <img src={attentionIcon} alt="attention" className={styles.img_attention} />
                        {t('kontaktnaya-informaciya-ne-dostupna-v-vashem-tarifnom-plane-oformite-podpisku')}
                      </div>}
                  </>
                }
                {(props.typeOfAction === "registrationTransport") &&
                  <div className={styles.payment_text}>
                    <p>{(userData.role === "Юридическое лицо") ? `${userData.orgname}, ` : `${userData.name} ${userData.surname},`}
                      {(userData.role === "Юридическое лицо") ? `${userData.name}` : ""}</p>
                    <p>{userData.number} </p>
                    <div className={styles.social_links}>
                      {userData.telegram &&
                        <NavLink className={styles.user_telegram} to={"https://t.me/" + userData.telegram} target="_blank">
                          <img src={telegram} alt="telegram" />
                        </NavLink>
                      }
                      {userData.whatsapp &&
                        <NavLink className={styles.user_whatsapp} to={"https://wa.me/" + userData.whatsapp} target="_blank">
                          <img src={whatsapp} alt="whatsapp" />
                        </NavLink>}
                      {userData.skype &&
                        <NavLink className={styles.user_skype} to={"skype:" + userData.skype} target="_blank">
                          <img src={skype} alt="skype" />
                        </NavLink>
                      }
                    </div>
                    <div className={styles.update} onClick={() => updateCurrenTransport(transport.id)}>{t('redaktirovat-zapis')}</div>
                    <div className={styles.delete} onClick={() => deleteCurrenTransport(transport.id)}>{t('buttons.deleteEntry')}</div>
                  </div>
                }
              </div>
            )
          })}
        {((resultsData.length > 30) && currentTableData) &&
          <>
            {currentTableData &&
              currentTableData.map((transport, index) => {
                return (
                  <div className={`${styles.block__content} ${styles.block__content_payment} ${transport.additionalSearch ? styles.additional_search_item : ''} `} key={index + transport.id} onClick={(e) => getDetails(transport.id, e)}>
                    <div className={styles.payment_text}>{transport.countryfrom}
                      {transport.regions && <p>{transport.regions}</p>}
                    </div>
                    <div className={styles.payment_text}>
                      {transport.location}
                    </div>
                    <div className={styles.payment_text}>
                      {(transport.transportTypeTransport && transport.transportTypeTransport.length > 0) ?
                        transport.transportTypeTransport.map((transportTypeTransport, index) => {
                          if (index === transport.transportTypeTransport.length - 1) {
                            return (
                              <span key={index + transportTypeTransport.id}>{transportTypeLang(transportTypeTransport) || transportTypeTransport.value} </span>
                            )
                          }
                          return (
                            <span key={index + transportTypeTransport.id}>{transportTypeLang(transportTypeTransport) || transportTypeTransport.value}, </span>
                          )

                        })
                        : <div>-</div>
                      }
                    </div>
                    <div className={styles.payment_text}>
                      {(transport.danger) && <span>{transport.danger} </span>}
                      {(transport.weighMax > 0) && <span>{transport.weighMax} т, </span>}
                      {((transport.long > 0) || (transport.weighMax > 0) || (transport.heightMax > 0)) &&
                        <p> {t('gabarity')}:
                          {(transport.long > 0) && <span>  {`${transport.long}`} x </span>}
                          {(transport.weighMax > 0) && <span>  {`${transport.weighMax}`}x  </span>}
                          {(transport.heightMax > 0) && <span>  {`${transport.heightMax}`} x</span>}
                        </p>
                      }
                      {(transport.loadingTypeTransport
                        && transport.loadingTypeTransport
                          .length > 0) &&
                        <p>{t('zagruzka')}:
                          {transport.loadingTypeTransport
                            .map((loading, index) => {
                              if (index === transport.loadingTypeTransport.length - 1) {
                                return (
                                  <span key={index + loading.id}> {loadingTypeLang(loading) || loading.value}, </span>
                                )
                              }
                              return (
                                <span key={index + loading.id}> {loadingTypeLang(loading) || loading.value} </span>
                              )
                            })}
                        </p>
                      }
                      {(transport.docTypeTransport && transport.docTypeTransport.length > 0) &&
                        <p>{t('dokumenty')}:
                          {transport.docTypeTransport.map((doc, index) => {
                            if (index === transport.docTypeTransport.length - 1) {
                              return (
                                <span key={index + doc.id}> {docTypeLang(doc) || doc.value}  </span>
                              )
                            }
                            return (
                              <span key={index + doc.id}> {docTypeLang(doc) || doc.value},  </span>
                            )
                          })}
                        </p>
                      }</div>
                    <div className={styles.payment_text}>
                      {(transport.paymentTypeTransport
                        && transport.paymentTypeTransport.length > 0) ?
                        transport.paymentTypeTransport
                          .map((payment, index) => {
                            if (index === transport.paymentTypeTransport
                              .length - 1) {
                              return (
                                <span key={index + payment.id}>{paymentTypeLang(payment) || payment.value}</span>
                              )
                            }
                            return (
                              <span key={index + payment.id}>{paymentTypeLang(payment) || payment.value}, </span>
                            )
                          })
                        : <div>-</div>
                      }
                    </div>
                    {props.typeOfAction === "searchTransport" &&
                      <>
                        {((props.typeOfAction === "searchTransport" && userData.promo15) || (props.typeOfAction === "searchTransport" && userData.isPaid) || (props.typeOfAction === "searchTransport" && userData.role === "Admin")) ?
                          <div className={styles.payment_text}>
                            <p>{(transport.user.role === "Юридическое лицо") ? `${transport.user.orgname} ` : `${transport.user.name} ${transport.user.surname}`}
                              {(transport.user.role === "Юридическое лицо") ? `${transport.user.name}` : ""}
                            </p> <p>{transport.user.number} </p></div> : < div className={`${styles.payment_text} ${styles.payment_text_attention} `} >
                            <img src={attentionIcon} alt="attention" className={styles.img_attention} />
                            {t('notifications.contactsNotAval')}
                          </div>}
                      </>
                    }
                    {(props.typeOfAction === "registrationTransport") &&
                      <div className={styles.payment_text}>
                        <p>{(userData.role === "Юридическое лицо") ? `${userData.orgname}, ` : `${userData.name} ${userData.surname},`}
                          {(userData.role === "Юридическое лицо") ? `${userData.name}` : ""}</p>
                        <p>{userData.number} </p>
                        <div className={styles.social_links}>
                          {userData.telegram &&
                            <NavLink className={styles.user_telegram} to={"https://t.me/" + userData.telegram} target="_blank">
                              <img src={telegram} alt="telegram" />
                            </NavLink>
                          }
                          {userData.whatsapp &&
                            <NavLink className={styles.user_whatsapp} to={"https://wa.me/" + userData.whatsapp} target="_blank">
                              <img src={whatsapp} alt="whatsapp" />
                            </NavLink>}
                          {userData.skype &&
                            <NavLink className={styles.user_skype} to={"skype:" + userData.skype} target="_blank">
                              <img src={skype} alt="skype" />
                            </NavLink>
                          }
                        </div>
                        <div className={styles.update} onClick={() => updateCurrenTransport(transport.id)}>{t('redaktirovat-zapis')}</div>
                        <div className={styles.delete} onClick={() => deleteCurrenTransport(transport.id)}>{t('buttons.deleteEntry')}</div>
                      </div>
                    }
                  </div>
                )
              })}
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={resultsData.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          </>
        }
      </section >
      <div ref={refDetails}></div>
    </div >
  )
}
export default ResultsTableTransport;

import styles from './main-about.module.css';
import { useTranslation } from "react-i18next";
import map from './images/map.png';

export function MainAbout() {
  const { t } = useTranslation();
  return (
    <div className={styles.main__about_wrapper} id="about">
      <div className={styles.main__about}>
        <img className={styles.main__about_img} src={map} alt="map" />
        <div className={styles.main__about_text}>
          <h2 className="section__title">{t('o-platforme')}</h2>
          <div className={styles.about__text}>
            <p>{t('aboutText1')} </p>
            <p>{t('aboutText2')} </p>
          </div>
        </div>
        <div className={styles.about__text_responsive}>
          {t('aboutText3')}
        </div>
      </div>
    </div>
  )
}
export default MainAbout;
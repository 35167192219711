import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentSelectedUser: "",
  currentSelectedUserDialog: false,
};

export const currentSelectedUserSlice = createSlice({
  name: 'currentSelectedUser',
  initialState,
  reducers: {
    saveCurrentSelectedUser: (state, action) => {
      return {
        ...state,
        currentSelectedUser: action.payload,
      }
    },
    saveCurrentSelectedUserDialog: (state, action) => {
      return {
        ...state,
        currentSelectedUserDialog: action.payload,
      }
    },
  }
});

export const { saveCurrentSelectedUser, saveCurrentSelectedUserDialog } = currentSelectedUserSlice.actions;
export const currentSelectedUserReducer = currentSelectedUserSlice.reducer;
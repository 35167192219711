import styles from './rules.module.css';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

export function Agreement() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.rules__body}>
      <div className={`${styles.rules__wrapper}`}>
        <h1 className={`${styles.rules__title}`}>Пользовательское соглашение
        </h1>
        <h2 className={`${styles.items__title}`}>1. Определения</h2>
        <p className={`${styles.item}`}>
          1.1. <b>Пользователь</b> или <b>Вы</b> — физическое лицо, фирма и ее представители, осуществляющее доступ к Сайту и/или
          пользование Сервисами.<br></br>
          1.2. <b>Физическое лицо</b> — человек, обладающий гражданской правоспособностью и полной гражданской дееспособностью,
          не являющийся субъектом хозяйственной деятельности, использующий Сайт и/или Сервисы для личных нужд и
          исключительно от своего имени.<br></br>
          1.3. <b>Фирма</b> — коммерческий субъект (“субъект хозяйствования”), юридическое лицо либо индивидуальный
          предприниматель, осуществляющие коммерческую деятельность с использованием Сайта и/или Сервисов.<br></br>
          1.4. <b>Сайт</b> — “Транспортно-информационный сервер iristrans.com”, информационный ресурс, доступный в сети
          Интернет с использованием доменного имени iristrans.com (в т.ч. также посредством иных доменных имен,
          перенаправляющих на iristrans.com).<br></br>
          1.5. <b>IrisTrans</b> или <b>Мы</b> — юридическое лицо Общество с ограниченной ответственностью “ИрИс интер ГРУПП”
          зарегистрированные и действующие по законодательству Республики Беларусь, которые действуют совместно или по
          отдельности на условиях, предусмотренных договором между ними.<br></br>
          1.6. <b>Сервис/Сервисы</b> — Сервис/Сервисы — информационные услуги, предоставляемые Пользователю (Фирме) посредством
          Сайта и/или иным способом (в частности, но не исключительно, посредством e-mail рассылки) с легитимным
          использованием торговой марки “ IrisTrans” и/или подобных (производных) обозначений, а также соответствующие
          функциональные разделы Сайта и способы предоставления услуг.
        </p>
        <h2 className={`${styles.items__title}`}>2. Общие положения</h2>
        <p className={`${styles.item}`}>
          2.1. Настоящее Пользовательское Соглашение (далее — “Соглашение”) регулирует, между Вами (далее также
          “Пользователь”), с одной стороны, и IisTrans.com, с другой стороны, отношения, возникающие в результате
          функционирования Сайта и/или предоставления Сервисов. В частности, но не исключительно, настоящее Соглашение
          устанавливает правила и условия предоставления Сайта и/или Сервисов.<br></br>
          2.1.1. Посредством Сайта мы предоставляем онлайн-платформу, через которую Пользователи в рамках своей
          профессиональной деятельности могут рекламировать, продвигать на рынке, реализовывать и/или предлагать свои
          услуги для заказа, покупки, резервирования транспортных и/или (транспортно-экспедиторских) услуг, а иные
          Пользователи Сайта могут находить такие услуги, сравнивать, заказывать, приобретать или оплачивать их.
          Используя Сайт и Сервисы, Вы можете вступать в прямые (юридически обязывающие) договорные отношения с
          поставщиком услуг, у которого вы резервируете или приобретаете услугу. IrisTrans действует исключительно как
          посредник между Вами и иными Пользователями Сайта, предоставляя им возможность просмотра соответствующих
          данных об условиях предоставления Вами услуг либо интересующих Вас услуг. IrisTrans не (пере)продает и не
          реализует какие-либо продукты или услуги.<br></br>
          2.1.2. При предоставлении наших Сервисов, размещаемая информация основана на сведениях, предоставленных нам
          Пользователями Сайта. Пользователи, которые размещают и продвигают свои услуги по перевозке и/или
          транспортно-экспедиторские услуги, несут полную ответственность за обновление информации о ценах, тарифах,
          сборах, наличии свободного транспорта, грузов, правилах, условиях и иных данных, отображаемых на нашем Сайте.
          Несмотря на то, что мы делаем все возможное, чтобы обеспечить качество нашего Сервиса, мы не проверяем
          достоверность и не можем гарантировать точность или полноту всей информации, а также не несем ответственности
          за какие-либо ошибки (включая явные ошибки и опечатки), сбои (возникшие из-за (временной и/или частичной)
          поломки, ремонта, усовершенствования или технического обслуживания нашего Сайта или по иным причинам),
          неточную, недостоверную или ложную информацию или непредставление информации. Каждый Пользователь при любых
          условиях несет ответственность за точность, полноту и правильность предоставленной (описательной) им
          информации (включая тарифы, сборы, цены, правила, условия и наличие свободного транспорта, грузов),
          отображаемой на Сайте. Наш Сайт не является и не должен рассматриваться в качестве гарантии качества
          предоставляемых Пользователями услуг, уровня сервиса и прочее, если прямо не указано иное.<br></br>
          2.2. Настоящее Соглашение (включая являющиеся частью Соглашения Правила и Условия использования отдельных
          Сервисов), в части применимости соответствующих положений к бесплатному/незарегистрированному доступу,
          распространяет свое действие как на зарегистрированных, так и на незарегистрированных Пользователей Сайта.<br></br>
          2.3. Каждый представитель Фирмы, до тех пор, пока нет достаточных оснований считать иначе, считается должным
          образом уполномоченным представителем соответствующей Фирмы, и, если прямо не указано иначе, подразумевается,
          что положения Пользовательского Соглашения касаются данной Фирмы в полном объеме.<br></br>
          2.4. Настоящее Соглашение становится доступно Пользователю при входе на Сайт. Использование Сайта, любых
          Сервисов и/или регистрация учетной записи означает подтверждение согласия Пользователя со всеми условиями
          настоящего Соглашения и безоговорочного их принятия.<br></br>
          2.5. IrisTrans.com оставляет за собой право по своему усмотрению изменять Соглашение в любое время без
          специального предварительного и/или последующего уведомления Пользователя. Действующая редакция Соглашения
          всегда доступна по адресу (АДРЕС В ВИДЕ URL). Продолжение использования Пользователем Сайта и/или Сервисов
          после любых изменений Соглашения подразумевает безоговорочное согласие Пользователя с такими изменениями.
        </p>
        <h2 className={`${styles.items__title}`}>3. Ограниченная ответственность IrisTrans</h2>
        <p className={`${styles.item}`}>
          3.1. Доступ к Сайту и Сервисам предоставляется в том виде, в котором IrisTrans.com считает целесообразным и
          удобным для реализации задач, стоящих перед Сайтом и/или Сервисами, и IrisTrans.com не гарантирует
          соответствие Сайта и/или Сервисов ожиданиям и/или целям Пользователя.<br></br>
          3.2. Пользователь понимает и соглашается с тем, что IrisTrans.com вправе, по своему усмотрению и без
          специального уведомления Пользователя, изменять набор доступных ему Сервисов и/или функционала Сайта.<br></br>
          3.3. Физическое лицо, не субъект хозяйственной деятельности, как Пользователь Сайта и его Сервисов, понимает и
          согласен с тем, что ввиду своего юридического статуса его доступ к Сервисам и/или функционалу Сайта имеет ряд
          ограничений, в том числе: в размещении заявок и отзывов, добавлении контактов, бренда, логотипа и т.п. -
          перечень которых IrisTrans.com вправе изменять по своему усмотрению и без специального уведомления такого
          Пользователя.<br></br>
          3.4. Пользователь понимает и соглашается с тем, что IrisTrans.com не гарантирует 100% безошибочную и
          бесперебойную работу Сайта и/или Сервисов и не несет ответственности за любой возможный ущерб, причиненный
          Пользователю и/или третьим лицам техническими сбоями аппаратного и/или программного обеспечения на любой
          стороне.<br></br>
          3.5. Пользователь понимает и соглашается с тем, что IrisTrans.com не гарантирует достоверность информации (в
          частности, но не исключительно: заявок о грузах, предлагаемых к перевозке, и/или о наличии свободного
          грузового транспорта), размещенной на Сайте третьими лицами, и не несет ответственности за любой возможный
          ущерб, причиненный Пользователю и/или третьим лицам в результате использования Сайта/Сервисов.<br></br>
          3.6. Пользователь понимает и соглашается с тем, что IrisTrans.com не несет ответственности за достоверность
          рекламной информации, размещенной на Сайте, и за качество рекламируемых товаров/услуг.<br></br>
          3.7. IrisTrans.com оставляет за собой право в любое время по своему усмотрению приостанавливать, ограничивать
          или прекращать доступ Пользователя к Сайту либо к отдельным Сервисам, в том числе, но не исключительно, в
          случае нарушения Пользователем настоящего Соглашения и/или применимого законодательства. При этом, в случаях
          нарушений по вине Пользователя, IrisTrans не несет обязанности по возврату денежных средств, уплаченных за
          доступ к Сервисам, и/или возмещения убытков.<br></br>
          3.8. Все взаимоотношения, возникающие между Пользователями Сайта и/или Сервисов ограничены кругом таких
          Пользователей. IrisTrans.com не несет финансовых либо иных обязательств по обязательствам, возникающим между
          Пользователями.<br></br>
          3.9. Ни IrisTrans.com, ни кто-либо из наших должностных лиц, директоров, работников, представителей, дочерних
          компаний, аффилированных лиц, дистрибьюторов, агентов и других лиц, участвующих в создании, продвижении и
          любой иной деятельности, направленной на поддержку Сайта и/или Сервисов и его информационного наполнения, не
          несет ответственности в соответствии с законодательством за:<br></br></p>
        <ul className={`${styles.list_items}`}>
          <li>любые штрафные санкции, прямые, косвенные и последующие убытки и ущерб, любые потери производства, упущенную
            выгоду, снижение дохода, не заключение договора, утрату или ущерб деловой репутации и неудовлетворение иска о
            возмещении ущерба,</li>
          <li>неточность, касающуюся (описательной) информации (в том числе, но не исключительно условия, цены, наличие
            свободного транспорта, груза, прочее), представленной Пользователем на Сайте,</li>
          <li>любые (прямые, косвенные, последующие или штрафные) убытки, ущерб или затраты, понесенные, принятые или
            оплаченные Вами и возникшие в связи или в результате использования, невозможности использования или перерыва в
            работе Сайта и/или Сервисов,</li>
          <li>(личные) телесные повреждения, смерть, порчу имущества или иные (прямые, косвенные, последующие или
            штрафные) убытки, ущерб или затраты, понесенные, принятые или оплаченные Вами вследствие (юридических)
            действий, ошибок, нарушений, халатности, умышленных неправомерных действий, бездействий, неисполнений,
            неправильной трактовки, правонарушений Пользователей, чьи продукты или услуги (прямо или косвенно) доступны,
            предлагаются или продвигаются на или через Сайт, включая любую (частичную) отмену, забастовку, форс-мажорные
            обстоятельства или любые иные неконтролируемые нами события.</li>
        </ul>
        <p className={`${styles.item}`}>3.10. Загружая фотографии/изображения в нашу систему (например, в дополнение к отзыву), вы подтверждаете,
          гарантируете и соглашаетесь, что вы являетесь обладателем авторских прав на эти фотографии/изображения и даете
          свое согласие на использование компанией IrisTrans.com загруженных фотографий/изображений на Сайте, в
          публикациях (размещенных онлайн и оффлайн), а также любым другим образом на усмотрение IrisTrans.com Вы
          предоставляете IrisTrans.com неисключительное, действующее во всем мире, безотзывное, безусловное и бессрочное
          право и разрешение на использование, воспроизведение, отображение, копирование, распространение,
          лицензирование, передачу и размещение фотографий/изображений любым способом по усмотрению IrisTrans.com
          Компания IrisTrans.com не является владельцем прав на загруженные фотографии/изображения и не одобряет их
          каким-либо образом. Ответственность за достоверность, действительность и право на использование всех
          фотографий/изображений принимает на себя лицо, которое загрузило фотографию/изображение. IrisTrans.com не
          осуществляет премодерации либо какой-то иной предварительной проверки загружаемых Пользователями
          фотографий/изображений и не несет никакой ответственности и обязательств в отношении размещенных фотографий.
          Лицо, которое загрузило фотографию, гарантирует, что фотографии/изображения не содержат вирусы, троянские
          программы или файлы, зараженные вирусами, а также не содержат никаких порнографических, незаконных,
          непристойных, оскорбительных, неприемлемых или неуместных материалов и не нарушают права третьих лиц (право
          интеллектуальной собственности, авторские права или право на неприкосновенность частной жизни). Любые
          фотография/изображение, которые не соответствуют вышеуказанным критериям, не будут опубликованы и/или могут
          быть удалены IrisTrans.com в любое время и без предварительного уведомления.
        </p>
        <h2 className={`${styles.items__title}`}>4. Учетная запись</h2>
        <p className={`${styles.item}`}>
          4.1. Осуществляя действия по регистрации учетной записи, Пользователь гарантирует, что он в должной мере
          правоспособен, полностью дееспособен и/или, в соответствующих случаях, уполномочен Фирмой.<br></br>
          4.2. Пользователь обязуется предоставлять в регистрационных данных достоверную информацию:</p>
        <ul className={`${styles.list_items}`}>
          <li>о Фирме, в том числе: наименование, юридический и почтовый адреса, регистрационные (идентификационный,
            налоговый) кода (номера), телефонный номер, адрес электронной почты, адрес веб-сайта при наличии, иные
            контактные данные, а также данные об осуществлении предпринимательской деятельности: данные о нахождении
            физического лица-предпринимателя в процессе прекращения предпринимательской деятельности и данные о нахождении
            юридического лица в процессе прекращения;</li>
          <li> о физическом лице, не субъекте хозяйственной деятельности, в том числе: фамилия, имя, отчество,
            идентификационный код, адрес регистрации, контактные телефоны;<br></br>
            и постоянно поддерживать предоставляемую информацию в актуальном состоянии.</li>
        </ul>
        <p className={`${styles.item}`}>4.3. Пользователь несет ответственность и самостоятельно принимает меры для обеспечения безопасности своей
          учетной записи на Сайте (включая контроль за связкой логин-пароль, адресом электронной почты и т.д.).
          Пользователь несет полную ответственность за все действия, совершенные с использованием учетной записи
          Пользователя. Пользователь обязуется незамедлительно уведомить IrisTrans о любых случаях несанкционированного
          использования учетной записи Пользователя третьими лицами. Пользователь обязуется не использовать в
          регистрационных данных информацию, могущую ввести в заблуждение, в частности, но не исключительно:
          обозначения, воспроизводящие торговые марки и/или коммерческие наименования третьих лиц либо сходные с ними до
          степени смешения; данные, дублирующие данные любой другой учетной записи.<br></br>
          4.4. Пользователь обязуется не регистрировать и не использовать одновременно более одной учетной записи.<br></br>
          4.5. Фирма обязуется использовать учетную запись исключительно для удовлетворения нужд Фирмы, непосредственно
          связанных с перевозкой грузов.
          4.6. Физическое лицо, не субъект хозяйственной деятельности, обязуется использовать учетную запись
          исключительно для удовлетворения собственных нужд, в частности, но не исключительно, связанных с перевозкой
          грузов.<br></br>
          4.7. IrisTrans.com оставляет за собой право запрашивать, как по индивидуальному усмотрению, так и в рамках
          системы верификации (аттестации), документы для подтверждения предоставленных Пользователем данных, и
          принимать решения относительно действительности учетной записи Пользователя на основании результатов таких
          запросов.<br></br>
          4.8. IrisTrans.com оставляет за собой право блокировать и/или отменить (удалить) регистрацию учетной записи
          Пользователя, в частности, но не исключительно, в следующих случаях:<br></br></p>
        <ul className={`${styles.list_items}`}>
          <li>нарушение приведенных в настоящем разделе условий регистрации и/или использования учетной записи;</li>
          <li>нарушение правил размещения (сообщения) и/или иного использования информации;</li>
          <li>успешные либо неуспешные попытки обхода технических средств защиты информации, используемых IrisTrans.com
            при предоставлении Сервисов, различного рода атаки, направленные против Сайта, в т.ч., но не исключительно, с
            умышленным использованием возможных ошибок в скриптах Сайта.</li>
        </ul>
        <p className={`${styles.item}`}>4.9. IrisTrans.com оставляет за собой право вести историю изменений Пользователем любых регистрационных данных
          для учетной записи, с тем же уровнем доступа к истории изменений, что и к самим данным. Любые данные,
          сохраненные в истории изменений для учетной записи, признаются приемлемыми и применимыми для установления
          любых обстоятельств, связанных с учетной записью и, кроме того — в спорных и/или сомнительных случаях, при
          условии дополнительного документального подтверждения Пользователем добросовестности изменения данных и
          пользования учетной записью до и после таких изменений — отсылающих к моменту актуальности указания таких
          регистрационных данных.<br></br>
          4.10. IrisTrans.com оставляет за собой право хранить в своих базах данных все сведения, данные и информацию,
          полученные от пользователей, самостоятельно размещенные пользователями или полученные из других официальных
          источников. IrisTrans.com обеспечивает конфиденциальность указанных сведений, данных и информации и их
          сохранение в соответствии с положениями Политики конфиденциальности, требованиями действующего
          законодательства Республики Беларусь и иными нормами, обязательными для выполнения на территории Республики
          Беларусь.<br></br>
          4.11. Удаление сведений из баз IrisTrans.com осуществляется в соответствии с Политикой конфиденциальности,
          требованиями действующего законодательства Республики Беларусь и иными нормами, обязательными для выполнения
          на территории Республики Беларусь.<br></br>
          4.12. Все споры, возникающие в связи с Сайтом и/или Сервисов и стороной которых является IrisTrans.com должны
          решаться посредством переговоров. В случае невозможности достижения согласия посредством переговоров, спор
          передается на рассмотрение компетентного суда, в соответствии с требованиями действующего законодательства
          Республики Беларусь и иными нормами, обязательными для выполнения на территории Республики Беларусь.
        </p>
        <h2 className={`${styles.items__title}`}>5. Персональные данные и приватность</h2>
        <p className={`${styles.item}`}>
          5.1. Пользователь — физическое лицо, не являющееся субъектом хозяйствования, этим предоставляет IrisTrans.com
          согласие (разрешение) на обработку персональных данных в соответствии с Политикой конфиденциальности. Данное
          разрешение может быть отозвано в любое время, посредством обращения в администрацию IrisTrans.com такого
          физического лица при условии безоговорочного подтверждения его личности. В случае отзыва согласия на
          обработку, персональные данные такого физического лица хранятся в базах данных IrisTrans.com и недоступны для
          любых третьих лиц, кроме случаев, предусмотренных национальным законодательством и положениями международных
          нормативно-правовых документов. Удаление сведений из баз данных IrisTrans.com осуществляется в соответствии с
          <NavLink to={'/pravila/politika-konfidencialnosti'}>
            <span className={styles.link}> Политикой конфиденциальности</span>
          </NavLink>
          , требованиями действующего законодательства Республики
          Беларусь и иными нормами, обязательными для выполнения на территории Республики Беларусь.<br></br>
          5.2. При размещении на Сайте, Пользователем (для Фирм) любой информации, содержащей персональные данные
          третьих лиц, Пользователь (для Фирм) гарантирует, что каждое такое действие не нарушает установленные законом
          и/или договором права третьих лиц, и, в частности, гарантирует, что на каждое такое действие распространяется,
          обеспеченное ответственностью данного Пользователя, согласие субъекта соответствующих персональных данных.<br></br>
          5.3. Пользователь несет самостоятельную ответственность за корректность выбора, где применимо, индивидуальных
          настроек приватности/публичности размещаемой информации при использовании в рамках Сервисов учетной записи
          Пользователя, в частности, но не исключительно, для Сервиса хранения документов (изображений). Настоящее
          положение касается в равной мере, как защиты персональных данных, так и защиты коммерческой тайны и/или иной
          конфиденциальной информации.<br></br>
          5.4. При использовании Сайта и Сервисов IrisTrans.com запрещено размещение персональных данных физического
          лица о расовом или этническом происхождении, политических взглядах, религиозных и других философских
          убеждениях, членствах в профсоюзных организациях, а также информации о биометрических данных, данных о
          здоровье, о личной и сексуальной жизни. В случае выявления такой информации, она будет удалена администрацией
          без каких-либо дополнительных оповещений.<br></br>
          5.5. IrisTrans.com не несет ответственности за любые последствия использования персональных данных физического
          лица, не являющегося субъектом хозяйствования, другими Пользователями в случае, если такие данные были
          самостоятельно размещены этим физическим лицом с нарушением требований настоящего Пользовательского соглашения
          и других Правил и Условий использования Сайта и отдельных Сервисов IrisTrans.com или стали известны третьим
          лицам из-за несоблюдения самим пользователем Пользовательского соглашения и Политики конфиденциальности.<br></br>
          5.6. Для соблюдения требований национального законодательства и положений международных нормативно-правовых
          актов в IrisTrans.com назначено уполномоченное лицо по вопросам защиты данных, связь с которым осуществляется
          через страницу «Контакты» или через администрацию IrisTrans.com.
        </p>
        <h2 className={`${styles.items__title}`}>6. Использование информации Пользователем</h2>
        <p className={`${styles.item}`}>
          6.1. Вне зависимости от используемых каналов коммуникации с другими Пользователями и/или IrisTrans.com,
          Пользователь обязуется соблюдать общепринятые нормы вежливости.<br></br>
          6.2. Пользователь обязуется не использовать, без специального разрешения IrisTrans.com, Сайт и/или Сервисы для
          сообщения любого вида рекламной информации и/или для массового сообщения любой информации, не отвечающей
          тематике Сайта (Сервисов).<br></br>
          6.3. Пользователь обязуется не совершать недобросовестных действий, направленных против других Пользователей
          и/или IrisTrans.com, в частности, но не исключительно, сообщение с использованием Сайта/Сервисов любой
          информации с указанием, без достаточных на то полномочий, регистрационных, персональных либо контактных данных
          иных лиц, а также любое иное сообщение информации, могущее ввести в заблуждение относительно истинной личности
          физического лица или достоверных данных о Фирме, отношений представительства между Пользователями и/или
          намерений Пользователей. Пользователь понимает, что подобные действия могут также быть квалифицированы как
          мошенничество и таким образом являться уголовно наказуемыми.<br></br>
          6.4. Физические лица, не являющиеся субъектом хозяйствования, имеют право на экспорт своих персональных
          данных, если им необходима копия этих данных или они планируют использовать эту информацию в сторонних
          сервисах. Экспорт персональных данных физического лица, не являющегося субъектом хозяйствования,
          осуществляется администрацией IrisTrans на основании обращения такого физического лица при условии
          безоговорочного подтверждения его личности.<br></br>
          6.5. Физические лица, не являющиеся субъектом хозяйствования, имеют право на удаление своих персональных
          данных в случаях, предусмотренных положениями национального законодательства и положениями международных
          нормативно-правовых актов, информация будет удалена полностью или частично. Удаление персональных данных
          физического лица, не являющегося субъектом хозяйствования, осуществляется лично Пользователем на его личной
          странице и/или администрацией IrisTrans.com на основании обращения такого физического лица при условии
          безоговорочного подтверждения его личности.
        </p>
        <h2 className={`${styles.items__title}`}>7. Права интеллектуальной собственности</h2>
        <p className={`${styles.item}`}>
          7.1. Если не оговорено иное, все программное обеспечение, необходимое для предоставления наших Сервисов и/или
          используемое на нашем Сайте, и права на интеллектуальную собственность (включая авторские и смежные с ними
          права) в отношении контента, информации и материалов, размещенных на Сайте, принадлежат компании ООО «ИрИс
          интер ГРУПП».<br></br>
          7.2. ООО «ИрИс интер ГРУПП» сохраняет за собой исключительное право владения, пользования и распоряжения (все
          права на интеллектуальную собственность) (стиль оформления (включая функционал)) Сайтом, на котором
          предлагаются Сервисы (в том числе отзывы) и Вы не наделены правами копирования, автоматического или
          программного считывания/сбора/анализа данных, создания (гипер-/внешних) ссылок, публикации, продвижения,
          маркетинга, интеграции, использования, объединения или иного применения контента (включая любые его переводы и
          отзывы Пользователей) или нашего бренда и товарных знаков без нашего прямого письменного разрешения. В случае,
          если Вы (полностью или частично) используете или объединяете наш (переведенный) контент (в том числе отзывы
          Пользователей) или иным образом владеете правом интеллектуальной собственности на любой (переведенный) контент
          или отзывы Пользователей, настоящим Вы передаете и уступаете все права на интеллектуальную собственность
          такого рода в пользу ООО «ИрИс интер ГРУПП». Любое незаконное использование или совершение любого из
          вышеупомянутых действий будет представлять собой существенное нарушение наших прав на интеллектуальную
          собственность (включая авторские права и смежные с ними права и права изготовителя базы данных).<br></br>
          7.3. ООО «ИрИс интер ГРУПП» является обладателем исключительных прав интеллектуальной собственности на
          компиляции данных (базы данных), доступ к которым предоставляется Пользователю в рамках Сервисов, в частности,
          но не исключительно, компиляции данных о заявках, о грузах, предлагаемых к перевозке, и/или о наличии
          свободного грузового транспорта. Пользователю предоставляется неисключительное право использовать
          вышеуказанные результаты интеллектуальной деятельности, права на которые принадлежат ООО «ИрИс интер ГРУПП»,
          исключительно для удовлетворения своих нужд, непосредственно связанных с перевозкой грузов. Все права на
          использование (способы использования) компиляций данных, не указанные прямо в настоящем Соглашении как
          предоставленные Пользователю, считаются не предоставленными Пользователю и сохраняются за ООО «ИрИс интер
          ГРУПП». В частности, но не исключительно: Пользователь не вправе осуществлять воспроизведение и/или
          распространение объектов интеллектуальной собственности ООО «ИрИс интер ГРУПП» любыми прямо не оговоренными в
          настоящем Соглашении способами.
        </p>
        <h2 className={`${styles.items__title}`}>8. Состав и действие Пользовательского Соглашения</h2>
        <p className={`${styles.item}`}>
          8.1. Во избежание разночтений, изложенные выше правила являются общими и распространяются на все
          Сервисы/разделы Сайта, в том числе, но не исключительно: размещение заявок о грузах, предлагаемых к перевозке,
          и/или о наличии свободного грузового транспорта, размещение отзывов в “Зоне надежности”, Автомаркет,
          Форум.<br></br>
          8.2. Перечисленные ниже документы и/или сопроводительные инструкции для функционала на соответствующих
          страницах Сайта устанавливают специальные правила пользования отдельными Сервисами и являются неотъемлемой
          частью настоящего Соглашения:<br></br>
          a. <NavLink to={'/tariff'}>
            <span className={styles.link}> Услуги и цены </span>
          </NavLink><br></br>
          b.  <NavLink to={'/pravila/pravila-razmeshcheniya-zayavok'}>
            <span className={styles.link}> Правила размещения Заявок</span>
          </NavLink><br></br>
          с. <NavLink to={'/pravila/verifikaciya'}>
            <span className={styles.link}> Правила проверки Ларди-Транс достоверности Вашей учетной записи</span>
          </NavLink><br></br>
          d. <NavLink to={'/pravila/politika-konfidencialnosti'}>
            <span className={styles.link}> Политикой конфиденциальности</span>
          </NavLink><br></br>
        </p>
        <h2 className={`${styles.items__title}`}>9. Контакт с IrisTrans.com</h2>
        <p className={`${styles.item}`}>
          9.1. Информация о том, каким образом связаться с Нами, размещена на странице "Контакты". Вы
          также можете использовать отдельную веб-форму “Обратиться в службу поддержки” либо Чат поддержки.<br></br>
          9.2. При обнаружении предполагаемых неисправностей и/или иных явлений, расходящихся с представлениями об
          обычной работе Сайта и/или Сервисов, Мы рекомендуем связаться с нами.<br></br>
          9.3. Вы понимаете и соглашаетесь с тем, что в интересах Ваших и Наших, равно как и всех иных добросовестных
          сторон, которые могут быть вовлечены в различные вопросы, Мы вправе осуществлять запись телефонных разговоров
          со службой поддержки.
        </p>
      </div>
    </div>

  )
}
export default Agreement;
import axios from "axios";


let baseURl= window.location.origin
if (process.env.NODE_ENV === 'development') {
  baseURl = process.env.REACT_APP_BASE_URL
}

export const BaseURL = baseURl;

export const $host = axios.create({
  withCredentials: true,
  baseURL: `${BaseURL}`
})

$host.interceptors.request.use((config) => {
  config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return config
})


$host.interceptors.response.use((config) => {
  config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return config
}, async (error) => {
  const originalRequest = error.config
  if (error.response.status === 401 && error.config && !error.config._isRetry) {
    originalRequest._isRetry = true;
    try {
      const response = await axios.get(`${BaseURL}/api/user/refresh`, { withCredentials: true })
      localStorage.setItem('token', response.data.accessToken)
      return $host.request(originalRequest)
    } catch (e) {
      console.log("Не авторизован")
    }
  }
  throw error;
})

export default $host

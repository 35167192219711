import stylesGeneral from './pravila.module.css'
import styles from './rules.module.css'
import { useEffect } from 'react';

export function RulesPlacingEn() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={stylesGeneral.wrapper}>
      <div className="inner">
        <div className={styles.rules__body}>
          <div class="wrapper">
            <div class="container">
              <div className={`${styles.rules__wrapper}`}>
                <h1 className={`${styles.rules__title}`}>Rules for placing applications
                </h1>
                <h2 className={`${styles.items__title}`}>1. General information</h2>
                <p className={`${styles.item}`}>
                  1.1.Application - a message that is submitted by the User in the "Cargo" and "Transport" sections of the IrisTrans.com website by filling out a special form and contains a proposal for the need to transport cargo and / or a proposal for the availability of free transport for cargo transportation.<br></br>
                  1.2. Any User can place an order for cargo transportation after registering on the IrisTrans.com website. Placing an application for the availability of free transport is possible only after account verification.<br></br>
                  1.3. Any application can be checked by the site administration for compliance with the requirements of these Rules.<br></br>
                  1.4. Upon the complaint of the User, the site administration checks the application for compliance with the requirements of the current legislation of the Republic of Belarus, the User Agreement of the IrisTrans.com site and these Rules.


                </p>
                <h2 className={`${styles.items__title}`}>2. Application rules</h2>
                <p className={`${styles.item}`}>
                  2.1. To place an application with a proposal for the need to transport cargo and / or a proposal for the availability of free transport for the transportation of cargo, it is necessary to fill in all the required fields in the appropriate section of the site in the form for adding an application.<br></br>
                  2.2. All information in the application must match the information of the User who submits the application.<br></br>
                  2.3. Responsibility for the accuracy of the information specified in the application is borne by the User who placed the corresponding application.<br></br>
                  2.4. All positive and negative feedback on the execution of submitted applications are addressed to and relate to the User who submitted the application and the User who accepted the application (accepted its terms).<br></br>
                  2.5. The submission of an application and its acceptance (acceptance of its conditions) can be carried out using additional verification methods, for example, by entering additional codes sent by the User to the e-mail or mobile phone number indicated in the profile.<br></br>
                  2.6. In case of failure to perform additional verification methods, the application is not placed on the IrisTrans.com website.


                </p>
                <h2 className={`${styles.items__title}`}>3. Application restrictions</h2>
                <p className={`${styles.item}`}>
                  3.1. It is forbidden to submit an application in the interests of third parties and / or on behalf of individuals, legal entities or individual entrepreneurs who are not registered Users of the IrisTrans.com website.<br></br>
                  3.2. It is forbidden to place applications that contain other proposals other than proposals for the need to transport cargo and/or proposals for the availability of free vehicles for cargo transportation.<br></br>
                  3.3. It is prohibited to place applications for the carriage of goods and / or the availability of free transport to / from the territory (s) that are not recognized in accordance with international law.<br></br>
                  3.4. It is prohibited to use profanity and/or veiled profanity (in particular, but not exclusively, words (phrases) of profanity, individual letters of which are replaced by other symbols).<br></br>
                  3.5. It is forbidden to write words entirely in capital letters, except for official names, titles and/or abbreviations.<br></br>
                  3.6. It is forbidden to indicate in the application information that does not match the information in the profile of the User who submits the application.<br></br>
                  3.7. It is forbidden to update applications more often than once every 60 minutes.<br></br>
                  3.8. It is forbidden to indicate the same cargo/transport in several identical applications (clones).<br></br>
                  3.9. Applications are prohibited from placing contact information (phone numbers, addresses, ICQ, and so on) in any fields other than the "Contact Information" field.<br></br>
                  3.10. In the "Note" you can specify only the information for which there are no special fields in the application form.<br></br>
                  3.11. Filling out the application form should be done exclusively in accordance with the purpose of the fields.<br></br>
                  3.12. In the application for the carriage of goods, the exact points of loading / unloading must be indicated.


                </p>
                <h2 className={`${styles.items__title}`}>4. Deletion of applications and responsibility of Users for violation of the Rules for submitting applications</h2>
                <p className={`${styles.item}`}>
                  4.1. If the User violates the provisions of paragraphs 3.1 - 3.12 of section 3 of these Rules, the specified application will be deleted, and sanctions may be applied to the User from blocking the possibility of placing applications to blocking the account. The period of deprivation of the right to place orders is determined in each case, taking into account all circumstances, at the discretion of IrisTrans.<br></br>
                  4.2. Unblocking the opportunity to submit applications is carried out by the site administration after considering the User's appeal to the site administration with an explanation of the reasons for the violation of the requirements of these Rules.<br></br>
                  4.3. The administration of the IrisTrans.com website retains the right to delete applications that do not comply with the requirements of the current legislation of the Republic of Belarus, the requirements of the User Agreement of the IrisTrans.com website and the requirements of these Rules, as well as refuse the User to unblock the opportunity to submit applications based on the results of consideration of the User's request.
                </p>
                <h2 className={`${styles.items__title}`}>5. Removing irrelevant requests</h2>
                <p className={`${styles.item}`}>
                  5.1. Applications are automatically deleted from the site database after the end of the date specified in the "Upload dates from" field or in the "Upload dates to" field of the application form.<br></br>
                  5.2. If the application is closed before the specified deadlines, the User is obliged to independently delete closed or irrelevant applications.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
export default RulesPlacingEn;
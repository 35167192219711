export const block1 = [
  {
    title: "Как пройти регистрацию на сайте?",
    content: [
      '1. Нажмите Регистрация в правом верхнем углу сайта.', '2. Выберите Тип регистрации.', '3. Выберите Вид деятельности.', '4. Заполните контактные данные в форме "Регистрация".', 'Если Вы регистрируетесь как юридическое лицо, то в форме регистрации, может быть, несколько идентификационных номеров юридического лица, у каждого из которых есть свое название. Обязательным для заполнения является один из номеров, остальные - опциональны. Количество идентификационных номеров и их названия зависят от страны регистрации.', '5. Подтвердите адрес электронной почты, указанный при регистрации вашей учетной записи. '
    ]
  },
  {
    title: "Как завершить регистрацию и проверить профиль?",
    content: ['Авторизуйтесь на сайте IrisTrans.', ' Пройти проверку профиля можно при регистрации на сайте, прикрепив документы для верификации (паспорт или свидетельство о государственной регистрации). Прохождение проверки профиля считается успешным, если в аккаунте появится значок “Профиль проверен”.']

  },
  {
    title: "Как редактировать контактные данные?",
    content: ['Авторизуйтесь на сайте IrisTrans', 'В меню слева нажмите "Аккаунт/Моя страница".', ' Войдите в раздел "Контакты".', 'Нажмите на иконку редактирования.', 'Внесите изменения.']
  },
  {
    title: "Какие ограничения на сайте имеют физические лица?",
    content: ['Физическое лицо, не субъект хозяйственной деятельности, поэтому, как Пользователь Сайта и его Cервисов, имеет ряд ограничений, в том числе:', ' в размещении отзывов,', 'невозможность добавления  сотрудников, бренда, логотипа и отдельных тем форума.', 'Перечень ограничений IrisTrans вправе изменять по своему усмотрению и без специального уведомления такого Пользователя.']
  },
  {
    title: "Что делать, если аккаунт взломали?",
    content: ['Сообщите в контактный центр IrisTrans.', 'Защитите компьютер от вирусов.', ' Проверьте настройки доступа и смените пароль для электронной почты.', 'Измените пароль для входа в учетную запись на сайте IrisTrans.', 'Если Вы авторизуетесь на сайте IrisTrans через аккаунт социальной сети, проверьте - не взломан ли этот аккаунт.']
  },
  {
    title: "Что делать, если забыли пароль?",
    content: ['На странице входа нажмите "Забыли пароль".', 'Введите адрес электронной почты, который указан в аккаунте, и нажмите "Отправить запрос".', 'На электронную почту будет отправлено сообщение с напоминанием Вашего логина и ссылкой для восстановления пароля.', 'Вам необходимо перейти по данной ссылке и ввести новый пароль.']
  }
]

export const block2 = [
  {
    title: "Размещение заявок",
    content: [
      '1. Для добавления заявок нажмите на “≡” возле логотипа IrisTrans, выберите” Я грузоотправитель - Мои грузы/Я перевозчик - Мой транспорт” кнопка “Добавить груз/Добавить транспорт” или при свернутом левом боковом меню:', '2. на любой странице нажмите справа “+”, выберите Груз/Транспорт', '3. нажмите “+” в блоке “Груз/Транспорт” в личном кабинете.', '4. Заполните обязательные поля в открывшейся форме "Добавить груз/Добавить транспорт".', '5. Проверьте наличие галочки в поле "Я согласен с Правилами размещения заявок".', '6. Нажмите "Добавить заявку".'
    ]
  },
  {
    title: "Обновление заявок",
    content: [
      '1. Авторизуйтесь на сайте IrisTrans.', '2. Для обновления заявок нажмите на “≡” возле логотипа IrisTrans, выберите” Я грузоотправитель - Мои грузы/Я перевозчик - Мой транспорт” или при свернутом левом боковом меню перейдите на страницу "Мои грузы"/ "Мой транспорт".', '3. В списке заявок выберите ту, которую хотите обновить.', '4. В столбце “Повтор” выбранной заявки нажмите "Повторить".'
    ]
  },
  {
    title: "Поиск заявок",
    content: [
      '1. Для поиска заявок нажмите на “≡” возле логотипа IrisTrans, выберите” Я грузоотправитель - Поиск транспорта/Я перевозчик - Поиск грузов” или при свернутом левом боковом меню перейдите на страницу "Поиск грузов" (или "Поиск транспорта").',
      '2. В открывшейся форме "Поиск грузов для перевозки от IrisTrans "/ "Поиск транспорта для грузоперевозок" введите параметры поиска или выберите созданные Вами фильтры. Вы можете выбрать простой или расширенный поиск.',
      '3. Нажмите "Найти".'
    ]
  },
  {
    title: "Создание фильтра и автоматический поиск",
    content: [
      '1. В Поиске груза/транспорта введите все необходимые параметры и нажмите "Найти".'
    ]
  },
  {
    title: "Просмотр предложений по грузу/транспорту",
    content: [
      'Для просмотра предложений по грузу/транспорту нажмите на “≡” возле логотипа IrisTrans, выберите «Я перевозчик/ Я грузоотправитель» - «Мой транспорт/ Мои грузы» или при свернутом левом боковом меню перейдите на страницу "Мой транспорт".'
    ]
  },
]

export const block3 = [
  {
    title: "Подключение пакета услуг",
    content: [
      '1. Авторизуйтесь на сайте IrisTrans.',
      '2. Для подключения пакета услуг нажмите на “≡” возле логотипа IrisTrans, выберите” Услуги и цены”. ',
      '3. На открывшейся странице "Услуги и цены" выберите необходимый пакет услуг.',
      '4. Нажмите "Подключить".',
    ]
  },
]
import styles from './rules.module.css'
import stylesGeneral from './pravila.module.css'
import { useEffect } from 'react';

export function PrivacyPolicyPer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={stylesGeneral.wrapper}>
      <div className="inner">
        <div className={styles.rules__body}>
          <div className={`${styles.rules__wrapper}`}>
            <h1 className={`${styles.rules__title}`}>سیاست حفظ حریم خصوصی
            </h1>
            <h2 className={`${styles.items__title}`}>1. مقررات عمومی</h2>
            <p className={`${styles.item}`}>
              1.1. این سیاست حفظ حریم خصوصی نحوه استفاده و ذخیره اطلاعات شخصی مشتریانی که از iristrans.com بازدید می کنند را توضیح می دهد. (از این پس سایت نامیده می شود).<br></br>
              1.2. . استفاده از سایت توسط مشتری و ارائه اطلاعات خصوصی به IrisTrans.com از طریق سایت به معنای پذیرش این سیاست حفظ حریم خصوصی و شرایط پردازش اطلاعات شخصی مشتری و همچنین موافقت با انتقال، استفاده و افشای اطلاعات وی است. داده های شخصی مطابق با شرایط این سیاست حفظ حریم خصوصی.<br></br>
              1.3. در صورت عدم موافقت با شرایط سیاست حفظ حریم خصوصی، مشتری باید
استفاده از سایت را متوقف کنید
 <br></br>
              1.4. این سیاست حفظ حریم خصوصی فقط برای اطلاعات خصوصی جمع آوری شده از طریق این سایت اعمال می شود. اطلاعات خصوصی اطلاعاتی هستند که در صورت استفاده به تنهایی یا در ترکیب با سایر اطلاعات موجود، امکان شناسایی داده های شخصی مشتری را فراهم می کنند.<br></br>
              1.5. این سیاست حفظ حریم خصوصی فقط در مورد سایت اعمال می شود. این سایت عمداً پیوندهایی به منابع اینترنتی منتشر نمی کند که می تواند به رایانه مشتری آسیب برساند یا آن را با ویروس آلوده کند.<br></br>
              1.6. . مدیریت سایت صحت داده های شخصی ارائه شده توسط کاربر سایت را تأیید نمی کند.
            </p>
            <h2 className={`${styles.items__title}`}>2. موضوع سیاست حفظ حریم خصوصی</h2>
            <p className={`${styles.item}`}>
              2.1. . داده های جمع آوری و ذخیره شده:<br></br>
              2.1.1. . داده های شخصی و همچنین سایر اطلاعات مجاز برای پردازش تحت این سیاست حفظ حریم خصوصی که مشتریان آگاهانه و داوطلبانه با پر کردن فرم ثبت نام یا انجام سایر اقدامات در سایت ارائه می دهند:</p>
            <ul className={`${styles.list_items}`}>

              <li>نام خانوادگی نام؛</li>
              <li>آدرس ایمیل (ایمیل)؛</li>
              <li>شماره تماس؛</li>
              <li>کالاهای انتخاب شده توسط مشتری.</li>
            </ul>
            <p className={`${styles.item}`}>اطلاعات به منظور پذیرش، پردازش و تحویل سفارش جمع آوری می شود. ارائه بازخورد به مشتری، ارائه پشتیبانی مشتری، از جمله ارسال اعلان ها، درخواست های مربوط به استفاده از سایت.<br></br>
              2.2.2. . داده ها از کوکی ها اینها فایل های متنی کوچکی هستند که در آنها، پس از بازدید از سایت، کوکی ها اطلاعات مربوط به اقدامات مشتری در منبع را ذخیره می کنند. این فایل ها روی سیستم عامل کامپیوتر تاثیری ندارند. چرا کوکی‌ها جمع‌آوری می‌شوند: IrisTrans از این اطلاعات برای نگهداری آمار کاربران و درخواست‌های آنها استفاده می‌کند. این مطالعات به ما در تجزیه و تحلیل، ارزیابی، بهبود کار، بهبود کیفیت خدمات، بهبود مکانیسم های ارتباط با مشتریان کمک می کند. مشتری ممکن است پس از بازدید از سایت، کوکی ها را حذف کند. با این حال، در این مورد، IrisTrans مسئولیتی در قبال کیفیت نامناسب منبع ندارد.<br></br>
              2.2.3. . اطلاعات از مرورگر - داده هایی که مرورگر به طور خودکار به سرور ارسال می کند، به عنوان مثال، آدرس IP، تاریخچه مرور اخیر، نام سیستم عامل، نام و نسخه برنامه که از طریق آن مشتری به اینترنت دسترسی پیدا می کند، تاریخ و زمانی که کاربر از آن بازدید کرده است. سایت. با تغییر تنظیمات خود می توانید از انتقال چنین اطلاعاتی توسط مرورگر خود جلوگیری کنید. برای چه هدفی جمع آوری می شود: فروشگاه آنلاین آمار بازدیدها را برای عملکرد سرورهای خود و حسابداری ترافیک خود نگه می دارد. در این حالت اطلاعات آماری جمع آوری شده با کاربران خاصی مرتبط نبوده و تنها در قالب آمار تعمیم یافته پردازش می شود. آدرس IP مشتری و زمان دسترسی به سیستم، با توجه به شناسه کاربری، به منظور جلوگیری از تقلب در یک پایگاه داده ذخیره می شود.<br></br>
              2.2.4. اطلاعات اشخاص ثالث داده های شخصی است که توسط شرکا، تامین کنندگان یا سایر اشخاص ثالث در اختیار IrisTrans قرار می گیرد.<br></br>
              2.4. هر گونه اطلاعات شخصی دیگری که در بالا ذکر نشده است مشمول ذخیره سازی ایمن و عدم توزیع است، به جز مواردی که در پاراگراف ها ارائه شده است. 3.1.، 3.2. و 3.3. این سیاست حفظ حریم خصوصی
            </p>
            <h2 className={`${styles.items__title}`}>3. حفاظت از اطلاعات شخصی کاربران</h2>
            <p className={`${styles.item}`}>
              3.1. IrisTrans هرگز و تحت هیچ شرایطی اطلاعات شخصی (شخصی) مشتریان خود را به اشخاص ثالث فاش نمی کند.<br></br>
              3.2. IrisTrans این حق را دارد که از سایر شرکت ها و افراد برای انجام انواع خاصی از کارها مانند: تحویل پستی و ایمیلی، حذف اطلاعات تکراری از لیست مشتریان، تجزیه و تحلیل داده ها، خدمات بازاریابی، کارت اعتباری و پردازش پرداخت اعتباری استفاده کند. این اشخاص حقوقی/افراد فقط زمانی به اطلاعات شخصی کاربران دسترسی دارند که انجام وظایف آنها ضروری باشد. این اطلاعات نمی تواند توسط آنها برای مقاصد دیگر استفاده شود.<br></br>
              3.3. IrisTrans اقداماتی را برای محافظت از داده های شخصی (شخصی) مشتریان در زمینه های زیر اجرا می کند: </p>
            <ul className={`${styles.list_items}`}>
              <li>جلوگیری از نشت اطلاعات حاوی داده های شخصی (شخصی) از طریق کانال های ارتباطی فنی و سایر وسایل.</li>
              <li>جلوگیری از دسترسی غیرمجاز به اطلاعات حاوی داده های شخصی (شخصی)، تأثیرات ویژه بر چنین اطلاعاتی (حامل های اطلاعات) به منظور به دست آوردن، تخریب، تحریف و مسدود کردن دسترسی به آن.</li>
              <li>محافظت در برابر بدافزار.</li>
              <li>تشخیص نفوذ و حملات کامپیوتری.</li>
            </ul>
            <h2 className={`${styles.items__title}`}>4. . دوره نگهداری اطلاعات شخصی</h2>
            <p className={`${styles.item}`}>
            IrisTrans اطلاعات شخصی مشتریان را دقیقاً تا زمانی که برای دستیابی به اهدافی که در ابتدا برای آن به دست آمده یا سایر اهداف قانونی ضروری است ذخیره می کند، مگر در مواردی که طبق قانون به مدت طولانی تری ذخیره سازی اطلاعات نیاز باشد یا توسط قانون مجاز باشد. آنها را
            </p>
            <h2 className={`${styles.items__title}`}>5. امنیت</h2>
            <p className={`${styles.item}`}>
              5.1. . ما اقدامات فنی و سازمانی را برای محافظت از اطلاعات محرمانه شما در برابر اقدامات غیرمجاز اشخاص ثالث انجام می دهیم. از طرف ما، دسترسی به داده‌های شخصی شما برای تعداد محدودی از کارمندان و افراد مجاز که برای پردازش داده‌ها از طرف ما و برای اهدافی که صریحاً در این خط‌مشی رازداری ارائه شده است، به این اطلاعات نیاز دارند. کلیه آنها تعهدات محرمانه را بر عهده می گیرند و در صورت تخلف از تعهدات مذکور حسب مورد مشمول مسئولیت انتظامی، مدنی، اداری و/یا کیفری خواهند بود.<br></br>
              5.2. دسترسی به اطلاعات محرمانه شما همچنین می تواند از طریق سیستم مجوز معمول با استفاده از ورود و رمز عبور شما انجام شود. اگر از تضمین مستقل امنیت حساب خود غفلت کنید، اشخاص ثالث ممکن است دسترسی غیرمجاز به اطلاعات شما داشته باشند، که ما به هیچ وجه مسئول آن نیستیم.
            </p>
            <h2 className={`${styles.items__title}`}>6. معرفی تغییرات و الحاقات</h2>
            <p className={`${styles.item}`}>
              6.1. این سیاست به طور نامحدود تا زمانی که با نسخه جدید جایگزین شود، قابل اجرا است.<br></br>
              6.2. . هرگونه تغییر در شرایط یا شرایط سیاست حفظ حریم خصوصی در این سند منعکس خواهد شد. IrisTrans این حق را برای خود محفوظ می دارد که در هر زمان بدون اطلاع قبلی با ارسال نسخه به روز شده این سیاست حفظ حریم خصوصی در سایت، تغییراتی در بخش های خاصی از این سند ایجاد کند. از لحظه انتشار، کاربران مشمول شرایط جدید سیاست حفظ حریم خصوصی هستند.
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}
export default PrivacyPolicyPer;
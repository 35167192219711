import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { FallbackLoading } from "./loader/FallbackLoading";
import cookies from "js-cookie";

const root = ReactDOM.createRoot(document.getElementById("root"));
const locationPath = setDefaultLang();

function setDefaultLang() {
  if (window.location.pathname === "/per") {
    return "per";
  }
  if (window.location.pathname === "/eng") {
    return "en";
  }
  if (
    cookies.get("i18next") === "per" ||
    cookies.get("i18next") === "eng" ||
    cookies.get("i18next") === "ru"
  ) {
    return cookies.get("i18next");
  } else {
    return "ru";
  }
}

// Localisaton
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["ru", "en", "per"],
    fallbackLng: "ru",
    lng: locationPath,
    keySeparator: ".",
    detection: {
      order: ["cookie", "localStorage", "htmlTag", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

root.render(
  <Suspense fallback={<FallbackLoading />}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>
);

reportWebVitals();
export const block1 = [
  {
    title: "نحوه ثبت نام?",
    content: [
      '1. روی ثبت نام در گوشه بالا سمت راست سایت کلیک کنید.', '2. نوع ثبت نام را انتخاب کنید.', '3. نوع فعالیت را انتخاب کنید.', '4. اطلاعات تماس را در فرم "ثبت نام" پر کنید. گر به عنوان یک شخص حقوقی ثبت نام می کنید ، ممکن است چندین شماره شناسایی شخص حقوقی در فرم ثبت نام وجود داشته باشد که هر کدام نام خاص خود را دارند. یکی از اعداد اجباری است ، بقیه اختیاری هستند. تعداد شماره های شناسایی و نام آنها به کشور ثبت نام بستگی دارد.', '5. آدرس ایمیل را که هنگام ثبت حساب کاربری خود ارائه داده اید تأیید کنید. '
    ]
  },
  {
    title: "تکمیل ثبت نام و چک کردن مشخصات شما",
    content: ['ورود به وب سایت IrisTrans', ' شما می توانید هنگام ثبت نام در وب سایت با ضمیمه کردن اسناد برای تأیید (پاسپورت یا گواهی ثبت نام دولتی) از چک پروفایل عبور کنید. تأیید نمایه در صورتی موفقیت آمیز تلقی می شود که نماد" تأیید نمایه " در حساب ظاهر شود.']

  },
  {
    title: "ویرایش اطلاعات تماس",
    content: ['ورود به وب سایت IrisTrans', 'در منوی سمت چپ ، روی "حساب/صفحه من"کلیک کنید.', 'ورود به بخش "مخاطبین"', 'روی نماد ویرایش کلیک کنید.', 'تغییراتی ایجاد کنید.']
  },
  {
    title: "افراد چه محدودیت هایی در سایت دارند؟",
    content: ['یک فرد ، نه یک نهاد تجاری ، بنابراین ، به عنوان یک کاربر از سایت و خدمات آن ، دارای تعدادی از محدودیت ها ، از جمله:', 'در قرار دادن بررسی ها', 'ناتوانی در اضافه کردن کارکنان ، برند ، لوگو و موضوعات فردی انجمن.', 'IrisTrans حق دارد لیست محدودیت ها را به اختیار خود و بدون اطلاع ویژه به چنین کاربر تغییر دهد.']
  },
  {
    title: "اگه حسابم هک بشه بايد چيکار کنم؟",
    content: ['به مرکز تماس IrisTrans اطلاع دهید.', 'از کامپیوتر خود در برابر ویروس ها محافظت کنید.', 'تنظیمات دسترسی خود را بررسی کنید و رمز عبور ایمیل خود را تغییر دهید.', 'برای ورود به حساب کاربری خود در وب سایت IrisTrans رمز عبور را تغییر دهید.', 'اگر از طریق یک حساب شبکه اجتماعی وارد وب سایت IrisTrans می شوید ، بررسی کنید که آیا این حساب ']
  },
  {
    title: "اگر رمز عبورم را فراموش کردم چه کار کنم؟",
    content: ['در صفحه ورود ، روی "کلمه عبور فراموش شده"کلیک کنید.', 'آدرس ایمیل مشخص شده در حساب را وارد کنید و روی "ارسال درخواست"کلیک کنید.', 'یک پیام به ایمیل شما با یادآوری ورود شما و یک لینک برای بازگرداندن رمز عبور شما ارسال خواهد شد.', 'شما باید این لینک را دنبال کنید و یک رمز عبور جدید وارد کنید.']
  }
]

export const block2 = [
  {
    title: "قرار دادن برنامه ها",
    content: [
      '1. برای اضافه کردن برنامه ها ، روی "≡" در نزدیکی آرم IrisTrans کلیک کنید ، "من یک فرستنده هستم - محموله من/من یک حامل هستم - حمل و نقل من" دکمه "اضافه کردن محموله/اضافه کردن حمل و نقل" یا زمانی که منوی سمت چپ به حداقل می رسد:', '2. در هر صفحه ، روی "+" در سمت راست کلیک کنید ، محموله/حمل و نقل را انتخاب کنید', '3. روی "+" در بلوک "بار"/حمل و نقل" در حساب شخصی خود کلیک کنید.', '4. فیلدهای مورد نیاز را در فرم "Add cargo" که باز می شود/Add transport را پر کنید.', '5. کادر "من با قوانین قرار دادن برنامه ها موافقم"را علامت بزنید', '6. روی "افزودن برنامه" کلیک کنید".'
    ]
  },
  {
    title: "به روز رسانی برنامه ها",
    content: [
      '1. ورود به وب سایت IrisTrans', '2. برای به روز رسانی برنامه ها ، روی "≡" در نزدیکی آرم IrisTrans کلیک کنید ، "من یک فرستنده هستم - محموله من/من یک حامل هستم - حمل و نقل من" را انتخاب کنید یا وقتی منوی سمت چپ به حداقل می رسد ، به صفحه "محموله من"/ "حمل و نقل من" بروید', '3. در لیست برنامه ها ، برنامه ای را که می خواهید به روز کنید انتخاب کنید.', '4. در ستون" تکرار "برنامه انتخاب شده ، روی"تکرار" کلیک کنید.'
    ]
  },
  {
    title: "جستجوی برنامه ها",
    content: [
      '1. رای جستجوی برنامه ها ، روی "≡" در نزدیکی آرم IrisTrans کلیک کنید ، "من یک جستجوی حمل و نقل هستم/من یک جستجوی حمل و نقل هستم" را انتخاب کنید یا هنگامی که منوی سمت چپ به حداقل می رسد ، به صفحه "جستجوی حمل و نقل" (یا "جستجوی حمل و نقل") بروید.',
      '2. در فرم باز شده ، "جستجوی کالا برای حمل و نقل از IrisTrans" / "جستجوی حمل و نقل برای حمل و نقل محموله" ، پارامترهای جستجو را وارد کنید یا فیلترهایی را که ایجاد کرده اید انتخاب کنید. شما می توانید جستجوی ساده یا پیشرفته را انتخاب کنید.',
      '3. روی "یافتن" کلیک کنید.'
    ]
  },
  {
    title: "ایجاد فیلتر و جستجوی خودکار",
    content: [
      '1. در جستجوی بار/حمل و نقل ، تمام پارامترهای لازم را وارد کرده و روی "یافتن"کلیک کنید.'
    ]
  },
  {
    title: "تایید درخواست ها مشاهده پیشنهادات حمل و نقل/حمل و نقل",
    content: [
      'برای مشاهده پیشنهادات حمل و نقل ، روی "≡" در نزدیکی آرم IrisTrans کلیک کنید ، "من یک حامل هستم/  من یک حمل کننده هستم" - "حمل و نقل من/ محموله من" را انتخاب کنید یا وقتی منوی سمت چپ به حداقل رسید، به صفحه "حمل و نقل من" بروید.' 
    ]
  },
]

export const block3 = [
  {
    title: " اتصال یک بسته خدمات اتصال ، قطع و وصل و تغییر پکیج سرویس",
    content: [
      '1. ورود به وب سایت IrisTrans',
      '2. برای اتصال بسته خدمات ، روی "≡" در نزدیکی آرم IrisTrans کلیک کنید ، "خدمات و قیمت ها"را انتخاب کنید. ',
      '3. در صفحه "خدمات و قیمت ها" که باز می شود ، بسته مورد نیاز خدمات را انتخاب کنید.',
      '4. روی "اتصال"کلیک کنید.',
    ]
  },
]
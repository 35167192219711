import styles from './main-tariff.module.css';
// import styles from './main-tariff-pers.module.css';
import { NavLink } from 'react-router-dom';
import Button from '../button/button';
import CardMainTariff from '../card-main-tariff/card-main-tariff';
import auto from './images/auto.png';
import autoMob from './images/auto-mob.png';
import boxes from './images/boxes.png';
import man from './images/man.png';
import { useTranslation } from "react-i18next";

export function MainTariff() {
  const { t } = useTranslation();
  return (
    <div className={styles.tariff_wrapper} id="tariff">
      <div className="inner">
        <h2 className={`${styles.title} section__title `}>{t('main.tarif.title')}</h2>
        <div className={styles.cards__wrapper}>
          <div className={styles.img_full}>
            <CardMainTariff title={t('main.tarif.item1Title')}
              subtitle={t('main.tarif.item1text')}
              img={auto} />
          </div>
          <div className={styles.img_mob} >
            <CardMainTariff title={t('main.tarif.item3Title')}
              subtitle={t('main.tarif.item3Text')}
              img={autoMob} />
          </div>
          <CardMainTariff title={t('main.tarif.item2Title')}
            subtitle={t('main.tarif.item2Text')}
            // subtitle="Поиск транспорта, регистрация груза"
            img={boxes} />
          <CardMainTariff title={t('main.tarif.item3Title')}
            // subtitle={'Поиск груза и транспорта, \n регистрация груза и транспорта'}
            subtitle={t('main.tarif.item3Text')}
            img={man} />
        </div>
        <NavLink to="/tariff" key='logo' >
          <Button>{t('servicePackages')}</Button>
        </NavLink>

      </div>

    </div >
  )
}
export default MainTariff;
import Button from '../button/button';
import { CardMainTop } from '../card-main-top';
import { useDispatch } from 'react-redux';
import styles from './main-top.module.css';
// import styles from './main-top-pers.module.css';
import auto from './images/icons/auto.svg'
import box from './images/icons/box.svg'
import plus from './images/icons/plus.svg'
import like from './images/icons/like.svg'
import React from "react";
import { openRegistrForm, } from '../../redux/reducers/registration-reducer';
import { useTranslation } from "react-i18next";


export function MainTop() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  function changeRegistrationOpen() {
    dispatch(openRegistrForm());
  }

  return (
    <div className={styles.mainTopWrapper}>
      <div className="inner">
        <div className={styles.main__wrapper}>
          <div className={styles.mainTopInfo}>
            <h1 className={styles.mainTopTitle}>
              {/* Международный поиск<br />груза и транспорта */}
              {t('page.main.title')}
            </h1>
            <h2 className={styles.mainTopSubTitle}>IrisTrans.com</h2>
            <Button click={changeRegistrationOpen}>{t('button.registrationText')}</Button>
          </div>
          <div className={styles.main__top_cards}>
            <CardMainTop img={auto}
              title={t('transport-search')}
              subtitle={t('instrument-po-poisku-transporta')}
            > </CardMainTop>
            <CardMainTop img={box}
              title={t('cargo-search')}
              subtitle={t('instrument-po-poisku-gruza-dlya-perevozki')}
            > </CardMainTop>
            <CardMainTop img={plus}
              title={t('dobavlenie-transporta-i-gruza')}
              subtitle={t('instrument-po-dobavleniyu-transporta-i-gruza-dlya-perevozki')}
            > </CardMainTop>
            <CardMainTop img={like}
              title={t('otzyvy-i-reiting')}
              subtitle={t('sistema-reitinga-dlya-opredeleniya-nadyozhnosti-sotrudnichestva')}
            > </CardMainTop>
          </div>
        </div>
      </div>

    </div>
  )
}
export default MainTop;
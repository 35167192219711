import stylesGeneral from './pravila.module.css'
import styles from './rules.module.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

export function RulesOfertaEn() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className={stylesGeneral.wrapper}>
      <div className="inner">
        <div className={styles.rules__body}>
          <div class="container">
            <div className={`${styles.rules__wrapper}`}>
              <h3 className={`${styles.rules__title}`}>public contract
(offer)
paid provision of services by bank transfer
Transport and information server IrisTrans.com Published: 05.09.2023

              </h3>
              <p className={`${styles.item}`}>This Public Agreement (hereinafter referred to as the "Agreement") determines the procedure for providing the services of the Site - "Transport and Information Server IrisTrans.com", as well as mutual rights, obligations and the procedure for the relationship between:<br></br>
                - a representative of the Site - LLC "IRIS inter GROUP" represented by the director Isapur Iraja, acting on the basis of the Charter (hereinafter referred to as -  <b>Executor</b>)<br></br>
                And<br></br>
                - a consumer of the Site's services (an individual, an individual entrepreneur or a legal entity) who has accepted (accepted) a public offer (offer) to conclude this Agreement (hereinafter referred to as  – <b>Customer</b>).<br></br>
                This agreement is concluded and executed in accordance with the provisions of Articles 396, 404, 407, 408 of the Civil Code of the Republic of Belarus, as well as other provisions of the legislation of the Republic of Belarus that regulate these legal relations.
              </p>

            </div>
          </div>
        </div>


        <h2 className={`${styles.items__title}`}>1. DEFINITIONS USED IN THIS AGREEMENT</h2><br></br>
        <p className={`${styles.item}`}>
          1.1. <b>User</b> — The Customer under this Agreement is an individual, an individual entrepreneur, a legal entity, as well as their authorized representatives who have accepted (accepted) a public offer (offer) to conclude this Agreement and access the Site and / or use the Site Services by creating an Account.<br></br>
          1.2. <b>Website</b> — the IrisTrans.com transport and information server, as well as an information resource available on the Internet using the iristrans.com domain name (including through any other domain names redirecting to iristrans.com).<br></br>
          1.3. <b>IrisTrans</b> — Transport and information server IrisTrans.com, as well as LLC "Iris inter GROUP" or other authorized representative of the Transport and information server IrisTrans.com.<br></br>
          1.4. <b>Service/Services</b> — separate information services provided to the User through the Site and / or in any other way (in particular, but not exclusively, through e-mail distribution, separately installed applications and / or other Internet resources) with the legitimate use of the IrisTrans trademark and / or similar (derivative) designations, as well as the relevant functional sections of the Site and methods of providing services.<br></br>
          1.5. <b>Services</b> — access to information and functionality of the Site, as well as obtaining other information services of the Site, by creating and using a User account.<br></br>
          1.6. <b>Agreement</b> — posted on the Site User Agreement, Privacy Policy, as well as other conditions and rules for using the Site and / or individual Services of the Site.<br></br>
          1.7. <b>Account</b> — a set of data stored on the Site about the User, which is necessary to identify him and provide access to the Services and Services of the Site after authorization using the User's login and password.<br></br>
          1.8. All other terms and definitions in this agreement are used in the meanings determined by the provisions of the current legislation of the Republic of Belarus.


        </p>
        <h2 className={`${styles.items__title}`}>2. SUBJECT OF THE CONTRACT</h2><br></br>
        <p className={`${styles.item}`}>
          2.1. The Contractor undertakes, on behalf of the Customer, to provide the Services of the Site and / or provide the Services in accordance with the package of services chosen by the Customer.<br></br>
          2.2. The Customer undertakes to accept the Services and / or Services provided by the Contractor and pay for them in the manner and on the terms determined by this Agreement and the list of services and prices indicated on the Site.<br></br>
          2.3. Tariffs, a list of specific services, services and functions of the Site, which are provided for payment to the Customer, are available for review and selection in the Customer's Account and/or at: <NavLink to={'/tariff'} key={'tariffinfo'}>
            <span className={styles.link}>Tariffs</span>
          </NavLink>.


        </p>
        <h2 className={`${styles.items__title}`}>3. PROCEDURE FOR CONCLUDING THE AGREEMENT</h2><br></br>
        <p className={`${styles.item}`}>
          3.1. This Agreement is a public agreement (Article 396 of the Civil Code of the Republic of Belarus), according to which the Contractor assumes an obligation to provide Services to an indefinite number of persons (Customers) who have applied for the provision of Services.<br></br>
          3.2. Publication (placement) of the text of this Agreement on the official website of the Contractor at: iristrans.com is a public offer (offer) of the Contractor addressed to an indefinite circle of persons to conclude this Agreement (clause 2, article 407 of the Civil Code of the Republic of Belarus).<br></br>
          3.3. The conclusion of this Agreement is made by joining the Customer to this Agreement, i.e. through the acceptance (acceptance) by the Customer of the terms of this Agreement as a whole, without any conditions, exceptions and reservations (Article 398 of the Civil Code of the Republic of Belarus).<br></br>
          3.4. The fact of acceptance (acceptance) of the terms of this Agreement is: 3.4.1. registration by the Customer of an online application for the provision of the Services and payment for the ordered Services in the manner and on the terms determined by this Agreement (clause 3 of article 408 of the Civil Code of the Republic of Belarus);<br></br>
          3.5. This Agreement, subject to the procedure for its acceptance, is considered concluded in a simple written form (clause 2, clause 3 of article 404 and clause 3 of article 408 of the Civil Code of the Republic of Belarus) and does not require paperwork and has full legal by force.<br></br>
          3.6. In order to gain access to the Services, the Customer goes through the procedure of registering a new client on the Contractor's page at iristrans.com or undergoes authorization using the login and password of access to the Site that the Customer already has.<br></br>
          3.7. When registering, the Customer is obliged to indicate the following reliable information in the Account:<br></br>
          3.7.1. natural person - surname, first name, patronymic (if any), postal address or registration address, identification number (if any), e-mail address, contact phone number;<br></br>
          3.7.2. individual entrepreneur - information specified in subparagraph 3.7.1. clause 3.7. of this Agreement, as well as information on state registration (registration or other identification number, name of the body (organization) that carried out state registration, date of registration, decision number (if any));<br></br>
          3.7.3. legal entity - full name, location (postal address), information on state registration (registration or other identification number, payer account number (if any), e-mail address, contact phone number, surname, first name, patronymic (if any) leader.<br></br>
          3.8. The data specified by the Customer in the manner specified in clause 3.7. of this Agreement will be automatically used to identify the Customer and to access the Site and/or Services.<br></br>
          3.9. The account created on the Site is activated after verification by IrisTrans of the information specified in the Account, using the specified phone number, e-mail or other identification data and technical means that allow identifying the identity and / or name of the user. At the same time, one phone number and / or one set of other identification data can be used to register no more than one user account on the Site.<br></br>
          3.10. Registration of an online application for the provision of Services is carried out by the Customer on the official website of the Contractor using the web interface located at: iristrans.com by performing a certain sequence of actions to fill out special template forms in order to provide the information necessary for concluding this Agreement and subsequent provision Services.<br></br>
          3.11. The Customer is obliged to check the information contained in the final text of the online application for the provision of Services, and, if necessary, repeat the procedure for filling out an online application in order to correct inaccurate information. Indication of inaccurate or false information is for IrisTrans the basis for refusal to provide the Services and / or Services.<br></br>
          3.12. The list of Services to be rendered under this Agreement is indicated in the online application for the provision of Services, executed by the Customer in the manner and on the conditions specified in this Agreement, as well as in the demand for payment for the ordered Services (account for legal entities and individual entrepreneurs or payment receipt for individuals) issued by the Contractor for payment by the Customer.<br></br>
        </p>
        <h2 className={`${styles.items__title}`}>4. RIGHTS AND OBLIGATIONS OF THE PARTIES</h2><br></br>
        <p className={`${styles.item}`}>
          4.1. The Contractor undertakes:<br></br>
          4.1.1. provide the Customer with the Services in the amount and within the timeframe stipulated by the tariffs, the list of specific services, services and functions of the Site and specified in the demand for payment for the Services ordered by the Customer (invoice or payment receipt), which is formed by the Customer independently using the web interface located in the Accounting records;<br></br>
          4.1.2. provide the Customer during working hours (from 9:00 to 18:00, except for weekends and official public holidays), consultations by phone and / or e-mail on issues arising from the Customer in connection with the provision of the Services;<br></br>
          4.1.3. ensure the confidentiality of the parameters of access to the server (login, password), except in cases where the provision of access to such information for third parties is a necessary condition for the provision of the Services, or is mandatory due to the requirements of the legislation of the Republic of Belarus.<br></br>
          4.2.1. получать оплату за предоставленные Услуги в объеме и порядке, предусмотренном указанным Договором, а также
          тарифами, перечнем конкретных услуг, сервисов и функций Сайта;<br></br>
          4.2. The performer has the right:<br></br>
          4.2.2. terminate the provision of the Services in the event that the Customer violates the terms of this Agreement or the Customer commits actions on the Site that violate the rights and legitimate interests of the Contractor, other users of the Site and / or any third parties;<br></br>
          4.2.3. unilaterally change the terms of this Agreement, tariffs and the list of Services and / or Services provided to the Customer, with mandatory notification of upcoming changes at least 1 (one) month before such changes.<br></br>
          4.3. The customer undertakes:<br></br>
          4.3.1. comply with the terms of this Agreement, the provisions of the current legislation of the Republic of Belarus and other norms that are mandatory for implementation on the territory of the Republic of Belarus;<br></br>
          4.3.2. pay the Contractor for the ordered Services in the manner, in the amount and within the time limits specified by this Agreement on the basis of an invoice or receipt of payment, which is generated by the Customer independently using the web interface located in the Account;<br></br>
          4.3.3. ensure the safety and confidentiality of service information received from the Contractor (links, access names and passwords, mobile phone numbers of the Contractor's specialists, etc.);<br></br>
          4.3.4. if there are claims for the Services provided by the Contractor, notify the Contractor thereof within 2 (two) working days from the moment when the Customer became aware or should have become aware of the non-performance or improper performance by the Contractor of the obligations assumed under this Agreement.<br></br>
          4.4. The customer has the right:<br></br>
          4.4.1. require the Contractor to properly provide the Services;<br></br>
          4.4.2. receive from the Contractor during business hours (from 9:00 to 18:00, excluding weekends and official public holidays) consultations by phone and / or e-mail on issues arising in connection with the provision of the Services;<br></br>
          4.4.3. terminate this Agreement in the event of:<br></br>
          non-fulfillment or improper fulfillment by the Contractor of its obligations to provide the Services under this Agreement;<br></br>
          disagreement with the changes and / or additions made by the Contractor to this Agreement.<br></br>
          4.5. By concluding this Agreement, the Customer gives his full and unconditional consent to receive advertising information from the Contractor, as well as other information distributed by the Contractor (including via SMS messages, messages to email addresses and / or personal messages in the Account). Such consent is used by IrisTrans for the purposes and in cases where the need to obtain such consent is provided for by the legislation of the Republic of Belarus. The Customer is informed of his right to demand from the Contractor the immediate termination of the placement (distribution) of advertising information addressed to the Customer.<br></br>
          4.6. The Contractor is obliged, at the first request of the Customer, to immediately stop placing (distributing) advertising information to the Customer, if the technical and software capabilities allow the Contractor to do this without restricting the Customer's access to the Site. Otherwise, the Contractor has the right to restrict or block the Customer's access to the Services of the Site and / or Services. The payment made by the Customer in this case is not refundable.<br></br>
          4.7. The Contractor does not guarantee 100% error-free and uninterrupted operation of the Site and / or Services and is not responsible for any possible damage caused to the User and / or third parties by technical failures of hardware and / or software on any side.<br></br>
          4.8. The Contractor does not guarantee the accuracy of information (in particular, but not exclusively: applications for goods offered for transportation and / or availability of free freight transport) posted on the Site by third parties, and is not responsible for any possible damage caused to the User and / or third parties as a result of using the Site/Services.
        </p>
        <h2 className={`${styles.items__title}`}>5. COST, PROCEDURE AND TERMS OF PAYMENT FOR SERVICES</h2><br></br>
        <p className={`${styles.item}`}>
          5.1. The cost of the Services provided under this Agreement is determined based on the volume, nature and duration of the Services ordered by the Customer in accordance with the tariffs approved by the Contractor, the list of specific services, services and functions of the Site, effective immediately at the time of the request for payment for the ordered Services.<br></br>
          5.2. The tariffs that were in effect immediately at the time of the request for payment for the ordered Services are made an integral part of this Agreement.<br></br>
          5.3. The Customer pays for the Contractor's services on the basis of an invoice or payment receipt, which is generated by the Customer independently using the web interface located in the Account.<br></br>
          5.4. Customers pay for the ordered Services by means of a non-cash transfer of funds to the Contractor's settlement account in accordance with the data and details specified in the invoice.<br></br>
          5.5. The procedure for making payments and the necessary bank details of the Contractor, to which payment for the ordered Services is accepted, are indicated on the official website of the Contractor at:https://iristrans.com., as well as in the corresponding invoice or payment receipt, which is generated by the Customer independently using the web interface located in the Account.
        </p>
        <h2 className={`${styles.items__title}`}>6. PROCEDURE FOR DELIVERY AND ACCEPTANCE OF SERVICES</h2><br></br>
        <p className={`${styles.item}`}>
          6.1. The provision of services under this Agreement for Customers who are legal entities and individual entrepreneurs is confirmed by the Service Provision Certificate, which is drawn up solely by the Contractor (in accordance with the Decree of the Ministry of Finance of the Republic of Belarus dated February 12, 2018 No. 13 “On the sole preparation of primary accounting documents and declaring invalid the Decree of the Ministry of Finance of the Republic of Belarus dated December 21, 2015 No. 58”).<br></br>
          6.2. The date of provision of the Services and / or Services is the moment of crediting funds to the settlement accounts of the Site, subject to the presence of a registered Customer Account on the Site.<br></br>
          6.3. The Customer confirms and agrees that the Service Provision Certificate in accordance with this Agreement is drawn up by the Contractor solely, and the Customer independently receives the Services Provision Certificate using the web interface located in the Account.<br></br>
          6.4. The provision of Services under this Agreement for Customers who are individuals is confirmed by the successful activation of the Customer's Account on the website and the absence of written claims from the Customer regarding the Services and/or Services provided by the Contractor, sent within 5 (five) calendar days from the end date of the period, in which the Services and/or Services were provided.
        </p>
        <h2 className={`${styles.items__title}`}>7. RESPONSIBILITIES OF THE PARTIES</h2><br></br>
        <p className={`${styles.item}`}>
          7.1. For non-fulfillment or improper fulfillment of its obligations under this Agreement, the guilty Party shall be liable in accordance with the current legislation of the Republic of Belarus, taking into account the specifics established by this Agreement.<br></br>
          7.2. The Contractor is released from liability for losses explicitly or indirectly incurred by the Customer as a result of full or partial consumption or inability to consume the Services in the following cases:<br></br>
          7.2.1. if the losses are caused due to the Customer's non-observance of the confidentiality of its credentials or other confidential information, as well as due to unauthorized access of third parties to the Customer's technical or information resources.<br></br>
          7.2.2. if losses are caused due to technical failures of hardware and/or software, as well as Internet providers on the side of the Contractor or the Customer.<br></br>
          7.3. The parties to this Agreement unconditionally agree that the maximum amount of damages that can be recovered from the Contractor is limited to the amount of Services paid for by the Customer, the failure to perform or improper performance of which caused losses.<br></br>
          7.4. The limitation of the Contractor's liability provided for in clause 7.5 of this Agreement cannot be applied in cases where:<br></br>
          7.4.1. the amount of liability for this type of obligations or for this violation is determined by the legislation of the Republic of Belarus;<br></br>
          7.4.2. non-fulfillment or improper fulfillment by the Contractor of its obligations under this Agreement is a consequence of its direct intent and this fact has been established by a court decision that has entered into force.<br></br>
          7.5. The Customer is solely responsible for any, including unauthorized, actions of third parties that occurred as a result of the Customer's non-compliance with the confidentiality of its credentials or other confidential information, as well as for the consequences of such actions.<br></br>
          7.6. The Customer independently bears full responsibility for possible violations of copyright, trademarks and other norms of the legislation of the Republic of Belarus related to the fact of placing any information of the Customer on the Internet on the information and technical resources of the Contractor.<br></br>
          7.7. The Customer shall reimburse the Contractor and/or the Contractor's officials for any losses incurred by the Contractor or its officials in connection with the placement of information on the Customer's information and technical resources, the content of which is contrary to the legislation of the Republic of Belarus, as well as in other cases when the Customer's failure to comply with the requirements of the legislation of the Republic of Belarus and /or obligations under this Public Agreement has caused damages.
        </p>
        <h2 className={`${styles.items__title}`}>8. FORCE MAJEURE</h2><br></br>
        <p className={`${styles.item}`}>
          8.1. The Parties are released from liability for partial or complete failure to fulfill their obligations under this Agreement, if this was the result of force majeure circumstances (force majeure) that arose after the conclusion of this Agreement as a result of extraordinary events that the Parties could neither foresee nor prevent by reasonable means. measures.<br></br>
          8.2. Force majeure events include events over which the Party has no influence and for the occurrence of which it is not responsible, such as: war, insurrection, strike, earthquake, flood, fire, severe weather or other natural disasters, government regulations, orders (decrees) of state bodies and officials, laws and other regulations of the competent authorities adopted after the acceptance of this Agreement and making it impossible to fulfill the obligations established by this Agreement, as well as actions of state or local state authorities and authorities or their representatives that impede the fulfillment of the conditions of this Agreement, and other unforeseen circumstances, including malfunctions in the city power grid.<br></br>
          8.3. In the event of force majeure circumstances preventing the fulfillment of obligations under this Agreement, the period for the Parties to fulfill such obligations shall be postponed in proportion to the duration of such circumstances, as well as the time required to eliminate their consequences, but not more than sixty calendar days.<br></br>
          8.4. In the event that force majeure circumstances continue to operate for more than the period specified in clause 8.3 of this Agreement, or when upon their occurrence it becomes obvious to both Parties that the circumstances will operate for more than this period, the Parties undertake to discuss the possibility of alternative ways of fulfilling this Agreement or its termination without damages.
        </p>
        <h2 className={`${styles.items__title}`}>9. NOTICES AND NOTICES</h2><br></br>
        <p className={`${styles.item}`}>
          9.1. The Parties agree that they unconditionally recognize the legal force of the texts of documents received via communication channels (e-mail) on an equal basis with documents executed in simple written form on paper, except in cases where the execution of documents on paper is mandatory due to the requirements of the legislation of the Republic of Belarus.<br></br>
          9.2. The Parties unconditionally agree that all correspondence, notices and notifications received at the e-mail addresses indicated on the Site as the details of the Contractor and in the Customer's Account as the details of the Customer.<br></br>
          9.3. The Parties are obliged to timely check the correspondence received at their e-mail addresses, which are indicated on the Site.<br></br>
          9.4. All risks associated with the onset of adverse consequences due to non-compliance with the requirements of clause 9.3 of this Agreement shall be borne by the Party that committed such a violation.

        </p>
        <h2 className={`${styles.items__title}`}>10. TERM OF AGREEMENT AND PROCEDURE FOR ITS TERMINATION</h2><br></br>
        <p className={`${styles.item}`}>
          10.1. This Agreement is considered concluded from the moment the funds paid by the Customer to pay for the services ordered by him are credited to the account of the Contractor in accordance with the demand for payment for the ordered services.<br></br>
          10.2. This Agreement is valid for an indefinite period until its termination in the manner prescribed by this Agreement.<br></br>
          10.3. This Agreement may be terminated:<br></br>
          10.3.1 by agreement of the Parties;<br></br>
          10.3.2 unilaterally at the initiative of the Contractor in accordance with clause 4.2.2 of this Agreement;<br></br>
          10.3.3 unilaterally at the initiative of the Customer in accordance with clause 4.4.3 of this Agreement.<br></br>
          10.4. The Customer unconditionally agrees that in the event of termination of this Agreement in accordance with clause 10.3.1 or clause 10.3.2 of this Agreement, he loses the right to demand from the Contractor the return of any amounts paid in payment for the Services, even if if these Services have not yet expired.

        </p>
        <h2 className={`${styles.items__title}`}>11. PROCEDURE FOR INTRODUCING CHANGES AND ADDITIONS TO THE AGREEMENT</h2><br></br>
        <p className={`${styles.item}`}>
          11.1. Changes and / or additions to this Agreement are made unilaterally by decision of the Contractor.<br></br>
          11.2. Changes and / or additions made by the Contractor to this Agreement on its own initiative shall enter into force no earlier than the day after they are published on the Contractor's website.<br></br>
          11.3. Changes and / or additions made by the Contractor to this Agreement in connection with changes in legislation come into force simultaneously with the entry into force of changes in these legislative acts.<br></br>
          11.4. The text of amendments and / or additions to this Agreement, or its new version, is brought to the public by the Contractor by posting (publishing) the relevant information on the website at the following address 
          <NavLink to={'https://IrisTrans.com'}>
            <span className={styles.link}> https://IrisTrans.com.</span>
          </NavLink>
          <br></br>
          11.5. In case of disagreement with the changes and / or additions made, the Customer has the right to terminate this Agreement in accordance with clause 4.4.3 of this Agreement.<br></br>
          11.6. Notice of termination of this Agreement is also recognized as any written notice of the Customer, drawn up on paper, of disagreement with the changes and / or additions made, or of non-adherence to the new version of this Agreement or refusal to comply with its terms.<br></br>
          11.7. The Parties unconditionally agree that the absence of written notices of termination of this Agreement, or of disagreement with certain provisions of this Agreement, including changes in the price list for the Services, is recognized as the Customer's consent and accession to the new version of this Agreement (clause 3 of Article 159 of the Civil Code of the Republic of Belarus).
        </p>
        <h2 className={`${styles.items__title}`}>12. DISPUTES RESOLUTION</h2><br></br>
        <p className={`${styles.item}`}>
          12.1. All disputes and disagreements related to this Agreement, the Parties undertake to resolve through negotiations.<br></br>
          12.2. If the Parties fail to resolve all disputes in the manner prescribed by clause 12.1 of this Agreement, all disputes arising from this Agreement, including those related to its conclusion, amendment, termination, performance, invalidity, shall be resolved in court in accordance with with the legislation of the Republic of Belarus.



        </p>
        <h2 className={`${styles.items__title}`}>13. OTHER TERMS</h2><br></br>
        <p className={`${styles.item}`}>
          13.1. The Parties unconditionally agree that this Agreement is concluded at the address of the Contractor's office.<br></br>
          13.2. By entering into this Agreement, the Customer hereby declares that:<br></br>
          13.2.1. according to his information, the content of his Data does not contradict the legislation of the Republic of Belarus and does not violate the rights and legitimate interests of third parties, including intellectual property rights; 13.2.2. the information provided by him when placing an order for the provision of the Services is complete, true and accurate;<br></br>
          13.2.3. he is aware and agrees that some of the information that he provided when placing an order for the provision of the Services may be available to third parties, due to the requirements of the legislation of the Republic of Belarus.<br></br>
          13.3. If any of the terms of this Agreement loses legal force, is declared illegal, or is excluded from this Agreement, this does not entail the invalidity of the remaining terms of this Agreement, which will remain legally valid and are binding on all Parties.<br></br>
          13.4. All issues not regulated by this Agreement shall be resolved in accordance with the current legislation of the Republic of Belarus, as well as local regulatory documents of the Contractor, subject to their compliance with the current legislation of the Republic of Belarus.

        </p>
        <h2 className={`${styles.items__title}`}>14. DETAILS OF THE PARTIES</h2><br></br>
        <p className={`${styles.item}`}>
          14.1. The Parties unconditionally agree under the details of the Customer to consider the information specified by him when placing an order for the provision of Services.<br></br>
          14.2. Details of the Contractor:<br></br>
          ООО «ИрИс интер ГРУПП» в лице директора Исапур Ираджа<br></br>
          Limited Liability Company "IRIS inter GROUP"<br></br>
          Postal address: 220034, Republic of Belarus, Minsk, PO Box 15<br></br>
          Legal address: Minsk, Babushkina st., 29, of. 14<br></br>
          UNP: 193438125<br></br>
          Tel. (+375 17) 303 96 99<br></br>
          Mob. (+375 44) 653 04 30<br></br>
          E-mail:info@irisintergroup.com <br></br>
          Bank details:<br></br>
          IBAN: BY66 BPSB 3012 3269 1601 2933 0000 (USD),<br></br>
          IBAN: BY62 BPSB 3012 3269 1603 8840 0000 (BYN),<br></br>
          IBAN: BY45 BPSB 3012 3269 1604 1978 0000 (EUR),<br></br>
          IBAN: BY66 BPSB 3012 3269 1605 4643 0000 (RUB)<br></br>
          in JSC "Sber Bank" Minsk, b-r Mulyavina, 6, BIC SWIFT BPSBBY2X
        </p>
      </div>
    </main >
  )
}
export default RulesOfertaEn;


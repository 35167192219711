/* eslint-disable react-hooks/exhaustive-deps */
import styles from './results-table.module.css';
import { saveCurrentID } from '../../../redux/reducers/current-id-reducer';
import { useSelector, useDispatch } from 'react-redux';
import { useMemo, useState, useRef } from 'react';
import { Pagination } from '../../pagination';
import { cargoDelete } from '../../../http/cargoApi';
import attentionIcon from './images/icon-attention.png';
import { addMyCargo } from '../../../redux/reducers/cargo-users-reducer';
import axios from "axios";
import { saveCargosUserData } from '../../../redux/reducers/cargo-users-reducer';
import { openEditCargoForm } from '../../../redux/reducers/cargo-update-reducer';
import { useTranslation } from "react-i18next";
import { BaseURL } from "../../../http";
import telegram from "./images/telegram.png";
import whatsapp from "./images/whatsapp.png";
import skype from "./images/skype.svg";
import { NavLink } from 'react-router-dom';

export function ResultsTable(props) {
  const { t } = useTranslation();
  const myCargoList = useSelector(state => state.cargoUsers.cargoUser);
  const currentLanguage = useSelector(state => state.language.language);

  const scrollToElement = () => {

    refDetails.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getDetails = (cargoId, e) => {
    if (!e.target.classList.contains("results-table_update__9OUE1") && !e.target.classList.contains("results-table_delete__hTDlQ")) {

      dispatch(saveCurrentID(cargoId))
      scrollToElement()
    }
  };

  const userId = useSelector(state => {
    if (state.userData.userLogin.user !== undefined) {
      return state.userData.userLogin.user.id
    } if (state.userData.userData.user !== undefined) {
      return state.userData.userData.user.id
    } else {
      return state.userData.userData.id
    }
  })

  let [resultsDataRegistration, setResultsDataRegistration] = useState((props.typeOfAction === "registrationCargo") ? myCargoList : "");
  async function getMyCargos() {
    try {
      const response = await axios.get(`${BaseURL}/api/cargo/list/${userId}`)

      dispatch(saveCargosUserData(response.data));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }



  const dispatch = useDispatch();
  const userData = useSelector(state => state.userData.userData);
  const foundCargo = useSelector(state => state.cargoFound.cargoFound);
  let PageSize = 30;
  const refDetails = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  let resultsDataSearch = (props.typeOfAction === "searchCargo") ? foundCargo : ""
  let resultsData = (props.typeOfAction === "searchCargo") ? resultsDataSearch : myCargoList
  function dateParse(date) {
    let d = new Date(date);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    if (month < 10) {
      month = `0${month}`;
      return `${day}.${month}.${year}`;
    }
    else {
      return `${day}.${month}.${year}`;
    }
  }

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return resultsData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const deleteCurrenCargo = async (cargoId, e) => {
    const response = await cargoDelete({ cargoId })
    if (response.statusText === "OK") {
      setResultsDataRegistration(myCargoList.filter(cargo => cargo.id !== cargoId))
      dispatch(saveCurrentID(""))

      getMyCargos()


    } else {
    }
  }
  const updateCurrenCargo = async (cargoId, e) => {
    dispatch(saveCurrentID(cargoId))
    dispatch(openEditCargoForm())
  }


  let transportTypeLang = function (transport) {
    if (currentLanguage === "ru") {
      return transport.name
    }
    if (currentLanguage === "en") {
      return transport.english
    }
    if (currentLanguage === "per") {
      return transport.persian
    }
  }

  let docTypeLang = function (doc) {
    if (currentLanguage === "ru") {
      return doc.name
    }
    if (currentLanguage === "en") {
      return doc.english
    }
    if (currentLanguage === "per") {
      return doc.persian
    }
  }
  let loadingTypeLang = function (loading) {
    if (currentLanguage === "ru") {
      return loading.name
    }
    if (currentLanguage === "en") {
      return loading.english
    }
    if (currentLanguage === "per") {
      return loading.persian
    }
  }
  let paymentTypeLang = function (payment) {
    if (currentLanguage === "ru") {
      return payment.name
    }
    if (currentLanguage === "en") {
      return payment.english
    }
    if (currentLanguage === "per") {
      return payment.persian
    }
  }


  return (
    <div className={styles.payment}>
      <section className={`${styles.block} ${styles.block__data} `}>
        <div className={` ${styles.block__content_payment} ${styles.block__header}`}>
          <div className={styles.payment_title}>{t('tables.result.countries')}</div>
          <div className={styles.payment_title}>{t('tables.result.date')}</div>
          <div className={styles.payment_title}>{t('tables.result.transport')}</div>
          <div className={styles.payment_title}>{t('tables.result.where')}</div>
          <div className={styles.payment_title}>{t('tables.result.from')}</div>
          <div className={styles.payment_title}>{t('tables.result.cargo')}</div>
          <div className={styles.payment_title}>{t('tables.result.payment')}</div>
          <div className={styles.payment_title}>{t('contact')}</div>
        </div>
        {resultsData.length <= 30 &&
          resultsData.map((cargo, index) => {
            return (
              <div className={`${styles.block__content} ${styles.block__content_payment} ${cargo.additionalSearch ? styles.additional_search_item : ''} `} key={index + cargo.id} onClick={(e) => getDetails(cargo.id, e)}>
                <div className={styles.payment_text}>{cargo.countryfrom} - {cargo.countryto}</div>
                <div className={styles.payment_text}>
                  {(cargo.calendar !== null) ? dateParse(cargo.calendar) : "-"}
                </div>
                <div className={styles.payment_text}>
                  {(cargo.transporttype && cargo.transporttype.length > 0) ?
                    cargo.transporttype.map((transport, index) => {
                      if (index === cargo.transporttype.length - 1) {
                        return (
                          <span key={index + transport.id}>{transportTypeLang(transport) || transport.value} </span>
                        )
                      }
                      return (
                        <span key={index + transport.id}>{transportTypeLang(transport) || transport.value}, </span>
                      )
                    })
                    : <div>-</div>
                  }
                </div>
                <div className={styles.payment_text}>{cargo.cityfrom}</div>
                <div className={styles.payment_text}>{cargo.cityto}</div>
                <div className={styles.payment_text}>
                  {(cargo.danger) && <span>{cargo.danger} </span>}
                  {(cargo.typecargo) && <span>{cargo.typecargo} </span>}
                  {(cargo.weighMax > 0) && <span>{cargo.weighMax} т {(cargo.volumeMax > 0) && ", "} </span>}
                  {(cargo.volumeMax > 0) && <span>{`${cargo.volumeMax}`}  м <sup><small>3</small></sup>, </span>}
                  {((cargo.long > 0) || (cargo.weighMax > 0) || (cargo.heightMax > 0)) &&
                    <p> {t('gabarity')}:
                      {(cargo.long > 0) && <span>  {`${cargo.long}`}  </span>}
                      {(cargo.weighMax > 0) && <span>  {`${cargo.weighMax}`}  </span>}
                      {(cargo.heightMax > 0) && <span>  {`${cargo.heightMax}`} </span>}
                    </p>
                  }
                  {((cargo.distancefrom > 0) || (cargo.distanceto > 0)) &&
                    <p> {t('rasstoyanie')}:
                      {(cargo.distancefrom > 0) && <span> от {`${cargo.distancefrom}`}  </span>}
                      {(cargo.distanceto > 0) && <span> до {`${cargo.distanceto}`}   </span>}
                    </p>
                  }
                  {(cargo.loadingtype && cargo.loadingtype.length > 0) &&
                    <p>{t('zagruzka')}:
                      {cargo.loadingtype.map((loading, index) => {
                        if (index === cargo.loadingtype.length - 1) {
                          return (
                            <span key={index + loading.id}> {loadingTypeLang(loading) || loading.value} </span>
                          )
                        }
                        return (
                          <span key={index + loading.id}> {loadingTypeLang(loading) || loading.value}, </span>
                        )
                      })}
                    </p>
                  }
                  {(cargo.doctype && cargo.doctype.length > 0) &&
                    <p>{t('dokumenty')}:
                      {cargo.doctype.map((doc, index) => {
                        if (index === cargo.doctype.length - 1) {
                          return (
                            <span key={index + doc.id}> {docTypeLang(doc) || doc.value} </span>
                          )
                        }
                        return (
                          <span key={index + doc.id}> {docTypeLang(doc) || doc.value},  </span>
                        )
                      })}
                    </p>
                  }
                </div>
                <div className={styles.payment_text}>
                  {(cargo.paymenttype && cargo.paymenttype.length > 0) ?
                    cargo.paymenttype.map((payment, index) => {
                      if (index === cargo.paymenttype.length - 1) {
                        return (
                          <span key={index + payment.id}>{paymentTypeLang(payment) || payment.value}</span>
                        )
                      }
                      return (
                        <span key={index + payment.id}>{paymentTypeLang(payment) || payment.value}, </span>
                      )
                    })
                    : <div>-</div>
                  }
                </div>
                {props.typeOfAction === "searchCargo" &&
                  <>
                    {((props.typeOfAction === "searchCargo" && userData.promo15) || (props.typeOfAction === "searchCargo" && userData.isPaid) || (props.typeOfAction === "searchCargo" && userData.role === "Admin")) ?
                      <div className={styles.payment_text}>
                        <p>{(cargo.user.role === "Юридическое лицо") ? `${cargo.user.orgname} ` : `${cargo.user.name} ${cargo.user.surname}`}
                          {(cargo.user.role === "Юридическое лицо") ? `${cargo.user.name}` : ""}
                        </p> <p>{cargo.user.number} </p>

                        <div className={styles.social_links}>
                          {cargo.user.telegram &&
                            <NavLink className={styles.user_telegram} to={"https://t.me/" + cargo.user.telegram} target="_blank">
                              <img src={telegram} alt="telegram" />
                            </NavLink>
                          }
                          {cargo.user.whatsapp &&
                            <NavLink className={styles.user_whatsapp} to={"https://wa.me/" + cargo.user.whatsapp} target="_blank">
                              <img src={whatsapp} alt="whatsapp" />
                            </NavLink>}
                          {cargo.user.skype &&
                            <NavLink className={styles.user_skype} to={"skype:" + cargo.user.skype} target="_blank">
                              <img src={skype} alt="skype" />
                            </NavLink>
                          }
                        </div>

                      </div> : < div className={`${styles.payment_text} ${styles.payment_text_attention} `} >
                        <img src={attentionIcon} alt="attention" className={styles.img_attention} />
                        {t('notifications.contactsNotAval')}
                      </div>}
                  </>
                }
                {(props.typeOfAction === "registrationCargo") &&
                  <div className={styles.payment_text}>
                    <p>{(userData.role === "Юридическое лицо") ? `${userData.orgname}, ` : `${userData.name} ${userData.surname},`}
                      {(userData.role === "Юридическое лицо") ? `${userData.name}` : ""}</p>
                    <p>{userData.number} </p>
                    <div className={styles.update} onClick={() => updateCurrenCargo(cargo.id)}>{t('redaktirovat-zapis')}</div>
                    <div className={styles.delete} onClick={() => deleteCurrenCargo(cargo.id)}>{t('buttons.deleteEntry')}</div>
                  </div>
                }
              </div>
            )
          })}
        {((resultsData.length > 30) && currentTableData) &&
          <>
            {currentTableData &&
              currentTableData.map((cargo, index) => {
                return (
                  <div className={`${styles.block__content} ${styles.block__content_payment} ${cargo.additionalSearch ? styles.additional_search_item : ''} `} key={index + cargo.id} onClick={(e) => getDetails(cargo.id, e)}>
                    <div className={styles.payment_text}>{cargo.countryfrom} - {cargo.countryto}</div>
                    <div className={styles.payment_text}>
                      {(cargo.calendar !== null) ? dateParse(cargo.calendar) : "-"}
                    </div>
                    <div className={styles.payment_text}>
                      {(cargo.transporttype && cargo.transporttype.length > 0) ?
                        cargo.transporttype.map((transport, index) => {
                          if (index === cargo.transporttype.length - 1) {
                            return (
                              <span key={index + transport.id}>{transportTypeLang(transport) || transport.value} </span>
                            )
                          }
                          return (
                            <span key={index + transport.id}>{transportTypeLang(transport) || transport.value}, </span>
                          )
                        })
                        : <div>-</div>
                      }
                    </div>
                    <div className={styles.payment_text}>{cargo.cityfrom}</div>
                    <div className={styles.payment_text}>{cargo.cityto}</div>
                    <div className={styles.payment_text}>
                      {(cargo.danger) && <span>{cargo.danger} </span>}
                      {(cargo.weighMax > 0) && <span>{cargo.weighMax} т {(cargo.volumeMax > 0) && ", "} </span>}
                      {(cargo.volumeMax > 0) && <span>{`${cargo.volumeMax}`}  м <sup><small>3</small></sup>, </span>}
                      {((cargo.long > 0) || (cargo.weighMax > 0) || (cargo.heightMax > 0)) &&
                        <p> {t('gabarity')}:
                          {(cargo.long > 0) && <span> д. {`${cargo.long}`} м. </span>}
                          {(cargo.weighMax > 0) && <span> ш. {`${cargo.weighMax}`} м.  </span>}
                          {(cargo.heightMax > 0) && <span> в. {`${cargo.heightMax}`}  м.</span>}
                        </p>
                      }
                      {((cargo.distancefrom > 0) || (cargo.distanceto > 0)) &&
                        <p> {t('rasstoyanie')}:
                          {(cargo.distancefrom > 0) && <span> от {`${cargo.distancefrom}`} км. </span>}
                          {(cargo.distanceto > 0) && <span> до {`${cargo.distanceto}`} км.  </span>}

                        </p>
                      }
                      {(cargo.loadingtype && cargo.loadingtype.length > 0) &&
                        <p>{t('zagruzka')}:
                          {cargo.loadingtype.map((loading, index) => {
                            if (index === cargo.loadingtype.length - 1) {
                              return (
                                <span key={index + loading.id}> {loadingTypeLang(loading) || loading.value} </span>
                              )
                            }
                            return (
                              <span key={index + loading.id}> {loadingTypeLang(loading) || loading.value}, </span>
                            )
                          })}
                        </p>
                      }
                      {(cargo.doctype && cargo.doctype.length > 0) &&
                        <p>{t('dokumenty')}:
                          {cargo.doctype.map((doc, index) => {
                            if (index === cargo.doctype.length - 1) {
                              return (
                                <span key={index + doc.id}> {docTypeLang(doc) || doc.value} </span>
                              )
                            }
                            return (
                              <span key={index + doc.id}> {docTypeLang(doc) || doc.value},  </span>
                            )
                          })}
                        </p>
                      }
                    </div>
                    <div className={styles.payment_text}>
                      {(cargo.paymenttype && cargo.paymenttype.length > 0) ?
                        cargo.paymenttype.map((payment, index) => {
                          if (index === cargo.paymenttype.length - 1) {
                            return (
                              <span key={index + payment.id}>{paymentTypeLang(payment) || payment.value}</span>
                            )
                          }
                          return (
                            <span key={index + payment.id}>{paymentTypeLang(payment) || payment.value}, </span>
                          )
                        })
                        : <div>-</div>
                      }
                    </div>
                    {props.typeOfAction === "searchCargo" &&
                      <>
                        {((props.typeOfAction === "searchCargo" && userData.promo15) || (props.typeOfAction === "searchCargo" && userData.isPaid) || (props.typeOfAction === "searchCargo" && userData.role === "Admin")) ?
                          <div className={styles.payment_text}>
                            <p>{(cargo.user.role === "Юридическое лицо") ? `${cargo.user.orgname} ` : `${cargo.user.name} ${cargo.user.surname}`}
                              {(cargo.user.role === "Юридическое лицо") ? `${cargo.user.name}` : ""}
                            </p> <p>{cargo.user.number} </p>
                            <div className={styles.social_links}>
                              {cargo.user.telegram &&
                                <NavLink className={styles.user_telegram} to={"https://t.me/" + cargo.user.telegram} target="_blank">
                                  <img src={telegram} alt="telegram" />
                                </NavLink>
                              }
                              {cargo.user.whatsapp &&
                                <NavLink className={styles.user_whatsapp} to={"https://wa.me/" + cargo.user.whatsapp} target="_blank">
                                  <img src={whatsapp} alt="whatsapp" />
                                </NavLink>}
                              {cargo.user.skype &&
                                <NavLink className={styles.user_skype} to={"skype:" + cargo.user.skype} target="_blank">
                                  <img src={skype} alt="skype" />
                                </NavLink>
                              }
                            </div>

                          </div> : < div className={`${styles.payment_text} ${styles.payment_text_attention} `} >
                            <img src={attentionIcon} alt="attention" className={styles.img_attention} />
                            {t('notifications.contactsNotAval')}
                          </div>}
                      </>
                    }
                    {(props.typeOfAction === "registrationCargo") &&
                      <div className={styles.payment_text}>
                        <p>{(userData.role === "Юридическое лицо") ? `${userData.orgname}, ` : `${userData.name} ${userData.surname},`}
                          {(userData.role === "Юридическое лицо") ? `${userData.name}` : ""}</p>
                        <p>{userData.number} </p>
                        <div className={styles.update} onClick={() => updateCurrenCargo(cargo.id)}>{t('redaktirovat-zapis')}</div>
                        <div className={styles.delete} onClick={() => deleteCurrenCargo(cargo.id)}>{t('buttons.deleteEntry')}</div>

                      </div>
                    }
                  </div>
                )
              })}
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={resultsData.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          </>
        }
      </section>
      <div ref={refDetails}></div>
    </div>
  )
}
export default ResultsTable;

import styles from './pages-item.module.css';
// import styles from './pages-item-pers.module.css';
// const getCurrentLng = localStorage.getItem('i18next')

// const { t, i18n } = useTranslation();
export function PagesItem(props) {


  return (
    <div className={styles.desc__wrapper}>
      <h2 className={`${styles.title}`} >{props.title}</h2>
      <div className={styles.desc__info}>
        <p className={styles.text}>{props.text}</p>
        <p className={styles.text}>{props.subtext}</p>
      </div>
      <img className={styles.img} src={props.img} alt="" />
    </div>
  )
}
export default PagesItem;
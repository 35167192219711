import stylesGeneral from './pravila.module.css'
import styles from './rules.module.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

export function RulesOferta() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className={stylesGeneral.wrapper}>
      <div className="inner">
        <div className={styles.rules__body}>
          <div class="container">
            <div className={`${styles.rules__wrapper}`}>
              <h3 className={`${styles.rules__title}`}>Публичный договор
                (оферта)
                возмездного оказания услуг по безналичному расчету
                Транспортно-информационного сервера IrisTrans.com
              </h3>
              <p className={`${styles.item}`}>Настоящий Публичный договор (далее именуемый по тексту «Договор») определяет порядок предоставления услуг
                Сайта — “Транспортно-информационный сервер IrisTrans.com”, а также взаимные права, обязанности и порядок
                взаимоотношений между:<br></br>
                - представителем Сайта – ООО «ИрИс интер ГРУПП» в лице директора Исапур Ираджа, действующим на основании
                Устава (именуемый в дальнейшем – <b>Исполнитель</b>)<br></br>
                и<br></br>
                - потребителем услуг Сайта (физическим лицом, индивидуальным предпринимателем или юридическим лицом),
                принявшим (акцептовавшим) публичное предложение (оферту) о заключении настоящего Договора (именуемый в
                дальнейшем – <b>Заказчик</b>).<br></br>
                Настоящий договор заключается и исполняется в соответствии с положениями статей 396, 404, 407, 408
                Гражданского кодекса Республики Беларусь, а также иных положений законодательства Республики Беларусь, которые
                регулируют указанные правоотношения.
              </p>

            </div>
          </div>
        </div>


        <h2 className={`${styles.items__title}`}>1. ОПРЕДЕЛЕНИЯ, ИСПОЛЬЗУЕМЫЕ В НАСТОЯЩЕМ ДОГОВОРЕ</h2><br></br>
        <p className={`${styles.item}`}>
          1.1. <b>Пользователь</b> — Заказчик по настоящему Договору - физическое лицо, индивидуальный предприниматель, юридическое лицо, а также их уполномоченные представители, принявшие (акцептовавшиe) публичное предложение (оферту) о заключении настоящего Договора и осуществляющее доступ к Сайту и/или пользование Сервисами Сайта путем создания Учетной записи.<br></br>
          1.2. <b>Сайт</b> — транспортно-информационный сервер IrisTrans.com, а также информационный ресурс, доступный в сети Интернет с использованием доменного имени iristrans.com (в том числе и посредством любых иных доменных имен, перенаправляющих на iristrans.com).<br></br>
          1.3. <b>IrisTrans</b> — Транспортно-информационный сервер IrisTrans.com, а также ООО “ИрИс интер ГРУПП” или иной уполномоченный представитель Транспортноинформационного сервера IrisTrans.com.<br></br>
          1.4. <b>Сервис/Сервисы</b> — отдельные информационные услуги, предоставляемые Пользователю посредством Сайта и/или иным способом (в частности, но не исключительно, посредством e-mail рассылки, отдельно устанавливаемых приложений и/или иных ресурсов сети Интернет) с легитимным использованием торговой марки “IrisTrans” и/или подобных (производных) обозначений, а также соответствующие функциональные разделы Сайта и способы предоставления услуг.<br></br>
          1.5. <b>Услуги</b> — доступ к информации и функциональным возможностям Сайта, а также получение иных информационных услуг Сайта, путем создания и использования учетной записи Пользователя.<br></br>
          1.6. <b>Соглашение</b> — размещенные на Сайте Пользовательское соглашение, Политика конфиденциальности, а также иные условия и правила использования Сайта и/или отдельных Сервисов Сайта.<br></br>
          1.7. <b>Учетная запись</b> — совокупность хранящихся на Сайте данных о Пользователе, которая необходима для его опознавания и предоставления доступа к Услугам и Сервисам Сайта после авторизации при помощи логина и пароля Пользователя.<br></br>
          1.8. Все иные термины и определения в настоящем договоре используются в значениях, определенных положениями действующего законодательства Республики Беларусь.


        </p>
        <h2 className={`${styles.items__title}`}>2. ПРЕДМЕТ ДОГОВОРА</h2><br></br>
        <p className={`${styles.item}`}>
          2.1. Исполнитель обязуется по поручению Заказчика оказать Услуги Сайта и/или предоставить Сервисы в соответствии с избранным Заказчиком пакетом услуг.<br></br>
          2.2. Заказчик обязуется принять Услуги и/или Сервисы, оказанные Исполнителем, и оплатить их в порядке и на условиях, определенных настоящим Договором и перечнем услуг и цен, указанным на Сайте.<br></br>
          2.3. Тарифы, перечень конкретных услуг, сервисов и функций Сайта, которые предоставляются за оплату Заказчику, доступны для ознакомления и выбора в Учетной записи Заказчика и/или по адресу: <NavLink to={'/tariff'} key={'tariffinfo'}>
            <span className={styles.link}>Тарифы</span>
          </NavLink>.


        </p>
        <h2 className={`${styles.items__title}`}>3. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА</h2><br></br>
        <p className={`${styles.item}`}>
          3.1. Настоящий Договор является публичным договором (ст.396 Гражданского кодекса Республики Беларусь), в
          соответствии с которым Исполнитель принимает на себя обязательство по оказанию Услуг в отношении неопределенного
          круга лиц (Заказчиков), обратившихся с заявкой на предоставление Услуг.<br></br>
          3.2. Публикация (размещение) текста настоящего Договора на официальном сайте Исполнителя по адресу: iristrans.com
          является публичным предложением (офертой) Исполнителя, адресованным неопределенному кругу лиц заключить настоящий
          Договор (п.2. ст.407 Гражданского кодекса Республики Беларусь).<br></br>
          3.3. Заключение настоящего Договора производится путем присоединения Заказчика к настоящему Договору, т.е.
          посредством принятия (акцепта) Заказчиком условий настоящего Договора в целом, без каких-либо условий, изъятий и
          оговорок (ст.398 Гражданского кодекса Республики Беларусь).<br></br>
          3.4. Фактом принятия (акцепта) условий настоящего Договора является: 3.4.1. оформление Заказчиком online-заявки на
          предоставление Услуг и оплата заказанных Услуг в порядке и на условиях, определенных настоящим Договором (п.3 ст.408
          Гражданского кодекса Республики Беларусь);<br></br>
          3.5. Настоящий Договор, при условии соблюдения порядка его акцепта, считается заключенным в простой письменной форме
          (п.2, п.3 ст.404 и п.3 ст.408 Гражданского кодекса Республики Беларусь) и не требует оформления в бумажном виде и
          обладает полной юридической силой.<br></br>
          3.6. Для получения доступа к Услугам Заказчик проходит процедуру регистрации нового клиента на странице Исполнителя
          по адресу iristrans.com либо проходит авторизацию посредством уже имеющихся у Заказчика логина и пароля доступа к
          Сайту.<br></br>
          3.7. При регистрации Заказчик обязан указать в Учетной записи следующие достоверные сведения:<br></br>
          3.7.1. физическое лицо - фамилию, собственное имя, отчество (если таковое имеется), почтовый адрес или адрес
          регистрации, идентификационный номер (при наличии), адрес электронной почты, контактный телефон;<br></br>
          3.7.2. индивидуальный предприниматель - сведения, указанные в подп.3.7.1. п.3.7. настоящего Договора, а также
          сведения о государственной регистрации (регистрационный или иной идентификационный номер, наименование органа
          (организации), осуществившего государственную регистрацию, дата регистрации, номер решения (при наличии));<br></br>
          3.7.3. юридическое лицо - полное наименование, место нахождения (почтовый адрес), сведения о государственной
          регистрации (регистрационный или иной идентификационный номер, учетный номер плательщика (при наличии), адрес
          электронной почты, контактный телефон, фамилия, собственное имя, отчество (если таковое имеется) руководителя).
          3.8. Данные, указанные Заказчиком в порядке, определенном п.3.7. настоящего Договора, автоматически будут
          использоваться для идентификации Заказчика и для доступа к Сайту и/или Сервисам.<br></br>
          3.9. Учетная запись, созданная на Сайте, активируется после проверки со стороны IrisTrans сведений, указанных в
          Учетной записи, с использованием указанного номера телефона, электронной почты или иных идентификационных данных и
          технических средств, позволяющих идентифицировать личность и/или наименование пользователя. При этом один номер
          телефона и/или один комплект иных идентификационных данных могут быть использованы для регистрации не более одной
          учетной записи пользователя на Сайте.<br></br>
          3.10. Оформление online-заявки на предоставление Услуг осуществляется Заказчиком на официальном сайте Исполнителя с
          помощью web-интерфейса, расположенного по адресу: iristrans.com посредством выполнения определенной
          последовательности действий по заполнению специальных форм-шаблонов с целью предоставления информации, необходимой
          для заключения настоящего Договора и последующего оказания Услуг.<br></br>
          3.11. Заказчик обязан проверить информацию, содержащуюся в итоговом тексте online-заявки на предоставление Услуг, и,
          в случае необходимости, повторить процедуру оформления online-заявки с целью исправления неточной информации.
          Указание неточной или недостоверной информации является для IrisTrans основанием для отказа в предоставлении Услуг
          и/или Сервисов.<br></br>
          3.12. Перечень Услуг, которые должны быть оказаны в рамках настоящего Договора, указываются в online-заявке на
          предоставление Услуг, оформленной Заказчиком в порядке и на условиях, определенных настоящим Договором, а также в
          требовании об оплате заказанных Услуг (счет для юридических лиц и индивидуальных предпринимателей или квитанция об
          оплате для физических лиц), выставляемом Исполнителем для оплаты Заказчиком.<br></br>
        </p>
        <h2 className={`${styles.items__title}`}>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2><br></br>
        <p className={`${styles.item}`}>
          4.1. Исполнитель обязуется:<br></br>
          4.1.1. оказать Заказчику Услуги в объеме и в сроки, предусмотренные тарифами, перечнем конкретных услуг, сервисов и
          функций Сайта и указанные в требовании об оплате заказанных Заказчиком Услуг (счете или квитанции об оплате),
          которая формируется Заказчиком самостоятельно с помощью web-интерфейса, расположенного в Учетной записи;<br></br>
          4.1.2. оказывать Заказчику в рабочее время (с 9-00 до 18-00, за исключением выходных дней и официальных
          государственных праздников), консультации по телефону и/или электронной почте по вопросам, возникающим у Заказчика в
          связи с оказанием ему Услуг;<br></br>
          4.1.3. обеспечивать конфиденциальность параметров доступа к серверу (логин, пароль), за исключением случаев, когда
          предоставление доступа к такой информации для третьих лиц является необходимым условием оказания Услуг, либо
          является обязательным в силу требований законодательства Республики Беларусь.
          4.2. Исполнитель имеет право:<br></br>
          4.2.1. получать оплату за предоставленные Услуги в объеме и порядке, предусмотренном указанным Договором, а также
          тарифами, перечнем конкретных услуг, сервисов и функций Сайта;<br></br>
          4.2.2. прекращать предоставление Услуг в случае нарушения Заказчиком условий настоящего Договора или совершения
          Заказчиком на Сайте действий, которые нарушают права и законные интересы Исполнителя, иных пользователей Сайта и/или
          любых третьих лиц;<br></br>
          4.2.3. изменять в одностороннем порядке условия настоящего Договора, тарифы и перечень Услуг и/или Сервисов, которые
          предоставляются Заказчику, с обязательным уведомлением о предстоящих изменения не менее чем за 1 (один) месяц до
          таких изменений.<br></br>
          4.3. Заказчик обязуется:<br></br>
          4.3.1. соблюдать условия настоящего Договора, положения действующего законодательства Республики Беларусь и иные
          нормы, обязательные для выполнения на территории Республики Беларусь;<br></br>
          4.3.2. оплатить Исполнителю заказываемые Услуги в порядке, размерах и в сроки, определенные настоящим Договором на
          основании счета или квитанции об оплате, которая формируется Заказчиком самостоятельно с помощью web-интерфейса,
          расположенного в Учетной записи;<br></br>
          4.3.3. обеспечивать сохранность и конфиденциальность полученной от Исполнителя служебной информации (ссылки, имена и
          пароли доступа, номера мобильных телефонов специалистов Исполнителя и др.);<br></br>
          4.3.4. при наличии претензий по оказанным Исполнителем Услугам уведомить об этом Исполнителя в течение 2- (двух)
          рабочих дней с момента, когда Заказчику стало известно или должно было стать известным о неисполнении или
          ненадлежащем исполнении Исполнителем принятых на себя обязательств по настоящему Договору.
          4.4. Заказчик имеет право:<br></br>
          4.4.1. требовать от Исполнителя надлежащего оказания Услуг;<br></br>
          4.4.2. получать у Исполнителя в рабочее время (с 9-00 до 18-00, за исключением выходных дней и официальных
          государственных праздников) консультации по телефону и/или электронной почте по вопросам, возникающим в связи с
          оказанием Услуг;<br></br>
          4.4.3. расторгнуть настоящий Договор в случае: <br></br>
          невыполнения либо ненадлежащего выполнения Исполнителем своих обязательств по оказанию Услуг в рамках настоящего
          Договора;<br></br>
          несогласия с вносимыми Исполнителем изменениями и/или дополнениями в настоящий Договор.<br></br>
          4.5. Заключая настоящий Договор, Заказчик дает свое полное и безусловное согласие на получение от Исполнителя
          рекламной информации, а также иной информации, распространяемой Исполнителем (в том числе, посредством SMS
          сообщений, сообщений на адреса электронной почты и/или личных сообщений в Учетной записи). Такое согласие
          используется IrisTrans в целях и в случаях, когда необходимость получения такого согласия предусмотрена
          законодательством Республики Беларусь. Заказчик проинформирован о своем праве требовать от Исполнителя
          незамедлительного прекращения размещения (распространения) рекламной информации в адрес Заказчика.<br></br>
          4.6. Исполнитель обязан по первому требованию Заказчика незамедлительно прекратить размещение (распространение)
          рекламной информации в адрес Заказчика, если технические и программные возможность позволяют Исполнителю это сделать
          без ограничения доступа Заказчика к Сайту. В противном случае, Исполнитель имеет право ограничить или заблокировать
          доступ Заказчика к Услугам Сайта и/или Сервисам. Внесенная Заказчиком оплата в этом случае не возвращается.<br></br>
          4.7. Исполнитель не гарантирует 100% безошибочную и бесперебойную работу Сайта и/или Сервисов и не несет
          ответственности за любой возможный ущерб, причиненный Пользователю и/или третьим лицам техническими сбоями
          аппаратного и/или программного обеспечения на любой стороне.<br></br>
          4.8. Исполнитель не гарантирует достоверность информации (в частности, но не исключительно: заявок о грузах,
          предлагаемых к перевозке, и/или о наличии свободного грузового транспорта), размещенной на Сайте третьими лицами, и
          не несет ответственности за любой возможный ущерб, причиненный Пользователю и/или третьим лицам в результате
          использования Сайта/Сервисов.
        </p>
        <h2 className={`${styles.items__title}`}>5. СТОИМОСТЬ, ПОРЯДОК И СРОКИ ОПЛАТЫ УСЛУГ</h2><br></br>
        <p className={`${styles.item}`}>
          5.1. Стоимость Услуг, оказываемых в рамках настоящего Договора, определяется исходя из объема, характера и
          продолжительности заказанных Заказчиком Услуг согласно утвержденным Исполнителем тарифам, перечнем конкретных услуг,
          сервисов и функций Сайта, действующим непосредственно в момент выставления требования об оплате заказанных
          Услуг.<br></br>
          5.2. Тарифы, действовавшие непосредственно в момент выставления требования об оплате заказанных Услуг, являются
          неотъемлемой частью настоящего Договора.<br></br>
          5.3. Заказчик оплачивает услуги Исполнителя на основании счета или квитанции об оплате, которая формируется
          Заказчиком самостоятельно с помощью web-интерфейса, расположенного в Учетной записи.<br></br>
          5.4. Заказчики осуществляют оплату заказанных Услуг посредством безналичного перевода денежных средств на расчетный
          счет Исполнителя согласно данным и реквизитам, указанным в выставленном счете.<br></br>
          5.5. Порядок проведения платежей и необходимые банковские реквизиты Исполнителя, на которые принимается оплата
          заказанных Услуг указываются на официальном сайте Исполнителя, а также в
          соответствующем счете или квитанции об оплате, которая сформирована Заказчиком самостоятельно с помощью
          web-интерфейса, расположенного в Учетной записи.
        </p>
        <h2 className={`${styles.items__title}`}>6. ПОРЯДОК СДАЧИ-ПРИЕМКИ УСЛУГ</h2><br></br>
        <p className={`${styles.item}`}>
          6.1. Предоставление услуг в рамках настоящего Договора для Заказчиков, являющихся юридическими лицами и
          индивидуальными предпринимателями, подтверждается Актом об оказании услуг, который составляется единолично
          Исполнителем (в соответствии с постановлением Министерства финансов Республики Беларусь от 12.02.2018г. № 13 «О
          единоличном составлении первичных учетных документов и признании утратившим силу постановления Министерства финансов
          Республики Беларусь от 21 декабря 2015 г. № 58»).<br></br>
          6.2. Датой оказания Услуг и/или Сервисов, является момент зачисления денежных средств на расчетные счета Сайта при
          условии наличия зарегистрированной Учетной записи Заказчика на Сайте.<br></br>
          6.3. Заказчик подтверждает и соглашается с тем, что Акт об оказании услуг в соответствии с настоящим Договором
          составляется Исполнителем единолично, а Заказчик самостоятельно получает Акт об оказании услуг с помощью
          web-интерфейса, расположенного в Учетной записи.<br></br>
          6.4. Предоставление Услуг в рамках настоящего Договора для Заказчиков, являющихся физическими лицами, подтверждается
          фактом успешной активации Учетной записи Заказчика на сайте и отсутствием письменных претензий Заказчика по
          оказанным Исполнителем Услугам и/или Сервисам, направленных в течение 5 (пяти) календарных дней с даты окончания
          периода, в котором оказывались Услуги и/или Сервисы.
        </p>
        <h2 className={`${styles.items__title}`}>7. ОТВЕТСТВЕННОСТЬ СТОРОН</h2><br></br>
        <p className={`${styles.item}`}>
          7.1. За неисполнение, либо ненадлежащее исполнение своих обязательств по настоящему Договору виновная Сторона несет
          ответственность в соответствии с действующим законодательством Республики Беларусь, с учетом особенностей,
          установленных настоящим Договором.<br></br>
          7.2. Исполнитель освобождается от ответственности за убытки, явно или косвенно понесенные Заказчиком в результате
          полного или частичного потребления или отсутствия возможности потребления Услуг в следующих случаях:<br></br>
          7.2.1. если убытки причинены вследствие несоблюдения Заказчиком конфиденциальности своих учетных данных или иной
          информации закрытого характера, а равно вследствие несанкционированного доступа третьих лиц к техническим или
          информационным ресурсам Заказчика.<br></br>
          7.2.2. если убытки причинены вследствие технических сбоев аппаратного и/или программного обеспечения, а также
          провайдеров сети Интернет на стороне Исполнителя или Заказчика.<br></br>
          7.3. Стороны настоящего Договора безоговорочно соглашаются с тем, что максимальный размер убытков, которые могут
          быть взысканы с Исполнителя, ограничен сумой оплаченных Заказчиком Услуг, неисполнение, либо ненадлежащие исполнение
          которых повлекло причинение убытков.<br></br>
          7.4. Ограничение ответственности Исполнителя, предусмотренное п.7.5 настоящего Договора, не может применяться в
          случаях, когда:<br></br>
          7.4.1. размер ответственности для данного вида обязательств или за данное нарушение определен законодательством
          Республики Беларусь;<br></br>
          7.4.2. неисполнение, либо ненадлежащее исполнение Исполнителем своих обязательств по настоящему Договору является
          следствием его прямого умысла и данный факт установлен вступившим в законную силу решением суда.<br></br>
          7.5. Заказчик самостоятельно несет полную ответственность за любые, в том числе и несанкционированные, действия
          третьих лиц, имевших место вследствие несоблюдения Заказчиком конфиденциальности своих учетных данных или иной
          информации закрытого характера, а также за последствия таких действий.<br></br>
          7.6. Заказчик самостоятельно несет полную ответственность за возможные нарушения авторского права, торговых марок и
          иных норм законодательства Республики Беларусь, связанных с фактом размещения любых сведений Заказчика в сети
          Интернет на информационно-технических ресурсах Исполнителя.<br></br>
          7.7. Заказчик возмещает Исполнителю и/или должностным лицам Исполнителя любые убытки, понесенные Исполнителем или
          его должностными лицами в связи с размещением на информационно-технических ресурсах Заказчика информации, содержание
          которой противоречит законодательству Республики Беларусь, а также в иных случаях, когда неисполнение Заказчиком
          требований законодательства РБ и/или обязательств по настоящему Публичному договору повлекло причинение убытков.
        </p>
        <h2 className={`${styles.items__title}`}>8. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ</h2><br></br>
        <p className={`${styles.item}`}>
          8.1. Стороны освобождаются от ответственности за частичное или полное неисполнение своих обязательств по настоящему
          Договору, если это явилось следствием действия обстоятельств непреодолимой силы (форс-мажора), возникших после
          заключения настоящего Договора в результате событий чрезвычайного характера, которые Стороны не могли ни предвидеть,
          ни предотвратить разумными мерами.<br></br>
          8.2. К обстоятельствам непреодолимой силы относятся события, на которые Сторона не может оказывать влияния и за
          возникновение которых она не несет ответственности, как то: война, восстание, забастовка, землетрясение, наводнение,
          пожар, суровые погодные условия или другие стихийные бедствия, правительственные постановления, распоряжения (указы)
          государственных органов и должностных лиц, законы и иные нормативные акты компетентных органов, принятые после
          акцепта настоящего Договора и делающие невозможным исполнение обязательств, установленных настоящим Договором, а
          также действия государственных или местных органов государственной власти и управления или их представителей,
          препятствующие выполнению условий настоящего Договора, и другие непредвиденные обстоятельства, в том числе неполадки
          в городской электросети, технические проблемы на транзитных узлах сети Интернет и прочие нарушения функционирования
          сетей передачи данных, находящихся вне сферы влияния Сторон, но не ограничиваясь указанным.<br></br>
          8.3. При наступлении обстоятельств непреодолимой силы, препятствующих исполнению обязательств по настоящему
          Договору, срок выполнения Сторонами таких обязательств переносится соразмерно времени действия таких обстоятельств,
          а также времени, требуемого для устранения их последствий, но не более шестидесяти календарных дней.
          8.4. В случае если обстоятельства непреодолимой силы продолжают действовать более срока, указанного в п.8.3
          настоящего Договора, либо когда при их наступлении обеим Сторонам становится очевидным, что обстоятельства будут
          действовать более этого срока, Стороны обязуются обсудить возможности альтернативных способов исполнения настоящего
          Договора или его прекращения без возмещения убытков.
        </p>
        <h2 className={`${styles.items__title}`}>9. ИЗВЕЩЕНИЯ И УВЕДОМЛЕНИЯ</h2><br></br>
        <p className={`${styles.item}`}>
          9.1. Стороны договариваются, что безоговорочно признают юридическую силу текстов документов, полученных по каналам
          связи (электронной почты) наравне с документами, исполненными в простой письменной форме на бумажном носителе, за
          исключением случаев, когда оформление документов на бумажном носителе является обязательным в силу требований
          законодательства Республики Беларусь.<br></br>
          9.2. Стороны безоговорочно соглашаются с тем, что вся переписка, извещения и уведомления, полученные на адреса
          электронной почты, указанные на Сайте как реквизиты Исполнителя и в Учетной записи Заказчика как реквизиты
          Заказчика.<br></br>
          9.3. Стороны обязаны своевременно проверять корреспонденцию, поступающую на их адреса электронной почты, которые
          указаны на Сайте.<br></br>
          9.4. Все риски, связанные с наступлением неблагоприятных последствий вследствие несоблюдения требований п.9.3
          настоящего Договора, несет Сторона, допустившая такое нарушение.

        </p>
        <h2 className={`${styles.items__title}`}>10. СРОК ДЕЙСТВИЯ ДОГОВОРА И ПОРЯДОК ЕГО РАСТОРЖЕНИЯ</h2><br></br>
        <p className={`${styles.item}`}>
          10.1. Настоящий Договор считается заключенным с момента зачисления на расчетный счет Исполнителя денежных средств,
          уплаченных Заказчиком в счет оплаты заказанных им услуг в соответствии с выставленным ему требованием об оплате
          заказанных услуг.<br></br>
          10.2. Настоящий Договор действует в течение неопределенного срока вплоть до его расторжения в порядке,
          предусмотренном настоящим Договором.<br></br>
          10.3. Настоящий Договор может быть расторгнут:<br></br>
          10.3.1 по соглашению Сторон;<br></br>
          10.3.2 в одностороннем порядке по инициативе Исполнителя согласно п.4.2.2 настоящего Договора;<br></br>
          10.3.3 в одностороннем порядке по инициативе Заказчика согласно п.4.4.3 настоящего Договора.<br></br>
          10.4. Заказчик безоговорочно соглашается с тем, что в случае расторжения настоящего Договора в соответствии с
          п.10.3.1 или п.10.3.2 настоящего Договора, он теряет право требовать от Исполнителя возврата каких-либо сумм,
          уплаченных в счет оплаты Услуг, даже в случае, если срок действия данных Услуг еще не окончен.

        </p>
        <h2 className={`${styles.items__title}`}>11. ПОРЯДОК ВНЕСЕНИЯ ИЗМЕНЕНИЙ И ДОПОЛНЕНИЙ В ДОГОВОР</h2><br></br>
        <p className={`${styles.item}`}>
          11.1. Изменения и/или дополнения в настоящий Договор вносятся в одностороннем порядке по решению Исполнителя.<br></br>
          11.2. Изменения и/или дополнения, вносимые Исполнителем в настоящий Договор по собственной инициативе, вступают в
          силу не ранее, чем на следующий день после их опубликования на сайте Исполнителя.<br></br>
          11.3. Изменения и/или дополнения, вносимые Исполнителем в настоящий Договор в связи с изменением законодательства,
          вступают в силу одновременно с вступлением в силу изменений в данных актах законодательства.<br></br>
          11.4. Текст изменений и/или дополнений настоящего Договора, либо его новая редакция доводится Исполнителем до
          всеобщего сведения посредством размещения (опубликования) соответствующей информации на сайте по следующему адресу: 
          <NavLink to={'https://IrisTrans.com'}>
            <span className={styles.link}> https://IrisTrans.com.</span>
          </NavLink>
          <br></br>
          11.5. В случае несогласия с внесенными изменениями и/или дополнениями, Заказчик имеет право расторгнуть настоящий
          Договор в соответствии с п.4.4.3 настоящего Договора.<br></br>
          11.6. Уведомлением о расторжении настоящего Договора также признается любое письменное уведомление Заказчика,
          составленное на бумажном носителе, о несогласии с внесенными изменениями и/или дополнениями, либо о неприсоединении
          к новой редакции настоящего Договора или об отказе соблюдать его условия.<br></br>
          11.7. Стороны безоговорочно соглашаются с тем, что отсутствие письменных уведомлений о расторжении настоящего
          Договора, либо о несогласии с отдельными положениями настоящего Договора, в том числе с изменением прейскуранта цен
          на Услуги признается согласием и присоединением Заказчика к новой редакции настоящего Договора (п.3 ст.159
          Гражданского кодекса Республики Беларусь).
        </p>
        <h2 className={`${styles.items__title}`}>12. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h2><br></br>
        <p className={`${styles.item}`}>
          12.1. Все споры и разногласия, связанные с настоящим Договором, Стороны обязуются разрешать путем переговоров.<br></br>
          12.2. В случае если Сторонам не удастся разрешить все спорные вопросы в порядке, установленном п.12.1 настоящего
          Договора, все споры, возникающие из настоящего Договора, в том числе связанные с его заключением, изменением,
          расторжением, исполнением, недействительностью подлежат разрешению в судебном порядке в соответствии с
          законодательством Республики Беларусь.
        </p>
        <h2 className={`${styles.items__title}`}>13. ПРОЧИЕ УСЛОВИЯ</h2><br></br>
        <p className={`${styles.item}`}>
          13.1. Стороны безоговорочно соглашаются с тем, что настоящий Договор заключен по адресу нахождения офиса Исполнителя.<br></br>
          13.2. Заключая настоящий Договор, Заказчик тем самым заявляет, что:<br></br>
          13.2.1. по его сведениям содержание его Данных не противоречит законодательству Республики Беларусь и не нарушают прав и законных интересов третьих лиц, в том числе прав интеллектуальной собственности; 13.2.2. информация, предоставленная им при оформлении заказа на предоставление Услуг, является полной, правдивой и точной;<br></br>
          13.2.3. он осознает и соглашается с тем, что часть информации, которую он предоставил при оформлении заказа на предоставление Услуг, может быть доступна для третьих лиц, в силу требований законодательства Республики Беларусь.<br></br>
          13.3. В случае если какое-либо из условий настоящего Договора потеряет юридическую силу, будет признано незаконным, или будет исключено из настоящего Договора, то это не влечет недействительность остальных условий настоящего Договора, которые сохранят юридическую силу и являются, обязательными для исполнения всеми Сторонами.<br></br>
          13.4. Все вопросы, неурегулированные настоящим Договором, разрешаются в соответствии с действующим законодательством Республики Беларусь, а также локальными нормативными документами Исполнителя, при условии их соответствия действующему законодательству Республики Беларусь.

        </p>
        <h2 className={`${styles.items__title}`}>14. РЕКВИЗИТЫ СТОРОН</h2><br></br>
        <p className={`${styles.item}`}>
          14.1. Стороны безоговорочно соглашаются под реквизитами Заказчика считать информацию, указанную им при оформлении заказа на предоставление Услуг.<br></br>
          14.2. Реквизиты Исполнителя:<br></br>
          ООО «ИрИс интер ГРУПП» в лице директора Исапур Ираджа<br></br>
          Общество с ограниченной ответственностью «ИрИс интер ГРУПП»<br></br>
          Почтовый адрес: 220034, Республика Беларусь, г. Минск, а/я 15<br></br>
          Юридический адрес: г. Минск, ул.Бабушкина, д.29, оф. 14<br></br>
          УНП: 193438125<br></br>
          Тел. (+375 17) 303 96 99<br></br>
          Моб. (+375 44) 653 04 30<br></br>
          E-mail:info@irisintergroup.com <br></br>
          Банковские реквизиты:<br></br>
          IBAN: BY66 BPSB 3012 3269 1601 2933 0000 (USD),<br></br>
          IBAN: BY62 BPSB 3012 3269 1603 8840 0000 (BYN),<br></br>
          IBAN: BY45 BPSB 3012 3269 1604 1978 0000 (EUR),<br></br>
          IBAN: BY66 BPSB 3012 3269 1605 4643 0000 (RUB)<br></br>
          в ОАО "Сбер Банк" г.Минск, б-р Мулявина, 6, BIC SWIFT BPSBBY2X
        </p>
      </div>
    </main >
  )
}
export default RulesOferta;
import styles from './rules.module.css'
import stylesGeneral from './pravila.module.css'
import { useEffect } from 'react';

export function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={stylesGeneral.wrapper}>
      <div className="inner">
        <div className={styles.rules__body}>
          <div className={`${styles.rules__wrapper}`}>
            <h1 className={`${styles.rules__title}`}>Политика конфиденциальности
            </h1>
            <h2 className={`${styles.items__title}`}>1. Общие положения</h2>
            <p className={`${styles.item}`}>
              1.1. Настоящая Политика конфиденциальности описывает методы использования и хранения сайтом «iristrans.com»
              персональной информации клиентов, посещающих сайт iristrans.com (далее Сайт).<br></br>
              1.2. Использование клиентом Сайта и предоставление «IrisTrans.com» информации частного характера через Сайт
              означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных клиента,
              а также согласие на передачу, использование и раскрытие его персональных данных согласно условиям настоящей
              Политики конфиденциальности.<br></br>
              1.3. В случае несогласия с условиями Политики конфиденциальности клиент должен
              прекратить использование Сайта. <br></br>
              1.4. Настоящая Политика конфиденциальности применяется только в отношении информации частного характера,
              полученной через данный Сайт. Информация частного характера – это информация, позволяющая при ее использовании
              отдельно или в комбинации с другой доступной информацией идентифицировать персональные данные клиента.<br></br>
              1.5. Настоящая Политика конфиденциальности применяется только к Сайту. На Сайте не публикуются преднамеренно
              ссылки на интернет-ресурсы, которые могут привести к повреждению компьютера клиента или заражению его
              вирусами.<br></br>
              1.6. Администрация Сайта не проверяет достоверность персональных данных, предоставляемых Пользователем Сайта.
            </p>
            <h2 className={`${styles.items__title}`}>2. Предмет Политики конфиденциальности</h2>
            <p className={`${styles.item}`}>
              2.1. Данные, которые собираются и сохраняются:<br></br>
              2.1.1. Персональные данные, а также любая другая информация, разрешённые к обработке в рамках настоящей
              Политики конфиденциальности, которую клиенты сознательно и добровольно предоставляют путём заполнения
              регистрационной формы или совершения иных действий на Сайте:</p>
            <ul className={`${styles.list_items}`}>

              <li>фамилия, имя;</li>
              <li>адрес электронной почты (e-mail);</li>
              <li>контактный телефон;</li>
              <li>товары, которые выбирает клиент.</li>
            </ul>
            <p className={`${styles.item}`}>Информация собирается с целью принятия, обработки и доставки заказа; осуществления обратной связи с клиентом,
              предоставления клиентской поддержки, включая направление уведомлений, запросов, касающихся использования
              Сайта.<br></br>
              2.2.2. Данные из cookies-файлов. Это небольшие текстовые файлы, в которых после посещения Сайта cookies-файлов
              сохраняется информация о действиях клиента на ресурсе. Эти файлы не влияют на операционную систему компьютера.
              С какой целью собираются cookies-файлы: IrisTrans использует эту информацию для ведения статистики о
              пользователях и их запросах. Эти исследования помогают нам анализировать, оценивать, улучшать свою работу,
              повышать качество услуг, совершенствовать механизмы общения с клиентами. Клиент может удалить cookies-файлы
              после посещения Сайта. Однако в этом случае IrisTrans не несет ответственности за ненадлежащее качество работы
              ресурса.<br></br>
              2.2.3. Информация от браузера – данные, которые браузер автоматически отправляет серверу, например, IP-адрес,
              историю последних посещений, название операционной системы, название и версию программы, через которую клиент
              осуществляет выход в интернет, дату и время посещения сайта пользователем. Можно запретить браузеру передавать
              подобную информацию путем изменения настроек. С какой целью собирается: интернет-магазин ведет статистику
              посещений для функционирования своих серверов и учета собственного трафика. В данном случае собранная
              статистическая информация не ассоциируется с конкретными пользователями и обрабатывается только в виде
              обобщенной статистики. IP-адрес клиента и время доступа к системе, в соответствии с пользовательским
              идентификатором, сохраняются в базе данных в целях предотвращения мошенничества.<br></br>
              2.2.4. Информация от третьих лиц – это персональные данные, которые предоставляют IrisTrans партнеры,
              поставщики или иная третья сторона.<br></br>
              2.4. Любая иная персональная информация неоговоренная выше подлежит надежному хранению и нераспространению, за
              исключением случаев, предусмотренных в п.п. 3.1., 3.2. и 3.3. настоящей Политики конфиденциальности.
            </p>
            <h2 className={`${styles.items__title}`}>3. Охрана личной информации пользователей</h2>
            <p className={`${styles.item}`}>
              3.1. IrisTrans никогда и ни при каких обстоятельствах не сообщает третьим лицам личную (персональную)
              информацию о своих клиентах.<br></br>
              3.2. IrisTrans имеет право использовать другие компании и частных лиц для выполнения определенных видов работ,
              например: доставка почты и сообщений по электронной почте, удаление дублированной информации из списков
              клиентов, анализ данных, предоставление маркетинговых услуг, обработка платежей по кредитным картам и
              кредитованию. Эти юридические/физические лица имеют доступ к личной информации пользователей, только когда это
              необходимо для выполнения их функций. Данная информация не может быть использована ими в других целях.<br></br>
              3.3. IrisTrans реализует мероприятия по защите личных (персональных) данных клиентов в следующих
              направлениях: </p>
            <ul className={`${styles.list_items}`}>
              <li>предотвращение утечки информации, содержащей личные (персональные) данные, по техническим каналам связи и
                иными способами;</li>
              <li>предотвращение несанкционированного доступа к информации, содержащей личные (персональные) данные,
                специальных воздействий на такую информацию (носителей информации) в целях ее добывания, уничтожения,
                искажения и блокирования доступа к ней;</li>
              <li>защита от вредоносных программ;</li>
              <li>обнаружение вторжений и компьютерных атак.</li>
            </ul>
            <h2 className={`${styles.items__title}`}>4. Срок хранения персональной информации</h2>
            <p className={`${styles.item}`}>
              IrisTrans хранит персональную информацию клиентов ровно столько времени, сколько она остается необходимой для
              достижения тех целей, ради которых она была изначально получена, или других законных целей, за исключением
              случаев, когда более длительный период хранения информации необходим в соответствии с законодательством либо
              разрешен им.
            </p>
            <h2 className={`${styles.items__title}`}>5. Безопасность</h2>
            <p className={`${styles.item}`}>
              5.1. Мы предпринимаем технические и организационные меры для защиты Вашей конфиденциальной информации от
              несанкционированных действий третьих лиц. От Нашей стороны доступ к Вашим персональным данным имеют
              ограниченное число работников и доверенных лиц, которым эта информация необходима для осуществления обработки
              данных от нашего имени и в целях, прямо предусмотренных настоящей Политикой конфиденциальности. Все они берут
              на себя обязательства по соблюдению конфиденциальности и в случае нарушения упомянутых обязательств будут
              подвержены, по мере применимости, дисциплинарной, гражданской, административной и/или уголовной
              ответственности.<br></br>
              5.2. Доступ к Вашей конфиденциальной информации также может осуществляться через обычную систему авторизации с
              использованием Вашего логина и пароля. В случае Вашего пренебрежительного отношения к самостоятельному
              обеспечению безопасности Вашей учетной записи, третьи лица могут получить несанкционированный доступ к Вашей
              информации, за что Мы никоим образом не несем ответственности.
            </p>
            <h2 className={`${styles.items__title}`}>6. Внесение изменений и дополнений</h2>
            <p className={`${styles.item}`}>
              6.1. Настоящая политика действует бессрочно до замены ее новой версией.<br></br>
              6.2. Все изменения положений или условий политики использования личной информации будут отражены в этом
              документе. IrisTrans оставляет за собой право вносить изменения в те или иные разделы данного документа в
              любое время без предварительного уведомления, разместив, обновленную версию настоящей Политики
              конфиденциальности на Сайте. С момента ее опубликования пользователи подчиняются новым условиям Политики
              конфиденциальности.
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}
export default PrivacyPolicy;
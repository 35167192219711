/* eslint-disable react-hooks/exhaustive-deps */
import styles from './home-page-admin.module.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openSelectedPage } from '../../../redux/reducers/selected-page-reducer';
import { saveCurrentSelectedUser } from '../../../redux/reducers/current-selected-user-reducer';
import { useMemo } from 'react';
import { Pagination } from '../../pagination';
import { getAllUsersAdmin } from '../../../http/userAPI';
import { setUsers } from '../../../redux/reducers/users-list-reducer';
import { useTranslation } from "react-i18next";


export function HomePageAdmin() {
  const currentLanguage = useSelector(state => state.language.language);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allUsers = useSelector(state => state.users.users);
  let PageSize = 15;
  const [currentPage, setCurrentPage] = useState(1);

  function goToUserPage(user) {
    dispatch(openSelectedPage("anotherUserPage"))
    dispatch(saveCurrentSelectedUser(user))
  }

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return allUsers.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  async function getAllUsers() {
    try {
      const response = await getAllUsersAdmin();
      const resp = response.data.rows.reverse();
      dispatch(setUsers(resp));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }

  useEffect(() => {
    // getAllUsers();

  }, [])


  return (
    <div className={styles.home_wrapper}>
      <h2 className={`${styles.title} section__title `}>{t('pages.admin.usersList')}</h2>
      {allUsers.length <= 15 &&
        allUsers.map((user, index) => {
          if (user.role === "Admin") {
            return ""
          }
          return (
            <div className={`${styles.users__content}`} key={"n" + user.id} onClick={() => goToUserPage(user)}>
              {(user.role === "Юридическое лицо") ? `${user.orgname}, ${user.name} ` : `${user.name} ${user.surname} `}
              {!user.isActivated && <span className={currentLanguage === 'per' ? `${styles.users__new__pers} ${styles.users__new}` : `${styles.users__new} `}>{t('pages.admin.userNotConfirm')}</span>}
            </div>
          )
        })
      }

      {((allUsers.length > 15)) &&
        <>
          {currentTableData.length > 0 &&
            currentTableData.map((user, index) => {
              if (user.role !== "Admin") {
                return (
                  <div className={`${styles.users__content}`} key={"n" + user.id} onClick={() => goToUserPage(user)}>
                    {(user.role === "Юридическое лицо") ? `${user.orgname}, ${user.name} ` : `${user.name} ${user.surname} `}
                    {!user.isActivated && <span className={currentLanguage === 'per' ? `${styles.users__new__pers} ${styles.users__new}` : `${styles.users__new} `}>{t('pages.admin.userWait')}</span>}
                  </div>
                )
              }
              return ""
            })

          }
          {currentTableData.length === 0 &&
            allUsers.slice(0, 15).map((user, index) => {
              if (user.role !== "Admin") {
                return (
                  <div className={`${styles.users__content}`} key={"n" + user.id} onClick={() => goToUserPage(user)}>
                    {(user.role === "Юридическое лицо") ? `${user.orgname}, ${user.name} ` : `${user.name} ${user.surname} `}
                    {!user.isActivated && <span className={currentLanguage === 'per' ? `${styles.users__new__pers} ${styles.users__new}` : `${styles.users__new} `}>{t('pages.admin.userWait')}</span>}
                  </div>
                )
              }
              return ""
            })
          }
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={allUsers.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          />
        </>
      }
    </div >
  )
}
export default HomePageAdmin;
import styles from './search-table-transport.module.css';
import stylesSelect from '../../select-items/select-items.module.css';
import arrow from "./images/arrow.svg"
import axios from "axios";
import Select, { components } from 'react-select';
import AsyncSelect from "react-select/async";
import { useFormik } from 'formik';
import { useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { docsItems, transportItems, loadingItems, cargoDangerItems, paymentItems, transportCloseBody, transportOpenBody, transportPassengers, transportSpecial, transportTankTruck } from './options-items';
import { transportFind } from '../../../http/transportApi';
import { saveFoundTransport } from '../../../redux/reducers/transport-search-reducer';
import { countriesList } from '../../../services/countries-list';
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import { setCountryFrom, setCountryFromId, setCityFrom, setCityTo, setCountryTo, setCountryToId } from '../../../redux/reducers/cargo-geo-reducer';
import { BaseURL } from "../../../http";


export function SearchTableTransport(props) {
  const { t } = useTranslation();
  const currentLanguage = useSelector(state => state.language.language);

  const dispatch = useDispatch();
  const [selectedOptionsDocs, setSelectedOptionsDocs] = useState("");
  const [selectedOptionsTransport, setSelectedOptionTransport] = useState("");
  const [selectedOptionsLoading, setSelectedOptionLoading] = useState("");
  const [selectedOptionsDanger, setselectedOptionsDanger] = useState("");
  const [selectedOptionsPayment, setSelectedOptionPayment] = useState("");
  const [selectedOptionsCountry, setselectedOptionsCountry] = useState();
  const [emptyField, setEmptyField] = useState(false);
  const selectCountryFromRef = useRef();
  const selectCountryToRef = useRef();
  const [selectedCargoOptionsCountryFrom, setSelectedCargoOptionsCountryFrom] = useState("");
  const [selectedCargoOptionsCountryTo, setSelectedCargoOptionsCountryTo] = useState("");
  const refTable = useRef(null);

  // поля селекты
  const docsRef = useRef();
  const transportRef = useRef();
  const loadingRef = useRef();
  const dangerRef = useRef();
  const paymentRef = useRef();

  // поля селекты

  const closeBodyTransportType = defineLanguagecloseBodyTransportType(currentLanguage)
  const openBodyTransportType = defineLanguageOpenBodyTransportType(currentLanguage)
  const tankTransportType = defineLanguagecTankTransportType(currentLanguage)
  const specialTransportType = defineLanguageSpecialTransportType(currentLanguage)
  const passengerTransportType = defineLanguagePassengerBodyTransportType(currentLanguage)

  function defineLanguagecloseBodyTransportType(lg) {
    if (lg === "ru") {
      return "Крытая"
    }
    if (lg === "en") {
      return "Closed body"
    }
    if (lg === "per") {
      return "در داخل"
    }
  }
  function defineLanguageOpenBodyTransportType(lg) {
    if (lg === "ru") {
      return "Открытая"
    }
    if (lg === "en") {
      return "Open body"
    }
    if (lg === "per") {
      return ""
    }
  }
  function defineLanguagecTankTransportType(lg) {
    if (lg === "ru") {
      return "Цестерна"
    }
    if (lg === "en") {
      return "Tank truck"
    }
    if (lg === "per") {
      return "مخزن"
    }
  }
  function defineLanguageSpecialTransportType(lg) {
    if (lg === "ru") {
      return "Специальная техника"
    }
    if (lg === "en") {
      return "Special machinery"
    }
    if (lg === "per") {
      return "تجهیزات ویژه"
    }
  }
  function defineLanguagePassengerBodyTransportType(lg) {
    if (lg === "ru") {
      return "Пассажирский"
    }
    if (lg === "en") {
      return "Passenger's"
    }
    if (lg === "per") {
      return "مسافر"
    }
  }


  const onClearAllFields = () => {
    selectCountryToRef.current.clearValue();
    selectCountryFromRef.current.clearValue();
    docsRef.current.clearValue();
    transportRef.current.clearValue();
    loadingRef.current.clearValue();
    dangerRef.current.clearValue();
    paymentRef.current.clearValue();
    setSelectedCargoOptionsCountryFrom("")
    setSelectedCargoOptionsCountryTo("")
    dispatch(setCountryFrom(""));
    dispatch(setCountryFromId(""));
    dispatch(setCityFrom(""));
    dispatch(setCityTo(""));
    dispatch(setCountryTo(""));
    dispatch(setCountryToId(""));
  };


  function handleDocsSelect(data) {
    setSelectedOptionsDocs(data);
  }

  function handleTransportSelect(data) {
    setSelectedOptionTransport(data);
  }
  function handleLoadingSelect(data) {
    setSelectedOptionLoading(data);
  }
  function handleDangerSelect(data) {
    setselectedOptionsDanger(data);
  }
  function handlePaymentSelect(data) {
    setSelectedOptionPayment(data);
  }

  // изменение значения выбранного селекта

  function handleCountryFromSelect(data) {
    if ((data !== undefined) && data !== null) {
      setSelectedCargoOptionsCountryFrom(data);
      dispatch(setCountryFrom(data.name));
      dispatch(setCountryFromId(data.id));
      setEmptyField(false)
    }
  }
  function handleCountryToSelect(data) {
    if ((data !== undefined) && data !== null) {
      setSelectedCargoOptionsCountryTo(data);
      dispatch(setCountryTo(data.name));
      dispatch(setCountryToId(data.id));
      setEmptyField(false)
    }
  }

  // изменение значения выбранного селекта конец


  function dataArray(arr) {
    let dataFromQuery = [];
    if (arr.length > 0) {
      arr.forEach(element => {
        dataFromQuery.push(element.id)
      });
      return dataFromQuery
    }
    dataFromQuery = ""
    return dataFromQuery

  }

  const initialValues = {
    location: "",
    weighMax: "",
    long: "",
    widthMax: "",
    heightMax: "",
  }

  const validate = (values) => {
    let errors = {}
    // if (!values.location) {
    //   errors.location = t('field.validate')
    // }

    if (!/^(\d*\.?\d*)$/i.test(values.weighMax)) {
      errors.weighMax = t('vvedite-cifry-ili-cifry-s-tochkoi')
    }
    if (!/^(\d*\.?\d*)$/i.test(values.long)) {
      errors.long = t('vvedite-cifry-ili-cifry-s-tochkoi')
    }
    if (!/^(\d*\.?\d*)$/i.test(values.widthMax)) {
      errors.widthMax = t('vvedite-cifry-ili-cifry-s-tochkoi')
    }
    if (!/^(\d*\.?\d*)$/i.test(values.heightMax)) {
      errors.heightMax = t('vvedite-cifry-ili-cifry-s-tochkoi')
    }
    return errors
  }

  const scrollToElement = () => {
    refTable.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onSubmit = async (values, { resetForm }) => {
    let fullValues = {
      ...values,
      countryfrom: selectedCargoOptionsCountryFrom ? selectedCargoOptionsCountryFrom.name : "",
      regions: selectedCargoOptionsCountryTo ? selectedCargoOptionsCountryTo.name : "",
      docs: dataArray(selectedOptionsDocs),
      transport: dataArray(selectedOptionsTransport),
      loading: dataArray(selectedOptionsLoading),
      payment: dataArray(selectedOptionsPayment),
      danger: selectedOptionsDanger ? selectedOptionsDanger.value : "",
    }


    if (!hasFilledField([selectedCargoOptionsCountryFrom, selectedCargoOptionsCountryTo])) {
      setEmptyField(true)
    } else {
      setEmptyField(false)
      const response = await transportFind(fullValues);
      dispatch(saveFoundTransport(response.data))
      // onClearAllFields()
      values.long = '';
      values.heightMax = '';
      values.widthMax = '';
      setEmptyField(false)
      scrollToElement();
    }

  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate
  })


  const hasFilledField = function (fields) {
    for (let field of fields) {
      if (field) {
        return true
      }

    }
    return false
  }



  // получение стран и городов


  async function getRequestedCountries(name) {
    return await axios.get(`${BaseURL}/api/geo/countries/${name}`)
      .then((response) => {
        return response.data.map((country) => ({
          name: country.name,
          id: country.id,
          label: country.name,
        }));
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  }

  async function getRequestedCountriesEnglish(name) {
    return await axios.get(`${BaseURL}/api/geo/countrieseng/${name}`)
      .then((response) => {
        return response.data.map((country) => ({
          name: country.english,
          id: country.id,
          label: country.english,
        }));
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  }


  // получение стран и городов  конец


  //загрузка опшн в селект

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCountryOptions = useCallback(
    debounce((inputText, callback) => {

      if (inputText.length >= 3) {
        if (currentLanguage === "ru") {
          getRequestedCountries(inputText).then((options) => {
            return callback(options)
          });
        } else {
          getRequestedCountriesEnglish(inputText).then((options) => {
            return callback(options)
          });
        }
      }
    }, 100),
    []
  );

  //загрузка опшн в селект  конец

  const groupedTransportOptions = [
    {
      label: closeBodyTransportType,
      options: transportCloseBody
    },
    {
      label: openBodyTransportType,
      options: transportOpenBody
    },
    {
      label: tankTransportType,
      options: transportTankTruck
    },
    {
      label: specialTransportType,
      options: transportSpecial
    },
    {
      label: passengerTransportType,
      options: transportPassengers
    }
  ];


  const colourStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#00354b',
      }
    }
  }

  const DropdownIndicator = (
    props
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={arrow} alt="arrow" />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className={styles.home_wrapper}>
      <section className={styles.block}>
        <div className={styles.block__header}>
          <div className={styles.header__title}>
            {props.title}
          </div>
        </div>
        <div className={styles.block__content}>
          <div className={styles.content__inner}>
            {emptyField && <div className={styles.error_message}>{t('ukazhite-stranu-ili-gorod')}</div>}
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.country_box}>
                <div className={styles.direction_box}>
                  <div className={styles.filed_and_label}>
                    <p className={styles.filed__label}>{t('placeholder.table.countryFrom')}</p>
                    <AsyncSelect
                      ref={selectCountryFromRef}
                      styles={colourStyles}
                      components={{ DropdownIndicator }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      placeholder={t('placeholder.table.countryFrom')}
                      onChange={handleCountryFromSelect}
                      loadOptions={loadCountryOptions}
                      defaultValue={[]}
                      defaultOptions={[]}
                      isClearable={true}
                      noOptionsMessage={({ inputValue }) => !inputValue ? t('nachnite-vvodit-tekst-0') : t('nichego-ne-naideno')
                      }
                    />
                  </div>
                  <div className={styles.filed_and_label}>
                    <p className={styles.filed__label}>{t('placeholder.table.countryTo')}</p>
                    <AsyncSelect
                      ref={selectCountryToRef}
                      styles={colourStyles}
                      components={{ DropdownIndicator }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      placeholder={t('placeholder.table.countryTo')}
                      onChange={handleCountryToSelect}
                      loadOptions={loadCountryOptions}
                      defaultValue={[]}
                      defaultOptions={[]}
                      isClearable={true}
                      noOptionsMessage={({ inputValue }) => !inputValue ? t('nachnite-vvodit-tekst-0') : t('nichego-ne-naideno')}
                    />
                  </div>
                </div>
                <div className={styles.direction_box}>
                  <div className={styles.filed_and_label}>
                    <p className={styles.filed__label}>{t('placeholder.table.transportLocation')}</p>
                    <input className={`${styles.input} ${styles.input__dir}`} type="text" id="location" name="location" onChange={formik.handleChange} value={formik.values.location} placeholder={t('placeholder.table.transportLocation')} />
                    {/* {formik.errors.location && <div className={styles.error_message}>{formik.errors.location}</div>} */}
                  </div>
                  <div className={styles.cargo_wrap}>
                    <div className={styles.filed_and_label}>
                      <p className={styles.filed__label}>{t('placeholder.table.weightMax')}</p>
                                          <div className={currentLanguage === 'per' ? `${styles.cargo_box} ${styles.cargo_box_weight} ${styles.cargo_box_weight__pers}` : `${styles.cargo_box} ${styles.cargo_box_weight}`}>
                        <input className={`${styles.input} ${styles.input__cargo}`} type="text" id="weighMax" name="weighMax" onChange={formik.handleChange} value={formik.values.weighMax} placeholder={t('placeholder.table.weightMax')} />
                        {formik.errors.weighMax && <div className={styles.error_message}>{formik.errors.weighMax}</div>}
                      </div>
                    </div>
                    <div className={styles.filed_and_label}>
                      <p className={styles.filed__label}>{t('placeholder.table.typeOftransport')}</p>
                      <div className={`${stylesSelect.select_custom}`}>
                        <Select
                          ref={transportRef}
                          styles={colourStyles}
                          components={{ DropdownIndicator }}
                          options={groupedTransportOptions}
                          getOptionLabel={(option) => {
                            if (currentLanguage === "ru") {
                              return option.value
                            } if (currentLanguage === "en") {
                              return option.english
                            } if (currentLanguage === "per") {
                              return option.persian
                            }
                          }}
                          placeholder={t('placeholder.table.typeOftransport')}
                          value={selectedOptionsTransport}
                          onChange={handleTransportSelect}
                          isMulti
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.add__info_box}>
                <div className={styles.add__wrap}>
                  <div className={`${styles.add__description} ${styles.add__dimensions}`}>
                    <div className={styles.description__header}>
                      {t('placeholder.table.dimensions')}
                    </div>
                    <div className={`${styles.description__content} ${styles.dimensions__content}`}>
                      <div className={styles.filed_and_label}>
                        <p className={styles.filed__label}>{t('placeholder.table.lengthTo')}</p>
                        <div className={currentLanguage === 'per' ? `${styles.cargo_box} ${styles.dimensions_box} ${styles.dimensions_box__pers}` : `${styles.cargo_box} ${styles.dimensions_box}`}>
                          <input className={`${styles.input} ${styles.input__cargo} ${styles.input__dimensions}`} type="text" id="long" name="long" onChange={formik.handleChange} value={formik.values.long} placeholder={t('placeholder.table.lengthTo')} />
                          {formik.errors.long && <div className={styles.error_message}>{formik.errors.long}</div>}
                        </div>
                      </div>

                      <div className={styles.filed_and_label}>
                        <p className={styles.filed__label}>{t('placeholder.table.widthTo')}</p>
                        <div className={currentLanguage === 'per' ? `${styles.cargo_box} ${styles.dimensions_box} ${styles.dimensions_box__pers}` : `${styles.cargo_box} ${styles.dimensions_box}`}>

                          <input className={`${styles.input} ${styles.input__cargo} ${styles.input__dimensions}`} type="text" id="widthMax" name="widthMax" onChange={formik.handleChange} value={formik.values.widthMax} placeholder={t('placeholder.table.widthTo')} />
                          {formik.errors.widthMax && <div className={styles.error_message}>{formik.errors.widthMax}</div>}
                        </div>
                      </div>
                      <div className={styles.filed_and_label}>
                        <p className={styles.filed__label}>{t('placeholder.table.heightTo')}</p>
                        <div className={currentLanguage === 'per' ? `${styles.cargo_box} ${styles.dimensions_box} ${styles.dimensions_box__pers}` : `${styles.cargo_box} ${styles.dimensions_box}`}>

                          <input className={`${styles.input} ${styles.input__cargo} ${styles.input__dimensions}`} type="text" id="heightMax" name="heightMax" onChange={formik.handleChange} value={formik.values.heightMax} placeholder={t('placeholder.table.heightTo')} />
                          {formik.errors.heightMax && <div className={styles.error_message}>{formik.errors.heightMax}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.add__info_wrap}>
                    <div className={styles.add__info1}>
                      <div className={styles.filed_and_label}>
                        <p className={styles.filed__label}>{t('placeholder.table.documents')}</p>
                        <div className={`${stylesSelect.select_custom}`}>
                          <Select
                            ref={docsRef}
                            styles={colourStyles}
                            components={{ DropdownIndicator }}
                            options={docsItems}
                            getOptionLabel={(option) => {
                              if (currentLanguage === "ru") {
                                return option.value
                              } if (currentLanguage === "en") {
                                return option.english
                              } if (currentLanguage === "per") {
                                return option.persian
                              }
                            }}
                            placeholder={t('placeholder.table.documents')}
                            value={selectedOptionsDocs}
                            onChange={handleDocsSelect}
                            isMulti
                          />
                        </div>
                      </div>
                      <div className={styles.filed_and_label}>
                        <p className={styles.filed__label}>{t('placeholder.table.danger')}</p>
                        <div className={`${stylesSelect.select_custom}`}>
                          <Select
                            ref={dangerRef}
                            styles={colourStyles}
                            components={{ DropdownIndicator }}
                            options={cargoDangerItems}
                            placeholder={t('placeholder.table.danger')}
                            value={selectedOptionsDanger}
                            onChange={handleDangerSelect}
                            isClearable={true}
                          />
                        </div>
                      </div>
                      <div className={styles.filed_and_label}>
                        <p className={styles.filed__label}>{t('placeholder.table.loadType')}</p>
                        <div className={`${stylesSelect.select_custom}`}>
                          <Select
                            ref={loadingRef}
                            styles={colourStyles}
                            components={{ DropdownIndicator }}
                            options={loadingItems}
                            getOptionLabel={(option) => {
                              if (currentLanguage === "ru") {
                                return option.value
                              } if (currentLanguage === "en") {
                                return option.english
                              } if (currentLanguage === "per") {
                                return option.persian
                              }
                            }}
                            placeholder={t('placeholder.table.loadType')}
                            value={selectedOptionsLoading}
                            onChange={handleLoadingSelect}
                            isMulti
                          />
                        </div>
                      </div>
                      <div className={styles.filed_and_label}>
                        <p className={styles.filed__label}>{t('header.menu.payment')}</p>
                        <div className={`${stylesSelect.select_custom}`}>
                          <Select
                            ref={paymentRef}
                            styles={colourStyles}
                            components={{ DropdownIndicator }}
                            options={paymentItems}
                            getOptionLabel={(option) => {
                              if (currentLanguage === "ru") {
                                return option.value
                              } if (currentLanguage === "en") {
                                return option.english
                              } if (currentLanguage === "per") {
                                return option.persian
                              }
                            }}
                            placeholder={t('header.menu.payment')}
                            value={selectedOptionsPayment}
                            onChange={handlePaymentSelect}
                            isMulti
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.add__wrap}>
                </div>
              </div>
              <div className={styles.btn__box} ref={refTable}>
                <button type="submit" className={`${styles.btn}`}>
                  {t('buttons.find')}
                </button>
              </div>
            </form>
          </div>
        </div >
      </section >

    </div >
  )
}
export default SearchTableTransport;

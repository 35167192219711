import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  country: "",
  countryId: "",
  city: "",
  telcode: "",
};

export const geoRegistrationSlice = createSlice({
  name: 'geoRegistration',
  initialState,
  reducers: {
    setCountry: (state, action) => {
      return {
        ...state,
        country: action.payload,
      }
    },
    setCountryId: (state, action) => {
      return {
        ...state,
        countryId: action.payload,
      }
    },
    setCity: (state, action) => {
      return {
        ...state,
        city: action.payload,
      }
    },
    setTelcode: (state, action) => {
      return {
        ...state,
        telcode: action.payload,
      }
    }
  }
});


export const { setCountry, setCity,  setTelcode, setCountryId } = geoRegistrationSlice.actions;
export const geoRegistrationReducer = geoRegistrationSlice.reducer;

import styles from './сarrier.module.css'
import { PagesDescription } from '../../components/pages-description';
import auto from "./images/auto.png"
import tableSearch from "./images/table1.png"
import tableReg from "./images/table2.png"
import { PagesItem } from '../../components/pages-item';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";

export function CarrierPage() {
  const { t } = useTranslation();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={styles.wrapper}>
      <div className="inner">
        <PagesDescription title={t('to-the-carrier')}
          text={t('iristrans-a-platform-for-fast-cargo')}
          img={auto} />
        <PagesItem title={t('convenient-cargo-search')}
          text={t('post-a-shipping-request-form-to-quickly')}
          img={tableSearch} />
        <PagesItem title={t('quickly-add-transport')}
          text={t('add-a-vehicle')}
          img={tableReg} />
        <p className={styles.item_title}> {t('set-your-own-price')} </p>
        <p className={styles.item_text}>{t('assign-your-own-freight')} </p>
        <p className={styles.item_text}>{t('in-addition-we-set-affordable-tariffs')} </p>
        <p className={styles.item_title}>{t('variety-of-directions')} </p>
        <p className={styles.item_text}>{t('cargo-owners-and-forwarders')} </p>
        <p className={styles.item_text}>{t('we-specialize-in-the-east')} </p>
      </div>
    </main>
  )
}
export default CarrierPage;
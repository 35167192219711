import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userMessages: [],
 
};

export const messagesSlice = createSlice({
  name: 'userMessages',
  initialState,
  reducers: {
    saveMessages: (state, action) => {
      return {
        ...state,
        userMessages: action.payload,

      }
    },

  }
});

export const { saveMessages } = messagesSlice.actions;
export const userMessagesReducer = messagesSlice.reducer;
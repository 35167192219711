import axios from "axios";
import {BaseURL} from "./index";

export const $authHost = axios.create({
  withCredentials: true,
  baseURL: `${BaseURL}`
})

const authInterceptor = config => {

  config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return config
}

$authHost.interceptors.request.use(authInterceptor)


export default $authHost

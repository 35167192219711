import styles from './main-stages.module.css';
// import styles from './main-stages-pers.module.css';
import Button from '../button/button';
import CardMainStage from '../card-main-stages/card-main-stages';
import { useDispatch } from 'react-redux';
import { openRegistrForm } from '../../redux/reducers/registration-reducer';
import { useTranslation } from "react-i18next";

export function MainStages() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function changeRegistrationOpen() {
    dispatch(openRegistrForm());
  }

  return (
    <div className={styles.main__stages_wrapper} id="stages">
      <div className="inner">
        <h2 className={`${styles.title} section__title `} >{t('etapy-raboty-s-saitom')}</h2>
        <div className={styles.cards__wrapper}>
          <CardMainStage title={t('stage1')}
              subtitle={t('stage1Text')} />
          <CardMainStage title={t('stage2')}
            subtitle={t('stage2Text')} />
          <CardMainStage title={t('stage3')}
            subtitle={t('stage3Text')} />
          <CardMainStage title={t('stage4')}
            subtitle={t('stage4Text')} />
        </div>
        <Button click={changeRegistrationOpen}>{t("button.registrationText")}</Button>
      </div>
    </div>
  )
}
export default MainStages;
import styles from './footer-bottom-copyright.module.css';
// import styles from './footer-bottom-copyright-pers.module.css';
import { NavLink } from 'react-router-dom';

export function FooterCopyrightLine() {
  return (
    <div className={styles.footerLine}>
      <div className={styles.footerText1}>Copyright IrisTrans.com © 2023</div>
      <NavLink to='https://vtop3.by/uslugi/sozdanie-sajtov/ ' key='vtop3' className={styles.footerText2} target="_blank">
        <div> Создание сайта ВТОП3</div>
      </NavLink>
    </div>
  )
}

export default FooterCopyrightLine;

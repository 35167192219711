/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import styles from './another-user-page.module.css';
import parcel from "./images/parcel.svg";
import truck2 from "./images/truck2.svg";
import star from "./images/star.svg";
import starbl from "./images/star-black.svg";
import nologo from "./images/no-logo.jpg";
import telegram from "./images/telegram.png";
import whatsapp from "./images/whatsapp.png";
import skype from "./images/skype.svg";
import like from "./images/like.svg";
import likebl from "./images/like-black.svg";
import staractive from "./images/star-active.svg";
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { activationUser, deleteUser, addUserAccount, uploadInvoice } from '../../../http/userAPI'
import { NavLink } from 'react-router-dom';
import axios from "axios";
import { openEditAccountForm } from '../../../redux/reducers/edit-account-reducer';
import AccountUpdate from '../update-account/update-account';
import { useFormik } from 'formik';
import { openRegFinishModal } from '../../../redux/reducers/registration-finish';
import { saveCurrentSelectedUserDialog } from '../../../redux/reducers/current-selected-user-reducer';
import { openSuccessModal } from '../../../redux/reducers/success-reducer';
import { ModalSuccess } from '../../modal-success';
import { useRef } from 'react';
import { openSelectedPage } from '../../../redux/reducers/selected-page-reducer';
import { openChat } from '../../../redux/reducers/chat-reducer';
import { useTranslation } from "react-i18next";
import { BaseURL } from "../../../http";


export function AnotherUserPage({ onlineUsers }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userData = useSelector(state => state.userData.userData);
  const selectedUser = useSelector(state => state.currentSelectedUser.currentSelectedUser);
  const [onlineUser] = useState(onlineUsers);
  const userIsOnline = onlineUser && onlineUser.some(user => user.userId === userData.id);
  const inputRef = useRef(null);

  let variable = {
    likesSenderId: userData.id,
    likesRecipientId: selectedUser.id
  }



  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [likeAction, setLikeAction] = useState(null);
  const [dislikeAction, setDislikeAction] = useState(null);
  const [selectedUserStatus, setSelectedUserStatus] = useState(selectedUser.isActivated);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [file, setFile] = useState(null);
  const [fileResponse, setFileResponse] = useState(null);
  const [deleteResponse, setDeleteResponse] = useState(null);
  const successModal = useSelector(state => state.successModal.successModal);

  const selectFile = e => {
    setFile(e.target.files[0])
  }

  async function getLikes() {
    try {
      const response = await axios.post(`${BaseURL}/api/user/like`, variable)
      setLikes(response.data.length)
      if (response.data.length > 0) {
        response.data.map(like => {
          if (like.likesSenderId === userData.id) {
            setLikeAction("liked")
          }
        })
      }

    } catch (e) {
      console.log(e.response?.data?.message)
    }
  }
  async function getDislikes() {
    try {
      const response = await axios.post(`${BaseURL}/api/user/dislike`, variable)
      setDislikes(response.data.length)
      if (response.data.length > 0) {
        response.data.map(dislike => {
          if (dislike.likesSenderId === userData.id) {
            setDislikeAction("disliked")
          }
        })
      }
    } catch (e) {
      console.log(e.response?.data?.message)
    }
  }

  useEffect(() => {
    getLikes();
    getDislikes();
  }, []
  )

  const countOfCargos = useSelector(state => state.cargoUsers.cargoUser);
  const countOfTransports = useSelector(state => state.transportsUser.transportsUser);
  const userLogo = useSelector(state => state.currentImg.currentImg);
  const userWork = useSelector(state => state.userData.userData.work);
  let avatar = selectedUser.img ? `${BaseURL}/avatars/${selectedUser.img}` : nologo;
  let userStat = (userWork === "Грузоотправитель" || userWork === "Перевозчик") ? styles.user_data_stat2 : styles.user_data_stat;

  function dateParse(date) {
    let d = new Date(date);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    if (month < 10) {
      month = `0${month}`;
      return `${day}.${month}.${year}`;
    }
    else {
      return `${day}.${month}.${year}`;
    }
  }

  const onLike = async () => {
    if (likeAction === null) {
      const response = await axios.post(`${BaseURL}/api/user/upLike`, variable)
      setLikes(likes + 1)
      setLikeAction("liked")
      if (dislikeAction !== null) {
        setDislikeAction(null)
        setDislikes(dislikes - 1)
      }
      return response
    } else {
      const response = await axios.post(`${BaseURL}/api/user/unLike`, variable)
      setLikeAction(null)
      setLikes(likes - 1)
      return response
    }
  }
  const onDislike = async () => {
    if (dislikeAction !== null) {
      const response = await axios.post(`${BaseURL}/api/user/unDisLike`, variable)
      setDislikeAction(null)
      setDislikes(dislikes - 1)
      return response

    } else {
      const response = await axios.post(`${BaseURL}/api/user/upDislike`, variable)
      setDislikeAction("disliked")
      setDislikes(dislikes + 1)
      if (likeAction !== null) {
        setLikes(likes - 1)
        setLikeAction(null)
      }
      return response
    }
  }

  const activationUserProfile = async (values) => {
    await activationUser(values)
    setSelectedUserStatus(true)

  }
  const deleteUserProfile = async (values) => {
    setDeleteStatus(true)
    const response = await deleteUser(values)
    if (response.statusText === "OK") {
      setDeleteResponse(response.statusText)
      dispatch(openSuccessModal())
      setTimeout(() => {
        dispatch(openSelectedPage("userspage"))
      }, 2000);
    } else {
      dispatch(openSuccessModal())
      setDeleteResponse('err')
    }
  }

  const editForm = useSelector(state => state.editAccountForm.editAccountForm);
  const initialValues = {
    userId: selectedUser.id,
    email: selectedUser.email,
  }
  const formData = new FormData();
  const onSubmit = async (values) => {
    if (file === null) {
      return
    } else {
      formData.append('file', file)
      formData.append('userId', values.userId)
      formData.append('email', values.email)
      const response = await uploadInvoice(formData);
      setFileResponse(response.data)
      if (response.data === "OK") {
        setFile(null)
        setFileResponse(response.data)
        dispatch(openSuccessModal())
      } else {
        dispatch(openSuccessModal())
        setFileResponse('err')
      }
      inputRef.current.value = null;
    }
  }
  const formik = useFormik({
    initialValues,
    onSubmit,

  })
  return (
    <>
      {(successModal && fileResponse === "OK") && <ModalSuccess title="Успешно" text="Счет-фактура отправлен" />}
      {(successModal && fileResponse === "err") && <ModalSuccess title="Ошибка" text="Счет-фактура  не отправлен" />}
      {(successModal && deleteResponse === "OK") && <ModalSuccess title="Успешно" text="Пользователь удален" />}
      {(successModal && deleteResponse === "err") && <ModalSuccess title="Ошибка" text="Пользователь не удален" />}
      <div className={styles.user_wrapper}>

        <section className={`${styles.block} ${styles.block__profile} `}>
          <div className={styles.block__header}>
            <div className={styles.user_name_header}>
              {(selectedUser.role === "Юридическое лицо") ? `${selectedUser.orgname}` : `${selectedUser.surname} ${selectedUser.name} ${selectedUser.secondname}`}
            </div>
            {(selectedUser.isActivated || selectedUserStatus) ?
              <div className={styles.user_check}>
                {t('profileVerified')}
                <img src={staractive} alt="star" />
              </div> :
              <div className={styles.user_check}>
                {t('profileNotVerified')}
                <img src={star} alt="star" />
              </div>
            }
          </div>
          <div className={styles.block__content}>
            <div className={styles.content__inner}>
              <div className={styles.logo__wrapper}>
                <div className={styles.user__status}></div>
                <div className={userIsOnline ? `${styles.user__status_active}` : ` ${styles.user__status}`}></div>
                <img className={styles.user__logo} src={userLogo ? userLogo : avatar} alt="logo" />
              </div>
              <div className={styles.user_info}>
                <p className={styles.user_name}>{(selectedUser.role === "Юридическое лицо") ? `${selectedUser.orgname}` : `${selectedUser.surname} ${selectedUser.name} ${selectedUser.secondname}`}</p>
                <p className={styles.user_city}> {`${selectedUser.city}  ${selectedUser.country} `}</p>
                <div className={styles.user_grade}>
                  <div className={styles.user_stars}>
                    <span className={styles.stars__count}>0</span>
                    <img className={styles.star_icon} src={starbl} alt="star" />
                  </div>
                  <div className={styles.user_likes}>
                    <div className={styles.likes}>
                      <img className={styles.like_icon} src={likebl} alt="star" onClick={onLike} />
                      <span className={styles.like__count}>{likes}</span>
                    </div>
                    <div className={styles.likes}>
                      <img className={styles.unlike_icon} src={likebl} alt="star" onClick={onDislike} />
                      <span className={styles.unlike__count}>{dislikes}</span>
                    </div>
                  </div>
                  <div className={`${styles.user_likes}  ${styles.user_btn_general}`}>
                    <div className={styles.likes} onClick={() => {
                      dispatch(saveCurrentSelectedUserDialog(true))
                      dispatch(openChat())
                    }}>
                      {t('buttons.message')}
                    </div>
                  </div>

                </div>
                {(userData.role === "Admin" && !deleteStatus) &&
                  <div className={styles.user_balance}>{t('buttons.userBalance')} {selectedUser.balance}</div>
                }
                <div className={styles.social_links}>
                  {selectedUser.telegram &&
                    <NavLink className={styles.user_telegram} to={"https://t.me/" + selectedUser.telegram} target="_blank">
                      <img src={telegram} alt="telegram" />
                    </NavLink>
                  }
                  {selectedUser.whatsapp &&
                    <NavLink className={styles.user_whatsapp} to={"https://wa.me/" + selectedUser.whatsapp} target="_blank">
                      <img src={whatsapp} alt="whatsapp" />
                    </NavLink>}
                  {selectedUser.skype &&
                    <NavLink className={styles.user_skype} to={"skype:" + selectedUser.skype} target="_blank">
                      <img src={skype} alt="skype" />
                    </NavLink>
                  }
                </div>
                <div className={styles.user_grade_btns}>
                  {(userData.role === "Admin" && !deleteStatus) &&
                    <>
                      {/* <div className={`${styles.user_likes}  ${styles.user_btn_general}`}>
                        <div className={`${styles.likes}  ${styles.link_btn}`}>
                          <NavLink to={`${BaseURL}/docs/${selectedUser.verification}`} target="_blank" className={styles.link_btn_size} >
                            {t('buttons.openDocs')}
                          </NavLink>
                        </div>
                      </div> */}
                      <div className={`${styles.user_likes}  ${styles.user_btn_general}`} onClick={() => {
                        dispatch(openEditAccountForm())
                      }}     >
                        <div className={styles.likes} >
                          {t('buttons.getUpBalance')}
                        </div>
                      </div>
                      {editForm && <AccountUpdate userId={selectedUser.id} email={selectedUser.email} />}
                      {(selectedUser.isActivated || selectedUserStatus) ?
                        <div className={`${styles.user_likes} ${styles.user_btn_confirm}`}>
                          <div className={styles.likes}>
                            {t('profileVerified')}
                          </div>
                        </div> :
                        <div className={`${styles.user_likes} ${styles.user_btn_confirm}`}>
                          <div className={styles.likes} onClick={() => { activationUserProfile({ userId: selectedUser.id }) }}>
                            {t('buttons.verifyProfile')}
                          </div>
                        </div>
                      }
                      <div className={`${styles.user_likes} ${styles.user_btn_delete}`}>
                        <div className={styles.likes} onClick={() => { deleteUserProfile({ userId: selectedUser.id }) }}>
                          {t('buttons.deleteProfile')}
                        </div>
                      </div>
                    </>
                  }
                  {(userData.role === "Admin" && deleteStatus) &&
                    <div className={`$${styles.user_btn_deleted}`}>
                      <div className={styles.likes} >
                        {t('bottons.profileDeleted')}
                      </div>
                    </div>
                  }
                </div>
                {userData.role === "Admin" &&
                  <div className={`${styles.user_grade} ${styles.input_verification}`}>
                    <form onSubmit={formik.handleSubmit}>
                      <label className={styles.input_label} htmlFor="invoice">{t('buttons.sendInvoice')}</label>
                      <input className={`${styles.input} ${styles.input_verification}`} ref={inputRef} type="file" id="invoice" name="invoice" onChange={selectFile} placeholder="Выберите файл" />
                      <button type="submit" className={styles.button_long} >{t('butttons.sendText')}</button>
                    </form>
                  </div>
                }
              </div>
            </div>
          </div>
        </section>
        <div className={styles.user_data}>
          <section className={`${styles.block} ${styles.block__data} `}>
            <div className={styles.block__header}>
              <div className={styles.header_title}>
                {t('registration-details')}
              </div>
            </div>
            <div className={styles.block__content}>
              <div className={styles.content__inner}>
                <ul className={styles.data_items}>
                  <li className={styles.data_item}>
                    <span>{t('profile.details.name')}</span>
                    {selectedUser.role === "Юридическое лицо" ? <span> {`${selectedUser.orgname}, ${selectedUser.role}`}</span> : <span> {`${selectedUser.surname} ${selectedUser.name} ${selectedUser.secondname}, ${selectedUser.role}`}</span>}
                  </li>
                  {(selectedUser.role === "Юридическое лицо") && <li className={styles.data_item}>
                    <span>{t('profile.details.unp')}</span>
                    {(selectedUser.role === "Юридическое лицо") && <span> {`${selectedUser.unp}`}</span>}
                  </li>}
                  <li className={styles.data_item}>
                    <span>{t('profile.details.country')}</span>
                    <span> {`${selectedUser.country} `}</span>
                  </li>
                  <li className={styles.data_item}>
                    <span>{t('profile.details.city')}</span>
                    <span> {`${selectedUser.city}`}</span>
                  </li>
                  <li className={styles.data_item}>
                    <span>{t('profile.details.date')}</span>
                    <span>{dateParse(selectedUser.createdAt)}</span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className={`${styles.block} ${styles.block__data} `}>
            <div className={styles.block__header}>
              <div className={styles.header_title}>
                {t('contact')}
              </div>
            </div>
            <div className={styles.block__content}>
              <div className={styles.content__inner}>
                <ul className={styles.data_items}>
                  {(selectedUser.role === "Юридическое лицо") && <li className={styles.data_item}>
                    <span>{t('profile.details.contactPerson')}</span>
                    {(selectedUser.role === "Юридическое лицо") && <span> {`${selectedUser.name}`}</span>}
                  </li>}
                  <li className={styles.data_item}>
                    <span>{t('profile.details.phone')}</span>
                    <span> {`${selectedUser.number}`}</span>
                  </li>
                  <li className={styles.data_item}>
                    <span>Email</span>
                    <span>{`${selectedUser.email}`}</span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div >
    </>
  )
}

export default AnotherUserPage;

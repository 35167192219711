/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styles from './services-prices.module.css';
import { openSelectedPage } from '../../../redux/reducers/selected-page-reducer';
import { useSelector, useDispatch } from 'react-redux';
import { ModalSF } from '../../modal-sf';
import { ModalSuccess } from '../../modal-success';
import { addUserAccount } from '../../../http/userAPI';
import axios from "axios";
import { openSuccessModal } from '../../../redux/reducers/success-reducer';
import { saveUserData } from '../../../redux/reducers/user-data-reducer';
import { BaseURL } from "../../../http";
import { useTranslation } from "react-i18next";

export function ServicesPrices() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sfModal = useSelector(state => state.sfModal.sfModal);
  const successModal = useSelector(state => state.successModal.successModal);
  const userData = useSelector(state => state.userData.userData);
  const userId = useSelector(state => state.userData.userData.id);
  const [balance, setBalance] = useState([]);
  const [btnIsActive, setBtnIsActive] = useState(userData.period ? false : true);
  const [btnIsActiveAdd, setBtnIsActiveAdd] = useState(userData.addperiod ? false : true);
  const [noticeSubscription, setNoticeSubscription] = useState("");
  const [noticeSubscriptionAdd, setNoticeSubscriptionAdd] = useState("");
  function goToBalancePage() {
    dispatch(openSelectedPage("balancepage"))
  }

  async function getUserAccount() {
    try {
      const response = await axios.get(`${BaseURL}/api/user/getaccount/${userId}`)
      setBalance(response.data)
      const user = await axios.get(`${BaseURL}/api/user/data/${userId}`)
      dispatch(saveUserData(user.data));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }

  function dateParse(date) {
    let d = new Date(date);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    if (month < 10) {
      month = `0${month}`;
      return `${day}.${month}.${year}`;
    }
    else {
      return `${day}.${month}.${year}`;
    }
  }

  function timeParse(date) {
    let d = new Date(date);
    let hours = d.getHours() + 3;
    let minutes = d.getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
  }

  useEffect(() => {
    getUserAccount()
    checkSubscription()
    checkSubscriptionAdd()
  }, []);


  const [responseStatus, setResponseStatus] = useState(null);

  const initialValues = {
    account: 10,
    userId: userId,
    date: new Date().toLocaleString(),
    email: userData.email,
    type: 'withdrawal',
  }
  const initialValuesAdd = {
    account: 5,
    userId: userId,
    date: new Date().toLocaleString(),
    email: userData.email,
    type: 'withdrawaladd',
  }

  const getSubscription = async (initialValues) => {
    if (userData.balance >= initialValues.account) {
      const response = await addUserAccount(initialValues)
      setResponseStatus(response.status);
      dispatch(openSuccessModal())
      getUserAccount()


      if (initialValues.type === 'withdrawal') {
        setBtnIsActive(false)
      }

      if (initialValues.type === 'withdrawaladd') {
        setBtnIsActiveAdd(false)
      }

    } else {
      dispatch(openSuccessModal())
      setResponseStatus("err")
    }
  }

  const checkSubscription = async () => {
    if (userData.period) {
      let currentDate = new Date();
      let periodDate = new Date(userData.period);
      let dateForCheck = new Date(periodDate.setDate(periodDate.getDate() - 2));
      if (currentDate >= dateForCheck) {
        setBtnIsActive(true)
        setNoticeSubscription("Срок действия подписки подходит к концу")
      }
    }
  }

  const checkSubscriptionAdd = async () => {
    if (userData.addperiod) {
      let currentDate = new Date();
      let periodDate = new Date(userData.addperiod);
      let dateForCheck = new Date(periodDate.setDate(periodDate.getDate() - 2));
      if (currentDate >= dateForCheck) {
        setBtnIsActiveAdd(true)
        setNoticeSubscriptionAdd("Срок действия подписки подходит к концу")
      }
    }
  }

  return (
    <div className={styles.user_wrapper}>
      {sfModal && <ModalSF />}
      {(successModal && responseStatus === 200) && <ModalSuccess title={t('uspeshno')} text={t('podpiska-oformlena')} />}
      {(successModal && responseStatus === "err") && <ModalSuccess title={t('oshibka')} text={t('nedostatochno-sredstv')} />}
      <div className={styles.payment_data}>
        <div className={styles.user_data}>
          <section className={`${styles.block} ${styles.block__data} `}>
            <div className={styles.block__header}>
              <div className={styles.header_title}>
                {t('pages.services.activeServices')}
              </div>
            </div>
            <div className={styles.block__content}>
              <div className={styles.content__inner}>
                <ul className={styles.data_items}>
                  <li className={styles.data_item}>
                    <span>{t('besplatnyi')}</span>
                    <span>{t('pages.services.unlimitedPeriod')}</span>
                  </li>
                </ul>
              </div>
              {userData.period &&
                <>
                  <div className={styles.content__inner}>
                    <ul className={styles.data_items}>
                      <li className={styles.data_item}>
                        <span>{t('pages.services.Subscr')}</span>
                        <span>{t('before')} {dateParse(userData.period)}</span>
                      </li>
                    </ul>
                  </div>
                  {noticeSubscription && <p className={styles.notice_subscription}>{noticeSubscription}</p>}
                </>
              }
              {userData.addperiod &&
                <>
                  <div className={styles.content__inner}>
                    <ul className={styles.data_items}>
                      <li className={styles.data_item}>
                        <span>{t('pages.services.addSubscr')}</span>
                        <span>{t('before')} {dateParse(userData.addperiod)}</span>
                      </li>
                    </ul>
                  </div>
                  {noticeSubscriptionAdd && <p className={styles.notice_subscription}>{noticeSubscription}</p>}
                </>
              }
            </div>
          </section>
          <section className={`${styles.block} ${styles.block__data} `}>
            <div className={styles.block__header}>
              <div className={styles.header_title}>
                {t('pages.services.inactiveServices')}
              </div>
            </div>
            <div className={styles.block__content}>
              <div className={styles.content__inner}>
                <ul className={styles.data_items}>
                  <li className={styles.data_item}>
                    <span>{t('pages.services.Subscr')}</span>
                    <span>{t('pages.services.limitedPeriod')}</span>
                  </li>
                </ul>
              </div>
              {btnIsActive ?
                <div className={styles.btn} onClick={() => getSubscription(initialValues)}>
                  {t('pages.services.getSubscr')}
                </div> :
                <div className={`${styles.btn} ${styles.btn_disable} `}>
                  {t('pages.services.getSubscr')}
                </div>
              }

              {userData.work !== "Экспедитор" &&
                <>              <div className={styles.content__inner}>
                  <ul className={styles.data_items}>
                    <li className={styles.data_item}>
                      <span>{t('pages.services.addSubscr')}</span>
                      <span>{t('pages.services.limitedPeriod')}</span>
                    </li>
                  </ul>
                </div>
                  {btnIsActiveAdd ?
                    <div className={styles.btn} onClick={() => getSubscription(initialValuesAdd)}>
                      {t('pages.services.getSubscr')}
                    </div> :
                    <div className={`${styles.btn} ${styles.btn_disable} `}>
                      {t('pages.services.getSubscr')}
                    </div>
                  }</>
              }
            </div>
          </section>
        </div>
        <div className={styles.user_data}>
          <section className={`${styles.block} ${styles.block__data} `}>
            <div className={styles.block__header}>
              <div className={styles.header_title}>
                {t('balans')}
              </div>
            </div>
            <div className={`${styles.block__content} ${styles.block__content_balance} `}>
              <div className={styles.content__inner_balance}>
                <p>{t('pages.services.yourBalance')}</p>
                <p className={styles.balance}>
                  {balance.length > 0 ? userData.balance : 0} $ </p>
              </div>
              <div className={styles.btn} onClick={() => goToBalancePage()}>
                {t('popolnit-balans')}
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className={styles.payment}>
        <section className={`${styles.block} ${styles.block__data} `}>
          <div className={styles.block__header}>
            <div className={styles.header_title}>
              {t('oplaty')}
            </div>
          </div>
          <div className={`${styles.block__content} ${styles.block__content_payment} ${styles.block__content_border}`}>
            <div className={styles.payment_title}>{t('vremya')}</div>
            <div className={styles.payment_title}>{t('operaciya')}</div>
            <div className={styles.payment_title}>{t('summa')}</div>
            <div className={styles.payment_title}>{t('ostatok')}</div>
          </div>
          {
            balance.length > 0 ?
              balance.map((item, index) => {
                return (
                  <div className={`${styles.block__content} ${styles.block__content_payment} `} key={index + "acc"}>
                    <div className={styles.payment_text}>{dateParse(item.date)} {timeParse(item.date)}</div>
                    {(item.type === "withdrawal" || item.type === "withdrawaladd") ?
                      <div className={styles.payment_text}>{t('withdrawal')}</div> :
                      <div className={styles.payment_text}>{t('popolnenie')}</div>
                    }
                    <div className={styles.payment_text}>{item.account}  $</div>
                    <div className={styles.payment_text}>
                      {item.rest} $</div>
                  </div>
                )
              }) : ""
          }
        </section>
      </div>
    </div >
  )
}
export default ServicesPrices;

import styles from './home-balance.module.css';
import stylesU from '../user-page/user-page.module.css';
import logo1 from "./images/logo1.png";
import logo2 from "./images/logo2.png";
import logo3 from "./images/logo3.png";
import icon from "./images/icon-attention.png";
import { useSelector, useDispatch } from 'react-redux';
import { openSFModal } from '../../../redux/reducers/sf-reducer';
import { getInvoice } from '../../../http/userAPI';
import { ModalSF } from '../../modal-sf';
  import { useTranslation } from "react-i18next";

export function BalancePage() {

  const dispatch = useDispatch();
  const sfModal = useSelector(state => state.sfModal.sfModal);
  const userId = useSelector(state => state.userData.userData.id);
    const { t } = useTranslation();

  async function invoiceRequest(userId) {

    await getInvoice({ userId })
    dispatch(openSFModal())
  }

  return (
    <div className={styles.home_wrapper}>
      {sfModal && <ModalSF />}
      <div className={styles.home_items}>
        <div className={styles.notice__card}>
          <div className={styles.notice__title}>
            <img src={icon} alt="attention-icon" />
            <span>{t('vnimanie')}</span>
          </div>
          <div className={styles.notice__text}>
           {(t('pages.payment.text'))}
          </div>
          <div className={styles.notice__items}>
            <div className={styles.notice__item}>
              <div className={styles.btn} onClick={() => invoiceRequest(userId)}>
                {t('requestInvoice')}
              </div>
            </div>
          </div>
        </div>
        <section className={`${stylesU.block} ${stylesU.block__data} ${styles.block__items}`}>
          <div className={stylesU.block__header}>
            <div className={styles.header__title}>
              {t('typeOfPayment')}
            </div>
          </div>
          <div className={`${stylesU.block__content} ${styles.content}`}>
            <div className={stylesU.content__inner}>
              <div className={styles.payment__items}>
                <div className={styles.payment__item}>
                  <img src={logo1} alt="logo" />
                </div>
                <div className={styles.payment__item}>
                  <img src={logo2} alt="logo" />
                </div>
                <div className={styles.payment__item}>
                  <img src={logo3} alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div >
  )
}
export default BalancePage;
import { NavLink } from 'react-router-dom';
import Button from '../button/button';
import logo from './images/logo.png';
import styles from './header.module.css'
import { ButtonEntrance } from '../button-entrance';
import BoxLanguage from '../box-language/box-language';
import Navigation from '../navigation/navigation';
import { useSelector, useDispatch } from 'react-redux';
import { openBurger, closeBurger } from '../../redux/reducers/burger-reducer';
import { openRegistrForm } from '../../redux/reducers/registration-reducer';
import { ModalRegistr } from '../modal-registr';
import { RegistrationForm } from '../form-registration';
import { ModalRegCompleted } from '../modal-reg-completed';
import { ModalEntranceForm } from '../modal-entrance-form';
import { ModalPasswordRecovery } from '../modal-password-recovery';
import { ModalComfirmEmail } from '../modal-comfirm-email';
import { openEntranceModal } from '../../redux/reducers/entrance-reducer';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";


export function Header() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(state => state.language.language);

  const userAuht = useSelector(state => state.userData.auth);
  const registrationOpen = useSelector(state => state.registration.registrationOpen);
  const registrationFormOpen = useSelector(state => state.registrationForm.registrationForm);
  const registrationFinish = useSelector(state => state.registrationFinish.registrationFinish);
  const entranceModal = useSelector(state => state.entranceModal.entranceModal);
  const passwordModal = useSelector(state => state.passwordModal.passwordModal);
  const emailModal = useSelector(state => state.emailModal.emailModal);
  const navigate = useNavigate();

  function changeBurgerMenu() {
    dispatch(openBurger());
  }

  function changeRegistrationOpen() {
    navigate("/")
    dispatch(openRegistrForm());
  }

  function openEntranceModalWindow() {
    navigate("/")
    dispatch(openEntranceModal());
  }

  function openMyProfile() {
    navigate("/profile");
  }


  return (
    <header className={styles.header}>
      <div className={currentLanguage === 'per' ? `${styles.burger__btn__pers} ${styles.burger__btn}` : `${styles.burger__btn}`} onClick={changeBurgerMenu}>
        <span className={styles.burger__icon}></span>
      </div>
      <NavLink to="/" key='logo' >
        <img src={logo} alt="logo" className={currentLanguage === 'per' ? `${styles.logo__pers}` : `${styles.logo}`} onClick={() => dispatch(closeBurger())} />
      </NavLink>
      <Navigation />
      <BoxLanguage />
      {userAuht ? <Button click={openMyProfile}>{t('moi-kabinet')}</Button> :
        <>
          <ButtonEntrance click={openEntranceModalWindow}>{t('vkhod')}</ButtonEntrance>
          <Button click={changeRegistrationOpen}>{t('button.registrationText')}</Button>
        </>
      }
      {registrationOpen && <ModalRegistr />}
      {registrationFormOpen && <RegistrationForm />}
      {registrationFinish && <ModalRegCompleted />}
      {entranceModal && <ModalEntranceForm />}
      {passwordModal && <ModalPasswordRecovery />}
      {emailModal && <ModalComfirmEmail />}
    </header>
  )
}

export default Header;
import stylesGeneral from './pravila.module.css'
import styles from './rules.module.css'
import { useEffect } from 'react';

export function RulesPlacing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={stylesGeneral.wrapper}>
      <div className="inner">
        <div className={styles.rules__body}>
          <div class="wrapper">
            <div class="container">
              <div className={`${styles.rules__wrapper}`}>
                <h1 className={`${styles.rules__title}`}>Правила размещения заявок
                </h1>
                <h2 className={`${styles.items__title}`}>1. Общая информация</h2>
                <p className={`${styles.item}`}>
                  1.1. Заявка – сообщение, которое подается Пользователем в разделах «Грузы» и «Транспорт» на сайте
                  IrisTrans.com через заполнение специальной формы и содержит предложение о необходимости перевозки груза и/или
                  предложение о наличии свободного транспорта для перевозки груза.<br></br>
                  1.2. Разместить заявку на перевозку груза может любой Пользователь после прохождения регистрации на сайте
                  IrisTrans.com. Размещение заявки о наличии свободного транспорта возможно только после верификации учетной
                  записи.<br></br>
                  1.3. Любая заявка может проверяться администрацией сайта на соответствие требованиям этих Правил.<br></br>
                  1.4. По жалобе Пользователя администрация сайта проверяет заявку на соответствие требованиям действующего
                  законодательства Республики Беларусь, Пользовательского соглашения сайта IrisTrans.com и данных Правил.


                </p>
                <h2 className={`${styles.items__title}`}>2. Правила подачи заявки</h2>
                <p className={`${styles.item}`}>
                  2.1. Для размещения заявки с предложением о необходимости перевозки груза и/или предложением о наличии
                  свободного транспорта для перевозки груза, необходимо в соответствующем разделе сайта в форме добавления
                  заявки заполнить все обязательные поля.<br></br>
                  2.2. Все сведения в заявке должны совпадать со сведениями Пользователя, который подает заявку.<br></br>
                  2.3. Ответственность за достоверность информации, указанной в заявке, несет Пользователь, который разместил
                  соответствующую заявку.<br></br>
                  2.4. Все положительные и отрицательные отзывы о выполнении поданных заявок адресуются и касаются Пользователя,
                  который подал заявку, и Пользователя, который акцептировал заявку (принял ее условия).<br></br>
                  2.5. Подача заявки и ее акцептирование (принятие ее условий) может осуществляться с применением дополнительных
                  способов проверки, например, введением Пользователем дополнительных кодов, присланных ему на указанные в
                  профиле электронную почту или номер мобильного телефона.<br></br>
                  2.6. В случае невыполнения дополнительных способов проверки, заявка на сайте IrisTrans.com не размещается.


                </p>
                <h2 className={`${styles.items__title}`}>3. Ограничения при подаче заявок</h2>
                <p className={`${styles.item}`}>
                  3.1. Запрещается подавать заявку в интересах третьих лиц и/или от имени физических лиц, юридических лиц или
                  физических лиц-предпринимателей, которые не являются зарегистрированными Пользователями сайта
                  IrisTrans.com.<br></br>
                  3.2. Запрещается размещение заявок, которые содержат иные предложения, отличные от предложений о необходимости
                  перевозки груза и/или предложения о наличии свободного транспорта для перевозки груза.<br></br>
                  3.3. Запрещается размещение заявок о перевозке груза и/или наличии свободного транспорта на/из территории(й),
                  не признанных в соответствии с нормами международного законодательства.<br></br>
                  3.4. Запрещается использование ненормативной лексики и/или завуалированной ненормативной лексики (в частности,
                  но не исключительно слова (фразы) ненормативной лексики, отдельные буквы которых заменены другими
                  символами).<br></br>
                  3.5. Запрещено написание слов полностью большими буквами, за исключением официальных наименований, названий
                  и/или аббревиатур.<br></br>
                  3.6. Запрещается указывать в заявке сведения, которые не совпадают со сведениями в профиле Пользователя,
                  который подает заявку.<br></br>
                  3.7. Запрещено обновлять заявки чаще, чем 1 раз в 60 минут.<br></br>
                  3.8. Запрещено указывать один и тот же груз/транспорт в нескольких идентичных заявках (клонах).<br></br>
                  3.9. В заявках запрещено размещение контактной информации (телефонов, адресов, ICQ и так далее) в любых полях,
                  кроме поля «Контактная информация».<br></br>
                  3.10. В «Примечании» можно указывать только ту информацию, для которой не предусмотрены специальные поля в
                  форме подачи заявки.<br></br>
                  3.11. Заполнение формы заявки должно производиться исключительно в соответствии с назначениями полей.<br></br>
                  3.12. В заявке на перевозку груза необходимо указывать точные пункты погрузки/разгрузки.


                </p>
                <h2 className={`${styles.items__title}`}>4. Удаление заявок и ответственность Пользователей за нарушение Правил
                  подачи заявок</h2>
                <p className={`${styles.item}`}>
                  4.1. В случае нарушения Пользователем положений пунктов 3.1 — 3.12 раздела 3 данных Правил указанная заявка
                  будет удалена, а в отношении Пользователя могут быть применены санкции от блокировки возможности размещения
                  заявок до блокировки учетной записи. Срок лишения права размещать заявки определяется в каждом случае с учетом
                  всех обстоятельств по усмотрению IrisTrans.<br></br>
                  4.2. Разблокирование возможности подавать заявки производится администрацией сайта после рассмотрения
                  обращения Пользователя к администрации сайта с разъяснением причин нарушения требований этих данных Правил.<br></br>
                  4.3. За администрацией сайта IrisTrans.com сохраняется право удалять заявки, которые не соответствуют
                  требованиям действующего законодательства Республики Беларусь, требованиям Пользовательского соглашения сайта
                  IrisTrans.com и требованиям данных Правил, а также отказать Пользователю в разблокировании возможности
                  подавать заявки по результатам рассмотрения обращения Пользователя.
                </p>
                <h2 className={`${styles.items__title}`}>5. Удаление неактуальных заявок</h2>
                <p className={`${styles.item}`}>
                  5.1. Заявки автоматически удаляются из базы сайта по окончании даты, указанной в поле «Даты загрузки с» или в
                  поле «Даты загрузки по» формы подачи заявки.<br></br>
                  5.2. В случае, если заявка закрыта раньше указанных сроков, то Пользователь обязан самостоятельно удалять
                  закрытые или неактуальные заявки.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
export default RulesPlacing;
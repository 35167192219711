import styles from './card-main-top.module.css'
// import styles from './card-main-top-pers.module.css'
import { useSelector } from 'react-redux';

export function CardMainTop(props) {
  const currentLanguage = useSelector(state => state.language.language);
  return (
    <div className={currentLanguage=== 'per' ?  `${styles.card__pers} ${styles.card}` : `${styles.card}`}>
      <div className={styles.card__name}>
        <div className={currentLanguage=== 'per' ?  `${styles.card__header__pers} ${styles.card__header}` : `${styles.card__header}`}>
          <div className={currentLanguage=== 'per' ?  `${styles.card__icon__pers} ${styles.card__icon}` : `${styles.card__icon}`}>
            <img src={props.img} alt="icon" />
          </div>
          <div className={styles.card__header_text}>
            <div className={currentLanguage=== 'per' ?  `${styles.card__title__pers} ${styles.card__title}` : `${styles.card__title}`}>{props.title}</div>
            <div className={`${styles.card__subtitle} ${styles.hidden}`}>{props.subtitle}</div>
          </div>
        </div>
        <div className={`${styles.card__subtitle} ${styles.block}`}>{props.subtitle}</div>
      </div>
    </div>
  )
}
export default CardMainTop;
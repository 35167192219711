import styles from './home-page.module.css';
import icon from "./images/icon-attention.png"
import balance from "./images/balance.svg"
import parcel from "./images/parcel.svg"
import truck from "./images/truck.svg"
import { ReactComponent as Search } from "./images/search.svg";
import { ReactComponent as Plus } from "./images/plus.svg";
import { useDispatch, useSelector } from 'react-redux';
import { openSelectedPage } from '../../../redux/reducers/selected-page-reducer';
import { useTranslation } from "react-i18next";

export function HomePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userDataBalance = useSelector(state => state.userData.userData.balance);
  const userWork = useSelector(state => state.userData.userData.work);
  const userIsPaid = useSelector(state => state.userData.userData.isPaid);
  const countOfCargos = useSelector(state => state.cargoUsers.cargoUser);
  const countOfTransports = useSelector(state => state.transportsUser.transportsUser);

  function goToBalancePage() {
    dispatch(openSelectedPage("balancepage"))
  }

  function goToservicesPrices() {
    dispatch(openSelectedPage("servicesPrices"))
  }

  function closeAddMenu(path) {
    dispatch(openSelectedPage(path))

  }

  return (
    <div className={styles.home_wrapper}>
      <h2 className={`${styles.title} section__title `} >{t('profile.home.welcom')}</h2>
      <div className={styles.home_items}>
        {!userIsPaid &&
          <div className={styles.notice__card}>
            <div className={styles.notice__title}>
              <img src={icon} alt="attention-icon" />
              <span> {t('vnimanie')}</span>
            </div>
            <div className={`${styles.notice__text}`}>
             {t('profile.home.stagesTitle')}
            </div>
            <div className={styles.notice__items}>
              <div className={styles.notice__item}>
                <p>1.</p>
                <div className={`${styles.btn} ${styles.notice__text_disable} `} >
                  {/* {t('profile.home.stage1Text')} */}
                                  {t('dozhdites-podtverzhdeniya-na-email')}
                </div>
              </div>
              <div className={styles.notice__item}>
                <p>2.</p>
                <div className={styles.btn} onClick={() => dispatch(goToservicesPrices)}>
                  {t('profile.home.stage2Text')}
                </div>
              </div>
              <div className={styles.notice__item}>
                <p>3.</p>
                <div className={styles.btn} onClick={() => dispatch(goToservicesPrices)}>
                  {t('profile.home.stage3Text')}
                </div>
              </div>
            </div>
          </div>
        }
        <div className={styles.services}>
          <div className={styles.services__btns}>
            <div className={styles.services__title}>
              <img src={balance} alt="balance" />
              <span>{t('uslugi')}</span>
            </div>
            <div className={styles.services__btn}>
              <div className={`${styles.btn} ${styles.btn_serv} `} onClick={() => dispatch(goToBalancePage)}>
                {t('popolnit-balans')}
              </div>
              <div className={`${styles.btn} ${styles.btn_serv2} `} onClick={() => dispatch(goToservicesPrices)}>
               {t('vse-uslugi')}
              </div>
            </div>
          </div>
          <div className={styles.services__text}>{t('balans')}</div>
          <div className={styles.services__balance}>
            <div className={`${styles.btn} ${styles.btn_serv3} `}>
              {t('besplatnyi')}
            </div>
            <div className={styles.services__sum}>{userDataBalance} $</div>
          </div>
        </div>
        <div className={styles.info}>
          {userWork === "Грузоотправитель" &&
            <div className={styles.info__item}>
              <div className={styles.services__btns}>
                <div className={styles.services__title}>
                  <img src={parcel} alt="balance" />
                  <span>{t('gruz')}</span>
                </div>
                <div className={styles.services__btn}>
                  <div className={styles.info__icon} onClick={() => closeAddMenu("cargoSearch")}>
                    <Search className={styles.imfo__img}></Search>
                  </div>
                  <div className={styles.info__icon} onClick={() => closeAddMenu("cargoRegistration")}>
                    <Plus className={styles.imfo__img}></Plus>
                  </div>
                </div>
              </div>
              <div className={styles.info_count}>
                <div className={styles.count}>
                  <p className={styles.count__title}>{t('profile.details.active')}</p>
                  <p className={styles.count__number}>{countOfCargos.length}</p>
                </div>
                <div className={styles.count}>
                  <p className={styles.count__title}>{t('profile.details.planned')}</p>
                  <p className={styles.count__number}>0</p>
                </div>
              </div>
            </div>}
          {userWork === "Перевозчик" &&
            <div className={styles.info__item}>
              <div className={styles.services__btns}>
                <div className={styles.services__title}>
                  <img src={truck} alt="truck" />
                  <span>{t('transport')}</span>
                </div>
                <div className={styles.services__btn}>
                  <div className={styles.info__icon} onClick={() => closeAddMenu("transportSearch")}>
                    <Search className={styles.imfo__img}></Search>
                  </div>
                  <div className={styles.info__icon} onClick={() => closeAddMenu("transportRegistration")}>
                    <Plus className={styles.imfo__img} ></Plus>
                  </div>
                </div>
              </div>
              <div className={styles.info_count}>
                <div className={styles.count}>
                  <p className={styles.count__title}>{t('profile.details.active')}</p>
                  <p className={styles.count__number}>{countOfTransports.length}</p>
                </div>
                <div className={styles.count}>
                  <p className={styles.count__title}>{t('profile.details.planned')}</p>
                  <p className={styles.count__number}>0</p>
                </div>
              </div>
            </div>}
          {(userWork === "Экспедитор" || userWork === "Admin") &&
            <>
              <div className={styles.info__item}>
                <div className={styles.services__btns}>
                  <div className={styles.services__title}>
                    <img src={parcel} alt="balance" />
                    <span>{t('gruz')}</span>
                  </div>
                  <div className={styles.services__btn}>
                    <div className={styles.info__icon} onClick={() => closeAddMenu("cargoSearch")}>
                      <Search className={styles.imfo__img}></Search>
                    </div>
                    <div className={styles.info__icon} onClick={() => closeAddMenu("cargoRegistration")}>
                      <Plus className={styles.imfo__img}></Plus>
                    </div>
                  </div>
                </div>
                <div className={styles.info_count}>
                  <div className={styles.count}>
                    <p className={styles.count__title}>{t('profile.details.active')}</p>
                    <p className={styles.count__number}>{countOfCargos.length}</p>
                  </div>
                  <div className={styles.count}>
                    <p className={styles.count__title}>{t('profile.details.planned')}</p>
                    <p className={styles.count__number}>0</p>
                  </div>
                </div>
              </div>
              <div className={styles.info__item}>
                <div className={styles.services__btns}>
                  <div className={styles.services__title}>
                    <img src={truck} alt="truck" />
                    <span>{t('transport')}</span>
                  </div>
                  <div className={styles.services__btn}>
                    <div className={styles.info__icon} onClick={() => closeAddMenu("transportSearch")}>
                      <Search className={styles.imfo__img}></Search>
                    </div>
                    <div className={styles.info__icon} onClick={() => closeAddMenu("cargoRegistration")}>
                      <Plus className={styles.imfo__img}></Plus>
                    </div>
                  </div>
                </div>
                <div className={styles.info_count}>
                  <div className={styles.count}>
                    <p className={styles.count__title}>{t('profile.details.active')}</p>
                    <p className={styles.count__number}>{countOfTransports.length}</p>
                  </div>
                  <div className={styles.count}>
                    <p className={styles.count__title}>{t('profile.details.planned')}</p>
                    <p className={styles.count__number}>0</p>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div >
  )
}
export default HomePage;
import { NavLink } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import logo from './images/logo.png';
import styles from './header-registered.module.css'
import BoxLanguage from '../box-language/box-language';
import UserManagement from '../user-management/user-management';
import searchicon from './images/search.svg'
import messageicon from './images/message.svg'
import plusicon from './images/plus.svg'
import parcel from './images/parcel.svg'
import truck from './images/truck.svg'
import { useDispatch, useSelector } from 'react-redux';
import { openBurger, closeBurger, changeBurgerStatus } from '../../redux/reducers/burger-reducer';
import { openSelectedPage } from '../../redux/reducers/selected-page-reducer';
import { openChat } from '../../redux/reducers/chat-reducer';
import { openSearchInput, closeSearchInput } from '../../redux/reducers/search-reducer';
import { saveFoundCargo } from '../../redux/reducers/cargo-search-reducer';
import { saveFoundTransport } from '../../redux/reducers/transport-search-reducer';
import useOnClickOutside from '../../hooks/onClickOutside';
import { saveCurrentID } from '../../redux/reducers/current-id-reducer';
import { useTranslation } from "react-i18next";
import { BaseURL } from "../../http";
import axios from "axios";

export function HeaderRegistered() {
  const currentLanguage = useSelector(state => state.language.language);
  const { t } = useTranslation();
  const [isOpenAddItem, setisOpenAddItem] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const userWork = useSelector(state => state.userData.userData.work);
  const userRole = useSelector(state => state.userData.userData.role);
  const userIsActive = useSelector(state => state.userData.userData.isActivated);
  const userIsAddPeriod = useSelector(state => state.userData.userData.addperiod);
  const userPromo15 = useSelector(state => state.userData.userData.promo15);
  const searchIsOpen = useSelector(state => state.searchIsOpen.searchIsOpen);
  const countUnreadMessages = useSelector(state => state.unreadMessages.countUnreadMessages);
  const dispatch = useDispatch();
  const searchEl = useRef();
  const registrationEl = useRef();

  useOnClickOutside(searchEl, () => {
    if (searchIsOpen) {
      dispatch(closeSearchInput(false));
    }
  });
  useOnClickOutside(registrationEl, () => {
    if (isOpenAddItem) {
      setisOpenAddItem(false)
    }
  });

  function changeBurgerMenu() {

    dispatch(openBurger());
  }

  const burgerElement = useRef();


  function closeAddMenu(path) {
    dispatch(openSelectedPage(path))
    setisOpenAddItem(false)
    dispatch(saveCurrentID(""))

  }


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (burgerElement.current && !burgerElement.current.contains(event.target)) {
        dispatch(changeBurgerStatus());
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);



  async function getSearchCargos() {
    try {
      if (!searchIsOpen) {
        dispatch(openSearchInput());
      } else if (searchValue) {
        if (userWork === "Грузоотправитель") {
          const response = await axios.get(`${BaseURL}/api/transport/searchlist/${searchValue}`)

          dispatch(saveFoundTransport(response.data));
          dispatch(openSelectedPage("transportSearch"))
          setSearchValue("")
        } if (userWork === "Перевозчик") {
          const response = await axios.get(`${BaseURL}/api/cargo/searchlist/${searchValue}`)
          dispatch(saveFoundCargo(response.data));
          dispatch(openSelectedPage("cargoSearch"))
          setSearchValue("")
        } if (userWork === "Экспедитор" || userWork === "Admin") {
          const responseCargo = await axios.get(`${BaseURL}/api/cargo/searchlist/${searchValue}`)
          dispatch(saveFoundCargo(responseCargo.data));
          dispatch(saveFoundTransport([]));
          dispatch(openSelectedPage("cargoSearch"))
          if (responseCargo.data.length === 0) {
            const responseTransport = await axios.get(`${BaseURL}/api/transport/searchlist/${searchValue}`)
            dispatch(saveFoundTransport(responseTransport.data));
            window.scrollTo({
              top: 1000,
              behavior: "smooth"
            });
            if (responseTransport.data.length > 0) {
              dispatch(saveFoundCargo([]));
            }
            dispatch(openSelectedPage("transportSearch"))
            window.scrollTo({
              top: 1000,
              behavior: "smooth"
            });
          } else
            setSearchValue("")
        }
        dispatch(closeSearchInput(false));
        window.scrollTo({
          top: 1000,
          behavior: "smooth"
        });
      } else {
        dispatch(closeSearchInput(false));
      }
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }


  function openSearch() {
    if (!searchIsOpen) {
      dispatch(openSearchInput());
    } else {
      dispatch(closeSearchInput())
    }
  }


  async function searchCargo() {
    try {
      dispatch(saveCurrentID(""))
      if (searchValue) {
        const response = await axios.get(`${BaseURL}/api/cargo/searchlist/${searchValue}`)
        if (response.data.length === 0) {
          dispatch(openSelectedPage("cargoIsEmpty"))
          dispatch(saveFoundCargo([]));
        } else {

          dispatch(saveFoundCargo(response.data));
          dispatch(openSelectedPage("cargoSearch"))
          setSearchValue("")
        }
        dispatch(closeSearchInput(false));
        window.scrollTo({
          top: 1000,
          behavior: "smooth"
        });

      }
      else {
        dispatch(closeSearchInput(false));
      }

    } catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }

  }

  async function searchTransport() {
    dispatch(saveCurrentID(""))
    try {
      if (searchValue) {
        const response = await axios.get(`${BaseURL}/api/transport/searchlist/${searchValue}`)
        if (response.data.length === 0) {
          dispatch(openSelectedPage("transportIsEmpty"))
          dispatch(saveFoundTransport([]));
        } else {
          dispatch(saveFoundTransport(response.data));
          dispatch(openSelectedPage("transportSearch"))
          setSearchValue("")
        }
        dispatch(closeSearchInput(false));
        window.scrollTo({
          top: 1000,
          behavior: "smooth"
        });
      }
      else {
        dispatch(closeSearchInput(false));
      }
    } catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }

  return (
    <>
      <header className={styles.header}>
        <div className={styles.burger__btn} onClick={changeBurgerMenu} ref={burgerElement}>
          <span className={styles.burger__icon}></span>
        </div>
        <NavLink to="/" key='logo' >
          <img src={logo} alt="logo" className={styles.logo} onClick={() => dispatch(closeBurger())} />
        </NavLink>

        <UserManagement />
        <BoxLanguage />
        <div className={styles.header__functional_items}>

          <div className={styles.header__search_block} ref={searchEl}>
            {searchIsOpen &&
              <input className={currentLanguage === 'per' ? `${styles.header__search_input__pers} ${styles.header__search_input}` : `${styles.header__search_input} `} type="text" name="search" id="search" placeholder={t('placeholder.header.search')} value={searchValue} onChange={(event) => setSearchValue(event.target.value)}
              />
            }
            {searchIsOpen &&
              <input className={currentLanguage === 'per' ? `${styles.header__search_input__pers} ${styles.header__search_input}` : `${styles.header__search_input} `} type="text" name="search" id="search" placeholder={t('placeholder.header.search')} value={searchValue} onChange={(event) => setSearchValue(event.target.value)}
              />
            }
            <div className={styles.header__functional_item} onClick={() => openSearch()}>
              <img src={searchicon} alt="search" />
            </div>
            {searchIsOpen &&
              <div className={styles.add_items} ref={registrationEl}>
                {(userWork === "Перевозчик" && !userIsAddPeriod) &&
                  <div className={styles.add_item} onClick={() => searchCargo()}>
                    <div className={currentLanguage === 'per' ? `${styles.add_item_img__pers} ${styles.add_item_img}` : `${styles.add_item_img} `}>
                      <img src={parcel} alt="parcel" />
                    </div>
                    {t('gruz')}
                  </div>}
                {(userWork === "Грузоотправитель" && !userIsAddPeriod) &&
                  <div className={styles.add_item} onClick={() => searchTransport()}>
                    <div className={currentLanguage === 'per' ? `${styles.add_item_img__pers} ${styles.add_item_img}` : `${styles.add_item_img} `}>
                      <img src={truck} alt="truck" />
                    </div>
                    {t('transport')}
                  </div>}
                {(userWork === "Экспедитор" || userWork === "Admin" || userIsAddPeriod || userPromo15) &&
                  <>
                    <div className={styles.add_item} onClick={() => searchCargo()}>
                      <div className={currentLanguage === 'per' ? `${styles.add_item_img__pers} ${styles.add_item_img}` : `${styles.add_item_img} `}>
                        <img src={parcel} alt="parcel" />
                      </div>
                      {t('gruz')}
                    </div>
                    <div className={styles.add_item} onClick={() => searchTransport()}>
                      <div className={currentLanguage === 'per' ? `${styles.add_item_img__pers} ${styles.add_item_img}` : `${styles.add_item_img} `}>
                        <img src={truck} alt="truck" />
                      </div>
                      {t('transport')}
                    </div>
                  </>
                }
              </div>}
          </div>
          <div className={styles.header__functional_item} onClick={() => dispatch(openChat())}>
            {(countUnreadMessages > 0) &&
              <div className={styles.header__new_message}>
                {countUnreadMessages}
              </div>}
            <img src={messageicon} alt="message" />
          </div>
          <div className={styles.header__functional_item} onClick={() => (setisOpenAddItem(!isOpenAddItem))}>
            <img src={plusicon} alt="add" />
          </div>
          {isOpenAddItem &&
            <div className={styles.add_items} ref={registrationEl}>
              {userWork === "Грузоотправитель" &&
                <div className={styles.add_item} onClick={() => closeAddMenu("cargoRegistration")}>
                  <div className={currentLanguage === 'per' ? `${styles.add_item_img__pers} ${styles.add_item_img}` : `${styles.add_item_img} `}>
                    <img src={parcel} alt="parcel" />
                  </div>
                  {t('gruz')}
                </div>}
              {userWork === "Перевозчик" &&
                <div className={styles.add_item} onClick={() => closeAddMenu("transportRegistration")}>
                  <div className={currentLanguage === 'per' ? `${styles.add_item_img__pers} ${styles.add_item_img}` : `${styles.add_item_img} `}>
                    <img src={truck} alt="truck" />
                  </div>
                  {t('transport')}
                </div>}
              {(userWork === "Экспедитор" || userWork === "Admin") &&
                <>
                  <div className={styles.add_item} onClick={() => closeAddMenu("cargoRegistration")}>
                    <div className={currentLanguage === 'per' ? `${styles.add_item_img__pers} ${styles.add_item_img}` : `${styles.add_item_img} `}>
                      <img src={parcel} alt="parcel" />
                    </div>
                    {t('gruz')}
                  </div>
                  <div className={styles.add_item} onClick={() => closeAddMenu("transportRegistration")}>
                    <div className={currentLanguage === 'per' ? `${styles.add_item_img__pers} ${styles.add_item_img}` : `${styles.add_item_img} `}>
                      <img src={truck} alt="truck" />
                    </div>
                    {t('transport')}
                  </div>
                </>
              }
            </div>}
        </div>

      </header>
      {(userRole === "Admin" || userIsActive) ? "" : <div className={styles.block__header_note}>{t('waitNote')}</div>}
    </>
  )
}

export default HeaderRegistered;

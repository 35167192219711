import stylesGeneral from './pravila.module.css'
import styles from './rules.module.css'
import { useEffect } from 'react';

export function RulesVerifacationEn() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={stylesGeneral.wrapper}>
      <div className="inner">
        <div className={styles.rules__body}>
          <div class="wrapper">
            <div class="container">
              <div className={`${styles.rules__wrapper}`}>
                <h1 className={`${styles.rules__title}`}>Account Validation Rules
                </h1>
                <h2 className={`${styles.items__title}`}>General Rules for Profile Verification</h2>
                <div>
                  <p className={`${styles.item}`}>Your registration and/or use of an account implies your understanding that We (IrisTrans) may naturally match the data we have about your Firm (obtained both from you and from other legitimate sources) with the data you provided in your account. Further, We may ultimately make decisions regarding the validity of Your account based on such reconciliations. In addition, we intend to specifically highlight accounts that meet the credibility requirements of our Special Verification Criteria (“Profile Verification”).</p>
                  <p className={`${styles.item}`}>Profile verification implies public confirmation by Us, for the information of Your real and potential partners, of the authenticity of Your account. By checking the User’s profile, we do not vouch for the quality of the cargo transportation services provided by the Company and/or the “solvency” of the Company as a transportation customer (to assess the business reliability/efficiency of working with the Company, reviews are provided on the Site), however, by checking the profile we record the reliability of the key data specified in the Company account.
                  </p>
                  <p className={`${styles.item}`}>
                    Profile verification is a free Service.
                  </p>
                  <p className={`${styles.item}`}>If for any reason You, having met the conditions for passing the Profile Verification, wish to refuse to assign Your account the status of successfully completing the Profile Verification, please notify Us by sending a message to info@iristrans.com .
                  </p>
                  <p className={`${styles.item}`}>If your payment details for some good faith reason do not match the data from your account, or if you have not yet had time to evaluate the benefits of paid IrisTrans Services, you are still not deprived of the opportunity to undergo Profile Verification by sending Us some documents.
                  </p>
                  <p className={`${styles.item}`}>Successful completion of Profile Verification does not relieve you of responsibility for the integrity of your account, which is the default for all Users. The corresponding obligations of the User and the possible consequences of registering and/or using an inauthentic account are described in section 4 of the main text of the User Agreement.<br></br>
                    We do not place any specific restrictions on any dates and/or periods associated with the validity of your Profile Verification status.</p>
                  <p className={`${styles.item}`}>It is your responsibility to ensure that your account is up to date after some time has passed after Profile Verification. You have the right to update your data at any time by completing the Profile Verification procedure in any way convenient for you as described below.
                  </p>
                  <p className={`${styles.item}`}>We reserve the right to additionally check the accuracy of the data associated with your account, in particular, the validity of the registration of your Company, as well as the compliance of the name and registration (identification, tax) code (number) of your Company with available official sources, and to refuse you in Profile Check or subsequently cancel your status if inconsistencies are identified.
                  </p>
                  <p className={`${styles.item}`}>The grounds for refusal to provide and grounds for cancellation of the status of successful completion of the Profile Verification are (taking into account the fact that the corresponding violations are also grounds for blocking the account):</p>
                  <p className={`${styles.item}`}>1. revealed at any time, by any legitimate means, the inaccuracy of data in the account regarding Profile Verification; </p>
                  <p className={`${styles.item}`}>2. violation of the basic rules for using the account set forth in the User Agreement.
                  </p>
                  <p className={`${styles.item}`}>Profile Verification requires that Your account information verified by Us should not be changed without additional confirmation to ensure the validity of Your status as a successfully completed Profile Verification. We reserve the right, at Our discretion:
                    <br></br>
                    1. establish a pre-moderation mode for changes to data about the Company in the account applied for Profile Verification and/or with the status of a successfully completed Profile Verification (you will be blocked from independently changing the values ​​of the corresponding fields of registration data); or <br></br>
                    2. automatically remove the status of a successfully completed Profile Verification if you make changes.
                  </p>
                  <p className={`${styles.item}`}>We reserve the right, at our discretion, to make reservations (cautions) to other Users about the guarantees of accuracy provided by certain account verification mechanisms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
export default RulesVerifacationEn;
import styles from './sidebar-full.module.css';
import arrow from './images/arrow.png';
import user from './images/user.png'
import parcel from './images/parcel.svg'
import truck from './images/truck.svg'
import balance from './images/balance.svg'
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openSelectedPage } from '../../../redux/reducers/selected-page-reducer';
import { openBurger, changeBurgerStatus, closeBurger } from '../../../redux/reducers/burger-reducer';
import { logoutUserData } from '../../../redux/reducers/user-data-reducer';
import { saveFoundCargo } from '../../../redux/reducers/cargo-search-reducer';
import { saveCurrentImg } from '../../../redux/reducers/current-img-reducer';
import { saveCurrentID } from '../../../redux/reducers/current-id-reducer';
import useOnClickOutside from '../../../hooks/onClickOutside';
import AuthService from '../../../services/AuthService';
import { useTranslation } from "react-i18next";
import { getAllUsersAdmin } from '../../../http/userAPI';
import { setUsers, setUsersInvoice } from '../../../redux/reducers/users-list-reducer';
import axios from "axios";
import { BaseURL } from "../../../http";

export function SidebarFull() {
  const burger = useSelector(state => state.burger.burger);

  const sidebarFullElement = useRef();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = useSelector(state => state.language.language);
  const userData = useSelector(state => state.userData.userData);
  const userPaidAdd = useSelector(state => state.userData.userData.addperiod);
  const userPromo15 = useSelector(state => state.userData.userData.promo15);
  const [isItem1MenuOpen, setIsItem1MenuOpen] = useState(true);
  const [isItem2MenuOpen, setIsItem2MenuOpen] = useState(true);
  const [isItem3MenuOpen, setIsItem3MenuOpen] = useState(true);
  const userWork = useSelector(state => state.userData.userData.work);
  let stylesMenu = currentLanguage === 'per' ? `${styles.menu__pers} ${styles.menu}` : `${styles.menu} `;
  const dispatch = useDispatch();


  async function getAllUsers() {
    try {
      const response = await getAllUsersAdmin();
      const resp = response.data.rows.reverse();
      dispatch(setUsers(resp));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }

  async function getAllUsersInvoice() {
    try {
      const response = await axios.get(`${BaseURL}/api/user/invoice-list`)
      const resp = response.data.reverse();
      dispatch(setUsersInvoice(resp));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }


  function openMenuUser() {
    setIsItem1MenuOpen(!isItem1MenuOpen);
  }
  function openMenuTransport() {
    setIsItem2MenuOpen(!isItem2MenuOpen);
  }
  function openMenuCargo() {
    setIsItem3MenuOpen(!isItem3MenuOpen);
  }

  function goToMyPage() {
    dispatch(openSelectedPage("mypage"))
    // dispatch(closeBurger());
    dispatch(openBurger());
  }

  function goToUsersPage() {
    getAllUsers()
    dispatch(openSelectedPage("userspage"))
    // dispatch(closeBurger());
    dispatch(saveCurrentID(""));
    dispatch(openBurger());
  }
  function goToUsersInvoicePage() {
    getAllUsersInvoice()
    dispatch(openSelectedPage("usersInvoicePage"))
    // dispatch(closeBurger());
    dispatch(saveCurrentID(""));
    dispatch(openBurger());
  }

  function goToBalancePage() {
    dispatch(openSelectedPage("balancepage"))
    // dispatch(closeBurger());
    dispatch(openBurger());
  }
  function goToCargoPage() {
    dispatch(openSelectedPage("cargoSearch"))
    // dispatch(closeBurger());
    dispatch(saveCurrentID(""))
    dispatch(openBurger());
  }
  function goToTransportPage() {
    dispatch(openSelectedPage("transportSearch"))
    // dispatch(closeBurger());
    dispatch(saveCurrentID(""));
    dispatch(openBurger());
  }
  function goToAddTarif() {
    // openMenuBalance()
    dispatch(openSelectedPage("addTarif"))
    dispatch(saveCurrentID(""));
    dispatch(openBurger());
  }
  function goToTransportRegistration() {
    dispatch(openSelectedPage("transportRegistration"))
    // dispatch(closeBurger());
    dispatch(saveCurrentID(""));
    dispatch(openBurger());
  }
  function goToCargoRegistration() {
    dispatch(openSelectedPage("cargoRegistration"))
    // dispatch(closeBurger());
    dispatch(saveCurrentID(""));
    dispatch(openBurger());
  }
  function goToservicesPrices() {
    dispatch(openSelectedPage("servicesPrices"))
    // dispatch(closeBurger());
    dispatch(saveCurrentID(""));
    dispatch(openBurger());
  }


  const logoutUser = async () => {
    try {
      await AuthService.logout();
      localStorage.removeItem('token');
      dispatch(logoutUserData());
      dispatch(saveFoundCargo(""))
      dispatch(saveCurrentImg(""))
      dispatch(openSelectedPage("homepage"))
      navigate("/");
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }

  return (
    <div className={styles.wrapper} ref={sidebarFullElement}>
      <div className={styles.items}>
        {userWork === "Грузоотправитель" &&
          <>
            {(userPaidAdd || userPromo15) ?
              <>
                <div className={styles.item_title} onClick={() => openMenuTransport()}>
                  <div className={isItem2MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`}>
                    <img src={truck} alt="truck" />
                  </div>
                  <div className={`${styles.item_name}`}>
                    {t('carrier')}
                  </div>
                  <img className={isItem2MenuOpen ? `${styles.item_arrow_up} ` : `${styles.item_arrow}`} src={arrow} alt="arrow" />
                </div>
                <div className={isItem2MenuOpen ? stylesMenu : `${styles.menu__hidden}`}>
                  <div className={styles.menu__item} onClick={() => dispatch(goToCargoPage)}>
                    {t('cargo-search')}
                  </div>
                  <div className={styles.menu__item} onClick={() => dispatch(goToTransportRegistration)}>
                    {t('my-transport')}
                  </div>
                </div>
              </> :
              <div className={styles.item_title} onClick={() => goToAddTarif()}>
                <div className={isItem2MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`}>
                  <img src={truck} alt="truck" />
                </div>
                <div className={`${styles.item_name}`}>
                  {t('carrier')}
                </div>
                <img className={isItem2MenuOpen ? `${styles.item_arrow_up} ` : `${styles.item_arrow}`} src={arrow} alt="arrow" />
              </div>
            }
          </>
        }

        {userWork === "Грузоотправитель" &&
          <>
            <div className={styles.item_title} onClick={() => openMenuCargo()}>
              <div className={isItem3MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`}>
                <img src={parcel} alt="parcel" />
              </div>
              <div className={`${styles.item_name}`}>
                {t('shipper')}
              </div>
              <img className={isItem3MenuOpen ? `${styles.item_arrow_up} ` : `${styles.item_arrow}`} src={arrow} alt="arrow" />
            </div>
            <div className={isItem3MenuOpen ? stylesMenu : `${styles.menu__hidden}`}>
              <div className={styles.menu__item} onClick={() => dispatch(goToTransportPage)}>
                {t('transport-search')}
              </div>
              <div className={styles.menu__item} onClick={() => dispatch(goToCargoRegistration)}>
                {t('my-cargo')}
              </div>
            </div>
          </>
        }
        {userWork === "Перевозчик" &&
          <>
            <div className={styles.item_title} onClick={() => openMenuTransport()}>
              <div className={isItem2MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`}>
                <img src={truck} alt="truck" />
              </div>
              <div className={`${styles.item_name}`}>
                {t('carrier')}
              </div>
              <img className={isItem2MenuOpen ? `${styles.item_arrow_up} ` : `${styles.item_arrow}`} src={arrow} alt="arrow" />
            </div>
            <div className={isItem2MenuOpen ? stylesMenu : `${styles.menu__hidden}`}>
              <div className={styles.menu__item} onClick={() => dispatch(goToCargoPage)}>
                {t('cargo-search')}
              </div>
              <div className={styles.menu__item} onClick={() => dispatch(goToTransportRegistration)}>
                {t('my-transport')}
              </div>
            </div>
          </>
        }
        {userWork === "Перевозчик" &&
          <>
            {(userPaidAdd || userPromo15) ?
              <>
                <div className={styles.item_title} onClick={() => openMenuCargo()}>
                  <div className={isItem3MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`}>
                    <img src={parcel} alt="parcel" />
                  </div>
                  <div className={`${styles.item_name}`}>
                    {t('shipper')}
                  </div>
                  <img className={isItem3MenuOpen ? `${styles.item_arrow_up} ` : `${styles.item_arrow}`} src={arrow} alt="arrow" />
                </div>
                <div className={isItem3MenuOpen ? stylesMenu : `${styles.menu__hidden}`}>
                  <div className={styles.menu__item} onClick={() => dispatch(goToTransportPage)}>
                    {t('transport-search')}
                  </div>
                  <div className={styles.menu__item} onClick={() => dispatch(goToCargoRegistration)}>
                    {t('my-cargo')}
                  </div>
                </div>
              </>
              :
              <div className={styles.item_title} onClick={() => goToAddTarif()}>
                <div className={isItem3MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`}>
                  <img src={parcel} alt="parcel" />
                </div>
                <div className={`${styles.item_name}`}>
                  {t('shipper')}
                </div>
                <img className={isItem3MenuOpen ? `${styles.item_arrow_up} ` : `${styles.item_arrow}`} src={arrow} alt="arrow" />
              </div>

            }
          </>
        }
        {(userWork === "Экспедитор" || userWork === "Admin") &&
          <>
            <div className={styles.item_title} onClick={() => openMenuTransport()}>
              <div className={isItem2MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`}>
                <img src={truck} alt="truck" />
              </div>
              <div className={`${styles.item_name}`}>
                {t('carrier')}
              </div>
              <img className={isItem2MenuOpen ? `${styles.item_arrow_up} ` : `${styles.item_arrow}`} src={arrow} alt="arrow" />
            </div>
            <div className={isItem2MenuOpen ? stylesMenu : `${styles.menu__hidden}`}>
              <div className={styles.menu__item} onClick={() => dispatch(goToCargoPage)}>
                {t('cargo-search')}
              </div>
              <div className={styles.menu__item} onClick={() => dispatch(goToTransportRegistration)}>
                {t('my-transport')}
              </div>
            </div>
          </>
        }
        {(userWork === "Экспедитор" || userWork === "Admin") &&
          <>
            <div className={styles.item_title} onClick={() => openMenuCargo()}>
              <div className={isItem3MenuOpen ? `${styles.item} ${styles.item_bg}` : `${styles.item}`}>
                <img src={parcel} alt="parcel" />
              </div>
              <div className={`${styles.item_name}`}>
                {t('shipper')}
              </div>
              <img className={isItem3MenuOpen ? `${styles.item_arrow_up} ` : `${styles.item_arrow}`} src={arrow} alt="arrow" />
            </div>
            <div className={isItem3MenuOpen ? stylesMenu : `${styles.menu__hidden}`}>
              <div className={styles.menu__item} onClick={() => dispatch(goToTransportPage)}>
                {t('transport-search')}
              </div>
              <div className={styles.menu__item} onClick={() => dispatch(goToCargoRegistration)}>
                {t('my-cargo')}
              </div>
            </div>
          </>
        }
        {(userData.role !== "Admin") &&
          <div className={styles.item_title} >
            <div className={styles.item}>
              <img src={balance} alt="balance" />
            </div>
            <div className={`${styles.item_name}`} onClick={() => dispatch(goToservicesPrices)}>
              {t('uslugi-i-ceny')}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default SidebarFull;

import styles from './details.module.css';
import { openSelectedPage } from '../../../redux/reducers/selected-page-reducer';
import { useSelector, useDispatch } from 'react-redux';
import { saveCurrentSelectedUser } from '../../../redux/reducers/current-selected-user-reducer';
import axios from "axios";
import icon from './images/icon.svg'
import like from './images/like.png'
import antilike from './images/antilike.png'
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';
import { BaseURL } from "../../../http";
import telegram from "./images/telegram.png";
import whatsapp from "./images/whatsapp.png";
import skype from "./images/skype.svg";
import { NavLink } from 'react-router-dom';
export function Details(props) {

  const { t } = useTranslation();
  const currentLanguage = useSelector(state => state.language.language);
  const myCargoList = useSelector(state => state.cargoUsers.cargoUser);
  const foundCargo = useSelector(state => state.cargoFound.cargoFound);
  let resultsData = (props.typeOfAction === "searchCargo") ? foundCargo : myCargoList
  const dispatch = useDispatch();
  let currentItemDetails = resultsData.find(item => item.id === props.currentID);
  const userData = useSelector(state => state.userData.userData);
  let currentItemUserId = currentItemDetails.user.id;

  let variable = {
    likesRecipientId: currentItemUserId
  }

  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);


  async function getLikes() {
    try {
      const response = await axios.post(`${BaseURL}/api/user/like`, variable)
      setLikes(response.data.length)
    } catch (e) {
      console.log(e.response?.data?.message)
    }
  }
  async function getDislikes() {
    try {
      const response = await axios.post(`${BaseURL}/api/user/dislike`, variable)
      setDislikes(response.data.length)
    } catch (e) {
      console.log(e.response?.data?.message)
    }
  }

  useEffect(() => {
    getLikes();
    getDislikes();
  }
  )

  function goToBalancePage() {
    dispatch(openSelectedPage("balancepage"))
  }
  function goToUserPage() {
    dispatch(openSelectedPage("anotherUserPage"))
    dispatch(saveCurrentSelectedUser(currentItemDetails.user))
  }

  function dateParse(date) {
    let d = new Date(date);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    if (month < 10) {
      month = `0${month}`;
      return `${day}.${month}.${year}`;
    }
    else {
      return `${day}.${month}.${year}`;
    }
  }


  let transportTypeLang = function (transport) {
    if (currentLanguage === "ru") {
      return transport.name
    }
    if (currentLanguage === "en") {
      return transport.english
    }
    if (currentLanguage === "per") {
      return transport.persian
    }
  }

  let docTypeLang = function (doc) {
    if (currentLanguage === "ru") {
      return doc.name
    }
    if (currentLanguage === "en") {
      return doc.english
    }
    if (currentLanguage === "per") {
      return doc.persian
    }
  }
  let loadingTypeLang = function (loading) {
    if (currentLanguage === "ru") {
      return loading.name
    }
    if (currentLanguage === "en") {
      return loading.english
    }
    if (currentLanguage === "per") {
      return loading.persian
    }
  }
  let paymentTypeLang = function (payment) {
    if (currentLanguage === "ru") {
      return payment.name
    }
    if (currentLanguage === "en") {
      return payment.english
    }
    if (currentLanguage === "per") {
      return payment.persian
    }
  }



  return (
    <>
      {currentItemDetails &&
        <div className={styles.user_wrapper}>
          <div className={styles.payment}>
            <section className={`${styles.block} ${styles.block__data} `}>
              <div className={styles.block__header}>
                <div className={styles.header_title}>
                  {t('details.info')}
                </div>
              </div>
            </section>
          </div>
          <div className={styles.payment_data}>
            <div className={styles.user_data}>
              <section className={`${styles.block} ${styles.block__data} `}>
                <div className={styles.block__header}>
                  <div className={styles.header_title}>
                    {t('details.general')}
                  </div>
                </div>
                <div className={styles.block__content}>
                  <div className={styles.content__inner}>
                    <ul className={styles.data_items}>
                      <li className={currentLanguage === 'per' ? `${styles.data_item__pers}` : `${styles.data_item}`}>
                        <p>{t('details.date')}</p>
                        <p className={styles.item_text}>{dateParse(currentItemDetails.createdAt)}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section className={`${styles.block} ${styles.block__data} `}>
                <div className={styles.block__header}>
                  <div className={styles.header_title}>
                    {t('details.route')}
                  </div>
                </div>
                <div className={styles.block__content}>
                  <div className={styles.content__inner}>
                    <ul className={styles.data_items}>
                      <li className={currentLanguage === 'per' ? `${styles.data_item__pers}` : `${styles.data_item}`}>
                        <p>{t('details.loading')}</p>
                        <p className={styles.item_text}>{currentItemDetails.cityfrom}</p>
                      </li>
                      <li className={currentLanguage === 'per' ? `${styles.data_item__pers}` : `${styles.data_item}`}>
                        <p>{t('details.Unloading')}</p>
                        <p className={styles.item_text}>{currentItemDetails.cityto}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section className={`${styles.block} ${styles.block__data} `}>
                <div className={styles.block__header}>
                  <div className={styles.header_title}>
                    {t('tables.result.cargo')}
                  </div>
                </div>
                <div className={styles.block__content}>
                  <div className={styles.content__inner}>
                    <ul className={styles.data_items}>
                      <li className={currentLanguage === 'per' ? `${styles.data_item__pers}` : `${styles.data_item}`}>
                        <p>{t('details.dateOfDownload')}</p>
                        <p className={styles.item_text}>  {(currentItemDetails.calendar !== null) ? dateParse(currentItemDetails.calendar) : "-"}</p>
                      </li>
                      <li className={currentLanguage === 'per' ? `${styles.data_item__pers}` : `${styles.data_item}`}>
                        <p>{t('placeholder.table.typeOftransport')}</p>
                        <p className={styles.item_text}> {(currentItemDetails.transporttype && currentItemDetails.transporttype.length > 0) ?
                          currentItemDetails.transporttype.map((transport, index) => {
                            if (index === currentItemDetails.transporttype.length - 1) {
                              return (
                                <span key={index + transport.id}>{transportTypeLang(transport) || transport.value} </span>
                              )
                            }
                            return (
                              <span key={index + transport.id}>{transportTypeLang(transport) || transport.value}, </span>
                            )
                          })
                          : "-"
                        }</p>
                      </li>
                      <li className={currentLanguage === 'per' ? `${styles.data_item__pers}` : `${styles.data_item}`}>
                        <p> {t('tables.result.cargo')}</p>
                        <p className={styles.item_text}> {(currentItemDetails.danger) ? currentItemDetails.danger : ""} {(currentItemDetails.typecargo) ? currentItemDetails.typecargo : ""}</p>

                      </li>
                      <li className={currentLanguage === 'per' ? `${styles.data_item__pers}` : `${styles.data_item}`}>
                        <p>{t('details.weight')}</p>
                        <p className={styles.item_text}> {(currentItemDetails.weighMax > 0) ? `${currentItemDetails.weighMax} т` : "-"}</p>
                      </li>
                      <li className={currentLanguage === 'per' ? `${styles.data_item__pers}` : `${styles.data_item}`}>
                        <p>{t('header.menu.payment')}</p>
                        <p className={styles.item_text}>    {(currentItemDetails.paymenttype && currentItemDetails.paymenttype.length > 0) ?
                          currentItemDetails.paymenttype.map((payment, index) => {
                            if (index === currentItemDetails.paymenttype.length - 1) {
                              return (
                                <span key={index + payment.id}>{paymentTypeLang(payment) || payment.value}</span>
                              )
                            }
                            return (
                              <span key={index + payment.id}>{paymentTypeLang(payment) || payment.value}, </span>
                            )
                          })
                          : "-"
                        }</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            </div>
            <div className={styles.user_data}>
              <section className={`${styles.block} ${styles.block__data} `}>
                <div className={styles.block__header}>
                  <div className={styles.header_title}>
                    {t('details.contact')}
                  </div>
                </div>
                {((userData.promo15 || userData.role === "Admin") || (userData.isPaid || userData.role === "Admin")) ?
                  <div className={`${styles.block__content} ${styles.block__content_contacts} `}>
                    <div className={styles.content__inner}>
                      <ul className={styles.data_items}>
                        <li className={currentLanguage === 'per' ? `${styles.data_item__pers}` : `${styles.data_item}`}>
                          <p>{t('details.name')}</p>
                          <p className={`${styles.item_text} ${styles.item_text_cursor}`} onClick={() => goToUserPage()}>{currentItemDetails.user.orgname}</p>
                        </li>
                        <li className={currentLanguage === 'per' ? `${styles.data_item__pers}` : `${styles.data_item}`}>
                          <p>{t('profile.details.contactPerson')}</p>
                          <div className={`${styles.item_text} ${styles.item_text_cursor}`} onClick={() => goToUserPage()}>{currentItemDetails.user.name}<div className={styles.item_text_inner}><div>{currentItemDetails.user.number}</div><div></div></div></div>
                          <div className={styles.social_links}>
                            {currentItemDetails.user.telegram &&
                              <NavLink className={styles.user_telegram} to={"https://t.me/" + currentItemDetails.user.telegram} target="_blank">
                                <img src={telegram} alt="telegram" />
                              </NavLink>
                            }
                            {currentItemDetails.user.whatsapp &&
                              <NavLink className={styles.user_whatsapp} to={"https://wa.me/" + currentItemDetails.user.whatsapp} target="_blank">
                                <img src={whatsapp} alt="whatsapp" />
                              </NavLink>}
                            {currentItemDetails.user.skype &&
                              <NavLink className={styles.user_skype} to={"skype:" + currentItemDetails.user.skype} target="_blank">
                                <img src={skype} alt="skype" />
                              </NavLink>
                            }
                          </div>
                        </li>
                        <li className={currentLanguage === 'per' ? `${styles.data_item__pers}` : `${styles.data_item}`}>
                          <p>{t('reviews')}</p>
                          <div className={styles.item_text_likes}>
                            <div className={`${styles.item_likes} ${styles.item_antilike}`}>
                              <img src={antilike} alt="antilike" className={styles.like_img} /> {likes}</div>
                            <div className={styles.item_likes_block}>
                              <div className={`${styles.item_likes} ${styles.item_like}`}>
                                <img src={like} alt="like" className={styles.like_img} /> {dislikes}</div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div> : <div className={`${styles.block__content} ${styles.block__content_contacts} `}>
                    <div className={styles.content__inner_contacts}>
                      <img src={icon} alt="icon" />
                      <span>{t('notifications.contactsNotAval')}</span>
                    </div>
                    <div className={styles.btn} onClick={() => goToBalancePage()}>
                      {t('pages.services.getSubscr')}
                    </div>
                  </div>
                }
              </section>
            </div>
          </div>
        </div >}
    </>
  )
}
export default Details;
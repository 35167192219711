import styles from './rules.module.css';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

export function Agreement() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.rules__body}>
      <div className={`${styles.rules__wrapper}`}>
        <h1 className={`${styles.rules__title}`}>Terms of use
        </h1>
        <h2 className={`${styles.items__title}`}>1. Definitions</h2>
        <p className={`${styles.item}`}>
          
          1.1. <b>User</b> or  <b>You </b> an individual, a company and its representatives accessing the Site and / or using the Services.<br></br>
          1.2. <b>Individual </b> — a person with civil legal capacity and full civil capacity, who is not a business entity, uses the Site and / or Services for personal needs and solely on his own behalf.<br></br>
          1.3. <b>Firm</b> — means a commercial entity (“business entity”), a legal entity or an individual entrepreneur carrying out commercial activities using the Site and/or Services.<br></br>
          1.4. <b>Site </b> — "Transport and Information Server iristrans.com", an information resource available on the Internet using the domain name iristrans.com (including also through other domain names redirecting to iristrans.com).<br></br>
          1.5. <b>IrisTrans </b> or  <b>We </b> — are a legal entity Limited Liability Company “Iris inter GROUP” registered and operating under the laws of the Republic of Belarus, which act jointly or separately on the terms stipulated by the agreement between them.<br></br>
          1.6. <b>Service/Services </b> —  information services provided to the User (Company) through the Site and/or in any other way (in particular, but not exclusively, through e-mail mailing) with the legitimate use of the trademark "IrisTrans" and/or similar ( derivatives) designations, as well as the relevant functional sections of the Site and methods of providing services.
        </p>
        <h2 className={`${styles.items__title}`}>2. General provisions</h2>
        <p className={`${styles.item}`}>
          2.1. This User Agreement (hereinafter referred to as the “Agreement”) regulates, between you (hereinafter also referred to as the “User”), on the one hand, and IisTrans.com, on the other hand, the relationship arising from the operation of the Site and / or the provision of the Services. In particular, but not exclusively, this Agreement sets out the rules and conditions for the provision of the Site and / or Services. <br></br>
          2.1.1. Through the Site, we provide an online platform through which Users, as part of their professional activities, can advertise, market, market and/or offer their services to order, purchase, reserve transport and/or (freight forwarding) services, and other Users Sites can find such services, compare, order, purchase or pay for them. By using the Site and Services, You may enter into a direct (legally binding) contractual relationship with the service provider from whom you reserve or purchase a service. IrisTrans acts solely as an intermediary between you and other Users of the Site, providing them with the opportunity to view relevant data on the conditions for the provision of services by you or the services of interest to you. IrisTrans does not (re)sell or market any products or services.<br></br>
          2.1.2. When providing our Services, the posted information is based on the information provided to us by the Users of the Site. Users who post and promote their transportation and/or freight forwarding services are solely responsible for updating the information about prices, tariffs, fees, availability of vehicles, cargo, rules, conditions and other data displayed on our Site. While we do our best to ensure the quality of our Service, we do not verify and cannot guarantee the accuracy or completeness of all information, and we are not responsible for any errors (including blunders and typographical errors), failures ( arising from (temporary and/or partial) breakdown, repair, improvement or maintenance of our Site or otherwise), inaccurate, inaccurate or false information or failure to provide information. Each User, under any conditions, is responsible for the accuracy, completeness and correctness of the information provided (descriptive) by him (including tariffs, fees, prices, rules, conditions and availability of free transport, cargo) displayed on the Site.<br></br>
          2.2.This Agreement (including the Rules and Conditions for the use of certain Services that are part of the Agreement), in terms of the applicability of the relevant provisions to free / unregistered access, applies to both registered and unregistered Users of the Site.<br></br>
          2.3. This Agreement (including the Rules and Conditions for the use of certain Services that are part of the Agreement), in terms of the applicability of the relevant provisions to free / unregistered access, applies to both registered and unregistered Users of the Site.<br></br>
          2.4. This Agreement becomes available to the User upon entering the Site. Using the Site, any Services and / or registering an account means confirmation of the User's consent to all the terms of this Agreement and their unconditional acceptance.<br></br>
          2.5. IrisTrans.com reserves the right, at its sole discretion, to change the Agreement at any time without special prior and/or subsequent notice to the User. The current version of the Agreement is always available at. The User's continued use of the Site and / or Services after any changes to the Agreement implies the User's unconditional consent to such changes.
        </p>
        <h2 className={`${styles.items__title}`}>3. Limited Liability of IrisTrans</h2>
        <p className={`${styles.item}`}>
          3.1. Access to the Site and Services is provided in the form in which IrisTrans.com deems appropriate and convenient for the implementation of the objectives of the Site and / or Services, and IrisTrans.com does not guarantee that the Site and / or Services will meet the User's expectations and / or goals.<br></br>
          3.2. The User understands and agrees that IrisTrans.com has the right, at its own discretion and without special notice to the User, to change the set of Services available to him and / or the functionality of the Site.<br></br>
          3.3.An individual, not a business entity, as a User of the Site and its Services, understands and agrees that, due to its legal status, its access to the Services and / or the functionality of the Site has a number of restrictions, including: in placing applications and reviews, adding contacts, brand, logo, etc. - the list of which IrisTrans.com has the right to change at its own discretion and without special notice to such User.<br></br>
          3.4. The User understands and agrees that IrisTrans.com does not guarantee 100% error-free and uninterrupted operation of the Site and / or Services and is not responsible for any possible damage caused to the User and / or third parties by technical failures of hardware and / or software on any side.<br></br>
          3.5. The User understands and agrees that IrisTrans.com does not guarantee the accuracy of information (in particular, but not exclusively: applications for goods offered for transportation and / or availability of free freight transport) posted on the Site by third parties, and is not responsible liability for any possible damage caused to the User and / or third parties as a result of using the Site / Services.<br></br>
          3.6. The User understands and agrees that IrisTrans.com is not responsible for the accuracy of the advertising information posted on the Site, and for the quality of the advertised goods/services.<br></br>
          3.7.IrisTrans.com reserves the right at any time, at its sole discretion, to suspend, restrict or terminate the User's access to the Site or to certain Services, including, but not exclusively, in case of violation by the User of this Agreement and / or applicable law. At the same time, in cases of violations due to the fault of the User, IrisTrans is not liable for the return of funds paid for access to the Services and / or compensation for losses.<br></br>
          3.8. All relationships arising between the Users of the Site and / or Services are limited to the circle of such Users. IrisTrans.com does not bear financial or other obligations for obligations arising between Users.<br></br>
          3.9. Neither IrisTrans.com nor any of our officers, directors, employees, representatives, subsidiaries, affiliates, distributors, agents or others involved in the creation, promotion and any other activities aimed at maintaining the Site and/or Services and its content, is not responsible in accordance with the law for:<br></br></p>
        <ul className={`${styles.list_items}`}>
          <li>any punitive damages, direct, indirect and consequential loss or damage, any loss of production, loss of profits, loss of income, failure to conclude a contract, loss or damage to goodwill and failure to satisfy a claim for damages,</li>
          <li>inaccuracy relating to (descriptive) information (including, but not exclusively, conditions, prices, availability of free transport, cargo, etc.) provided by the User on the Site,</li>
          <li>	any (direct, indirect, consequential or punitive) losses, damages or costs incurred, accepted or paid by you and arising in connection with or as a result of the use, inability to use or interruption of the Site and / or Services,</li>
          <li>(personal) personal injury, death, property damage or other (direct, indirect, consequential or punitive) loss, damage or cost incurred, incurred or paid by you as a result of (legal) acts, errors, violations, negligence, willful misconduct, omissions , non-performance, misinterpretation, tort by Users whose products or services (directly or indirectly) are available, offered or promoted on or through the Site, including any (partial) cancellation, strike, force majeure or any other event beyond our control.</li>
        </ul>
        <p className={`${styles.item}`}>3.10. By uploading photos/images to our system (for example, in addition to a review), you represent, warrant and agree that you are the copyright owner of those photos/images and you consent to IrisTrans.com's use of the uploaded photos/images on the Site , in publications (posted online and offline), and in any other way at the discretion of IrisTrans.com, you grant IrisTrans.com a non-exclusive, worldwide, irrevocable, unconditional and perpetual right and permission to use, reproduce, display, copy, distribute , licensing, transferring and posting photos/images in any way at the discretion of IrisTrans.com IrisTrans.com does not own the rights to uploaded photos/images and does not endorse them in any way. Responsibility for the accuracy, validity and right to use all photographs/images is assumed by the person who uploaded the photograph/image. IrisTrans.com does not carry out pre-moderation or any other preliminary verification of photos/images uploaded by Users and does not bear any responsibility and obligations in relation to the posted photos. The person who uploaded the photo warrants that the photos/images do not contain viruses, Trojan horses or files infected with viruses, nor do they contain any pornographic, illegal, obscene, offensive, inappropriate or inappropriate material and do not violate the rights of third parties (intellectual property rights). property, copyright or privacy rights). Any photo/image,
        </p>
        <h2 className={`${styles.items__title}`}>4. Account</h2>
        <p className={`${styles.item}`}>
          4.1. By carrying out the actions to register an account, the User guarantees that he is duly legally capable, fully capable and / or, in appropriate cases, authorized by the Firm.<br></br>
          4.2. The user undertakes to provide reliable information in the registration data:</p>
        <ul className={`${styles.list_items}`}>
          <li>•	about the Firm, including: name, legal and postal addresses, registration (identification, tax) codes (numbers), telephone number, e-mail address, website address, if any, other contact details, as well as data on business activities : data on the presence of an individual entrepreneur in the process of termination of entrepreneurial activity and data on the presence of a legal entity in the process of termination;</li>
          <li> •	about an individual, not a subject of economic activity, including: last name, first name, patronymic, identification code, registration address, contact numbers;<br></br>
          and keep the information provided up to date.</li>
        </ul>
        <p className={`${styles.item}`}>4.3. The User is responsible and independently takes measures to ensure the security of his account on the Site (including control over the login-password link, e-mail address, etc.). The User is fully responsible for all actions performed using the User's account. The User undertakes to immediately notify IrisTrans of any cases of unauthorized use of the User's account by third parties. The User undertakes not to use information in the registration data that could be misleading, in particular, but not exclusively: designations that reproduce trademarks and / or commercial names of third parties or are confusingly similar to them; data that duplicates the data of any other account.<br></br>
          4.4. The User undertakes not to register or use more than one account at the same time.<br></br>
          4.5. The Firm undertakes to use the account solely to meet the needs of the Firm directly related to the transportation of goods.
          4.6. An individual, not a business entity, undertakes to use the account solely to meet their own needs, in particular, but not exclusively, related to the transportation of goods.<br></br>
          4.7. IrisTrans.com reserves the right to request, both at its sole discretion and as part of the verification (attestation) system, documents to confirm the data provided by the User, and make decisions regarding the validity of the User's account based on the results of such requests.<br></br>
          4.8. IrisTrans.com reserves the right to block and/or cancel (delete) the registration of the User's account, in particular, but not exclusively, in the following cases:<br></br></p>
        <ul className={`${styles.list_items}`}>
          <li>violation of the terms of registration and / or use of the account given in this section;</li>
          <li>violation of the rules for posting (messaging) and / or other use of information;</li>
          <li>successful or unsuccessful attempts to circumvent the technical means of information protection used by IrisTrans.com when providing the Services, various types of attacks directed against the Site, including, but not exclusively, with the deliberate use of possible errors in the Site's scripts.</li>
        </ul>
        <p className={`${styles.item}`}>4.9.IrisTrans.com reserves the right to maintain a history of changes by the User of any registration data for an account, with the same level of access to the history of changes as to the data itself. Any data stored in the history of changes for the account is recognized as acceptable and applicable for establishing any circumstances related to the account and, moreover, in disputable and / or doubtful cases, subject to additional documentary confirmation by the User of the good faith of the data change and the use of the account before and after such changes - referring to the time of the relevance of the indication of such registration data.<br></br>
          4.10. IrisTrans.com reserves the right to store in its databases all information, data and information received from users, independently posted by users or obtained from other official sources. IrisTrans.com ensures the confidentiality of the specified information, data and information and their preservation in accordance with the provisions of the Privacy Policy, the requirements of the current legislation of the Republic of Belarus and other norms that are mandatory for implementation on the territory of the Republic of Belarus.<br></br>
          4.11. Removal of information from IrisTrans.com databases is carried out in accordance with the Privacy Policy, the requirements of the current legislation of the Republic of Belarus and other norms that are mandatory for implementation on the territory of the Republic of Belarus.<br></br>
          4.12. All disputes arising in connection with the Site and / or Services and to which IrisTrans.com is a party must be resolved through negotiations. If it is impossible to reach an agreement through negotiations, the dispute is referred to the competent court, in accordance with the requirements of the current legislation of the Republic of Belarus and other norms that are mandatory for implementation on the territory of the Republic of Belarus.
        </p>
        <h2 className={`${styles.items__title}`}>5. Personal data and privacy</h2>
        <p className={`${styles.item}`}>
          5.1. User - an individual who is not a business entity, thereby provides IrisTrans.com consent (permission) to the processing of personal data in accordance with the Privacy Policy. This permission may be revoked at any time by contacting the administration of IrisTrans.com of such an individual, subject to the unconditional confirmation of his identity. In the event of withdrawal of consent to processing, the personal data of such an individual is stored in the databases of IrisTrans.com and is not available to any third parties, except as provided by national legislation and the provisions of international regulatory documents. Deletion of information from the IrisTrans.com databases is carried out in accordance with
          <NavLink to={'/pravila/politika-konfidencialnosti'}>
            <span className={styles.link}> the Privacy Policy,</span>
          </NavLink>
<br></br>
          5.2. When the User (for Firms) places on the Site any information containing personal data of third parties, the User (for Firms) guarantees that each such action does not violate the rights of third parties established by law and / or contract, and, in particular, guarantees that each such action is subject to, secured by the responsibility of this User, the consent of the subject of the relevant personal data.<br></br>
          5.3. The User is solely responsible for the correctness of the choice, where applicable, of the individual settings for the privacy/publicity of the posted information when used within the Services of the User's account, in particular, but not exclusively, for the Service for storing documents (images). This provision applies equally to both the protection of personal data and the protection of trade secrets and / or other confidential information.<br></br>
          5.4. When using the Site and IrisTrans.com Services, it is prohibited to post personal data of an individual about racial or ethnic origin, political views, religious and other philosophical beliefs, membership in trade union organizations, as well as information about biometric data, health data, personal and sexual life . If such information is revealed, it will be deleted by the administration without any additional notifications.<br></br>
          5.5. IrisTrans.com is not responsible for any consequences of the use of personal data of an individual who is not a business entity by other Users in the event that such data was independently posted by this individual in violation of the requirements of this User Agreement and other Rules and Conditions for using the Site and individual Services IrisTrans.com or became known to third parties due to non-compliance by the user with the User Agreement and the Privacy Policy.<br></br>
          5.6. In order to comply with the requirements of national legislation and the provisions of international regulations, IrisTrans.com has appointed an authorized person for data protection issues, contact with which is carried out through the "Contact" page or through the administration of IrisTrans.com.
        </p>
        <h2 className={`${styles.items__title}`}>6. Use of information by the User</h2>
        <p className={`${styles.item}`}>
          6.1. Regardless of the channels of communication used with other Users and / or IrisTrans.com, the User undertakes to comply with generally accepted norms of courtesy.<br></br>
          6.2. The User undertakes not to use, without special permission from IrisTrans.com, the Site and / or Services to communicate any type of advertising information and / or to mass message any information that does not correspond to the theme of the Site (Services).<br></br>
          6.3. The User undertakes not to commit unfair actions directed against other Users and / or IrisTrans.com, in particular, but not exclusively, the communication using the Site / Services of any information indicating, without sufficient authority, registration, personal or contact details of other persons , as well as any other communication of information that could be misleading about the true identity of an individual or reliable data about the Firm, the relationship of representation between Users and / or the intentions of Users. The User understands that such actions may also be qualified as fraud and thus be criminally punishable.<br></br>
          6.4. Individuals who are not business entities have the right to export their personal data if they need a copy of this data or plan to use this information in third-party services. The export of personal data of an individual who is not a business entity is carried out by the IrisTrans administration on the basis of the request of such an individual, subject to unconditional confirmation of his identity.<br></br>
          6.5. Individuals who are not a business entity have the right to delete their personal data in cases provided for by the provisions of national legislation and the provisions of international regulations, the information will be deleted in whole or in part. Deletion of personal data of an individual who is not a business entity is carried out personally by the User on his personal page and/or by the administration of IrisTrans.com based on the request of such an individual, subject to unconditional confirmation of his identity.
        </p>
        <h2 className={`${styles.items__title}`}>7. Intellectual Property Rights</h2>
        <p className={`${styles.item}`}>
          7.1.Unless otherwise stated, all software required to provide our Services and/or used on our Site and intellectual property rights (including copyrights and related rights) in respect of the content, information and materials posted on the Site belong to the company LLC "IRIS inter GROUP".<br></br>
          7.2. Iris inter GROUP LLC retains the exclusive right to own, use and dispose of (all intellectual property rights) (design style (including functionality)) the Site on which the Services are offered (including reviews) and you are not entitled to copy, automatically or programmatically reading/collecting/analyzing data, creating (hyper/external) links, publishing, promoting, marketing, integrating, using, combining or otherwise applying content (including any translations thereof and User reviews) or our brand and trademarks without our express written permission. When, if you (wholly or in part) use or combine our (translated) content (including User reviews) or otherwise own the intellectual property rights in any (translated) content or User reviews, you hereby transfer and assign all intellectual property rights to such kind in favor of IrIS inter GROUP LLC. Any illegal use or performance of any of the above will constitute a material infringement of our intellectual property rights (including copyright and related rights and the rights of the database manufacturer).<br></br>
          7.3. LLC "IRIS inter GROUP" is the owner of exclusive intellectual property rights to compilations of data (databases), access to which is provided to the User within the framework of the Services, in particular, but not exclusively, compilation of data on applications, on goods offered for transportation, and / or availability of free freight transport. The user is granted a non-exclusive right to use the above results of intellectual activity, the rights to which belong to IrIS inter GROUP LLC, solely to meet their needs directly related to the transportation of goods. All rights to use (methods of use) of data compilations that are not expressly specified in this Agreement as granted to the User are considered not granted to the User and are reserved by IrIS inter GROUP LLC. In particular,
        </p>
        <h2 className={`${styles.items__title}`}>8. Composition and operation of the User Agreement</h2>
        <p className={`${styles.item}`}>
          8.1. For the avoidance of doubt, the above rules are general and apply to all Services / sections of the Site, including, but not limited to: placing applications for cargo offered for transportation and / or availability of free trucks, posting reviews in the “Reliability Zone ”, Automarket, Forum.<br></br>
          8.2. The documents and/or accompanying instructions for the functionality on the relevant pages of the Site listed below establish special rules for using certain Services and are an integral part of this Agreement:<br></br>
          a. <NavLink to={'/tariff'}>
            <span className={styles.link}> Services and prices</span>
          </NavLink><br></br>
          b.  <NavLink to={'/pravila/pravila-razmeshcheniya-zayavok'}>
            <span className={styles.link}> Rules for placing Applications</span>
          </NavLink><br></br>
          с. <NavLink to={'/pravila/verifikaciya'}>
            <span className={styles.link}> Rules for checking the authenticity of your account </span>
          </NavLink><br></br>
          d. <NavLink to={'/pravila/politika-konfidencialnosti'}>
            <span className={styles.link}> Privacy Policy</span>
          </NavLink><br></br>
        </p>
        <h2 className={`${styles.items__title}`}>9.Contact with IrisTrans.com</h2>
        <p className={`${styles.item}`}>
          9.1.Information on how to contact Us is available on the "Contact Us" page. You can also use the separate "Contact Support" web form or Support Chat.<br></br>
          9.2.If you discover suspected malfunctions and / or other phenomena that are at odds with the normal operation of the Site and / or Services, We recommend that you contact us.<br></br>
          9.3. You understand and agree that in the interests of Yours and Ours, as well as all other bona fide parties that may be involved in various issues, We have the right to record telephone conversations with the support service.
        </p>
      </div>
    </div>

  )
}
export default Agreement;
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { HomePage } from '../../components/main-registered/home-page';
import { HomePageAdmin } from '../../components/main-registered/home-page-admin';
import { UserPage } from '../../components/main-registered/user-page';
import { BalancePage } from '../../components/main-registered/home-balance';
import { SearchTable } from '../../components/main-registered/search-table';
import { RegistrationTable } from '../../components/main-registered/registration-table';
import { ServicesPrices } from '../../components/main-registered/services-prices';
import { useDispatch, useSelector } from 'react-redux';
import styles from './main-registered.module.css'
import Sidebar from '../../components/main-registered/sidebar/sidebar';
import SidebarFull from '../../components/main-registered/sidebar-full/sidebar-full';
import axios from "axios";
import { useEffect, useRef } from 'react';
import { saveUserData } from '../../redux/reducers/user-data-reducer';
import { saveMyDialogs } from '../../redux/reducers/dialogs-reducer';
import { saveCargosUserData } from '../../redux/reducers/cargo-users-reducer';
import { saveCountUnreadMessages, saveCountUnreadDialogs } from '../../redux/reducers/unread-reducer';
import { saveTransportsUserData } from '../../redux/reducers/transports-user-reducer';
import { setUsers } from '../../redux/reducers/users-list-reducer';
import ResultsTable from '../../components/main-registered/results-table/results-table';
import { Details } from '../../components/main-registered/details';
import { RegistrationTableTransport } from '../../components/main-registered/registration-table-transport';
import ResultsTableTransport from '../../components/main-registered/results-table-transport/results-table-transport';
import { SearchTableTransport } from '../../components/main-registered/search-table-transport';
import DetailsTransport from '../../components/main-registered/details-transport/details-transport';
import { AnotherUserPage } from '../../components/main-registered/another-user-page';
import { Chat } from '../../components/main-registered/chat';
import { UsersInvoice } from '../../components/main-registered/users-invoice';
import { closeSubscription, closeAddSubscription } from '../../http/userAPI';
import { getAllUsersAdmin } from '../../http/userAPI';
import { checkUserAuth, loginUserData } from '../../redux/reducers/user-data-reducer';
import { openSelectedPage } from '../../redux/reducers/selected-page-reducer';
import io from 'socket.io-client';
import { useTranslation } from "react-i18next";
import useOnClickOutside from '../../hooks/onClickOutside';
import { openBurger, closeBurger } from '../../redux/reducers/burger-reducer';
import { BaseURL } from "../../http";
import CargoUpdate from '../../components/main-registered/cargo-update/cargo-update';
import TransportUpdate from '../../components/main-registered/transport-update/transport-update';



// const socketIO = io()
const socketIO = io('', { secure: true, transports: ['websocket'] });
// const socketIO = io(`${BaseURL}`);

export function MainRegisteredPage() {




  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();


  // const userId = useSelector(state => state.userData.userLogin.user.id);

  const userId = useSelector(state => {
    if (state.userData.userLogin.user !== undefined) {
      return state.userData.userLogin.user.id
    } if (state.userData.userData.user !== undefined) {
      return state.userData.userData.user.id
    } else {
      return state.userData.userData.id
    }
  })

  const userName = useSelector(state => state.userData.userData.name);
  const userPromo15 = useSelector(state => state.userData.userData.promo15);
  // console.log("userPromo15", userPromo15)
  const confirmEmail = useSelector(state => state.userData.userData.confirmEmail);
  const userRole = useSelector(state => state.userData.userData.role);
  const userPaidAdd = useSelector(state => state.userData.userData.addperiod);
  const myCargoList = useSelector(state => state.cargoUsers.cargoUser);
  const myTransportList = useSelector(state => state.transportsUser.transportsUser);
  const foundCargo = useSelector(state => state.cargoFound.cargoFound);
  const notfoundCargo = useSelector(state => state.cargoFound.cargoNotFound);
  const foundTransport = useSelector(state => state.transportFound.transportFound);
  const notfoundTransport = useSelector(state => state.transportFound.transportNotFound);
  const currentItem = useSelector(state => state.currentId.currentId);
  const chatIsOpen = useSelector(state => state.chatIsOpen.chatIsOpen);
  const cargoUpdateFormIsOpen = useSelector(state => state.updateCargoForm.cargoUpdateForm);
  const transportUpdateFormIsOpen = useSelector(state => state.updateTransportForm.transportUpdateForm);
  // console.log(cargoUpdateFormIsOpen, "cargoUpdateFormIsOpen")
  // console.log(transportUpdateFormIsOpen, "transportUpdateFormIsOpen")
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [userDeleted, setuserDeleted] = useState(false);
  const [cargoRegistrationOpen, setcargoRegistrationOpen] = useState(false);
  const [transportRegistrationOpen, setTransportRegistrationOpen] = useState(false);
  const [isUserData, setisUserData] = useState(false);
  const userAuht = useSelector(state => state.userData.auth);

  function openCargoREgistration() {
    setcargoRegistrationOpen(true);
    //  console.log("openCargoREgistration")
  }
  function openTransportRegistration() {
    setTransportRegistrationOpen(true);
    //  console.log("openTransportRegistration")
  }
  function closeRegistration() {
    setTransportRegistrationOpen(false);
    setcargoRegistrationOpen(false);
    //  console.log("openTransportRegistration")
  }



  async function getUserData() {
    try {
      const response = await axios.get(`${BaseURL}/api/user/data/${userId}`)


      if (response.data.deleted) {
        setuserDeleted(true);
      }
      if (new Date(response.data.period) < new Date()) {
        await closeSubscription({ userId })
      }
      if (new Date(response.data.addperiod) < new Date()) {
        await closeAddSubscription({ userId })
      }
      if (response.data.role === "Admin") {
        getAllUsers()
      }

      dispatch(saveUserData(response.data));

      socketIO.emit('login', userId);
      socketIO.on("onlineUsers", (users) => {
        setOnlineUsers(users);
              });
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }

  async function getMyCargos() {
    try {
      const response = await axios.get(`${BaseURL}/api/cargo/list/${userId}`)
      //  console.log(response.data, "myCargo")
      dispatch(saveCargosUserData(response.data));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }
  async function getMyTransport() {
    try {
      const response = await axios.get(`${BaseURL}/api/transport/list/${userId}`)
      dispatch(saveTransportsUserData(response.data));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }
  async function getMyDialogs() {
    try {
      const response = await axios.get(`${BaseURL}/api/dialog/${userId}`)
      dispatch(saveMyDialogs(response.data));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }

  async function getNewMessages() {
    try {
      const unreadMessages = await axios.get(`${BaseURL}/api/message/unread/${userId}`)
      if (unreadMessages.data.length > 0) {
        dispatch(saveCountUnreadMessages(unreadMessages.data.length));
        let newMessagesData = [];
        unreadMessages.data.forEach(el => {
          newMessagesData.push(el.dialogId)
        })
        const res = newMessagesData.reduce((acc, i) => {
          if (acc.hasOwnProperty(i)) {
            acc[i] += 1;
          } else {
            acc[i] = 1;
          }
          return acc;
        }, {})
        let countOfDialogs = res;
        dispatch(saveCountUnreadDialogs(countOfDialogs));
      }
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }

  async function getAllUsers() {
    try {
      const response = await getAllUsersAdmin();
      const resp = response.data.rows.reverse();
      dispatch(setUsers(resp));
    }
    catch (e) {
      console.log(e, "error")
      console.log(e.response?.data?.message)
    }
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {

      getUserData()
      getMyCargos()
      getMyTransport()
      getMyDialogs()
      getNewMessages()

      // socketIO.on("new-dialog", (dialog) => {
      //   if (dialog.partner === userId) {
      //   }
      // });

    }
  }, [])

  useEffect(() => {
    if (userRole !== undefined) {
      setisUserData(true)
    }
  }, [userRole])

  useEffect(() => {
    // console.log(userId, "userId")
    if (userId) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'userId',
        'user_id': userId
      })
    }
    // console.log(userId, "userId ga")
  }, [])

  const selectedPage = useSelector(state => state.selectedPage.selectedPage);
  const burger = useSelector(state => state.burger.burger);
  // console.log(burger, "burger mail-registered")
  return (
    <>
      {isUserData == undefined ? <div className={styles.loader}>Loading</div> :
        <>
          {(!confirmEmail && isUserData) ? <div className={styles.confirmEmail}>{t('notifications.confirmEmail')}</div> :
            <>
              {userDeleted ? <div className={styles.deleted}>{t('notifications.profileDeleted')}</div> :
                <div className={styles.main_wrapper}>
                  {(selectedPage === "cargoRegistration" || selectedPage === "transportRegistration") ?
                    <main className={styles.main__registration}>
                      {burger && <Sidebar />}
                      {!burger && <SidebarFull />}

                      {(selectedPage === "transportRegistration") && transportRegistrationOpen &&
                        <div className={styles.main__registration_table_wrapper}>
                          <div className={styles.table_wrapper_close} onClick={() => closeRegistration()}>X</div>
                          <RegistrationTableTransport title={t('registraciya-transporta')} />
                        </div>}
                      {(selectedPage === "transportRegistration") && transportUpdateFormIsOpen &&
                        <div className={styles.main__registration_table_wrapper}>
                          
                          <TransportUpdate  currentID={currentItem}/>
                        </div>}
                      {(selectedPage === "cargoRegistration") && cargoRegistrationOpen &&
                        <div className={styles.main__registration_table_wrapper}>
                          <div className={styles.table_wrapper_close} onClick={() => closeRegistration()}>X</div>
                          <RegistrationTable title={t('registraciya-gruza')} />
                        </div>}
                      {(selectedPage === "cargoRegistration") && cargoUpdateFormIsOpen &&
                        <div className={styles.main__registration_table_wrapper}>
                
                          <CargoUpdate  currentID={currentItem}/>
                        </div>}

                      {/* {(selectedPage === "cargoRegistration") && cargoRegistrationOpen  && <RegistrationTable title={t('registraciya-gruza')} />} */}
                      <div className={styles.main__registration_inner}>
                        {(selectedPage === "cargoRegistration") && userId && <div className={styles.btn_add} onClick={() => openCargoREgistration()}>{t('dobavit-gruz')}</div>}
                        {(selectedPage === "transportRegistration") && userId && <div className={styles.btn_add} onClick={() => openTransportRegistration()}>{t('dobavit-transport')}</div>}
                        {(selectedPage === "cargoRegistration") && myCargoList.length > 0 && userId && <ResultsTable typeOfAction="registrationCargo" />}
                        {/* {(selectedPage === "transportRegistration") && myTransportList.length > 0 && userId && <ResultsTableTransport typeOfAction="registrationTransport" />} */}
                        {(selectedPage === "transportRegistration") && myTransportList.length > 0 && userId && <ResultsTableTransport typeOfAction="registrationTransport" />}
                      </div>

                    </main> :
                    <main className={styles.main}>
                  {burger && <Sidebar/>}
                      {!burger && <SidebarFull />}

                      {((selectedPage === "userspage" && userRole === "Admin") || (selectedPage === "homepage" && userRole === "Admin")) && <HomePageAdmin />}
                      {(selectedPage === "homepage" && userRole !== "Admin") && <HomePage />}
                      {(selectedPage === "balancepage") && <BalancePage />}
                      {(selectedPage === "mypage") && <UserPage onlineUsers={onlineUsers} />}
                      {(selectedPage === "cargoSearch") && <SearchTable title={t('tables.title.searchCargo')} />}
                      {(selectedPage === "transportSearch") && <SearchTableTransport title={t('tables.title.searchTransport')} />}
                      {(selectedPage === "transportRegistration") && <RegistrationTableTransport title={t('registraciya-transporta')} />}
                      {(selectedPage === "addTarif" && !userPaidAdd) && <div className={styles.notfound}> {t('notifications.addtariff')} <br></br><span className={styles.link} onClick={() => dispatch(openSelectedPage("servicesPrices"))}>{t('notifications.buytarif')} </span></div>}
                      {/* {(selectedPage === "cargoRegistration" && !userPaidAdd && userRole === "Перевозчик") && <div className={styles.notfound}>Для регистрации груза приобретите тариф </div>} */}
                      {(selectedPage === "cargoRegistration") && <RegistrationTable title={t('registraciya-gruza')} />}
                      {(selectedPage === "servicesPrices") && <ServicesPrices />}
                      {(selectedPage === "anotherUserPage") && <AnotherUserPage onlineUsers={onlineUsers} />}
                      {(selectedPage === "usersInvoicePage") && <UsersInvoice />}
                      {(selectedPage === "cargoIsEmpty") && foundCargo.length === 0 && notfoundCargo && userId && <div className={styles.notfound}>{t('notifications.cargoNotFound')}</div>}
                      {(selectedPage === "transportIsEmpty") && foundTransport.length === 0 && notfoundTransport && userId && <div className={styles.notfound}>{t('notifications.transportNotFound')}</div>}
                    </main>
                  }


                  {/* {(selectedPage === "cargoRegistration") && myCargoList.length > 0 && userId && <ResultsTable typeOfAction="registrationCargo" />} */}
                  {/* {(selectedPage === "transportRegistration") && myTransportList.length > 0 && userId && <ResultsTableTransport typeOfAction="registrationTransport" />} */}
                  {(selectedPage === "cargoSearch" || selectedPage === "transportSearch") && ((foundCargo.length && foundCargo[0].additionalSearch) || (foundTransport.length && foundTransport[0].additionalSearch)) && userId && <div className={styles.notfound}>{t('notifications.noExactMatches')}</div>}
                  {(selectedPage === "cargoSearch" || selectedPage === "transportSearch") && ((foundCargo.length && foundCargo[0].additionalSearch) || (foundTransport.length && foundTransport[0].additionalSearch)) && userId && <div className={styles.notfound}>{t('notifications.similarQueries')}</div>}
                  {(selectedPage === "cargoSearch") && foundCargo.length > 0 && userId && <ResultsTable typeOfAction="searchCargo" cargoItem={foundCargo} />}
                  {(selectedPage === "transportSearch") && foundTransport.length > 0 && userId && <ResultsTableTransport typeOfAction="searchTransport" />}

                  {(selectedPage === "cargoSearch") && foundCargo.length > 0 && currentItem && <Details currentID={currentItem} typeOfAction="searchCargo" />}
                  {(selectedPage === "transportSearch") && foundTransport.length > 0 && currentItem && <DetailsTransport currentID={currentItem} typeOfAction="searchTransport" />}
                  {(selectedPage === "cargoRegistration") && myCargoList.length > 0 && currentItem && <Details currentID={currentItem} typeOfAction="registrationCargo" />}
                  {(selectedPage === "transportRegistration") && myTransportList.length > 0 && currentItem && <DetailsTransport currentID={currentItem} typeOfAction="registrationTransport" />}
                  {(selectedPage === "cargoSearch") && foundCargo.length === 0 && notfoundCargo && userId && <div className={styles.notfound}>{t('notifications.cargoNotFound')}</div>}
                  {(selectedPage === "transportSearch") && foundTransport.length === 0 && notfoundTransport && userId && <div className={styles.notfound}>{t('notifications.transportNotFound')}</div>}
                  {chatIsOpen && <Chat username={userName} />}
                </div>
              }
            </>
          }
        </>
      }
    </>
  )
}
export default MainRegisteredPage;

import styles from './form-registration.module.css';
// import styles from './form-registration-pers.module.css';
import { RegistrationFl } from '../registration-fl';
import { useSelector, useDispatch } from 'react-redux';
import { closeRegistrForm2 } from '../../redux/reducers/registration-stage2-reducer';
import { setUserRole, setUserWork } from '../../redux/reducers/user-role-reducer';
import { useState } from 'react';
import { useTranslation } from "react-i18next";




export function RegistrationForm() {
  const { t } = useTranslation();

  const userRole = useSelector(state => state.userRole.userRole);
  const userWork = useSelector(state => state.userRole.userWork);
  const currentLanguage = useSelector(state => state.language.language);

  const dispatch = useDispatch();

  const [value, setValue] = useState(userRole);
  const [valueActivity, setValueActivity] = useState(userWork);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isSelectOpenActivity, setIsSelectOpenActivity] = useState(false);


  const optionsTypesofRegist = [
    { value: 'Физическое лицо', label: t('select.role.individual') },
    { value: 'Юридическое лицо', label: t('select.role.legal') },
    { value: 'Предприниматель', label: t('select.role.entrepreneur') },
  ];
  const optionsTypesofActivity = [
    { value: 'Грузоотправитель', label: t('select.activity.shipper')} ,
    { value: 'Перевозчик', label: t('select.activity.carrier')} ,
    { value: 'Экспедитор', label: t('select.activity.forwarder')} ,
  ];


  let valueRole = optionsTypesofRegist.find(role => role.value === value)
  let valueUserActivity = optionsTypesofActivity.find(role => role.value === valueActivity)
   function handleChange(value) {
    setValue(value)
    setIsSelectOpen(false)
  }
  function handleChangeActivity(valueActivity) {
    setValueActivity(valueActivity)
    setIsSelectOpenActivity(false)
  }

  function closeAllModal() {
    dispatch(closeRegistrForm2());
  }

  return (
    <>
      <div className={styles.darkBG} onClick={() => dispatch(closeAllModal)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <p className={styles.heading}>{t('button.registrationText')}</p>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.select_box}>
              <div className="">
             
                <div className= {currentLanguage === 'per' ?  `${styles.select_menu__pers} ${styles.select_menu}` : `${styles.select_menu} `} onClick={() => setIsSelectOpen(!isSelectOpen)} >{valueRole.label}</div>
                {isSelectOpen && <ul className={currentLanguage === 'per' ?  `${styles.select_items_right}` : `${styles.select_items_left}`}>
                  {optionsTypesofRegist.map((option) => (
                    <li className={styles.select_item} value={option.value} onClick={() => { dispatch(setUserRole(option.value)); handleChange(option.value) }}>{option.label}</li>
                  ))}
                </ul>}
              </div>
              <div className="">
                <div className= {currentLanguage === 'per' ?  `${styles.select_menu__pers} ${styles.select_menu}` : `${styles.select_menu} `} onClick={() => setIsSelectOpenActivity(!isSelectOpenActivity)} >{valueUserActivity.label}</div>
                {isSelectOpenActivity && <ul className={currentLanguage === 'per' ?  `${styles.select_items_left}` : `${styles.select_items_right}`}>
                  {optionsTypesofActivity.map((option) => (
                    <li className={styles.select_item} value={option.value} onClick={() => { dispatch(setUserWork(option.value)); handleChangeActivity(option.value) }}>{option.label}</li>
                  ))}
                </ul>} </div>
            </div>
            <RegistrationFl />
          </div>
        </div>
      </div>
    </>
  )
}
export default RegistrationForm;
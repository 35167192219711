import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  transportUpdateForm: false,
};

export const updateTransportFormSlice = createSlice({
  name: 'transportUpdateForm',
  initialState,
  reducers: {
    openEdittransportForm: (state, action) => {
      return {
        ...state,
        transportUpdateForm: true
      }
    },
    closeEdittransportForm: (state, action) => {
      return {
        ...state,
        transportUpdateForm: false
      }

    },
  }
});

export const { openEdittransportForm, closeEdittransportForm } = updateTransportFormSlice.actions;
export const updateTransportFormReducer = updateTransportFormSlice.reducer;
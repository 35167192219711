import styles from './registration-fl.module.css';
// import styles from './registration-fl-pers.module.css';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import Select, { components } from 'react-select';
import AsyncSelect from "react-select/async";
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeRegistrForm2 } from '../../redux/reducers/registration-stage2-reducer';
import { openRegFinishModal } from '../../redux/reducers/registration-finish';
import { registration } from '../../http/userAPI';
import { loginUserData, saveUserData } from '../../redux/reducers/user-data-reducer';
import { setCountry, setCountryId, setCity, setTelcode } from '../../redux/reducers/registration-geo-reducer';
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import axios from "axios";
import { ReactComponent as Eye } from "./images/eye.svg";
import { ReactComponent as EyeClosed } from "./images/eye-closed.svg";
import { NavLink } from 'react-router-dom';
import { BaseURL } from "../../http";

export function RegistrationFl() {
  const { t } = useTranslation();
  const currentLanguage = useSelector(state => state.language.language);
  const listCountries = useSelector(state => state.countries.countries);
  const selectedCountry = useSelector(state => state.geoRegistration.country);
  const selectedCountryId = useSelector(state => state.geoRegistration.countryId);
  const selectedCity = useState("");
  const selectedTelcod = useSelector(state => state.geoRegistration.telcode);
  const userRole = useSelector(state => state.userRole.userRole);
  const userWork = useSelector(state => state.userRole.userWork);
  const [datapolicy, setDatapolicy] = useState(false);
  const [selectedOptionsCountry, setselectedOptionsCountry] = useState("");
  const [selectedCountryIdTest, setselectedCountryIdTest] = useState("Тулуп");
  const [selectedOptionsCity, setSelectedOptionsCity] = useState("");
  const [selectCityError, setSelectCityError] = useState(false);
  const [selectCountryError, setSelectCountryError] = useState(false);
  const selectCityRef = useRef();
  const selectCountryRef = useRef();

  let reqData = {
    countryId: selectedCountryId,
    city: ""
  }


  const onClearCountryRef = () => {
    selectCountryRef.current.clearValue();
  };

  function handleCountrySelect(data) {
    dispatch(setCountry(data.name));
    dispatch(setCountryId(data.id));
    dispatch(setTelcode(data.telcod))
    dispatch(setCity(""));
  }
  function handleCitySelect(data) {
    if ((data !== undefined) && data !== null) {
      dispatch(setCity(data.name));
      setSelectedOptionsCity(data);
    }

  }

  const initialValues = {
    role: userRole,
    work: userWork,
    orgname: "",
    surname: "",
    name: "",
    secondname: "",
    unp: "",
    number: "",
    email: "",
    password: "",
    password2: "",
    verification: "",
    datapolicy: datapolicy,
  }
  const dispatch = useDispatch();

  function openModalRegFinish() {
    dispatch(openRegFinishModal());
    dispatch(closeRegistrForm2());
  }

  const [file, setFile] = useState(null);
  const [type, setType] = useState('password');
  const [typeConfirm, setTypeConfirm] = useState('password');
  const [passwordView, setPasswordView] = useState(<Eye />);
  const [passwordConfirmView, setPasswordConfirmView] = useState(<Eye />);
  const selectFile = e => {
    setFile(e.target.files[0])
  }

  const togglePassInput = (e) => {
    if (type === 'password') {
      setType('text');
      setPasswordView(<EyeClosed />)
    } else {
      setType('password');
      setPasswordView(<Eye />)
    }
  }
  const togglePassConfirmInput = (e) => {
    if (typeConfirm === 'password') {
      setTypeConfirm('text');
      setPasswordConfirmView(<EyeClosed />)
    } else {
      setTypeConfirm('password');
      setPasswordConfirmView(<Eye />)
    }
  }

  const validate = values => {
    let errors = {}
    if (userRole === "Юридическое лицо") {
      if (!values.orgname) {
        errors.orgname = t('field.validate')
      }
      if (!values.name) {
        errors.name = t('field.validate')
      }

      else if (!/^([^0-9]*)$/i.test(values.name)) {
        errors.name = t('field.notNumber')
      }

      if (!values.unp) {
        errors.unp = t('field.validate')
      }

      if (!values.number) {
        errors.number = t('field.validate')
      }

      if (!values.email) {
        errors.email = t('field.validate')
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {

        errors.email = t('field.checkEmail')
      }
      if (!values.password) {
        errors.password = t('field.validate')
      }
      else if ((!/^[а-яА-ЯёЁa-zA-Z0-9\u0600-\u06FF\u0698\u067E\u0686\u06AF\u06F0-\u06F9]{8,}$/g.test(values.password))) {
        errors.password = t('field.passwordRule')
      }


      if (!values.password2) {
        errors.password2 = t('field.validate')
      } else if (values.password !== values.password2) {
        errors.password2 = t('field.password')
      }
      if (!datapolicy) {

        errors.datapolicy = t('field.validate')
      }

      if (!selectedCountry) {
        setSelectCountryError(true)
      } else {
        setSelectCountryError(false)
      }

    } else {
      if (!values.name) {
        errors.name = t('field.validate')
      }
      if (!values.surname) {
        errors.surname = t('field.validate')
      }
      if (!values.number) {
        errors.number = t('field.validate')
      }
      if (!values.number) {
        errors.number = t('field.validate')
      }
      if (!values.email) {
        errors.email = t('field.validate')
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = t('field.checkEmail')
      }
      if (!values.password) {
        errors.password = t('field.validate')
      } else if ((!/^[а-яА-ЯёЁa-zA-Z0-9\u0600-\u06FF\u0698\u067E\u0686\u06AF\u06F0-\u06F9]{8,}$/g.test(values.password))) {
        errors.password = t('field.passwordRule')
      }
      if (!values.password2) {
        errors.password2 = t('field.validate')
      } else if (values.password !== values.password2) {
        errors.password2 = t('field.password')
      }
      if (!datapolicy) {
        errors.datapolicy = t('field.validate')
      }

      if (!selectedCountry) {
        setSelectCountryError(true)
      } else {
        setSelectCountryError(false)
      }

    }
    return errors
  }


  const formData = new FormData();
  const onSubmit = async (values) => {

    formData.append('verification', values.verification)
    formData.append('role', userRole)
    formData.append('work', userWork)
    formData.append('surname', values.surname)
    formData.append('name', values.name)
    formData.append('secondname', values.secondname)
    formData.append('country', selectedCountry)
    formData.append('city', "")
    formData.append('unp', values.unp)
    formData.append('number', values.number)
    formData.append('email', values.email)
    formData.append('password', values.password)
    formData.append('datapolicy', datapolicy)
    formData.append('orgname', values.orgname)
    if (selectedCountry) {
      try {
        const response = await registration(formData);
        localStorage.setItem('token', response.data.accessToken);
        dispatch(saveUserData(response.data));
        dispatch(loginUserData(response.data));
        openModalRegFinish();
      } catch (e) {
        console.log(e.response?.data?.message);
        alert(e.response?.data?.message)
      }
    }


  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
    validate,

  })


  const colourStyles = {
    marginBottom: 4,
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 2,
        color: '#a3a3a3',
      }
    },
    menu: provided => ({ ...provided, zIndex: 10 })
  }

  const DropdownIndicator = (
    props
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        {/* <img src={arrow} alt="arrow" /> */}
      </components.DropdownIndicator>
    );
  };

  async function getRequestedCountries(name) {

    return await axios.get(`${BaseURL}/api/geo/countries/${name}`)
      .then((response) => {
        return response.data.map((country) => ({
          name: country.name,
          id: country.id,
          label: country.name,
          telcod: country.telcod

        }));
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  }

  async function getRequestedCountriesEnglish(name) {
    return await axios.get(`${BaseURL}/api/geo/countrieseng/${name}`)
      .then((response) => {
        return response.data.map((country) => ({
          name: country.english,
          id: country.id,
          label: country.english,
          telcod: country.telcod
        }));
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  }
  async function getRequestedCities(name) {
    reqData.city = name

    return await axios.post(`${BaseURL}/api/geo/cities`, reqData)
      .then((response) => {
        return response.data.map((city) => ({
          name: city.name,
          id: city.id


        }));
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  }
  async function getRequestedCitiesEnglish(name) {
    reqData.city = name

    return await axios.post(`${BaseURL}/api/geo/citieseng`, reqData)
      .then((response) => {

        return response.data.map((city) => ({
          name: city.english,
          id: city.id


        }));
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      if (inputText.length >= 3) {
        if (currentLanguage === "ru") {
          getRequestedCountries(inputText).then((options) => {
            return callback(options)
          });
        } else {
          getRequestedCountriesEnglish(inputText).then((options) => {
            return callback(options)
          });
        }
      }
    }, 100),
    []
  );

  return (
    <div className={styles.form_wrapper}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.input_wrapper}>
          {userRole === "Юридическое лицо" ?
            <div className={styles.input_box}>
              <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="orgname">{t('forms.field.orgName')}*</label>
              <input className={styles.input} type="text" id="orgname" name="orgname" onChange={formik.handleChange} value={formik.values.orgname} placeholder={t('forms.field.enter')} />
              {formik.errors.orgname && <div className={styles.error_message}>{formik.errors.orgname}</div>}
            </div>
            : <div className={styles.input_box_fl}>
              <div className={styles.input_box}>
                <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="surname">{t('forms.field.secondName')}*</label>
                <input className={styles.input} type="text" id="surname" name="surname" onChange={formik.handleChange} value={formik.values.surname} placeholder={t('forms.field.secondName')} />
                {formik.errors.surname && <div className={styles.error_message}>{formik.errors.surname}</div>}
              </div>
              <div className={styles.input_box}>
                <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="name">{t('forms.field.name')}*</label>
                <input className={styles.input} type="text" id="name" name="name" onChange={formik.handleChange} value={formik.values.name} placeholder={t('forms.field.name')} />
                {formik.errors.name && <div className={styles.error_message}>{formik.errors.name}</div>}
              </div>
              <div className={styles.input_box}>
                <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="secondname">{t('forms.field.fatherName')}</label>
                <input className={styles.input} type="text" id="secondname" name="secondname" onChange={formik.handleChange} value={formik.values.secondname} placeholder={t('forms.field.fatherName')} />
              </div>
            </div>}
          <div className={styles.input_box}>
            <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="country">{userRole === "Юридическое лицо" ? t('forms.field.countryOflive') + "*" : t('forms.field.country') + "*"}{currentLanguage === "per" && t('na-angliiskom-yazyke')} </label>
            <AsyncSelect
              ref={selectCountryRef}
              styles={colourStyles}
              className='mySelect'
              components={{ DropdownIndicator }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
              placeholder={t('forms.field.enter')}
              onChange={handleCountrySelect}
              loadOptions={loadOptions}
              defaultValue={[]}
              defaultOptions={[]}
              noOptionsMessage={({ inputValue }) => !inputValue ? t('nachnite-vvodit-tekst-0') : t('nichego-ne-naideno')}


            />
            {selectCountryError && <div className={styles.error_message}>{t('field.validate')}</div>}
          </div>
          {/* поле Город регистрации */}
          {/* <div className={styles.input_box}>
            <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="city">{userRole === "Физическое лицо" ? t('cityoflRegist') + "*" : t('forms.field.cityOfLive') + "*"}</label>
            <AsyncSelect
              ref={selectCityRef}
              styles={colourStyles}
              components={{ DropdownIndicator }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
              placeholder={t('forms.field.enter')}
              onChange={handleCitySelect}
              loadOptions={loadCityOptions}
              defaultValue={[]}
              defaultOptions={[]}
              noOptionsMessage={({ inputValue }) => !inputValue ? t('nachnite-vvodit-tekst-0') : t('nichego-ne-naideno')}
            />
            {selectCityError && <div className={styles.error_message}>{t('field.validate')}</div>}
          </div> */}
          {/* поле Город регистрации end*/}

          {(userRole === "Юридическое лицо" || userRole === "Предприниматель") &&
            <div className={styles.input_box}>
              <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="unp">{t('forms.field.unp')}*</label>
              <input className={styles.input} type="text" id="unp" name="unp" onChange={formik.handleChange} value={formik.values.unp} placeholder={t('forms.field.enter')} />
              {formik.errors.unp && <div className={styles.error_message}>{formik.errors.unp}</div>}
            </div>
          }
          {userRole === "Юридическое лицо" &&
            <div className={styles.input_box}>
              <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="name">{t('forms.field.fio')}*</label>
              <input className={styles.input} type="text" id="name" name="name" onChange={formik.handleChange} value={formik.values.name} placeholder={t('forms.field.name')} />
              {formik.errors.name && <div className={styles.error_message}>{formik.errors.name}</div>}
            </div>
          }
          <div className={styles.input_box}>
            {/* <input className={styles.input} onChange={formik.handleChange} id="number" name="number" value={formik.values.number} placeholder={t('forms.field.enter')} >
            </input> */}
            {/* <InputMask className={styles.input} mask="+375 (99) - 999- 99 -99" placeholder="+375 (__) - ___ - __ - __" onChange={formik.handleChange} id="number" name="number">
            </InputMask> */}
            <InputMask maskChar="" className={styles.input} mask={`+${selectedTelcod} 9999999999999 `} placeholder={`+${selectedTelcod}  ___ - __ - __`} onChange={formik.handleChange} id="number" name="number">
            </InputMask>
            <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="number">{t('forms.field.phoneNumber')}*</label>
            {formik.errors.number && <div className={styles.error_message}>{formik.errors.number}</div>}
          </div>
          <div className={styles.input_box}>
            <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="email">Email*</label>
            <input className={styles.input} type="text" id="email" name="email" onChange={formik.handleChange} value={formik.values.email} placeholder="example@email.com" />
            {formik.errors.email && <div className={styles.error_message}>{formik.errors.email}</div>}
          </div>
          <div className={currentLanguage === 'per' ? `${styles.input_box} ${styles.input_password} ${styles.input_password__pers}` : `${styles.input_box} ${styles.input_password}`}>
            <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="password" title={t('parol-mozhet-soderzhat')}>{t('forms.field.password')}* {t('parol-mozhet-soderzhat')}</label>
            <input className={styles.input} type={type} id="password" name="password" onChange={formik.handleChange} value={formik.values.password} placeholder={t('forms.field.enter')} />
            <div className={styles.password__view__toggle} onClick={togglePassInput}>{passwordView}</div>
            {formik.errors.password && <div className={styles.error_message}>{formik.errors.password}</div>}
          </div>
          <div className={currentLanguage === 'per' ? `${styles.input_box} ${styles.input_password} ${styles.input_password__pers}` : `${styles.input_box} ${styles.input_password}`}>
            <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="password2">{t('forms.field.repeatPassword')}*</label>
            <input className={styles.input} type={typeConfirm} id="password2" name="password2" onChange={formik.handleChange} value={formik.values.password2} placeholder={t('forms.field.enter')} />
            <div className={styles.password__view__toggle} onClick={togglePassConfirmInput}>{passwordConfirmView}</div>
            {formik.errors.password2 && <div className={styles.error_message}>{formik.errors.password2}</div>}
          </div>
          {/* <div className={styles.input_box}>
            <label className={currentLanguage === 'per' ? `${styles.input_label} ${styles.input_label__pers}` : `${styles.input_label} `} htmlFor="verification">{t('forms.field.verification')}*</label>
            <span className={(file !== null) ? `${styles.input_file_text_hidden}` : `${styles.input_file_text}`} type="text">{(file !== null) ? file.name : (userRole === "Физическое лицо") ? t('forms.field.passport') : t('forms.field.certificate')}</span>
                       <input className={currentLanguage === 'per' ? `${styles.input} ${styles.input_verification} ${styles.input_verification_per}` : `${styles.input} ${styles.input_verification}`} type="file" id="verification" name="verification" onChange={selectFile} placeholder={userRole === "Физическое лицо" ? t('forms.field.passport') : t('forms.field.certificate')} />
            {formik.errors.verification && <div className={styles.error_message}>{formik.errors.verification}</div>}
          </div> */}
          <p className={styles.form_text}>*{t('forms.field.required')}</p>
          <div className={styles.input_box}>
            <input className={styles.check} type="checkbox" id="datapolicy" name="datapolicy" onChange={() => setDatapolicy(!datapolicy)} />
            <label className={styles.check_label} htmlFor="datapolicy">{t('forms.field.confirmation')}</label>
            {formik.errors.datapolicy && <div className={styles.error_message_check}>{formik.errors.datapolicy}</div>}
            <NavLink to={`/pravila/verifikaciya`} target="_blank">
              <p className={`${styles.input_label_link} `}>{t('pravila-proverki-profilya')}</p>
            </NavLink>
          </div>
          <button type="submit" className={styles.button_long} >{t('forms.field.register')}</button>
        </div>
      </form>
    </div>
  )
}
export default RegistrationFl;

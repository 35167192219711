import styles from './main-promotions.module.css';
// import styles from './main-promotions-pers.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigation } from "swiper";
import 'swiper/css';
import { ModalPromo } from '../modal-promotions';
import CardMainPromo from '../card-main-promo/card-main-promo';
import { openModalPromo } from '../../redux/reducers/modal-promo';
import { useTranslation } from "react-i18next";



export function MainPromo() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(state => state.language.language);
  const modalPromo = useSelector(state => state.modalPromo.modalPromo);
  const [period, setPeriod] = useState('');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  function openModalPromoWindow() {
    // setPeriod(period)
    // setTitle(title)
    // setSubtitle(subtitle)
    // dispatch(openModalPromo());
  }

  return (
    <div className={styles.promo_wrapper} id="promo">
      <div className="inner">
        <h2 className={`${styles.title} section__title`} >{t('main.promotions.title')}</h2>
        <div className={styles.promo__slider}>
          {/* стрелки для слайдера  - раскомментировать когда будет слайдов больше одного */}
          {/* стрелки для слайдера  - закомментировать  если не нужны */}


          <div className={currentLanguage === 'per' ? `${styles.prev__pers}  prev ` : `${styles.prev} prev`}> &#60; </div>
          <div className={currentLanguage === 'per' ? `${styles.next__pers}  next` : `${styles.next} next`}> &#62; </div>
          <Swiper
            spaceBetween={32}
            slidesPerView={1}
            modules={[Navigation]}
            navigation={{
              nextEl: ".next",
              prevEl: ".prev",
              disabledClass: "swiper-button-disabled"
            }}
            breakpoints={{
              540: {
                slidesPerView: 2,
              },
              1100: {
                slidesPerView: 3,
              },
              1400: {
                slidesPerView: 4,
              },
            }}
          >
            <SwiperSlide className={styles.swiper_slide}>

              <CardMainPromo click={openModalPromoWindow}
                // period={t('main.ptomotions.period3m')}
                period={t('main.ptomotions.period15d')}
                title={t('main.promotions.offer1Title')}
                subtitle={t('main.promotions.offer1Text')} />
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
              <CardMainPromo click={openModalPromoWindow} period=""
                title="100"
                // subtitle={t('main.promotions.offer1Text')} 
                subtitle={t('main.promotions.offer2Text')} />
            </SwiperSlide >

            {/* слайды для других акций, которых пока нет */}


            {/* <SwiperSlide className={styles.swiper_slide}>
              <CardMainPromo click={openModalPromoWindow} period="до 31 мая 2023 года"
                title="Название акции и спецпредложения"
                subtitle="Текст акции в две строки /полный текст открывается в модальном окне" />
            </SwiperSlide>
            <SwiperSlide className={styles.swiper_slide}>
              <CardMainPromo click={openModalPromoWindow} period="до 31 мая 2023 года"
                title="Название акции и спецпредложения"
                subtitle="Текст акции в две строки /полный текст открывается в модальном окне" />
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>
      {/* {modalPromo && <ModalPromo period={period} title={title} subtitle={subtitle}/>} */}
      {modalPromo && <ModalPromo period={period} title={title} subtitle={subtitle} />}
    </div >
  )
}
export default MainPromo;
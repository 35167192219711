import { SemipolarLoading } from 'react-loading';
import './../index.css'
import React from 'react';


// fallback style
export function FallbackLoading() {
  return (
    <div className='App-header'>
      {/* <SemipolarLoading color='orange' size='large' /> */}
      Loading
    </div>
  )
}
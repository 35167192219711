import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countryFrom: "",
  countryFromId: "",
  countryTo: "",
  countryToId: "",
  cityFrom: "",
  cityTo: "",
};

export const geoCargoSlice = createSlice({
  name: 'geoCargo',
  initialState,
  reducers: {
    setCountryFrom: (state, action) => {
      return {
        ...state,
        countryFrom: action.payload,
      }
    },
    setCountryFromId: (state, action) => {
      return {
        ...state,
        countryFromId: action.payload,
      }
    },
    setCountryTo: (state, action) => {
      return {
        ...state,
        countryTo: action.payload,
      }
    },
    setCountryToId: (state, action) => {
      return {
        ...state,
        countryToId: action.payload,
      }
    },
    setCityFrom: (state, action) => {
      return {
        ...state,
        cityFrom: action.payload,
      }
    },
    setCityTo: (state, action) => {
      return {
        ...state,
        cityTo: action.payload,
      }
    }
  }
});


export const { setCountryFrom, setCountryFromId,  setCityFrom, setCityTo, setCountryTo, setCountryToId } = geoCargoSlice.actions;
export const geoCargoReducer = geoCargoSlice.reducer;
import styles from './box-language.module.css'
import rus from './images/rus.jpg'
import eng from './images/eng.jpg';
import per from './images/per.jpg';
import arrow from './images/arrow.svg';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cookies from 'js-cookie';
import i18next from 'i18next';
import useOnClickOutside from '../../hooks/onClickOutside';
import { saveLanguage } from '../../redux/reducers/language-reducer';


export function BoxLanguage() {
  const dispatch = useDispatch();
  const langEl = useRef();
  const [isOpenlang, setIsOpenLang] = useState(false);
  const currentLanguage = useSelector(state => state.language.language);

  function changeLangWindow() {
    if (!isOpenlang) {
      setIsOpenLang(true)
    } else {
      setIsOpenLang(false)
    }
  }

  useOnClickOutside(langEl, () => {
    if (isOpenlang) {
      setIsOpenLang(false)
    }
  });

  useEffect(() => {
    dispatch(saveLanguage(cookies.get('i18next')))
  })

  const currentLanguageCode = cookies.get('i18next') || 'ru'

  return (
    <div className={currentLanguage === 'per' ? `${styles.boxWrapper__pers}` : `${styles.boxWrapper}`} ref={langEl} onClick={changeLangWindow}>
      <div className={styles.mainLang}>
        {currentLanguageCode === 'ru' && <img src={rus} alt="rus" className={styles.box_img} />}
        {currentLanguageCode === 'en' && <img src={eng} alt="eng" className={styles.box_img} />}
        {currentLanguageCode === 'per' && <img src={per} alt="per" className={styles.box_img} />}
        <div className={styles.arrow}  >
          <img src={arrow} alt="lang" className={styles.box_arrow} />
        </div>
      </div>
      <div className={isOpenlang ? `${styles.langList}` : `${styles.langListNone}`}>
        <img src={rus} alt="rus" onClick={() => {
          i18next.changeLanguage('ru')
          changeLangWindow()
        }} className={currentLanguageCode === 'ru' ? `${styles.lang_active}` : `${styles.lang_passive}`} />
        <img src={eng} alt="eng" onClick={() => {
          i18next.changeLanguage('en')
          changeLangWindow()
        }} className={currentLanguageCode === 'en' ? `${styles.lang_active}` : `${styles.lang_passive}`} />
        <img src={per} alt="per" onClick={() => {
          i18next.changeLanguage('per')
          changeLangWindow()
        }} className={currentLanguageCode === 'per' ? `${styles.lang_active}` : `${styles.lang_passive}`} />
      </div>
    </div>
  )

}
export default BoxLanguage;

export const docsItems = [
  { id: 19, value: 'CMR', label: 'CMR',  english: "CMR", persian: "CMR" },
  { id: 20, value: 'T1', label: 'T1', english: "T1", persian: "T1" },
  { id: 21, value: 'TIR', label: 'TIR', english: "TIR", persian: "TIR" },
  { id: 22, value: 'Мед. книжка (Санпаспорт)', label: 'Мед. книжка (Санпаспорт)', english: "Certificate of hygiene ", persian: "کارت معاینه سلامت راننده" },
  { id: 23, value: 'Таможенный перевозчик', label: 'Таможенный перевозчик', english: "Customs carrier ", persian: "مجوز گمرکی بار(محموله)" },
  { id: 24, value: 'Лицензия на грузоперевозки', label: 'Лицензия на грузоперевозки', english: "Cargo transportation license", persian: "مجوز حمل بار" },
  { id: 25, value: 'Свидетельство компании', label: 'Свидетельство компании', english: "Company Certificate ", persian: "مدارک مثبته شرکت" },
  { id: 26, value: 'CMR-страхование', label: 'CMR-страхование', english: "CMR Insurance ", persian: "بیمه مربوط به سی ام آر(سمر)" },
  { id: 27, value: 'FRC', label: 'FRC', english: "FRC", persian: "FRC" },
];
// export const docsItems = [
//   { id: 1, value: 'CMR', label: 'CMR',  english: "CMR", persian: "CMR" },
//   { id: 2, value: 'T1', label: 'T1', english: "T1", persian: "T1" },
//   { id: 3, value: 'TIR', label: 'TIR', english: "TIR", persian: "TIR" },
//   { id: 4, value: 'Мед. книжка (Санпаспорт)', label: 'Мед. книжка (Санпаспорт)', english: "certificate of hygiene ", persian: "کارت معاینه سلامت راننده" },
//   { id: 5, value: 'Таможенный перевозчик', label: 'Таможенный перевозчик', english: "Customs carrier ", persian: "مجوز گمرکی بار(محموله)" },
//   { id: 7, value: 'Лицензия на грузоперевозки', label: 'Лицензия на грузоперевозки', english: "Cargo transportation license", persian: "مجوز حمل بار" },
//   { id: 8, value: 'Свидетельство компании', label: 'Свидетельство компании', english: "Company Certificate ", persian: "مدارک مثبته شرکت" },
//   { id: 9, value: 'CMR-страхование', label: 'CMR-страхование', english: "CMR Insurance ", persian: "بیمه مربوط به سی ام آر(سمر)" },
//   { id: 10, value: 'FRC', label: 'FRC', english: "FRC", persian: "FRC" },
// ];

export const transportItems = [
  { id: 1, value: 'открытый', label: 'открытый' },
  { id: 2, value: 'крытый', label: 'крытый' },
  { id: 3, value: 'цистерна с выбором материала закрывающего борт', label: 'цистерна с выбором материала закрывающего борт' },
  { id: 4, value: 'тент', label: 'тент' },
  { id: 5, value: 'бус', label: 'бус' },
];

export const loadingItems = [
  { id: 8, value: 'верхняя', label: 'верхняя',  english: 'top', persian: 'بالایی' },
  { id: 9, value: 'задняя', label: 'задняя' ,  english: 'back', persian: 'عقبی(پشتی)'},
  { id: 10, value: 'со снятием стоек', label: 'со снятием стоек' ,  english: 'with racks removal', persian: 'بدون قفسه (راک)'},
  { id: 11, value: 'без ворот', label: 'без ворот',  english: 'without gates', persian: 'بدون عبور از گیت' },
  { id: 12, value: 'боковая', label: 'боковая',  english: 'side', persian: 'جانبی ( از بغل)' },
  { id: 13, value: 'c полной растентовкой', label: 'с полной растентовкой' ,  english: 'with full uncovering', persian: 'مشاهده کامل'},
  { id: 14, value: 'гидроборт', label: 'гидроборт',  english: 'tail-gate lift', persian: 'گیت همراه با بالابر' },
];
// export const loadingItems = [
//   { id: 1, value: 'верхняя', label: 'верхняя',  english: 'top', persian: 'بالایی' },
//   { id: 2, value: 'задняя', label: 'задняя' ,  english: 'back', persian: 'عقبی(پشتی)'},
//   { id: 3, value: 'со снятием стоек', label: 'со снятием стоек' ,  english: 'with racks removal', persian: 'بدون قفسه (راک)'},
//   { id: 4, value: 'без ворот', label: 'без ворот',  english: 'without gates', persian: 'بدون عبور از گیت' },
//   { id: 5, value: 'боковая', label: 'боковая',  english: 'side', persian: 'جانبی ( از بغل)' },
//   { id: 6, value: 'c полной растентовкой', label: 'с полной растентовкой' ,  english: 'with full uncovering', persian: 'مشاهده کامل'},
//   { id: 7, value: 'гидроборт', label: 'гидроборт',  english: 'tail-gate lift', persian: 'گیت همراه با بالابر' },
// ];
//danger - 1, other - multi
export const cargoDangerItems = [
  // { id: 0, value: '', label: 'не выбран' },
  // { id: 1, value: 'неопасный', label: 'неопасный' },
  // { id: 2, value: 'опасный', label: 'опасный' },
  { id: 3, value: 'ADR1', label: 'ADR1' },
  { id: 4, value: 'ADR2', label: 'ADR2' },
  { id: 5, value: 'ADR3', label: 'ADR3' },
  { id: 6, value: 'ADR4', label: 'ADR4' },
  { id: 7, value: 'ADR5', label: 'ADR5' },
  { id: 8, value: 'ADR6', label: 'ADR6' },
  { id: 9, value: 'ADR7', label: 'ADR7' },
  { id: 10, value: 'ADR8', label: 'ADR8' },
  { id: 11, value: 'ADR9', label: 'ADR9' },
];

export const paymentItems = [
  { id: 5, value: 'комб.', label: 'комб.', english: 'combined', persian: 'ترکیب شده'  },
  { id: 6, value: 'безнал.', label: 'безнал.', english: 'non-cash', persian: 'غیر نقدی'  },
  { id: 7, value: 'нал.', label: 'нал.' , english: 'cash', persian: 'پول نقد' },
  { id: 8, value: 'карта', label: 'карта' , english: 'card', persian: 'کارت' },
];

// export const paymentItems = [
//   { id: 1, value: 'комб.', label: 'комб.', english: 'combined', persian: 'ترکیب شده'  },
//   { id: 2, value: 'безнал.', label: 'безнал.', english: 'non-cash', persian: 'غیر نقدی'  },
//   { id: 3, value: 'нал.', label: 'нал.' , english: 'cash', persian: 'پول نقد' },
//   { id: 4, value: 'карта', label: 'карта' , english: 'card', persian: 'کارت' },
// ];

export const transportCloseBody = [
  { id: 9, value: 'Тент', label: 'Тент', english: "Curtainsider", persian: "سایباندار" },
  { id: 10, value: 'Цельнометаллический фургон(цельномет)', label: 'Цельнометаллический фургон(цельномет)', english: "Box trailer", persian: "فلزی تمام پیچ مهره ای"  },
  { id: 11, value: 'Бус', label: 'Бус', english: "Minibus", persian: "ظروف"  },
  { id: 12, value: 'Контейнер', label: 'Контейнер', english: "Cargo transporter", persian: "کامیون کاوردار"  },
  { id: 13, value: 'Одеждовоз', label: 'Одеждовоз' , english: "Clothing transporter", persian: "" },
  { id: 14, value: 'Изотерм', label: 'Изотерм', english: "Thermo", persian: "ایزوله گرمایشی"  },
  { id: 15, value: 'Рефрежиратор(реф.)', label: 'Рефрежиратор(реф.)', english: "Refrigerator", persian: "یخچال و فریزردار"  },
  { id: 16, value: 'Рефрежиратор-тушевоз (реф.-тушевоз)', label: 'Рефрежиратор-тушевоз (реф.-тушевоз)', english: "Ref.-meat carrier", persian: "یخچالدار"  },
];
export const transportOpenBody = [
  { id: 17, value: 'Бортовая/Открытая', label: 'Бортовая/Открытая', english: "Dropside / Open body", persian: "جانبی/باز (باز از طرفین)" },
  { id: 18, value: 'Платформа', label: 'Платформа', english: "Platform", persian: "پلتفرمی "  },
  { id: 19, value: 'Манипулятор (Кран манипулятор)', label: 'Манипулятор (Кран манипулятор)', english: "Truk with crane", persian: "(جهت بارگیری نیاز به جرثقیل ) جرثقیلی"  },
  { id: 20, value: 'Ломовоз/Металловоз', label: 'Ломовоз/Металловоз', english: "Scrap transport", persian: "ضایعات / فلز"  },
  { id: 21, value: 'Зерновоз/Самосвал', label: 'Зерновоз/Самосвал' , english: "Grain truck", persian: "غلات / کمپرس " },
  { id: 22, value: 'Контейнеровоз', label: 'Контейнеровоз', english: "Container chassis", persian: "کشتی کانتینری"  },
  { id: 23, value: 'Трал/Негабарит', label: 'Трал/Негабарит', english: "Low loader / Oversize", persian: "متاسایز/سایزبزرگ"  },
  { id: 24, value: 'Плитовоз', label: 'Плитовоз', english: "Slabs carrier", persian: "پلیتووز "  },
  { id: 25, value: 'Самосвал', label: 'Самосвал', english: "Dump truck", persian: "کامیون کمپرسی دار"  },
];
export const transportTankTruck = [
  { id: 26, value: 'Кормовоз', label: 'Бортовая/Открытая', english: "Feed truck", persian: "خوراک"},
  { id: 27, value: 'Молоковоз', label: 'Молоковоз', english: "Milk tank vechicle", persian: "حمل شیر کامیون"},
  { id: 28, value: 'Битумовоз', label: 'Битумовоз', english: "Bitument transport", persian: "کامیون حمل قیر"},
  { id: 29, value: 'Газовоз', label: 'Газовоз', english: "Gas carrier truck", persian: "حمل گاز"},
  { id: 30, value: 'Муковоз', label: 'Муковоз', english: "Flour truck", persian: "حمل آرد"},
  { id: 31, value: 'Автоцистерна', label: 'Автоцистерна', english: "Tank truck", persian: "کامیون تانکردار"},
  { id: 32, value: 'Цементовоз', label: 'Цементовоз', english: "Cement truck", persian: "حمل سیمان (بونکر)- حمل سیمان پاکتی"},
  { id: 33, value: 'Масловоз', label: 'Бортовая/Открытая', english: "Oil truck", persian: "ماسلووز "},
  { id: 34, value: 'Бензовоз', label: 'Бензовоз', english: "Petrol transport", persian: "تانکر بنزین "},
 ]

export const transportPassengers = [
   { id: 35, value: 'Микроавтобус', label: 'Микроавтобус', english: "Minibus", persian: "مینی بوس"},
   { id: 36, value: 'Автобус', label: 'Автобус', english: "Bus", persian: "اتوبوس"},
  ]

export const transportSpecial = [
   { id: 37, value: 'Автовоз', label: 'Автовоз', english: "Car transporter", persian: "حمل خودرو- خودروهای سبک"},
   { id: 38, value: 'Автовышка', label: 'Автовышка', english: "Truck-mounted platform", persian: "برجدار/ سکوهای صنعتی ماشین "},
   { id: 39, value: 'Бетоновоз', label: 'Бетоновоз', english: "Concrete mixer", persian: "کامیون حمل بتون "},
   { id: 40, value: 'Лесовоз', label: 'Лесовоз', english: "Timber truck", persian: "کامیون حمل چوب"},
   { id: 41, value: 'Коневоз', label: 'Коневоз', english: "Horse transport", persian: "کامیون حمل اسب "},
   { id: 42, value: 'Кран', label: 'Кран', english: "Crane", persian: "جرثقیل"},
   { id: 43, value: 'Мусоровоз', label: 'Мусоровоз', english: "Garbage truck", persian: "کامیون حمل زباله"},
   { id: 44, value: 'Погрузчик', label: 'Погрузчик', english: "Forklift", persian: "لودر و ماشین آلات راهسازی"},
   { id: 45, value: 'Птицевоз', label: 'Птицевоз', english: "Poultry transport", persian: "کامیون حمل مرغ"},
   { id: 46, value: 'Скотовоз', label: 'Скотовоз', english: "Livestock trailer", persian: "کامیون حمل گاو و احشام"},
   { id: 47, value: 'Стекловоз', label: 'Стекловоз', english: "Glass transport", persian: "حمل شیشه و مصنوعات شیشه ای"},
   { id: 48, value: 'Трубовоз', label: 'Трубовоз', english: "Pipe transport", persian: "حمل لوله و مصنوعات لوله ای ( پلی اتیلنی-فلزی و ...)"},
   { id: 49, value: 'Тягач', label: 'Тягач', english: "Tractor", persian: "تراکتور و ماشین آلات سنگین و نیمه سنگین"},
   { id: 50, value: 'Щеповоз', label: 'Щеповоз', english: "Wood chip truck", persian: "کامیون حمل محصولات الکتریکی و الکترونیکی"},
   { id: 51, value: 'Эвакуатор', label: 'Эвакуатор', english: "Tow truck", persian: "کامیون یدک کش"},
   { id: 52, value: 'Яхтовоз', label: 'Яхтовоз', english: "Yacht transpoter", persian: "حمل تجهیزات و مصنوعات دریایی"},
]

import styles from './404.module.css'
// import styles from './tariff-pers.module.css'
import icon from "./images/icon-attention.png"
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function NotFoundPage() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={styles.wrapper}>
      <div className="inner">
        <h2 className={`${styles.title}`} >404</h2>
        <h2 className={`${styles.title}`} > Page not found</h2>
        <div className={styles.notice__card}>
          <div className={styles.notice__text__link}
            onClick={() => navigate("/")}>
            Вернуться на главную
          </div>
        </div>
      </div>
    </main>
  )
}
export default NotFoundPage;
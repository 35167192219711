/* eslint-disable react-hooks/exhaustive-deps */
import styles from './chat.module.css';
import dots from './image/dots.svg';
import close from './image/close.svg';
import user from './image/user.svg';
import search from './image/search.svg';
import { openChat, openChatRoom } from '../../../redux/reducers/chat-reducer';
import { saveMessages } from '../../../redux/reducers/messages-reducer';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDialog } from '../../../http/dialogAPI';
import { ChatRoom } from '../chat-room';
import { useEffect } from 'react';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { BaseURL } from "../../../http";

export function Chat({ username }) {
  const { t } = useTranslation();
  const currentLanguage = useSelector(state => state.language.language);
  const myDialogs = useSelector(state => state.myDialogs.myDialogs);
  const chatRoom = useSelector(state => state.chatIsOpen.chatRoomIsOpen);
  const currentSelectedUser = useSelector(state => state.currentSelectedUser.currentSelectedUser);
  const currentSelectedUserDialog = useSelector(state => state.currentSelectedUser.currentSelectedUserDialog);
  const countUnreadMessages = useSelector(state => state.unreadMessages.unreadDialogs);
  const [newChatIsOpen, setnewChatIsOpen] = useState(false);
  const [searchIsOpen, setsearchIsOpen] = useState(false);
  const [name, setSearchValue] = useState("");
  const [foundNameUser, setFoundNameUser] = useState(currentSelectedUserDialog ? currentSelectedUser : "");
  const userData = useSelector(state => state.userData.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (foundNameUser && currentSelectedUserDialog) {
      chekDialog(foundNameUser)
    }
  }, [])

  async function searchUser() {
    try {
      if (name === "") {
        return
      } else {
        const foundUser = await axios.get(`${BaseURL}/api/user/getuser/${name}`)
        if (foundUser.data.length === 0) {
          setFoundNameUser(null)
        } else {
          setFoundNameUser(foundUser.data)
        }
      }
    }
    catch (e) {
      console.log(e.response?.data?.message)
    }
  }
  async function getAllMessages(dialogId, name, user) {
    const response = await axios.get(`${BaseURL}/api/message/dialog/${dialogId}`)
    dispatch(saveMessages(response.data));
    dispatch(openChatRoom({ chatRoomId: dialogId, chatRoomUserName: name, chatRoomUserId: user }));
  }

  function getExistDialog(foundUser) {
    for (let dialog of myDialogs) {
      if ((dialog.author === userData.id && dialog.partner === foundUser.id) || (dialog.author === foundUser.id && dialog.partner === userData.id)) {
        return dialog
      }
    }
    return null
  }

  async function chekDialog(foundUser) {

    if (myDialogs.length > 0) {

      let dialog = getExistDialog(foundUser)
      if (dialog === null) {
        let createdDialog = await createDialog({ author: userData.id, partner: foundUser.id })
        dispatch(openChatRoom({ chatRoomId: createdDialog.data.id, chatRoomUserName: foundUser.name, chatRoomUserId: foundUser.id }))
      } else {
        getAllMessages(dialog.id, dialog.dataValues.name, dialog.dataValues.id);
      }
    } else {
      let createdDialog = await createDialog({ author: userData.id, partner: foundUser.id })
      dispatch(openChatRoom({ chatRoomId: createdDialog.data.id, chatRoomUserName: foundUser.name, chatRoomUserId: foundUser.id }))
    }
  }


  return (
    <div className={currentLanguage === 'per' ? `${styles.wrapper__pers} ${styles.wrapper}` : `${styles.wrapper} `}>
      {chatRoom ? <ChatRoom username={userData.name} /> :
        <div className={styles.chat}>
          <div className={styles.chat_header}>
            {searchIsOpen ? t('chat.header.newText') : t('chat.header.messagesText')}
            <div className={styles.newchat_btn} onClick={() => { setsearchIsOpen(true); setnewChatIsOpen(false) }}>
                <img className={styles.newchat_icon} src={user} alt="user" />
                {t('chat.header.newText')}
              </div>
            {/* <img className={styles.chat_header_dots} src={dots} alt="dots" onClick={() => setnewChatIsOpen(!newChatIsOpen)} /> */}
            <img className={styles.chat_header_close} src={close} alt="close" onClick={() => dispatch(openChat())} />
            {/* {newChatIsOpen &&
              <div className={styles.newchat_btn} onClick={() => { setsearchIsOpen(true); setnewChatIsOpen(false) }}>
                <img className={styles.newchat_icon} src={user} alt="user" />
                {t('chat.header.newText')}
              </div>
            } */}
          </div>
          <div className={styles.chatbody}>
            {searchIsOpen ? <div>
              <div className={styles.search_input}>
                <input className={styles.header__search_input} type="text" name="search" id="search" placeholder={t('placeholder.chat.userSearch')} value={name} onChange={(event) => setSearchValue(event.target.value)} />
                <div className={styles.header__functional_item} onClick={() => { searchUser(); setSearchValue("") }} >
                  <img src={search} alt="search" />
                </div>
              </div>
              {foundNameUser &&
                <>
                  {foundNameUser.map((foundUser, index) => (
                    <div key={index + "user"} className={currentLanguage === 'per' ? `${styles.chat_user__pers} ${styles.chat_user}` : `${styles.chat_user} `} onClick={() => { chekDialog(foundUser) }}>
                      {foundUser.role === "Юридическое лицо" ?
                        <strong> {foundUser.name}, {foundUser.orgname} </strong> :
                        <strong> {foundUser.name}  {foundUser.surname} </strong>
                      }
                    </div>
                  ))}
                </>
              }
              {foundNameUser === null &&
                <div className={styles.chat_notuser}>Пользователь не найден</div>
              }
            </div> :
              <div>
                {myDialogs.map((dialog, index) => (
                  <div key={index} className={currentLanguage === 'per' ? `${styles.chat_user__pers} ${styles.chat_user}` : `${styles.chat_user} `} onClick={() => { getAllMessages(dialog.id, dialog.dataValues.name, dialog.dataValues.id); }}>
                    <strong> {dialog.dataValues.name}</strong>
                    {dialog.id in countUnreadMessages && <div className={styles.header__new_message}>
                      {countUnreadMessages[dialog.id]}
                    </div>}
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
      }
    </div >
  )
}

export default Chat;

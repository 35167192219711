import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: "ru",
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    saveLanguage(state, action) {
      state.language = action.payload;
    }
  
  },
});


export const { saveLanguage } = languageSlice.actions;
export const languageReducer = languageSlice.reducer;
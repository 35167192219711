import styles from './modal-registr.module.css';
// import styles from './modal-registr-pers.module.css';
import ReactDOM from 'react-dom';
import Button from '../button/button';
import { ReactComponent as Person } from "./images/registr/01.svg";
import { ReactComponent as Organization } from "./images/registr/02.svg";
import { ReactComponent as Individual } from "./images/registr/03.svg";
import { ReactComponent as Carrier } from "./images/work/auto.svg";
import { ReactComponent as Shipper } from "./images/work/box.svg";
import { ReactComponent as Forwarder } from "./images/work/truck.svg";
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeRegistrForm } from '../../redux/reducers/registration-reducer';
import { openRegistrForm2 } from '../../redux/reducers/registration-stage2-reducer';
import { setUserRole, setUserWork } from '../../redux/reducers/user-role-reducer';

export function ModalRegistr() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const registrationFormOpen = useSelector(state => state.registrationForm.registrationForm);

  function changeRegistrationFormOpen() {
    dispatch(closeRegistrForm());
    dispatch(openRegistrForm2());
  }

  function closeAllModal() {
    dispatch(closeRegistrForm());
  }

  const [regType, setRegType] = useState("");
  const [workType, setworkType] = useState("");
  const portal = document.getElementById('portal');
  const currentLanguage = useSelector(state => state.language.language);

  return ReactDOM.createPortal(
    <>
      <div className={styles.darkBG} onClick={() => dispatch(closeAllModal)} />
      {
        (!registrationFormOpen) &&
        <div className={styles.centered}>

          <div className={currentLanguage === 'per' ? `${styles.modal__pers} ${styles.modal}` : `${styles.modal} `}>
            <div className={styles.modalHeader}>
              <p className={styles.heading}>{t('modal.registr.tellAbout')}</p>
            </div>
            <div className={styles.modalContent}>
              <p>{t('modal.registr.typeOfRegistr')}</p>
              <div className={styles.registration__items}>
                <div >
                  <input type="radio" className={styles.regisr_type__radio}
                    id="person" name="registration-type" checked={regType === "person"} />
                  <label htmlFor="person" className={currentLanguage === 'per' ? `${styles.regisr_type__label__pers} ${styles.regisr_type__label}` : `${styles.regisr_type__label} `} onClick={() => { dispatch(setUserRole('Физическое лицо')); setRegType('person') }}>
                    <div className={regType === "person" ? `${styles.registration__item} ${styles.active}` : `${styles.registration__item}`}>
                      <Person className={styles.item__logo}></Person>
                      {t('select.role.individual')}
                    </div>
                  </label> </div>

                <div>
                  <input type="radio" className={styles.regisr_type__radio}
                    id="organization" name="registration-type" checked={regType === "organization"} />
                  <label htmlFor="organization" className={currentLanguage === 'per' ? `${styles.regisr_type__label__pers} ${styles.regisr_type__label}` : `${styles.regisr_type__label} `} onClick={() => { dispatch(setUserRole('Юридическое лицо')); setRegType('organization') }}>
                    <div className={regType === "organization" ? `${styles.registration__item} ${styles.active}` : `${styles.registration__item}`}>
                      <Organization className={styles.item__logo}></Organization>
                      {t('select.role.legal')}
                    </div>
                  </label>
                </div>
                <div>
                  <input type="radio" className={styles.regisr_type__radio}
                    id="individual" name="registration-type" checked={regType === "individual"} />
                  <label htmlFor="individual" className={currentLanguage === 'per' ? `${styles.regisr_type__label__pers} ${styles.regisr_type__label}` : `${styles.regisr_type__label} `} onClick={() => { dispatch(setUserRole('Предприниматель')); setRegType('individual') }}>

                    <div className={regType === "individual" ? `${styles.registration__item} ${styles.active}` : `${styles.registration__item}`}>
                      <Individual className={styles.item__logo}></Individual>
                      {t('select.role.entrepreneur')}
                    </div>
                  </label>
                </div>
              </div> <p>{t('modal.registr.typeOfActivity')}</p>
              <div className={styles.registration__items}>
                <div>
                  <input type="radio" className={styles.regisr_type__radio}
                    id="carrier" name="working-type" checked={workType === "carrier"} />
                  <label htmlFor="carrier" className={currentLanguage === 'per' ? `${styles.regisr_type__label__pers} ${currentLanguage === 'per' ? `${styles.regisr_type__label__pers} ${styles.regisr_type__label}` : `${styles.regisr_type__label} `}` : `${currentLanguage === 'per' ? `${styles.regisr_type__label__pers} ${styles.regisr_type__label}` : `${styles.regisr_type__label} `} `} onClick={() => { dispatch(setUserWork('Грузоотправитель')); setworkType('carrier') }}>
                    <div className={workType === "carrier" ? `${styles.registration__item} ${styles.active}` : `${styles.registration__item}`}>
                      <Shipper className={styles.item__logo}></Shipper>
                      {t('select.activity.shipper')}
                    </div>
                  </label>
                </div>
                <div>
                  <input type="radio" className={styles.regisr_type__radio}
                    id="shipper" name="working-type" checked={workType === "shipper"} />
                  <label htmlFor="shipper" className={currentLanguage === 'per' ? `${styles.regisr_type__label__pers} ${styles.regisr_type__label}` : `${styles.regisr_type__label} `} onClick={() => { dispatch(setUserWork('Перевозчик')); setworkType('shipper') }}>
                    <div className={workType === "shipper" ? `${styles.registration__item} ${styles.active}` : `${styles.registration__item}`}>
                      <Carrier className={styles.item__logo}></Carrier>
                      {t('select.activity.carrier')}
                    </div>
                  </label>
                </div>
                <div>
                  <input type="radio" className={styles.regisr_type__radio}
                    id="forwarder" name="working-type" checked={workType === "forwarder"} />
                  <label htmlFor="forwarder" className={currentLanguage === 'per' ? `${styles.regisr_type__label__pers} ${styles.regisr_type__label}` : `${styles.regisr_type__label} `} onClick={() => { dispatch(setUserWork('Экспедитор')); setworkType('forwarder') }}>
                    <div className={workType === "forwarder" ? `${styles.registration__item} ${styles.active}` : `${styles.registration__item}`}>
                      <Forwarder className={`${styles.item__logo} ${styles.item__logo_forwarder}`}></Forwarder>
                      {t('select.activity.forwarder')}
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className={styles.modalActions}>
              <div className={currentLanguage === 'per' ? `${styles.actionsContainer__pers} ${styles.actionsContainer}` : `${styles.actionsContainer} `}>
                <Button click={changeRegistrationFormOpen}>{t('modal.registr.further')}</Button>
              </div>
            </div>
          </div>
        </div >
      }
    </>,
    portal
  )
}
export default ModalRegistr;